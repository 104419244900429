import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import References from "../../../../pages/Profile/Documentation/References";
import { DocumentsPagesContainerProps } from "../../../../interfaces/containers";
import { editUserRequest } from "../../../../actions/auth";
import { getIsValidDocuments } from "../../../../actions/documents";

function mapStateToProps(state: DocumentsPagesContainerProps) {
    return{
        user: state.auth.user,
        fetchingEdit: state.auth.fetchingEdit,
        types: state.documents.types
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        editUserRequest,
        getIsValidDocuments
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(References);