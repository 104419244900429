import { useState } from "react";
import styles from "./suspenseLoader.module.scss";
import { images } from "../../constants/images";
import { LinearProgress } from "@mui/material";

const SuspenseLoader = () => {

    return (
        <div className={styles.suspenseLoader}>
            <img src={images.isoLogoTipoAnimated} className={styles.text} alt="logo" />
            <LinearProgress
                className={styles.loader}
                classes={{
                    barColorPrimary: styles.barColor
                }}
            />
        </div>
    )
}
export default SuspenseLoader;