import { useEffect, useState } from 'react';
import styles from './favorite.module.scss';
import { IconButton } from '@mui/material';
import { FavoriteSharedProps } from '../../interfaces/components';
import { useToast } from "../../helpers/hooks";
import { useNavigate } from "react-router-dom";
import { Skeleton } from '@mui/material';
import { images } from '../../constants/images';

const FavoriteShared = ({
    onPress,
    id, 
    liked = false,
    className,
    user,
    likedFetching,
    likePublication,
    dislikePublication,
    loading = false,
    redirect = "",
    setLoginRedirection,
    withText = false,
    color = "black",
    size = "normal"
}: FavoriteSharedProps) => {
    const toast = useToast();
    const navigate = useNavigate();
    const [likedIcon, setLikedIcon] = useState<boolean>(liked);

    const handleChangeFavorite = (event: any, idItem: string) => {
        event.preventDefault();
        if (!user) {
            toast("Debes iniciar sesion");
            setLoginRedirection(redirect);
            return navigate("/login")
        };

        if(likedFetching === "loading"){
            return;
        }

        if (likedIcon) {
            dislikePublication(idItem);
        } else {
            likePublication(idItem);
        }

        setLikedIcon(!likedIcon);
        if(onPress) onPress(event);
    }

    useEffect(() => {
        setLikedIcon(liked);
    }, [liked]);
    
    useEffect(() => {
        if(likedFetching === 'failure'){
            toast("Ha ocurrido un error");
        }
        if(likedFetching === 'success'){
            setLikedIcon(liked);
        }
    }, [likedFetching]);

    return (
        <>
        {
            loading
            ? <Skeleton animation="wave" variant="circular" className={styles.skeleton} />
            : 
            <div className={`${styles.favorite} ${className} ${color === "black" ? styles.black : styles.blue} ${size === "small" && styles.small}`} onClick={(event: any) => handleChangeFavorite(event, id)}>
                <IconButton id="icon" className={className}>
                    <img src={liked ? images.favorite : images.noFavorite} alt="Me gusta" title="Me gusta" id="iconFavorite" className={styles.icon} />
                </IconButton>
                {
                    withText &&
                    <p className={styles.text}>Favoritos</p>
                }
            </div>
        }
        </>
    );
};

export default FavoriteShared;