import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { PaymentsContainerProps } from "../../../../../interfaces/containers";
import Payments from "../../../../../pages/Profile/MyBuys/Management/Payments";
import { 
    cleanGetCreditData,
    cleanGetPayments, 
    postPaymentDocument,
    cleanPostPaymentDocument,
    postPayment,
    cleanPostPayment
} from "../../../../../actions/profile";

function mapStateToProps(state: PaymentsContainerProps) {
    return{
        payments: state.profile.payments,
        myBuy: state.profile.myBuy,
        publication: state.publication
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        cleanGetCreditData,
        cleanGetPayments,
        postPaymentDocument,
        cleanPostPaymentDocument,
        postPayment,
        cleanPostPayment
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Payments);