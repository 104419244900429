import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { BuyProps } from "../../../interfaces/containers";
import AdditionalCosts from "../../../pages/Buy/AdditionalCosts/AdditionalCosts";
import { setStep, getAdditionalExpensesTransaction, setCleanAll } from "../../../actions/buy";

function mapStateToProps(state: BuyProps) {
    return{
        step: state.buy.step,
        method: state.buy.method,
        publication: state.buy.publication,
        additionalCost: state.buy.additionalCost
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        getAdditionalExpensesTransaction,
        setStep,
        setCleanAll
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalCosts);