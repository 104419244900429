import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { MyPublicationEditContainerProps } from "../../../../interfaces/containers";
import EntryPoint from "../../../../pages/Profile/MyPublications/Edit";
import {
    getPublication, 
    getArticlesFromPublication, 
    setUploadImages,
    updatePublication, 
    cleanUpdate, 
    cleanAll, 
    postPublicationImages, 
    deletePublicationImage,
    cleanImagesSaved
} from "../../../../actions/publication";
import { getModels, getVersions } from "../../../../actions/publications";
import { getMyPublicationsRequest } from "../../../../actions/profile";

function mapStateToProps(state: MyPublicationEditContainerProps) {
    return{
        publication: state.publication,
        models: state.publications.models,
        versions: state.publications.versions,
        updateReducer: state.publication.update
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        getModels,
        getVersions,
        getPublication,
        getArticlesFromPublication,
        setUploadImages,
        updatePublication,
        cleanAll,
        cleanUpdate,
        postPublicationImages,
        deletePublicationImage,
        cleanImagesSaved,
        getMyPublicationsRequest
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(EntryPoint);