import styles from "./modalValidationDocuments.module.scss";
import ButtonShared from '../Button/Button';
import { useNavigate } from 'react-router-dom';
import Modal from "../Modal/Modal";
import { ModalValidationDocumentsProps } from "../../interfaces/components";
import { useEffect, useState } from "react";

const ModalValidationDocuments = ({ open, setOpen, type }: ModalValidationDocumentsProps) => {
    const navigate = useNavigate();

    const onPressButton = () => {
        if(type === "locationFault"){
            navigate("/profile/my_account");
            return;
        }
        if(type === "documentsFault"){
            navigate("/profile/documentation");
            return;    
        }
        navigate("/buy/schedule_inspection");
    }

    const onClose = () => {
        setOpen({open: false, type: type});
    }
    
    return(
        <Modal open={open}>
            <div className={styles.modalContainer}>
                <p className={styles.message}>
                    {
                        type === "locationFault"
                        ?
                            <>
                                <span className={styles.title}>¡Ya casi tienes la información completa!</span>
                                <span className={styles.description}>Falta que completes tu ubicación</span>
                            </>
                        :
                        type === "documentsFault"
                        ?
                            <>
                                <span className={styles.title}>¡Ya casi tienes la información completa!</span>
                                <span className={styles.description}>Falta que completes tu documentación</span>
                            </>
                        :
                            <>
                                <span className={styles.title}>¡Ya tienes la información completada!</span>
                                <span className={styles.description}>Ahora puedes continuar con tu compra pendiente</span>
                            </>
                    }
                </p>
                <div className={styles.buttons}>
                    <ButtonShared 
                        onPress={onClose}
                        title="Continuar"
                        fourtuary
                        size="small"
                    />
                    <ButtonShared 
                        onPress={onPressButton}
                        title={type === "" ? "Terminar" : "Completar"}
                        secondary
                        size="small"
                    />
                </div>
            </div>
        </Modal>
    )
}

export default ModalValidationDocuments;