import { memo, useEffect, useState } from 'react';
import styles from './scheduleInspection.module.scss';
import { useNavigate } from "react-router-dom";
import { capitalize, nextDatesAvailable } from '../../../helpers/app';
import { useToast } from '../../../helpers/hooks';
import BuyModal from '../../../components/BuyModal';
import ButtonShared from '../../../components/Button';
import TextFieldShared from '../../../components/TextField';
import ToggleButtonShared from '../../../components/ToggleButton/ToggleButton';
import Modal from '../../../components/Modal';
import Slider from '../../../components/Slider';
import { images } from '../../../constants/images';
import { BuyScheduleInspectionProps } from '../../../interfaces/pages';

const ScheduleInspection = ({
    user,
    publication,
    setStep,
    step,
    method,
    transactionFetching,
    postTransaction,
    cleanPostTransaction,
    creditSimulation,
    financing,
    setCleanAll,
    getIsValidDocuments, 
    valid,
    cleanIsValidDocuments,
    setValidationReducer
}: BuyScheduleInspectionProps) => {
    const toast = useToast();
    const navigate = useNavigate();
    const [daysAvailable, setDaysAvailable] = useState<object[]>([]);
    const [selectedDays, setSelectedDays] = useState<any[]>([]);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [validation, setValidation] = useState<any>({ residence: false, documents: false });
    const [openModalSuccess, setOpenModalSuccess] = useState<boolean>(false);
    const [openModalValidation, setOpenModalValidation] = useState<boolean>(false);
    const [direction, setDirection] = useState<any>({value: "", coords: {lat: "", long: ""}, error: ""});

    const handlePressBack = () => {
        setStep(step - 1);
        if(method === "cash"){
            navigate(`/buy/additional_costs`);
        }
        if(method === "credit"){
            navigate("/buy/summary");
        }
    }

    const handlePressDate = (day: any) => {
        const dayFound = selectedDays.find((item: any) => item?.value === day?.value);
        if(dayFound){
            const filterSelectedDays = selectedDays.filter((item: any) => item?.value !== day?.value);
            setSelectedDays(filterSelectedDays);
        }else{
            setSelectedDays([...selectedDays, day]);
        }
    }

    const onPressModalBuy = () => {
        setCleanAll();
        navigate('/');
    }

    const onPressModalValidation = () => {
        setValidationReducer({
            residence: validation?.residence,
            documents: validation?.documents,
            waiting: true
        });
        (!validation?.residence && !validation?.documents)
        ?   navigate("/profile/documentation")
        :   validation?.residence && !validation?.documents
            ?   navigate("/profile/documentation")
            :   navigate("/profile/my_account")
    }

    const handleChangeDirection = ({value, coords}: any) => {
        setDirection({
            ...direction,
            value: value,
            coords: {
                lat: coords?.lat,
                long: coords?.long
            }
        });
    }

    const formatTransactionDates = (days: any) => {
        let formattedDates: any[] = [];
        days && days.map((day: any) => {
            formattedDates.push({
                date_start: new Date(day?.value).toISOString(),
                date_end: new Date(day?.value).toISOString()
            })
        })
        return formattedDates;
    }

    const handleSend = () => {
        if(!validation?.residence || !validation?.documents){
            return setOpenModalValidation(true);
        }
        if(!direction?.value){
            return setDirection({...direction, error: "Debes ingresar una direcciôn"});
        }
        if(selectedDays.length < 3){
            return toast("Debes seleccionar las fechas de inspección");
        }
        setValidationReducer({
            residence: validation?.residence,
            documents: validation?.documents,
            waiting: false
        });
        setLoading(true);
        formatTransactionDates(selectedDays);
        let transaction: any;
        if(method === "credit"){
            transaction = {
                publication: publication?.data?._id,
                dates: formatTransactionDates(selectedDays),
                address: direction?.value,
                coordinates:{
                    lat: direction?.coords?.lat,
                    long: direction?.coords?.long
                },
                type_payment: "financing",
                financing: {
                    type_credit: financing?.creditType,
                    CAE: creditSimulation?.data?.simulation_response?.financing.cae,
                    fee_value: creditSimulation?.data?.simulation_response?.financing?.installment_value,
                    term: creditSimulation?.data?.simulation_request?.credit?.installments_number
                },
                foot_cash: Number(financing?.footValue),
                total_amount: creditSimulation?.data?.simulation_request?.credit?.vehicle_price
            };
        }else{
            transaction = {
                publication: publication?.data?._id,
                dates: formatTransactionDates(selectedDays),
                address: direction?.value,
                coordinates:{
                    lat: direction?.coords?.lat,
                    long: direction?.coords?.long
                },
                foot_cash: publication?.data?.publicationPrice,
                total_amount: publication?.data?.publicationPrice,
                type_payment: "cash"
            };
        }
        postTransaction(transaction);
    }

    useEffect(() => {
        if(direction?.value && direction?.value.length > 0 && selectedDays.length >= 3){
            setDisabled(false);
        }else{
            setDisabled(true);
        }
    }, [direction?.value, selectedDays]);

    useEffect(() => {
        if(transactionFetching === "failure"){
            toast("Ha ocurrido un error al realizar la compra su vehículo");
            setLoading(false);
            cleanPostTransaction();
        }
        if(transactionFetching === "success"){
            setOpenModalSuccess(true);
            setLoading(false);
            cleanPostTransaction();
        }
    }, [transactionFetching]);

    useEffect(() => {
        if(valid?.fetching === "failure"){
            toast("Ha ocurrido un error al calcular la validación de su documentación");
            cleanIsValidDocuments();
        }
        if(valid?.fetching === "success"){
            const documents: boolean = valid?.data?.completed?.bank_data && valid?.data?.completed?.identity_card && valid?.data?.completed?.labor_data && valid?.data?.completed?.references;
            setValidation({ residence: valid?.data?.completed?.residence, documents: documents });
            cleanIsValidDocuments();
        }
    }, [valid?.fetching]);

    useEffect(() => {
        setOpenModalValidation(false);
        window.scrollTo(0, 0);
        document.body.style.overflowY = "auto";
        setDaysAvailable(nextDatesAvailable(3));
        getIsValidDocuments();
    }, []);

    return (
        <>
            <Modal open={openModalValidation}>
                <div className={styles.modalValidation}>
                    <p className={styles.message}>
                        <span className={styles.title}>
                            {` 
                                ${(!validation?.residence && !validation?.documents)
                                ?   "Ubicación y documentación"
                                :   validation?.residence && !validation?.documents
                                    ?   "Documentación"
                                    :   "Ubicación"
                                }
                            incompleta`}
                        </span>
                        <span className={styles.description}>Para poder terminar el proceso de compra</span>
                        <span className={styles.description}>
                            {`Debes terminar de completar tu 
                                ${(!validation?.residence && !validation?.documents)
                                ?   "ubicación y documentación"
                                :   validation?.residence && !validation?.documents
                                    ?   "documentación"
                                    :   "ubicación"
                                }
                            `}
                        </span>
                    </p>
                    <ButtonShared 
                        onPress={onPressModalValidation}
                        title="Completar" 
                        secondary
                    />
                </div>
            </Modal>
            <Modal open={openModalSuccess}>
                <div className={styles.modalBuy}>
                    <p className={styles.message}>
                        <span className={styles.title}>¡Felicitaciones!</span>
                        <span className={styles.subtitle}>Estás a un paso de hacer Match</span>
                        <span className={styles.description}>Enviamos las fechas con éxito.</span>
                        <span className={styles.description}>Te contactaremos para validar tu medio de Pago Vale Vista, el cual tendrá que estar disponible en la fecha agendada para la inspección y entrega del vehículo.</span>
                    </p>
                    <ButtonShared 
                        onPress={onPressModalBuy}
                        title="Completar" 
                        secondary
                    />
                </div>
            </Modal>
            <BuyModal title="Agendar inspección" onBack={handlePressBack} step={step} method={method}>
                <div className={styles.scheduleInspection}>
                    <p className={styles.title}>
                        <span>Espera la llamada y visita de nuestro agente Linzer certificado para mostrarte el vehículo y la inspección técnica.</span>
                        <span>Escoge 3 posibles fechas:</span>
                    </p>
                    <div className={`${styles.item} ${styles.dates}`}>
                        <p className={styles.name}>Fechas</p>
                        <div className={styles.content}>
                            <Slider
                                type="normal"
                                perView="auto"
                                perGroup={3}
                                limit={5}
                                center={false}
                                options={
                                    daysAvailable && daysAvailable.map((day: any, index: number) => (
                                        <ToggleButtonShared 
                                            key={index} 
                                            type="single"
                                            disabled={(!selectedDays.some((item: any) => item?.value === day?.value) && selectedDays.length === 3) ? true : false}
                                            selected={(selectedDays.some((item: any) => item?.value === day?.value)) ? true : false}
                                            onPress={() => handlePressDate(day)}
                                        >
                                            <p>
                                                <span>{capitalize(day?.day)}</span>
                                                <span>{`${day?.number} ${day?.month}`}</span>
                                            </p>
                                        </ToggleButtonShared>
                                    ))
                                }
                            />
                        </div>
                        <p className={styles.description}>
                            {
                                selectedDays.length === 3 
                                ?   
                                    <>
                                        Asignaste los días
                                        <span> {selectedDays[0]?.number} de {selectedDays[0]?.month}, </span>
                                        <span> {selectedDays[1]?.number} de {selectedDays[1]?.month}, </span>
                                        <span> {selectedDays[2]?.number} de {selectedDays[2]?.month} </span>
                                        de 9 a 14hs
                                    </>
                                :
                                    <>
                                        Asigna {(3 - selectedDays.length)} dia{3 - selectedDays.length === 1 ? '' : 's'}
                                    </>
                            }
                        </p>
                    </div>
                    <div className={`${styles.item} ${styles.direction}`}>
                        <p className={styles.name}>Dirección</p>
                        <div className={styles.content}>
                            <TextFieldShared
                                variant="location"
                                label="Confirma tu dirección"
                                name="direction"
                                value={direction?.value}
                                onChange={handleChangeDirection}
                                error={direction?.error}
                                className={styles.full}
                            />
                        </div>
                    </div>
                    <div className={`${styles.item} ${styles.participants}`}>
                        <p className={styles.name}>Participantes</p>
                        <div className={styles.content}>
                            <div className={`${styles.people} ${styles.you}`}>
                                <img src={user?.picture ? user?.picture : images.profile} alt="" />
                                <p>Tu</p>
                            </div>
                            <div className={`${styles.people} ${styles.linzer}`}>
                                <div className={styles.image}>
                                    <img src={images.isotipo} alt="" />
                                </div>
                                <p>Linzer</p>
                            </div>
                        </div>
                    </div>
                </div>
                <ButtonShared
                    onPress={handleSend}
                    title="Agendar inspección"
                    primary
                    disabled={disabled}
                    loading={loading}
                    className={styles.submitButton}
                />
            </BuyModal>
        </>
    )
}

export default memo(ScheduleInspection);