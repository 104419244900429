import React from "react";
import { RouteObject } from "../../interfaces/navigation";
import EntryPoint from "./EntryPoint";

export const policiesRoutes: RouteObject[] = [
    {
        title: "Policies",
        path: "/policies",
        component: <EntryPoint />
    }
];