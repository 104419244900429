import { createSlice } from "@reduxjs/toolkit";
import { initialStateProps } from '../interfaces/inspection';

const initialState : initialStateProps = {
    approval: {
        fetchingGet: "unknown",
        fetchingPut: "unknown",
        fault: false
    }
}

const inspectionSlice = createSlice({
    name: "transaction",
    initialState,
    reducers: {
        getInspectionApprovalRequest: (state) => {
            state.approval.fetchingGet = "loading";
        },
        getInspectionApprovalSuccess: (state, action) => {
            state.approval.fetchingGet = "success";
            state.approval.fault = action.payload
        },
        getInspectionApprovalFailure: (state) => {
            state.approval.fetchingGet = "failure";
        },
        cleanGetInspectionApproval: (state) => {
            state.approval.fetchingGet = initialState.approval.fetchingGet;
        },
        putInspectionApprovalRequest: (state) => {
            state.approval.fetchingPut = "loading";
        },
        putInspectionApprovalSuccess: (state) => {
            state.approval.fetchingPut = "success";
        },
        putInspectionApprovalFailure: (state) => {
            state.approval.fetchingPut = "failure";
        },
        cleanPutInspectionApproval: (state) => {
            state.approval.fetchingPut = initialState.approval.fetchingPut;
        }
    }
})

export const {
    getInspectionApprovalRequest,
    getInspectionApprovalSuccess,
    getInspectionApprovalFailure,
    cleanGetInspectionApproval,
    putInspectionApprovalRequest,
    putInspectionApprovalSuccess,
    putInspectionApprovalFailure,
    cleanPutInspectionApproval
} =
    inspectionSlice.actions;

export default inspectionSlice.reducer;