import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import Address from "../../../../pages/Profile/Documentation/Address";
import { DocumentsPagesContainerProps } from "../../../../interfaces/containers";

function mapStateToProps(state: DocumentsPagesContainerProps) {
    return{
        
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Address);