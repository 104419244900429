import { createSlice } from "@reduxjs/toolkit";
import { initialStateProps } from "../interfaces/terms";

const initialState: initialStateProps = {
    fetching: "unknown",
    data: [],
    error: ""
}

const termsSlice = createSlice({
    name: 'terms',
    initialState,
    reducers: {
        getTermsRequest: (state) => {
            state.fetching = "loading";
        },
        getTermsSuccess: (state, action) => {
            state.fetching = "success";
            state.data = action.payload;
        },
        getTermsFailure: (state, action) => {
            state.fetching = "failure";
            state.error = action.payload;
        },
        cleanTerms: (state) => {
            state.fetching = initialState.fetching;
        }
    }
});

export const {
    getTermsRequest,
    getTermsSuccess,
    getTermsFailure,
    cleanTerms
} = termsSlice.actions;

export default termsSlice.reducer;