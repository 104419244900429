import EntryPoint from "../../../containers/Profile/MyBuys/MyBuysContainer";
import Management from "../../../containers/Profile/MyBuys/Management/ManagementContainer";
import Inspection from "../../../containers/Profile/Inspection/EntryPointContainer";
import QRGenerator from "../../../containers/Profile/QrGenerator/EntryPointContainer";
import { RouteObject } from "../../../interfaces/navigation";

export const myBuysSubRoutes: RouteObject[] = [
    {
        title: "Gestión",
        path: ":id/management",
        component: <Management/>
    },
    {
        title: "Qr Generator",
        path: ":id/qr_generator",
        component: <QRGenerator/>
    },
    {
        title: "Inspección",
        path: ":id/inspection",
        component: <Inspection/>
    }
];

export const myBuysRoutes: RouteObject[] = [
    {
        title: "Mis Compras",
        path: "my_buys",
        component: <EntryPoint/>,
        subRoutes: myBuysSubRoutes
    }
];