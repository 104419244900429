import React from "react";
import { RouteObject } from "../../interfaces/navigation";

const EntryPoint = React.lazy(() => import("../../pages/GettingStarted/index"));

export const gettingStartedRoutes: RouteObject[] = [
    {
        title: "Getting started",
        path: "/what-is-linze",
        component: <EntryPoint />
    }
];