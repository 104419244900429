import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./management.module.scss";
import { ManagementProps } from '../../../../interfaces/pages';
import ProfileCard from "../../../../components/ProfileCard";
import { myPubManagementBreadCrumb } from "../../../../constants/breadCrumbs";
import { images } from "../../../../constants/images";
import TimeLine from "./TimeLine/TimeLine";
import InspectionSkeleton from "./InspectionSkeleton/InspectionSkeleton";
import moment from "moment";
import { translations } from "../../../../constants/app";
import { addPointsInText, capitalize, copyToClipboard } from "../../../../helpers/app";
import OfferSkeleton from "./OfferSkeleton/OfferSkeleton";
import RightButtons from "./RightButtons/RightButtons";
import DeleteContainer from "../../../../containers/Profile/Delete/DeleteContainer";
import { useToast } from "../../../../helpers/hooks";
import { Divider, IconButton, Tab, Tabs } from "@mui/material";
import Slider from "../../../../components/Slider/Slider";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import QR from "./QR";
import Views from "../../../../containers/Profile/MyPublications/Management/Views/ViewsContainer";
import Favorites from "./Favorites";
import Interested from "../../../../containers/Profile/MyPublications/Management/Interested/InterestedContainer";
import OffersModal from "../../../../containers/Profile/MyPublications/Management/OffersModal/OffersModalContainer";

const Management = ({
    publication,
    getPublication,
    getArticlesFromPublication,
    fetchingHistory,
    history,
    getOffersPublication,
    offer,
    getInspections,
    getInspectionsFiles,
    inspection,
    cleanInspection,
    cleanInspectionFiles,
    getHistoryPublication,
    getSchedulingPublication,
    schedules,
    clearPublicationSchedule,
    cleanAll,
    getRecords,
    records,
    cleanGetRecords
}: ManagementProps) => {
    const toast = useToast();
    const params = useParams();
    const navigate = useNavigate();
    const timeLineRef = useRef<any>(null);
    const [cardSectionFirst, setCardSectionFirst] = useState<string>('offers');
    const [cardSectionSecond, setCardSectionSecond] = useState<string>('inspection');
    const [openInspectionFiles, setOpenInspectionFiles] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [offersModal, setOffersModal] = useState<any>({open: false, offer: {}});

    const historyNormalized = useMemo(() => {
        return history && history.map((item: any) => (
            {
                title: item.title,
                price: item.description,
                description: moment(item.created_at).format("DD MMM HH:mm")
            }
        ))
    }, [history]);

    const offerScheduleItem = (item: any, type: string) => {
        let stateCode = "";
        switch(item?.status){
            case "accepted":
                stateCode = styles.green
                break;
            case "rejected":
                stateCode = styles.red
                break;
            case "cancelled":
            case "pause":
                stateCode = styles.gray
                break;
            default:
                stateCode = styles.blue
                break;
        }
        return (
            type === "offer"
            ?
                <>
                    <div className={`${styles.item} ${styles.hover}`} onClick={() => setOffersModal({open: true, offer: item})}>
                        <p className={styles.info}>
                            <span className={styles.price}><strong>$ {addPointsInText(item.offer)}</strong></span>
                            <span className={styles.date}>{moment(item.created_at).format("DD MMM HH:mm")}</span>
                        </p>
                        <p className={`${styles.chip} ${stateCode}`}>
                            { translations(item.status) }
                        </p>
                    </div>
                    <Divider />
                </>
            :
                <>
                    <div className={styles.item}>
                        <p className={styles.info}>{moment(item.created_at).format("DD MMM HH:mm")}</p>
                        {
                            item.status === "pending"
                            ?
                                <p 
                                    className={`${styles.button} ${styles.blue} ${stateCode}`}
                                    onClick={() => navigate(`/sell/${item?.transaction}/schedule_inspection`)}
                                >
                                    { type === "schedule" ? "Agendar" : translations(item.status) }
                                    <IconButton aria-label='icon'>
                                        { <OpenInNewIcon className={styles.icon} /> }
                                    </IconButton>
                                </p>
                            :
                                <p className={`${styles.chip} ${stateCode}`}>
                                    { translations(item.status) }
                                </p>
                        }
                    </div>
                    <Divider />
                </>
        );
    }

    const handleClickEye = () => {
        if(inspection?.files?.data.length === 0){
            return toast("Esta inspección no contiene imagenes");
        }
        setOpenInspectionFiles(true);
    }
    
    const onShare = () => {
        copyToClipboard(`https://${process.env.REACT_APP_DOMAIN}.cl/publication/${publication?._id}`);
        toast("Publicación copiada al portapapeles");
    };

    const onDelete = () => {
        setOpenDelete(true);
    };

    useEffect(() => {
        if(inspection?.files?.fetching === "failure"){
            toast("Ha ocurrido un error al mostrar las fotos de la inspección");
            cleanInspectionFiles();
        }
        if(inspection?.files?.fetching === "success"){
            cleanInspectionFiles();
        }
    }, [inspection?.files?.fetching]);

    useEffect(() => {
        if(inspection?.fetching === "failure"){
            toast("Ha ocurrido un error al mostrar la inspección");
            cleanInspection();
        }
        if(inspection?.fetching === "success"){
            if(inspection?.data && inspection?.data.length > 0){
                getInspectionsFiles(inspection?.data[0]?._id);
            }
            cleanInspection();
        }
    }, [inspection?.fetching]);

    useEffect(() => {
        if(schedules?.fetching === "failure"){
            toast("Ha ocurrido un error al mostrar los agendamientos");
            clearPublicationSchedule();
        }
        if(schedules?.fetching === "success"){
            clearPublicationSchedule();
        }
    }, [schedules?.fetching]);

    useEffect(() => {
        if(records?.fetching === "failure"){
            toast("Ha ocurrido un error mostrar la información de retiro");
            cleanGetRecords();
        }
        if(records?.fetching === "success"){
            cleanGetRecords();
        }
    }, [records?.fetching]);
    
    useEffect(() => {
        window.scrollTo(0, 0);
        cleanAll();
        getPublication(params?.id);
        getArticlesFromPublication(params?.id);
        getOffersPublication(params?.id);
        getInspections(params?.id, "sell");
        getHistoryPublication(params?.id);
        getSchedulingPublication(params?.id);
        getRecords(params?.id);
        return () => {
            cleanAll();
        }
    }, []);

    return (
        <ProfileCard 
            className={styles.profileCardContainer} 
            breadCrumbs={myPubManagementBreadCrumb(params?.id)}
            rightOptions={
                <RightButtons 
                    onShare={onShare} 
                    onDelete={onDelete} 
                />
            }
        >
            <div className={styles.managementContainer}>
                <QR id={params.id} publication={publication} className={`${styles.card} ${styles.qr}`}/>
                <Views id={params.id} className={`${styles.card} ${styles.views}`} />
                <Favorites likes={publication.likes} className={`${styles.card} ${styles.favorites}`} />
                <Interested id={params.id} className={`${styles.card} ${styles.interested}`} />
                <div className={`${styles.card} ${styles.historyCard}`}>
                    <div className={styles.header}>
                        <p className={styles.title}>Historial</p>
                    </div>
                    <div className={styles.content}>
                        <TimeLine ref={timeLineRef} data={historyNormalized} loading={fetchingHistory === "loading"}/>
                    </div>
                </div>
                <div className={`${styles.card} ${styles.tabsCard} ${styles.first}`}>
                    <div className={styles.header}>
                        <Tabs 
                            className={styles.cardTabs} 
                            value={cardSectionFirst} 
                            onChange={(event: React.SyntheticEvent, value: string) => setCardSectionFirst(value)}
                            TabIndicatorProps={{ className: styles.sideBarIndicator }}
                            variant="fullWidth"
                        >
                            <Tab 
                                value="offers" 
                                label="Ofertas" 
                                className={styles.tab}
                                classes={{
                                    selected: styles.selected
                                }}
                            />
                            <Tab 
                                value="schedules"
                                label="Agendamientos" 
                                className={styles.tab}
                                classes={{
                                    selected: styles.selected
                                }}
                            />
                        </Tabs>
                    </div>
                    <div className={styles.content}>
                        {
                            offer?.fetching === "loading"
                            ?   <OfferSkeleton/>
                            :   
                                ((cardSectionFirst === "offers" && !offer?.data.length) || (cardSectionFirst === "schedules" && !schedules?.data.length))
                                ?
                                    <div className={styles.textNoData}>{`Aún no tienes ${cardSectionFirst === "offers" ? "ofertas" : "agendamientos"}`}</div>
                                :
                                    cardSectionFirst === "offers"
                                    ?
                                        offer?.data && offer?.data.map((offer: any) => (
                                            offerScheduleItem(offer, 'offer')
                                        ))
                                    :
                                        schedules?.data && schedules?.data.map((schedule: any) => (
                                            offerScheduleItem(schedule, 'schedule')
                                        ))
                        }
                    </div>
                </div>
                <div className={`${styles.card} ${styles.tabsCard} ${styles.second}`}>
                    <div className={styles.header}>
                        <Tabs 
                            className={styles.cardTabs} 
                            value={cardSectionSecond} 
                            onChange={(event: React.SyntheticEvent, value: string) => setCardSectionSecond(value)}
                            TabIndicatorProps={{ className: styles.sideBarIndicator }}
                            variant="fullWidth"
                        >
                            <Tab 
                                value="inspection" 
                                label="Inspección" 
                                className={styles.tab}
                                classes={{
                                    selected: styles.selected
                                }}
                            />
                            <Tab 
                                value="delivery"
                                label="Información de retiro" 
                                className={styles.tab}
                                classes={{
                                    selected: styles.selected
                                }}
                            />
                        </Tabs>
                    </div>
                    <div className={styles.content}>
                        {
                            cardSectionSecond === "inspection"
                            ?
                                inspection?.fetching === "loading" 
                                ?   <InspectionSkeleton/>
                                :   (inspection?.data && inspection?.data.length > 0)
                                    ?
                                        <>
                                            <p className={styles.cardRow}>
                                                <span className={styles.title}>Nº de inspección</span>
                                                <span>#{inspection?.data[0]?._id.slice(0,10)}</span>
                                            </p>
                                            <p className={styles.cardRow}>
                                                <span className={styles.title}>Fecha</span>
                                                <span>{moment(inspection?.data[0]?.started_at).format("DD")} de {moment(inspection?.data[0]?.started_at).format("MMM YYYY")}</span>
                                            </p>
                                            <p className={styles.cardRow}>
                                                <span className={styles.title}>Linzer asignado</span>
                                                <span>
                                                    {
                                                        inspection?.data[0]?.assigned_brief
                                                        ?
                                                            `${capitalize(inspection?.data[0]?.assigned_brief?.name)} ${capitalize(inspection?.data[0]?.assigned_brief?.last_name)}`
                                                        :
                                                            "-"
                                                    }
                                                </span>
                                            </p>
                                            <p className={styles.cardRow}>
                                                <span className={styles.title}>Estado</span>
                                                <span className={`
                                                        ${inspection?.data[0]?.status === "in_progress" ? styles.button : styles.chip} 
                                                        ${inspection?.data[0]?.status === "accepted" ? styles.green :
                                                        inspection?.data[0]?.status === "rejected" ? styles.red :
                                                        inspection?.data[0]?.status === "cancelled" ? styles.gray : 
                                                        inspection?.data[0]?.status === "pending" ? "Ver más" : styles.blue}`
                                                    }
                                                    style={{textDecoration: inspection?.data[0]?.status === "in_progress" ? "underline" : "none"}}
                                                >
                                                    {translations(inspection?.data[0]?.status)}
                                                </span>
                                            </p>
                                            {
                                                (inspection?.files?.data && inspection?.files?.data.length > 0) 
                                                ?   <p className={styles.cardRow}>
                                                        <span className={styles.title}>Fotos de inspección</span>
                                                        <span>
                                                            <IconButton aria-label='Ver fotos' onClick={handleClickEye}>
                                                                <img src={images.purpleEye} />
                                                            </IconButton>
                                                        </span>
                                                    </p>
                                                :   null
                                            }   
                                        </>
                                    :
                                        <div className={styles.textNoData}>Inspección no inicializada</div>
                            :
                                records?.fetching === "loading" 
                                ?   <InspectionSkeleton />
                                :   (records?.address && records?.date_start && records?.date_end)
                                    ?   
                                        <>
                                            <p className={styles.cardRow}>
                                                <span className={styles.title}>Dirección de retiro</span>
                                                <span>{records?.address}</span>
                                            </p>
                                            <p className={styles.cardRow}>
                                                <span className={styles.title}>Fecha de retiro</span>
                                                <span>{moment(records?.date_start).format("DD MMM YYYY")}</span>
                                            </p>
                                        </>
                                    :
                                        <div className={styles.textNoData}>No hay información de retiro</div>

                        }
                    </div>
                </div>
            </div>
            <Slider
                type="preview"
                options={
                    inspection?.files?.data &&
                    inspection?.files?.data.map((item: any, index: number) => (
                        <img key={index} src={item?.url} alt="Image" />
                    ))
                }
                pagination
                open={openInspectionFiles}
                onClose={() => setOpenInspectionFiles(false)}
            />
            <DeleteContainer
                item={publication}
                open={openDelete}
                setOpen={setOpenDelete}
            />
            <OffersModal
                open={offersModal?.open}
                offer={offersModal?.offer}
                publication={publication}
                onClose={() => setOffersModal({...offersModal, open: false})}
                variant="sell"
            />
        </ProfileCard>
    )
}

export default Management;