import { NoDataListProps } from "../../interfaces/components";
import styles from "./noDataList.module.scss"

const NoDataList = ({
    image, 
    text, 
    title,
    className
}: NoDataListProps ) => {
    return(
        <div className={`${styles.noDataContainer} ${className}`}>
            <img className={styles.image} src={image} />
            <p className={styles.title}>{title}</p>
            <p className={styles.text}>{text}</p>
        </div>
    )
}

export default NoDataList;