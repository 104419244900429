import { Dispatch } from "redux";
import { GetState } from "../config/store";
import { api } from "../config/api";
import {
    setStep as setStepReducer,
    setMethod as setMethodReducer,
    setPublication as setPublicationReducer,
    setValidation as setValidationReducer,
    setInspectionDates as setInspectionDatesReducer,
    setInspectionDirection as setInspectionDirectionReducer,
    setCreditRequest as setCreditRequestReducer,
    setFinancing as setFinancingRequestReducer,
    cleanStep,
    cleanMethod,
    cleanInspection,
    cleanCreditRequest,
    cleanFinancing,
    cleanAll,
    getAdditionalExpensesTransactionRequest,
    getAdditionalExpensesTransactionSuccess,
    getAdditionalExpensesTransactionFailure,
    postTransactionRequest,
    postTransactionSuccess,
    postTransactionFailure,
    cleanPostTransaction as cleanPostTransactionReducer,
    getCreditSimulationRequest,
    getCreditSimulationSuccess,
    getCreditSimulationFailure,
    cleanCreditSimulation as cleanCreditSimulationReducer,
} from "../reducers/buy";
import { 
    MethodTypes, 
    RevenueTypes, 
    ContractTypesBuy, 
    CreditTypesBuy, 
    FeesTypes 
} from '../interfaces/types';
import { TransactionProps, SimulationDataProps } from '../interfaces/index';
import { axiosClient } from "../config/axios";

export const setStep = (step: number) => (dispatch: Dispatch) => _setStep(step, dispatch);
const _setStep = async (step: number, dispatch: Dispatch) => dispatch({ type: setStepReducer, payload: step });

export const setMethod = (method: MethodTypes) => (dispatch: Dispatch) => _setMethod(method, dispatch);
const _setMethod = async (method: MethodTypes, dispatch: Dispatch) => dispatch({ type: setMethodReducer, payload: method });

export const setPublication = (publication: any) => (dispatch: Dispatch) => _setPublication(publication, dispatch);
const _setPublication = async (publication: any, dispatch: Dispatch) => dispatch({ type: setPublicationReducer, payload: publication });

export const setValidation = ({residence, documents, waiting}: any) => (dispatch: Dispatch) => _setValidation({residence, documents, waiting}, dispatch);
const _setValidation = async ({residence, documents, waiting}: any, dispatch: Dispatch) => {
    dispatch({ 
        type: setValidationReducer, 
        payload: {
            residence: residence, 
            documents: documents, 
            waiting: waiting
        } 
    });
}

export const setInspectionDates = (dates: any[]) => (dispatch: Dispatch) => _setInspectionDates(dates, dispatch);
const _setInspectionDates = async (dates: any[], dispatch: Dispatch) => dispatch({ type: setInspectionDatesReducer, payload: dates });

export const setInspectionDirection = (direction: object) => (dispatch: Dispatch) => _setInspectionDirection(direction, dispatch);
const _setInspectionDirection = async (direction: object, dispatch: Dispatch) => dispatch({ type: setInspectionDirectionReducer, payload: direction });

export const setCreditRequest = (liquidRent: number, revenue: RevenueTypes, contract: ContractTypesBuy, seniority: number) => 
(dispatch: Dispatch) => _setCreditRequest(liquidRent, revenue, contract, seniority, dispatch);
const _setCreditRequest = async (liquidRent: number, revenue: RevenueTypes, contract: ContractTypesBuy, seniority: number, dispatch: Dispatch) => {
    dispatch({ 
        type: setCreditRequestReducer, 
        payload: {
            liquidRent: liquidRent,
            revenue: revenue,
            contract: contract,
            seniority: seniority
        }
    });
}

export const setFinancingRequest = (price: number, creditType: CreditTypesBuy, footValue: number, fees: FeesTypes) => 
(dispatch: Dispatch) => _setFinancingRequest(price, creditType, footValue, fees, dispatch);
const _setFinancingRequest = async (price: number, creditType: CreditTypesBuy, footValue: number, fees: FeesTypes, dispatch: Dispatch) => {
    dispatch({ 
        type: setFinancingRequestReducer, 
        payload: {
            price: price,
            creditType: creditType,
            footValue: footValue,
            fees: fees
        }
    });
}

export const setCleanStep = () => (dispatch: Dispatch) => _setCleanStep(dispatch);
const _setCleanStep = async (dispatch: Dispatch) => dispatch({ type: cleanStep });

export const setCleanMethod = () => (dispatch: Dispatch) => _setCleanMethod(dispatch);
const _setCleanMethod = async (dispatch: Dispatch) => dispatch({ type: cleanMethod });

export const setCleanInspection = () => (dispatch: Dispatch) => _setCleanInspection(dispatch);
const _setCleanInspection = async (dispatch: Dispatch) => dispatch({ type: cleanInspection });

export const setCleanCreditRequest = () => (dispatch: Dispatch) => _setCleanCreditRequest(dispatch);
const _setCleanCreditRequest = async (dispatch: Dispatch) => dispatch({ type: cleanCreditRequest });

export const setCleanFinancing = () => (dispatch: Dispatch) => _setCleanFinancing(dispatch);
const _setCleanFinancing = async (dispatch: Dispatch) => dispatch({ type: cleanFinancing });

export const setCleanAll = () => (dispatch: Dispatch) => _setCleanAll(dispatch);
const _setCleanAll = async (dispatch: Dispatch) => dispatch({ type: cleanAll });

export const getAdditionalExpensesTransaction = (type: string, price: string) => (dispatch: Dispatch) => _getAdditionalExpensesTransaction(type, price, dispatch);
const _getAdditionalExpensesTransaction = async (type: string, price: string, dispatch: Dispatch) => {
    try {
        dispatch({ type: getAdditionalExpensesTransactionRequest });

        const response = await axiosClient.get(`${api.settings}/additional-costs/calculate?operation_type=purchase&operation_flow=transaction&price=${price}&flow_type=${type}&with_no_visible=false`);

        dispatch({ type: getAdditionalExpensesTransactionSuccess, payload: response?.data.data.calculation_addcosts });
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse?.code);
        dispatch({ type: getAdditionalExpensesTransactionFailure})
    }
}

export const postTransaction = ( transaction: TransactionProps) => (dispatch: Dispatch) => _postTransaction(transaction, dispatch);
const _postTransaction = async ( transaction: TransactionProps, dispatch: Dispatch ) => {
    try {
        dispatch({type: postTransactionRequest});
        const body: BodyInit = JSON.stringify(transaction);

        await axiosClient.post(`${api.transactions}`, body);

        dispatch({type: postTransactionSuccess});

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse?.code);
        dispatch({type: postTransactionFailure});
    }
}

export const cleanPostTransaction = () => (dispatch: Dispatch) => _cleanPostTransaction(dispatch);
const _cleanPostTransaction = (dispatch: Dispatch) => dispatch({type: cleanPostTransactionReducer});

export const getCreditSimulation = (simulation_data: SimulationDataProps) => (dispatch: Dispatch) => _getCreditSimulation(simulation_data, dispatch);
const _getCreditSimulation = async (simulation_data: SimulationDataProps, dispatch: Dispatch) => {
    try {
        dispatch({ type: getCreditSimulationRequest });
        const body = JSON.stringify(simulation_data);

        const response = await axiosClient.post(`${api.credits}/simulations`, body);
        console.log(response);
        
        if (response?.data?.data?.simulation?.Error){
            const errorResponse = response?.data?.data?.simulation?.Mensaje;
            console.log(errorResponse?.code);
            dispatch({ type: getCreditSimulationFailure });
            return
        }

        dispatch({type: getCreditSimulationSuccess, payload: response?.data.data.simulation});

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse?.code);
        dispatch({ type: getCreditSimulationFailure });
    }
};

export const cleanCreditSimulation = () => (dispatch: Dispatch) => _cleanCreditSimulation(dispatch);
const _cleanCreditSimulation = (dispatch: Dispatch) => dispatch({type: cleanCreditSimulationReducer});