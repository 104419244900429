import styles from './certified.module.scss';
import VerifiedIcon from '@mui/icons-material/Verified';

const Certified = ({
    className = ""
}: any) => {
    return (
        <div className={`${styles.certified} ${className}`}>
            <VerifiedIcon className={styles.icon} />
            <p>Verificado</p>
        </div>
    );
};

export default Certified;