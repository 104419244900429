import { useEffect, useState } from "react";
import styles from './app.module.scss';
import { useLocation } from "react-router-dom";
import { SnackbarProvider } from 'notistack';
import { HelmetProvider } from "react-helmet-async";
import Toast from "../Toast/Toast";
import { useIsMobileDevice } from "../../helpers/hooks";
import MetaDecoration from "../MetaDecoration/MetaDecoration";
import RefreshToken from "../../containers/RefreshToken/RefreshTokenContainer";
import NavBar from '../../containers/NavBar/NavBarContainer';
import Footer from '../../containers/Footer/FooterContainer';
import { dev } from "../../config/api";
import Routes from "../../containers/RoutesContainer";
import { useWindowSize } from "use-hooks";
import { GoogleOAuthProvider } from '@react-oauth/google';
import ModalDownloadApp from "../ModalDownloadApp/ModalDownloadApp";
import ModalTopDownloadApp from "../ModalTopDownloadApp";
import FloatButton from "../FloatButton/FloatButton";

const App = () => {
	const location = useLocation();
	const windowSize = useWindowSize();
	const [openFrame, setOpenFrame] = useState(false);
	const isMobileDevice = useIsMobileDevice();
	const [openDownloadModal, setOpenDownloadModal] = useState<boolean>(false);
	const [openDownloadModalTop, setOpenDownloadModalTop] = useState<boolean>(false);
	const helmetContext = {};

	useEffect(() => {
		if(!dev && isMobileDevice){
			setOpenDownloadModalTop(true);
		}else{
			setOpenDownloadModalTop(false);
		}
	}, [windowSize.width, location]);
	
	useEffect(() => {
		if(!dev && isMobileDevice && !(location.pathname === "/" || location.pathname.match("/download") || location.pathname.match("/frequent_questions") || location.pathname.match("/publication/") || location.pathname.match("/linze_ai") || location.pathname.match("/login" ) || location.pathname.match("/what-is-linze"))){
			setOpenDownloadModal(true);
		}else{
			setOpenDownloadModal(false);
		}
	}, [windowSize.width, location]);

	return (
		<HelmetProvider context={helmetContext}>
			<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}>
				<SnackbarProvider content={(key, message) => <Toast id={key} content={message} />}>
					<MetaDecoration />
					<RefreshToken />
					<div className={styles.app}>
						{/*<FloatButton openFrame={openFrame} setOpenFrame={setOpenFrame}/>*/}
						{
							!location.pathname.match("/delete_account") && <NavBar openDownloadModalTop={openDownloadModalTop} />
						}
						<ModalTopDownloadApp open={openDownloadModalTop} setOpen={setOpenDownloadModalTop} />
						<Routes />
						<ModalDownloadApp setOpen={setOpenDownloadModal} open={openDownloadModal} />
					</div>
					{
						!location.pathname.match("/delete_account") && <Footer />
					}
				</SnackbarProvider>
			</GoogleOAuthProvider>
		</HelmetProvider>
	);
}

export default App;
