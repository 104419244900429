import styles from "./modalDownloadApp.module.scss";
import Modal from '../Modal/Modal';
import { images } from '../../constants/images';
import { ModalDownloadAppProps } from '../../interfaces/components';

const ModalDownloadApp = ({
    open,
    setOpen
}: ModalDownloadAppProps) => {
    const handleClickDownload = () => {
        const platform = window.navigator?.platform.toLowerCase();
        if(platform.match("mac") || platform.match("iphone") || platform.match("ipad") || platform.match("ipod") || platform.match("pike")){
            window.open("https://apps.apple.com/us/app/linze-car/id1632630015", "_blank");
        }else{
            window.open("https://play.google.com/store/apps/details?id=com.mavimovil.linzeapp", "_blank");
        }
    };
    
    return(
        <Modal open={open} className={styles.modalContainer}>
            <button onClick={() => setOpen(false)} className={styles.closeButton}>
                <img src={images.closeWhite}/>
            </button>
            <p className={styles.title}>Descarga nuestra aplicación</p>
            <p className={styles.description}>
                <span>Esta sección está disponible únicamente en nuestra versión app.</span>
                <span>Descárgala para acceder a todas las funciones.</span>
            </p>
            <div className={styles.buttons}>
                <div className={styles.link} onClick={handleClickDownload}>
                    <img src={images.googlePlay} alt="Google play" />
                </div>
                <div className={styles.link} onClick={handleClickDownload}>
                    <img src={images.appleStore} alt="AppStore" />
                </div>
            </div>
        </Modal>
    )
}

export default ModalDownloadApp;