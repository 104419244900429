import { NavOptionsProps } from "../interfaces/navigation";

export const navOptions: NavOptionsProps[] = [
    {
        title: "¿Qué es Linze?",
        to: "/what-is-linze",
        id: "what-is-linze",
        realPath: "/what-is-linze"
    },
    {
        title: "Comprar",
        to: "/search",
        id: "search",
        realPath: "/search"
    },
    {
        title: "Vender",
        to: "/sell",
        id: "sell",
        realPath: "/sell/search"
    },
    {
        title: "Notificaciones",
        to: "/notifications",
        id: "notifications",
        realPath: "/notifications",
    },
    {
        title: "Perfil",
        to: "/profile",
        id: "profile",
        realPath: "/profile"
    },
    {
        title: "Ingresar",
        to: "/login",
        id: "login",
        realPath: "/login"
    }
];