import { createSlice } from '@reduxjs/toolkit';
import { BuyStateProps } from '../interfaces/buy';

const initialState: BuyStateProps = {
    step: 1,
    method: 'cash',
    publication: [],
    validation: {
        residence: false,
        documents: false,
        waiting: false
    },
    transactionFetching: "unknown",
    transactionError: "",
    additionalCost: {
        fetching: "unknown",
        data: []
    },
    inspection: {
        dates: [],
        direction: {
            fetching: "unknown",
            data: [],
            selected: {}
        },
    },
    creditRequest: {
        liquidRent: 0,
        revenue: 'dependent',
        contract: 'fixed_term',
        seniority: 0
    },
    financing: {
        price: 0,
        creditType: 'conventional',
        footValue: 0,
        fees: 24
    },
    creditSimulation: {
        fetching: "unknown",
        data: []
    }
}

const buySlice = createSlice({
    name: 'buy',
    initialState,
    reducers: {
        setStep: (state, action) => {
            state.step = action.payload;
        },
        setMethod: (state, action) => {
            state.method = action.payload;
        },
        setPublication: (state, action) => {
            state.publication = action.payload;
        },
        setValidation: (state, action) => {
            state.validation.residence = action.payload.residence;
            state.validation.documents = action.payload.documents;
            state.validation.waiting = action.payload.waiting;
        },
        setInspectionDates: (state, action) => {
            state.inspection.dates = action.payload;
        },
        setInspectionDirection: (state, action) => {
            state.inspection.direction.selected = action.payload;
        },
        getDirectionRequest: (state) => {
            state.inspection.direction.fetching = "loading";
        },
        getDirectionSuccess: (state, action) => {
            state.inspection.direction.fetching = "success";
            state.inspection.direction.data = action.payload;
        },
        getDirectionFailure: (state) => {
            state.inspection.direction.fetching = "failure";
        },
        getAdditionalExpensesTransactionRequest: (state) => {
            state.additionalCost.fetching = "loading";
        },
        getAdditionalExpensesTransactionSuccess: (state, action) => {
            state.additionalCost.fetching = "success";
            state.additionalCost.data = action.payload;
        },
        getAdditionalExpensesTransactionFailure: (state) => {
            state.additionalCost.fetching = "failure";
        },
        setCreditRequest: (state, action) => {
            state.creditRequest.liquidRent = action.payload.liquidRent;
            state.creditRequest.revenue = action.payload.revenue;
            state.creditRequest.contract = action.payload.contract;
            state.creditRequest.seniority = action.payload.seniority;
        },
        setFinancing: (state, action) => {
            state.financing.price = action.payload.price;
            state.financing.creditType = action.payload.creditType;
            state.financing.footValue = action.payload.footValue;
            state.financing.fees = action.payload.fees;
        },
        cleanStep: (state) => {
            state.step = initialState.step;
        },
        cleanMethod: (state) => {
            state.method = initialState.method;
        },
        cleanInspection: (state) => {
            state.inspection.dates = initialState.inspection.dates;
            state.inspection.direction.fetching = initialState.inspection.direction.fetching;
            state.inspection.direction.data = initialState.inspection.direction.data;
            state.inspection.direction.selected = initialState.inspection.direction.selected;
        },
        cleanCreditRequest: (state) => {
            state.creditRequest = initialState.creditRequest;
        },
        cleanFinancing: (state) => {
            state.financing.price = initialState.financing.price;
            state.financing.creditType = initialState.financing.creditType;
            state.financing.footValue = initialState.financing.footValue;
            state.financing.fees = initialState.financing.fees;
        },
        cleanAll: (state) => state = initialState,
        postTransactionRequest: (state) => {
            state.transactionFetching = "loading";
        },
        postTransactionSuccess: (state) => {
            state.transactionFetching = "success";
        },
        postTransactionFailure: (state, action) => {
            state.transactionFetching = "failure";
            state.transactionError = action.payload;
        },
        cleanPostTransaction: (state) => {
            state.transactionFetching = initialState.transactionFetching;
        },
        getCreditSimulationRequest: (state) => {
            state.creditSimulation.fetching = "loading";
        },
        getCreditSimulationSuccess: (state, action) => {
            state.creditSimulation.fetching = "success";
            state.creditSimulation.data = action.payload;
        },
        getCreditSimulationFailure: (state) => {
            state.creditSimulation.fetching = "failure";
        },
        cleanCreditSimulation: (state) => {
            state.creditSimulation.fetching = initialState.creditSimulation.fetching;
        }
    }
});

export const { 
    setStep,
    setMethod,
    setPublication,
    setValidation,
    setInspectionDates,
    setInspectionDirection,
    setCreditRequest,
    setFinancing,
    getDirectionRequest,
    getDirectionSuccess,
    getDirectionFailure,
    cleanStep,
    cleanMethod,
    cleanInspection,
    cleanCreditRequest,
    cleanFinancing,
    cleanAll,
    getAdditionalExpensesTransactionRequest,
    getAdditionalExpensesTransactionSuccess,
    getAdditionalExpensesTransactionFailure,
    postTransactionRequest,
    postTransactionSuccess,
    postTransactionFailure,
    cleanPostTransaction,
    getCreditSimulationRequest,
    getCreditSimulationSuccess,
    getCreditSimulationFailure,
    cleanCreditSimulation
} = buySlice.actions;

export default buySlice.reducer;