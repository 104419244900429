import styles from "./sidebar.module.scss";
import { SidebarProps } from "../../interfaces/components";
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from "react";

const Sidebar = ({
    variant = "normal",
    open,
    onBack = () => {},
    children,
    id,
    title,
    className
}: SidebarProps) => {

    useEffect(() => {
        const overflow = open ? 'hidden' : 'auto';
        document.body.style.overflowY = overflow;
    }, [open]);

    return (
        <>
            <div id={id} className={`${styles.sidebarContainer} ${className} ${open && styles.open} ${variant === "close" && styles.variantClose}`}>
                {
                    title &&
                    <div className={styles.title}>
                        <p>{title}</p>
                        <div className={styles.line}></div>
                    </div>
                }
                {
                    <IconButton className={styles.back} aria-label='Atras' onClick={onBack}>
                        <CloseIcon className={styles.icon} />
                    </IconButton>
                }
                {children}
            </div>
            <div className={`${styles.bkgrResponsive} ${open && styles.open}`} onClick={onBack}></div>
        </>
    )
}

export default Sidebar;