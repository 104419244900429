import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { MyPublicationManagementContainerProps } from "../../../../../interfaces/containers";
import Interested from "../../../../../pages/Profile/MyPublications/Management/Interested";
import {
    getInterested,
    cleanGetInterested
} from "../../../../../actions/publication";

function mapStateToProps(state: MyPublicationManagementContainerProps) {
    return{
        interested: state.publication.interested
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        getInterested,
        cleanGetInterested
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Interested);