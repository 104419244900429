import styles from './vehicleSimilarsLoader.module.scss';
import { Skeleton } from "@mui/material";
import Card from '../../../../containers/Card/CardContainer';

const VehicleSimilarsLoader = () => {

    return (
        <>
            <Skeleton animation="wave" variant="text" className={styles.title} />
            <div className={styles.content}>
                <Card loading />
                <Card loading />
                <Card loading />
            </div>
        </>
    )
}

export default VehicleSimilarsLoader;