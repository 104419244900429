import ProfileCard from "../../components/ProfileCard";
import { images } from "../../constants/images";
import {QRCodeSVG} from 'qrcode.react';
import styles from "./qRGenerator.module.scss";
import variables from "../../styles/variables.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { QrGeneratorProps } from "../../interfaces/components";
import PublicationCardDetail from "../../containers/PublicationCardDetail/PublicationCardDetailContainer";
import { useEffect, useMemo } from "react";
import { myBuysQRBreadCrumb } from "../../constants/breadCrumbs";
import { useToast } from "../../helpers/hooks";

const QRGenerator = ({
    getMyBuy,
    myBuy,
    cleanMyBuy,
    getPublication,
    publication,
    cleanPublication,
    setProfilePublication,
    publicationProfile
}: QrGeneratorProps) => {
    const toast = useToast();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const publicationData = useMemo(() => publicationProfile?.data ?? publicationProfile?.publication ?? publicationProfile, [publicationProfile]);

    useEffect(() => {
        if(myBuy?.fetching === "failure"){
            toast("ha ocurrido un error al mostrar informacion de la compra");
            navigate("/profile/my_buys");
            return cleanMyBuy();
        }
        if(myBuy?.fetching === "success"){
            getPublication(myBuy?.data?.publication);
            cleanMyBuy();
        }
    }, [myBuy?.fetching]);

    useEffect(() => {
        if(publication?.fetching === "failure"){
            toast("ha ocurrido un error al mostrar informacion de la compra");
            navigate("/profile/my_buys");
            cleanPublication();
        }
        if(publication?.fetching === "success"){
            setProfilePublication(publication?.data);
            cleanPublication();
        }
    }, [publication?.fetching]);

    useEffect(() => {
        const { id } = params;
        if(id){
            if(!publicationProfile){
                if(location.pathname.match("/my_publications")){
                    getPublication(id);
                }else{
                    getMyBuy(id);
                }
            }
        }
    }, [params]);
    
    return(
        <ProfileCard className={styles.profileCardLimited} breadCrumbs={myBuysQRBreadCrumb(publicationData?.title)}>
            <PublicationCardDetail type="onlyPicture" >
               <div className={styles.qrGeneratorContainer}>
               <QRCodeSVG
                    value={params?.id ?? ""}
                    size={256}
                    bgColor={"#ffffff"}
                    fgColor={variables.fontColor}
                    level={"L"}
                    includeMargin={false}
                    />
                    <p className={styles.qrDescription}>Deberás mostrar este QR al Linzer que te asignamos y así poder finalizar la venta de tu </p>
                    <p className={styles.qrCarTitle}>{ publicationData?.primary_description} {publicationData?.title }</p>
               </div>
            </PublicationCardDetail>
        </ProfileCard>
    )
}

export default QRGenerator;