import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import {  PicturesContainerProps } from "../../interfaces/containers";
import Pictures from "../../pages/Sell/Pictures";
import { setImages, postPublicationsImages, deletePublicationImage, cleanDeleteImage } from "../../actions/salePost";

function mapStateToProps(state: PicturesContainerProps) {
    return{
        imagesVideos: state.salePost.imagesVideos,
        plate: state.salePost.plate,
        isFoundPlate: state.salePost.isFoundPlate,
        fetchingDeleteImage: state.salePost.fetchingDeleteImage
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        postPublicationsImages,
        deletePublicationImage,
        cleanDeleteImage,
        setImages
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Pictures);