import { useEffect } from "react";
import styles from "../frequentQuestions.module.scss";
import MenuQuestions from "../../../components/MenuQuestions";

const Buy = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={styles.content}>
            <div className={styles.questionsContainer}>
                <MenuQuestions section={1} />
                <div className={styles.sectionContent}>
                    <div className={`${styles.item} ${styles.howBuy}`}>
                        <p className={styles.title}>Cómo podrás comprar tu auto en Linze</p>
                        <div className={styles.info}>
                            <p className={styles.data}>
                                <span className={styles.subTitle}>¿Cómo comprar en Linze?</span>
                                <span>Entre todas las opciones que tenemos disponibles busca el auto que te guste, puedes elegir la marca y modelo que prefieras.</span>
                            </p>
                            <p className={styles.data}>
                                <span className={styles.subTitle}>¿Puedo negociar el precio del auto que me gusta?</span>
                                <span>Si, tienes la posibilidad de realizar una oferta para así negociar el precio con el vendedor.</span>
                            </p>
                            <p className={styles.data}>
                                <span className={styles.subTitle}>¿Cómo puedo pagar?</span>
                                <span>Al momento de realizar la compra puedes elegir hacerla de contado o financiada. Si eliges financiamiento deberás anexar la documentación requerida, optar por un tipo de crédito y antes de las 48hs podrás finalizar la compra. Si, así de rápido, así de fácil.</span>
                            </p>
                            <p className={styles.data}>
                                <span className={styles.subTitle}>¿Cómo recibo el auto que compré?</span>
                                <span>Primero debes elegir 3 posibles fechas en las que quieras recibir tu auto, luego el vendedor elegirá una de ellas y así harán match de entrega.</span>
                            </p>
                            <p className={styles.data}>
                                <span className={styles.subTitle}>¿Cómo realizo el pago?</span>
                                <span>Deberás generar un Vale Vista con las instrucciones de pago ya sea para el pie si escogiste financiar o el precio completo del auto si comprarás de contado.</span>
                            </p>
                            <p className={styles.data}>
                                <span className={styles.subTitle}>¿Cuándo veo el auto que compré?</span>
                                <span>El día que acordaste para recibir tu auto un Linzer (personal de Linze encargado del proceso de compra/venta) irá al domicilio del vendedor para revisar el auto, llevar a cabo una inspección y hacer una videollamada contigo.</span>
                            </p>
                            <p className={styles.data}>
                                <span className={styles.subTitle}>¿Qué sucede luego de la inspección?</span>
                                <span>En esta instancia pueden suceder tres cosas:</span>
                                <span>Si no se aprueba la inspección se cancela la transacción.</span>
                                <span>Si no se llega a un acuerdo en la negociación del precio, se cancela la transacción.</span>
                                <span>Si se aprueba la inspección, en este paso tendrás la posibilidad de renegociar el precio según lo que hayas visto en la videollamada.</span>
                            </p>
                            <p className={styles.data}>
                                <span className={styles.subTitle}>¿Cuándo recibo el auto?</span>
                                <span>Una vez finalizada la inspección, el Linzer llevará el vehículo a tu domicilio, en ese momento deberás entregar el Vale Vista. Si hay alguna diferencia en los montos acordados el Linzer se hará cargo de todas las devoluciones.</span>
                                <span>Por último el Linzer te hará entrega del nuevo auto al listo, ya podrás disfrutarlo.</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Buy;