import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import Identity from "../../../../pages/Profile/Documentation/Identity";
import { DocumentsPagesContainerProps } from "../../../../interfaces/containers";
import { 
    setDocuments,
    getDocuments,
    cleanGetDocumentsTypes,
    cleanGetDocuments,
    getIsValidDocuments,
    postDocuments,
    cleanPostDocuments,
    postDocumentImage,
    cleanPostDocumentImage,
    deleteDocumentImage,
    cleanDeleteDocumentImage,
    deleteDocument,
    cleanDeleteDocument
} from "../../../../actions/documents";

function mapStateToProps(state: DocumentsPagesContainerProps) {
    return{
        fetchingDocuments: state.documents.fetchingDocuments,
        documents: state.documents.documents.identity_card,
        types: state.documents.types,
        fetchingPostDocuments: state.documents.fetchingPostDocuments,
        fetchingPostDocumentImage: state.documents.fetchingPostDocumentImage,
        fetchingDeleteDocument: state.documents.fetchingDeleteDocument,
        fetchingDeleteDocumentImage: state.documents.fetchingDeleteDocumentImage,
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        setDocuments,
        getDocuments,
        cleanGetDocuments,
        getIsValidDocuments,
        postDocuments,
        cleanPostDocuments,
        postDocumentImage,
        cleanPostDocumentImage,
        deleteDocumentImage,
        cleanDeleteDocumentImage,
        deleteDocument,
        cleanDeleteDocument
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Identity);