import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import Routes from "../routes/Routes";
import { AuthContainerProps } from "../interfaces/containers";

function mapStateToProps(state: AuthContainerProps) {
    return{
        fetchingRefreshToken: state.auth.fetchingRefreshToken,
        refreshType: state.auth.refreshType
    }
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
    }, dispatch)
};
export default connect(mapStateToProps, mapDispatchToProps)(Routes);