import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { ValueAndPaymentContainerProps } from "../../interfaces/containers";
import ValueAndPayment from "../../pages/Sell/ValueAndPayment";
import { getAdditionalExpensesSalePost, setPrice, setSuggestedPrices, setIsPaid, setFinancingBonus, setIsNegotiate } from "../../actions/salePost";
import { setLoginRedirection } from "../../actions/auth";

function mapStateToProps(state: ValueAndPaymentContainerProps) {
    return{
        vehiclePaid: state.salePost.vehiclePaid,
        negotiatePrice: state.salePost.negotiatePrice,
        suggestedPrices: state.salePost.suggestedPrices, 
        fetchingSuggedtedPrices: state.salePost.fetchingSuggedtedPrices,
        additionalCost: state.salePost.additionalCost,
        user: state.auth.user,
        fetchingAdditionalCost: state.salePost.fetchingAdditionalCost,
        plate: state.salePost.plate,
        price: state.salePost.price,
        isFoundPlate: state.salePost.isFoundPlate
    }
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        setSuggestedPrices,
        setPrice,
        setLoginRedirection,
        getAdditionalExpensesSalePost,
        setFinancingBonus,
        setIsNegotiate,
        setIsPaid
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(ValueAndPayment);