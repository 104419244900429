import styles from "./favorites.module.scss";
import { images } from "../../../../../constants/images";
import { FavoritesManagementProps } from "../../../../../interfaces/components";

const Favorites = ({
    likes,
    className
}: FavoritesManagementProps) => {

    return(
        <div className={`${styles.favorites} ${className}`}>
            <div className={styles.content}>
                <div className={styles.cardLeft}>
                    <p className={`${styles.text} ${styles.title}`}>Favoritos</p>
                    <p className={`${styles.text} ${styles.value}`}>{likes ?? 0}</p>
                    <p className={`${styles.text} ${styles.period}`}>Últimos 30 días</p>
                </div>
                <img src={images.purpleHeart} className={styles.cardImg} />
            </div>
        </div>
    )
};

export default Favorites;