import React from "react";
import { RouteObject } from "../../interfaces/navigation";

const EntryPoint = React.lazy(() => import("../../containers/Publication/EntryPointContainer"));

export const publicationRoutes: RouteObject[] = [
    {
        title: "Publication",
        path: "/publication/:id",
        component: <EntryPoint/>
    }
];