import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import SellModal from "../../../components/SellModal/SellModal";
import { getYears as calculateYears } from "../../../helpers/app";
import { YearPageProps } from "../../../interfaces/salePost";
import styles from "./year.module.scss";
import SelectShared from "../../../components/Select/Select";
import { useToast } from "../../../helpers/hooks";

const Year = ({
    setYear, 
    plate,
    isFoundPlate,
    getYears,
    years,
    cleanGetYears
}: YearPageProps) => {
    const toast = useToast();
    const navigate = useNavigate();

    const yearsNormalized: any = useMemo(() => {
        const yearsCount = calculateYears(years?.data?.value);
        return yearsCount && yearsCount.map((year: any) => (
            {
                id: year,
                name: year
            }
        ))
    }, [years?.data]);
    
    const onPressNext = (year: any) => {
        setYear(year?.name);
        navigate("/sell/model");
    }

    useEffect(() => {
        if(years?.fetching === "failure"){
            toast("Ha ocurrido un error al calcular los años");
            cleanGetYears();
        }
        if(years?.fetching === "success"){
            cleanGetYears();
        }
    }, [years?.fetching]);

    useEffect(() => {
        getYears();
        if(!plate) return navigate("/sell");
    }, [plate]);

    return (
        <SellModal 
            step={3} 
            title="Ingresa el año de tu auto"
            onClickBack={() => navigate("/sell/brand")}
            className={styles.sellModal}
            isFoundPlate={isFoundPlate}
        >
            <div className={styles.inputsContainer}>
                <SelectShared
                    variant="scroll"
                    label="Año"
                    name="year"
                    onPressOption ={(value: any) => onPressNext(value)}
                    options={yearsNormalized}
                    input={false}
                    className={styles.input}
                    loading={years?.fetching === "loading"}
                    loadingMessage="Cargando años"
                />
            </div>
        </SellModal>
    )
};

export default Year;