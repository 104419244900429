import { useEffect } from 'react';
import styles from './pause.module.scss';
import { PausePublicationsProps } from '../../../../../interfaces/components';
import ButtonShared from '../../../../../components/Button/Button';
import Modal from '../../../../../components/Modal';
import { useToast } from '../../../../../helpers/hooks';

const Pause = ({
    open,
    setOpen,
    loading,
    getPublication,
    publication,
    pausePublication,
    pause,
    cleanPausePublication
}: PausePublicationsProps) => {
    const toast = useToast();

    const onBack = () => {
        setOpen(false)
    }
    
    const onPause = () => {
        if (publication?.data && publication?.data?.in_transaction) {
            return toast("No puedes pausar una publicación en transacción");
        }
        pausePublication(publication?._id, !publication?.paused);
    }

    useEffect(() => {
        if (pause?.fetching === "failure") {
            cleanPausePublication();
            toast("Ha ocurrido un error al pausar la publicación");
        }
        if (pause?.fetching === "success") {
            toast(`Publicación ${!pause?.paused ? "despausada" : "pausada"} correctamente`);
            getPublication(publication?._id);
            onBack();
            cleanPausePublication();
        }
    }, [pause?.fetching]);

    return (
        <Modal open={open} variant="white" className={styles.modal}>
            <p>
                <span className={styles.message}>¿Seguro que quieres {publication?.paused ? "despausar" : "pausar"} esta publicación?</span>
                <span className={styles.name}>{`${publication?.primary_description} ${publication?.title}`}</span>
            </p>
            <div className={styles.buttons}>
                <ButtonShared 
                    onPress={onBack}
                    title="Regresar" 
                    tertiary
                    size="small"
                    disabled={publication?.pause?.fetching === "loading"}
                />
                <ButtonShared 
                    onPress={onPause}
                    title={publication?.paused ? "Reanudar" : "Pausar"}
                    primary
                    size="small"
                    loading={loading}
                />
            </div>
        </Modal>
    )
};
export default Pause;