import { useMemo, useState, useEffect } from 'react';
import Button from "../../../../components/Button";
import { RELATIVES } from "../../../../constants/profile";
import countries from "../../../../constants/countries.json"
import styles from "./references.module.scss";
import { ReferencesProps } from "../../../../interfaces/profile";
import { useToast } from "../../../../helpers/hooks";
import TextFieldShared from "../../../../components/TextField/TextField";
import SelectShared from "../../../../components/Select/Select";
import PhoneInput from '../../../../components/PhoneInput/PhoneInput';

const References = ({ 
	user, 
	editUserRequest, 
	fetchingEdit,
	getIsValidDocuments,
	types
}: ReferencesProps) => {
	const toast = useToast();
	const [firstReference, setFirstReference] = useState({
		name: {
			value: "",
			error: ""
		},
		relationship: {
			value: "",
			error: ""
		},
		phone: {
			code: "",
			number: {
				value: "",
				error: ""
			},
		}
	});
	const [secondReference, setSecondReference] = useState({
		name: {
			value: "",
			error: ""
		},
		relationship: {
			value: "",
			error: ""
		},
		phone: {
			code: "",
			number: {
				value: "",
				error: ""
			},
		}
	});

	const handleChangePhone = (value: any, type: string, reference: any, setReference: Function) => {
        if(type === "code"){
            setReference({...reference, phone: {...reference?.phone, code: value}});
        }else{
            setReference({...reference, phone: {...reference?.phone, number: {...reference?.phone?.number, value: value}}});
        }
    }

	const onConfirmReferences = () => {
		let errorFirstRef = {
			name: "",
			relationship: "",
			phone: ""
		};
		let errorSecondRef = {
			name: "",
			relationship: "",
			phone: ""
		};

		if(!firstReference?.name?.value) errorFirstRef.name = "Debes ingresar un nombre";
		if(!firstReference?.relationship?.value) errorFirstRef.relationship = "Debes ingresar un parentesco";
		if(!firstReference?.phone?.number?.value) errorFirstRef.phone = "Debes ingresar un teléfono";

		setFirstReference({
			...firstReference, 
			name: {
				...firstReference?.name, 
				error: errorFirstRef.name
			},
			relationship: {
				...firstReference?.relationship, 
				error: errorFirstRef.relationship
			},
			phone: {
				...firstReference?.phone, 
				number: {
					...firstReference?.phone?.number, 
					error: errorFirstRef.phone
				}
			}
		});

		if(!secondReference?.name?.value) errorSecondRef.name = "Debes ingresar un nombre";
		if(!secondReference?.relationship?.value) errorSecondRef.relationship = "Debes ingresar un parentesco";
		if(!secondReference?.phone?.number?.value) errorSecondRef.phone = "Debes ingresar un teléfono";

		setSecondReference({
			...secondReference, 
			name: {
				...secondReference?.name, 
				error: errorSecondRef.name
			},
			relationship: {
				...secondReference?.relationship, 
				error: errorSecondRef.relationship
			},
			phone: {
				...secondReference?.phone, 
				number: {
					...secondReference?.phone?.number, 
					error: errorSecondRef.phone
				}
			}
		});

		if(!(errorFirstRef.name || errorFirstRef.relationship || errorFirstRef.phone || errorSecondRef.name || errorSecondRef.relationship || errorSecondRef.phone)) {
			let references: any = user?.references ? [...user?.references] : [];
	
			references[0] = {
				full_name: firstReference?.name?.value,
				relationship: firstReference?.relationship?.value,
				phone: {
					code: firstReference?.phone?.code.replace(/[^\w]/gi, ''),
					number: firstReference?.phone?.number?.value
				}
			}
			references[1] = {
				full_name: secondReference?.name?.value,
				relationship: secondReference?.relationship?.value,
				phone: {
					code: secondReference?.phone?.code.replace(/[^\w]/gi, ''),
					number: secondReference?.phone?.number?.value
				}
			}
			editUserRequest({
				...user,
				references,
			});
		}
	};

	const phoneCodesNormalized: any = useMemo(() => {
        return countries && countries.map((country: any) => (
            {
                id: country?.dial_code,
                name: country?.flag + " " + country?.name + " (" + country?.dial_code + ")",
                icon: country?.flag,
            }
        ))
    }, [countries]);

	useEffect(() => {
		if(user && user.references.length === 0){
			setFirstReference({...firstReference, phone: {...firstReference?.phone, code: "+56"}});
			setSecondReference({...secondReference, phone: {...secondReference?.phone, code: "+56"}});
		}
    }, [phoneCodesNormalized]);
	
	useEffect(() => {
		if (user && user.references.length > 0) {
			user.references.forEach((reference: any, index: number) => {
				if (index === 0) {
					setFirstReference({
						...firstReference, 
						name: {
							...firstReference?.name, 
							value: reference?.full_name,
						},
						relationship: {
							...firstReference?.relationship, 
							value: reference?.relationship
						},
						phone: {
							code: "+"+reference?.phone?.code,
							number: {
								...firstReference?.phone?.number,
								value: reference?.phone?.number
							}
						}
					});
				} else {
					setSecondReference({
						...secondReference, 
						name: {
							...firstReference?.name, 
							value: reference?.full_name,
						},
						relationship: {
							...firstReference?.relationship, 
							value: reference?.relationship
						},
						phone: {
							code: "+"+reference?.phone?.code,
							number: {
								...secondReference?.phone?.number,
								value: reference?.phone?.number
							}
						}
					});
				}
			});
		}
	}, [user, countries]);

    useEffect(() => {
        if(fetchingEdit === "success") {
			toast("Felicitaciones! Los cambios han sido guardados.")
			getIsValidDocuments();
		};
    },[fetchingEdit]);

	return (
		<div className={styles.referencesContainer}>
			<div className={styles.referenceData}>
				<TextFieldShared
					label="Nombre y apellido"
					name="name"
					value={firstReference?.name?.value}
					className={styles.half}
					onChange={(value: any) => setFirstReference({...firstReference, name: {...firstReference?.name, value: value}})}
					error={firstReference?.name?.error}
					checker="onlyLetters"
				/>
				<TextFieldShared
					label="Nombre y apellido"
					name="name"
					value={secondReference?.name?.value}
					className={styles.half}
					onChange={(value: any) => setSecondReference({...secondReference, name: {...secondReference?.name, value: value}})}
					error={secondReference?.name?.error}
					checker="onlyLetters"
				/>
				<SelectShared
                    label="Parentesco"
                    name="relationship"
                    value={firstReference?.relationship?.value}
					onChange={(value: any) => setFirstReference({...firstReference, relationship: {...firstReference?.relationship, value: value}})}
                    className={styles.half}
                    options={RELATIVES}
                    keyValue="name"
					error={firstReference?.relationship?.error}
                />
				<SelectShared
                    label="Parentesco"
                    name="relationship"
                    value={secondReference?.relationship?.value}
                    onChange={(value: any) => setSecondReference({...secondReference, relationship: {...secondReference?.relationship, value: value}})}
                    className={styles.half}
                    options={RELATIVES}
                    keyValue="name"
					error={secondReference?.relationship?.error}
                />
				<PhoneInput
					label="Telefono"
					numberValue={firstReference?.phone?.number?.value}
					codeValue={firstReference?.phone?.code}
					onChange={(value: any, type: string) => handleChangePhone(value, type, firstReference, setFirstReference)}
					error={firstReference?.phone?.number?.error}
					className={styles.half}
				/>
				<PhoneInput
					label="Telefono"
					numberValue={secondReference?.phone?.number?.value}
					codeValue={secondReference?.phone?.code}
					onChange={(value: any, type: string) => handleChangePhone(value, type, secondReference, setSecondReference)}
					error={secondReference?.phone?.number?.error}
					className={styles.half}
				/>
			</div>
			<Button 
				onPress={onConfirmReferences} 
				title="Guardar Cambios" 
				primary 
				disabled={types.fetching === "loading" || types.fetching === "failure"}
				loading={fetchingEdit === "loading"} 
				className={styles.submitButton}
			/>
		</div>
	)
}

export default References;