import { Dispatch } from "@reduxjs/toolkit";
import { api } from "../config/api";
import { GetState } from "../config/store";
import { AuthActionPost, NewUserProps } from "../interfaces/auth";
import { RefreshTokenTypes } from "../interfaces/types";
import {
    logInFailure,
    logInRequest as logInRequestReducer,
    logInSuccess,
    externalLogInSuccess,
    cleanExternalUser as cleanExternalUserReducer,
    cleanExternalLogin as cleanExternalLoginReducer,
    logOut,
    refreshTokenRequest as refreshTokenRequestReducer,
    refreshTokenSuccess,
    refreshTokenFailure,
    initRefreshType as initRefreshTypeReducer,
    editUserRequest as editUserRequestReducer,
    editUserSuccess,
    editUserFailure,
    resetPasswordRequest,
    resetPasswordSuccess,
    resetPasswordFailure,
    updatePasswordRequest,
    updatePasswordSuccess,
    updatePasswordFailure,
    registerUserRequest,
    registerUserSuccess,
    registerUserFailure,
    cleanErrRegister,
    cleanEditUser as cleanEditUserReducer,
    setLoginRedirection as setLoginRedirectionReducer,
    postUserImageRequest,
    postUserImageSuccess,
    postUserImageFailure,
    cleanPostImage as cleanPostImageReducer,
    deleteUserRequest,
    deleteUserSuccess,
    deleteUserFailure,
    cleanDeleteUser as cleanDeleteUserReducer,
    reSendValidationRequest,
    reSendValidationSuccess,
    reSendValidationFailure,
    cleanReSendValidation as cleanReSendValidationReducer
} from "../reducers/auth";
import jwt from "jwt-decode";
import { UserProps } from "../interfaces";
import { axiosClient } from "../config/axios";
import { datadogRum } from "@datadog/browser-rum";

export const loginRequest = (user: AuthActionPost) => (dispatch: Dispatch) => _loginRequest(user, dispatch);
const _loginRequest = async (user: AuthActionPost, dispatch: Dispatch) => {
    try {
        dispatch({ type: logInRequestReducer });

        const response = await axiosClient.post(`users/access`, user);
        if (response?.data?.data?.user?.profile?.code !== 'final-user') {
            throw response;
        }
        // @ts-ignore
        const { exp } = jwt(response?.data?.data.token);

        sessionStorage.setItem("userSession",
            JSON.stringify({
                user: response?.data.data.user,
                token: response?.data.data.token,
                refreshToken: response?.data.data.refresh_token,
                expirationTime: exp,
            })
        );

        datadogRum.setUser({
            id: response?.data.data?.user?._id,
            name: response?.data.data?.user?.name,
            email: response?.data.data?.user?.email,
            profile: response?.data.data?.user?.profile?.name
        });

        dispatch({
            type: logInSuccess,
            payload: {
                user: response?.data.data.user,
                token: response?.data.data.token,
                refreshToken: response?.data.data.refresh_token,
                expirationTime: exp
            }
        });

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        if (errorResponse?.errors) return dispatch({ type: logInFailure, payload: errorResponse?.errors });
        if (errorResponse?.code) return dispatch({ type: logInFailure, payload: errorResponse?.code });
        if (errorResponse?.message) return dispatch({ type: logInFailure, payload: errorResponse?.message });
        dispatch({ type: logInFailure, payload: "Error de conexión." });
    }
};

export const externalLoginRequest = (type: string, user: any) => (dispatch: Dispatch) => _externalLoginRequest(type, user, dispatch);
const _externalLoginRequest = async (type: string, user: any, dispatch: Dispatch) => {
    try {
        dispatch({ type: logInRequestReducer });

        const body = JSON.stringify(
            (user?.credential)
                ? { idToken: user?.credential }
                : user
        );

        const response = await axiosClient.post(`${api.users}/access/${type}?type=web`, body, {
            headers: {
                "origin-platform": "final_user_web",
                "Content-Type": "application/json",
            }
        });

        if (response?.data?.data?.user?._id) {
            if (response?.data?.data?.user?.profile?.code !== 'final-user') {
                throw response;
            }
            // @ts-ignore
            const { exp } = jwt(response?.data.data.token);

            sessionStorage.setItem("userSession",
                JSON.stringify({
                    user: response?.data.data.user,
                    token: response?.data.data.token,
                    refreshToken: response?.data.data.refresh_token,
                    expirationTime: exp,
                })
            );
            datadogRum.setUser({
                id: response?.data.data?.user?._id,
                name: response?.data.data?.user?.name,
                email: response?.data.data?.user?.email,
                profile: response?.data.data?.user?.profile?.name
            });

            dispatch({
                type: logInSuccess,
                payload: {
                    user: response?.data.data.user,
                    token: response?.data.data.token,
                    refreshToken: response?.data.data.refresh_token,
                    expirationTime: exp
                }
            });
        } else {
            dispatch({
                type: externalLogInSuccess,
                payload: {
                    user: response?.data.data.user
                }
            });
        }

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        if (errorResponse?.errors) return dispatch({ type: logInFailure, payload: errorResponse?.errors });
        if (errorResponse?.code) return dispatch({ type: logInFailure, payload: errorResponse?.code });
        if (errorResponse?.message) return dispatch({ type: logInFailure, payload: errorResponse?.message });
        dispatch({ type: logInFailure, payload: "Error de conexión." });
    }
};

export const cleanExternalUser = () => (dispatch: Dispatch) => _cleanExternalUser(dispatch);
const _cleanExternalUser = async (dispatch: Dispatch) => dispatch({ type: cleanExternalUserReducer });

export const cleanExternalLogin = () => (dispatch: Dispatch) => _cleanExternalLogin(dispatch);
const _cleanExternalLogin = async (dispatch: Dispatch) => dispatch({ type: cleanExternalLoginReducer });

export const logoutUser = () => (dispatch: Dispatch) => _logoutUser(dispatch);
const _logoutUser = async (dispatch: Dispatch) => {
    try {
        dispatch({ type: logOut });

        sessionStorage.removeItem("userSession")
    } catch (err: any) {
        console.log(err);
    }
};

export const refreshTokenRequest = (refreshType?: RefreshTokenTypes) => (dispatch: Dispatch, getState: GetState) => _refreshTokenRequest(refreshType, dispatch, getState);
const _refreshTokenRequest = async (refreshType: RefreshTokenTypes = "", dispatch: Dispatch, getState: GetState) => {
    try {
        const refreshToken = getState().auth.refreshToken;

        if (!refreshToken) return;
        if(refreshType === "first_initialization"){
            dispatch({ type: refreshTokenRequestReducer, payload: {refreshType, fetchingFirstTime: true} });
        }else{
            dispatch({ type: refreshTokenRequestReducer, payload: {refreshType, fetchingFirstTime: false} });
        }
        const response = await fetch(process.env.REACT_APP_API_TOKEN || "", {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${refreshToken}`,
            },
        });

        const json = await response.json();
        if (!json.success) throw json;

        const { data: { token, refresh_token, user } } = json;
        const { exp }: any = jwt(token);

        datadogRum.setUser({
            id: user?._id,
            name: user?.name,
            email: user?.email,
            profile: user?.profile?.name
        });

        dispatch({
            type: refreshTokenSuccess,
            payload: {
                token: token,
                refreshToken: refresh_token,
                expirationTime: exp,
                refreshType: refreshType,
                user: user,
                fetchingFirstTime: false
            }
        });

    } catch (err: any) {
        const errorResponse = err?.error?.message;
        dispatch({ type: refreshTokenFailure, payload: errorResponse });
    }
};

export const initRefreshType = () => (dispatch: Dispatch) => _initRefreshType(dispatch);
const _initRefreshType = async (dispatch: Dispatch) => dispatch({ type: initRefreshTypeReducer });


export const editUserRequest = (user: UserProps) => (dispatch: Dispatch, getState: GetState) => _editUserRequest(user, dispatch, getState);
const _editUserRequest = async (user: UserProps, dispatch: Dispatch, getState: GetState) => {
    try {
        dispatch({ type: editUserRequestReducer });
        const userId = getState().auth.user?._id;

        const response = await axiosClient.patch(`${api.users}/${userId}`, user);

        dispatch({ type: editUserSuccess, payload: response?.data?.data?.user });
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse?.code);
        dispatch({ type: editUserFailure, payload: errorResponse?.code });
    }
};

export const cleanEditUser = () => (dispatch: Dispatch) => _cleanEditUser(dispatch);
const _cleanEditUser = async (dispatch: Dispatch) => dispatch({ type: cleanEditUserReducer });

export const resetPassword = (email: string) => (dispatch: Dispatch) => _resetPassword(email, dispatch);
const _resetPassword = async (email: string, dispatch: Dispatch) => {
    try {
        dispatch({ type: resetPasswordRequest });

        await axiosClient.post(`${api.users}/reset-password`, { email: email });

        dispatch({ type: resetPasswordSuccess, payload: email });
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse?.code);
        dispatch({ type: resetPasswordFailure, payload: errorResponse?.code })
    }
};

export const updatePassword = (email: string, code: string, password: string) => (dispatch: Dispatch) => _updatePassword(email, code, password, dispatch)
const _updatePassword = async (email: string, code: string, password: string, dispatch: Dispatch) => {
    try {
        dispatch({ type: updatePasswordRequest });

        await axiosClient.post(`${api.users}/update-password`, {
            email: email,
            code: code,
            password: password
        });

        dispatch({ type: updatePasswordSuccess })
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse?.code);
        dispatch({ type: updatePasswordFailure, payload: errorResponse?.code });
    }
}

export const registerRequest = (newUser: NewUserProps) => (dispatch: Dispatch) => _registerRequest(newUser, dispatch);
const _registerRequest = async (newUser: NewUserProps, dispatch: Dispatch) => {
    try {
        dispatch({ type: registerUserRequest });

        const response = await axiosClient.post(`${api.users}`, newUser);

        dispatch({ type: registerUserSuccess, payload: response?.data.data.user });

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse?.code);
        if (errorResponse?.errors) return dispatch({ type: registerUserFailure, payload: errorResponse.errors });
        if (errorResponse?.code) return dispatch({ type: registerUserFailure, payload: errorResponse.code });
        dispatch({ type: registerUserFailure, payload: "Error de conexión." });
    }
};

export const cleanErrorRegister = () => (dispatch: Dispatch) => _cleanErrorRegister(dispatch);
const _cleanErrorRegister = async (dispatch: Dispatch) => dispatch({ type: cleanErrRegister });

export const setLoginRedirection = (route: string) => (dispatch: Dispatch) => _setLoginRedirection(route, dispatch);
const _setLoginRedirection = (route: string, dispatch: Dispatch) => dispatch({ type: setLoginRedirectionReducer, payload: route });


export const postUserImage = (formData: any) => (dispatch: Dispatch) => _postUserImage(formData, dispatch);
export const _postUserImage = async (formData: any, dispatch: Dispatch) => {
    try {
        dispatch({ type: postUserImageRequest });

        const response = await axiosClient.post(`${api.users}`, formData);

        dispatch({ type: postUserImageSuccess, payload: response?.data?.data?.file });
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse?.code);
        dispatch({ type: postUserImageFailure });
        return "error";
    }
};

export const cleanPostImage = () => (dispatch: Dispatch) => _cleanPostImage(dispatch);
const _cleanPostImage = async (dispatch: Dispatch) => dispatch({ type: cleanPostImageReducer });

export const deleteUser = (id: string) => (dispatch: Dispatch) => _deleteUser(id, dispatch);
const _deleteUser = async (id: string, dispatch: Dispatch) => {
    try {
        dispatch({ type: deleteUserRequest });

        await axiosClient.delete(`${api.users}/${id}`);

        dispatch({ type: deleteUserSuccess });
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse?.code);
        dispatch({ type: deleteUserFailure });
    }
}

export const cleanDeleteUser = () => (dispatch: Dispatch) => _cleanDeleteUser(dispatch);
const _cleanDeleteUser = async (dispatch: Dispatch) => dispatch({ type: cleanDeleteUserReducer });

export const reSendValidation = () => (dispatch: Dispatch) => _reSendValidation(dispatch);
const _reSendValidation = async (dispatch: Dispatch) => {
    try {
        dispatch({ type: reSendValidationRequest });

        await axiosClient.post(`${api.users}/account/resend-verification`);

        dispatch({ type: reSendValidationSuccess });
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse?.code);
        dispatch({ type: reSendValidationFailure });
    }
};

export const cleanReSendValidation = () => (dispatch: Dispatch) => _cleanReSendValidation(dispatch);
const _cleanReSendValidation = async (dispatch: Dispatch) => dispatch({ type: cleanReSendValidationReducer });