import { useEffect, useMemo, useRef, useState } from "react";
import { images } from "../../constants/images";
import styles from "./notifications.module.scss";
import moment from 'moment';
import { capitalize } from "../../helpers/app";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../helpers/hooks";
import { CircularProgress } from "@mui/material";
import { NotificationsProps } from "../../interfaces/components";
import DiscountLinzer from "../../containers/DiscountLinzer/DiscountLinzerContainer";
import InspectionApproval from "../../containers/InspectionApproval/InspectionApprovalContainer";
import CounterOfferSidebar from "../../containers/CounterOfferSidebar/EntryPointCountainer";

const Notifications = ({ 
    getNotifications,
    notifications, 
    fetchingNotifications, 
    fetchingRead, 
    putReadNotification,
    inspection,
    getInspectionSchedule,
    clearInspectionSchedule,
    getDiscount,
    discount,
    cleanGetDiscount,
    getInspectionApproval,
    inspectionApproval,
    cleanGetInspectionApproval,
    fetchingRefreshToken,
    setOfferSelected
 }: NotificationsProps) => {
    const toast = useToast();
    const navigate = useNavigate();
    const scrollNotificationsRef: any = useRef(null);
    const [limit, setLimit] = useState<number>(5);
    const [notificationSelected, setNotificationSelected] = useState<any>();
    const [discountLinzerModal, setDiscountLinzerModal] = useState<any>({open: false, type: "seller"});
    const [inspectionApprovalModal, setInspectionApprovalModal] = useState<any>({open: false, notification: {}});
    const [counterOfferSidebar, setCounterOfferSidebar] = useState<any>({open: false, publicationId: "", offerId: ""});

    const onShowMore = () => {
        scrollNotificationsRef.current.scroll(0, 1000000);
        if(limit === 20){
            toast("Solamente puedes ver hasta 20 notificaciones");
            return;
        }
        setLimit(limit + 5);
    }
    
    const onSetofferSelected = (item: any) => {
        setOfferSelected(item?.push_notification?.data?.offer);
        setCounterOfferSidebar({open: true, offerId: item?.push_notification?.data?.offer, publicationId: item?.push_notification?.data?.publication})
    }
    
    const handlePress = (item: any) => {
        const type = item?.push_notification?.data?.type;
        item = {...item, reading: false};
        setNotificationSelected(item);
        if(!item?.read){
            putReadNotification({
                id: item?._id,
                read: true,
                deleted: false
            });
        }

        switch(type){
            case "publication-created":
                navigate(`profile/my_publications/${item?.push_notification?.data?.publication}/detail`);
                break;
            case "publication-counteroffer-pending":
                (item?.push_notification?.data?.type_user === "buyer") 
                ? onSetofferSelected(item)
                : navigate(`profile/my_publications/${item?.push_notification?.data?.publication}/management`);
                break;
            case "publication-counteroffer-rejected":
            case "publication-counteroffer-accepted":
                navigate(`publication/${item?.push_notification?.data?.publication}`);
                break;
            case "publication-offer-rejected":
            case "openai-chatgpt":
            case "publication-offer-time-left":
            case "publication-offer-accepted":
                navigate(`publication/${item?.push_notification?.data?.publication}`);
                break;
            case "publication-offer-pending":
                if(item?.push_notification?.data?.type_user === "seller") navigate(`profile/my_publications/${item?.push_notification?.data?.publication}/management`);
                break;
            case "signature-enrollment":
            case "mail-validation-pending":
                window.open("https://mail.google.com/");
                break;
            case "mail-validation-error":
                navigate(`profile/my_account`);
                break;
            case "signature-activate":
                navigate(`profile/documentation`);
                break;
            case "payment-last-payment":
                navigate(`profile/my_buys/${item?.push_notification?.data?.transaction}/management`);
                break;
            case "transaction-is-accepted":
            case type.match("credit"):
                if(item?.push_notification?.data?.type_user === "buyer") navigate(`profile/my_buys/${item?.push_notification?.data?.transaction}/management`);
                break;
            case "payment-discount-difference":
                navigate(`profile/my_buys`);
                break;
            case "inspection":
            case "inspection-rejected":
                navigate(`profile/my_buys/${item?.push_notification?.data?.publication}/inspection`);
                break;
            case "inspection-approval-pending": 
                getInspectionApproval(item?.push_notification?.data?.inspection_approval, item?.push_notification?.data?.type_user);
                setInspectionApprovalModal({open: false, notification: item?.push_notification?.data});
                break;
            case "transaction-created":
                if(item?.push_notification?.data?.type_user === "seller") getInspectionSchedule(item?.push_notification?.data?.transaction);
                break;
            case "transaction-discount-pending":
                getDiscount(item?.push_notification?.data?.discount);
                setDiscountLinzerModal({open: true, type: item?.push_notification?.data?.type_user});
                break;
            case "transaction-is-accepted-instruction":
                item?.push_notification?.data?.navigateTo === "instructionSeller" ? navigate(`/frequent_questions/sell`) : navigate(`/frequent_questions/buy`);
                break;
        }
    }

    useEffect(() => {
        if(inspectionApproval?.fetchingGet === "loading"){
            setNotificationSelected({...notificationSelected, reading: true});
        }
        if(inspectionApproval?.fetchingGet === "failure"){
            setNotificationSelected({...notificationSelected, reading: false});
            cleanGetInspectionApproval();
        }
        if(inspectionApproval?.fetchingGet === "success"){
            setNotificationSelected({...notificationSelected, reading: false});
            if(!inspectionApproval?.fault){
                toast("La inspección ya fue aceptada");
            }else{
                setInspectionApprovalModal({...inspectionApprovalModal, open: true});
            }
            cleanGetInspectionApproval();
        }
    }, [inspectionApproval?.fetchingGet])

    useEffect(() => {
        if(discount?.get?.fetching === "loading"){
            setNotificationSelected({...notificationSelected, reading: true});
        }
        if(discount?.get?.fetching === "failure"){
            toast("Ha ocurrido un error al mostrar el descuento");
            setNotificationSelected({...notificationSelected, reading: false});
            cleanGetDiscount();
        }
        if(discount?.get?.fetching === "success"){
            setNotificationSelected({...notificationSelected, reading: false});
            cleanGetDiscount();
        }
    }, [discount?.fetching]);

    useEffect(() => {
        if(inspection?.fetching === "loading"){
            setNotificationSelected({...notificationSelected, reading: true});
        }
        if(inspection?.fetching === "failure"){
            toast("Ha ocurrido un error");
            setNotificationSelected({...notificationSelected, reading: false});
            clearInspectionSchedule();
        }
        if(inspection?.fetching === "success" && inspection?.data && !inspection?.data?.inspection_schedule){
            navigate(`/sell/${notificationSelected?.push_notification?.data?.transaction}/schedule_inspection`);
            setNotificationSelected({...notificationSelected, reading: false});
            clearInspectionSchedule();
        }
        if(inspection?.fetching === "success" && inspection?.data && inspection?.data?.inspection_schedule){
            setNotificationSelected({...notificationSelected, reading: false});
            toast("Ya has aceptado la transacción");
        }
    }, [inspection?.fetching]);

    useEffect(() => {
        if(fetchingRead === "failure"){
            setNotificationSelected({...notificationSelected, reading: false});
            toast("Ha ocurrido un error al leer la notificación");
        }
        if(fetchingRead === "success"){
            setNotificationSelected({...notificationSelected, reading: false});
        }
    }, [fetchingRead]);

    let notificationsTimeout = useMemo(() => {
        if(fetchingNotifications === "success" && fetchingRefreshToken !== "loading"){
            return setTimeout(() => getNotifications(limit), 60000);
        }
    }, [fetchingNotifications])

    useEffect(() => {
        clearTimeout(notificationsTimeout);
        getNotifications(limit);
    }, [limit]);

    return (
        <>
            <InspectionApproval open={inspectionApprovalModal?.open} setOpen={setInspectionApprovalModal} notification={inspectionApprovalModal?.notification} />
            <DiscountLinzer open={discountLinzerModal?.open} setOpen={setDiscountLinzerModal} type={discountLinzerModal?.type} />
            <CounterOfferSidebar 
                offerId={counterOfferSidebar?.offerId} 
                publicationId={counterOfferSidebar?.publicationId} 
                open={counterOfferSidebar?.open} 
                onClose={() => setCounterOfferSidebar({...counterOfferSidebar, open: false})} 
            />
            <div id="notification-modal" className={styles.notificationsContainer}>
                {
                    notifications && notifications.length === 0 && fetchingNotifications === "loading"
                    ?
                        <div className={styles.emptyLoader}>
                            <img src={images.loader} alt="Cargando notificaciones" />
                        </div>
                    :
                        notifications && notifications.length > 0
                        ?
                            <>
                                {
                                    fetchingNotifications === "loading" &&
                                    <CircularProgress 
                                        size={styles.loader}
                                        classes={{
                                            colorPrimary: styles.loader
                                        }}
                                    />
                                }
                                <div className={styles.scroll} ref={scrollNotificationsRef}>
                                    {
                                        notifications.map((item: any, index: number) => {
                                            return (
                                                <div key={index} className={`${styles.item} ${(item?.read) ? styles.readed : undefined}`} onClick={() => handlePress(item)}>
                                                    <img 
                                                        src={item?.push_notification?.data?.icon && item?.push_notification?.data?.icon.length > 0 ? item?.push_notification?.data?.icon : ((item?.read) ? images?.notificationDefaultReaded : images?.notificationDefaultNoReaded)} 
                                                        alt="Icon" 
                                                        className={styles.icon} 
                                                    />
                                                    {
                                                        item?._id === notificationSelected?._id && notificationSelected?.reading &&
                                                        <CircularProgress 
                                                            size={styles.loader}
                                                            classes={{
                                                                colorPrimary: styles.loader
                                                            }}
                                                        />
                                                    }
                                                    <div className={styles.itemContainer}>
                                                        <p className={styles.title}>{item.push_notification?.notification?.title}</p>
                                                        <p className={styles.description}>{item.push_notification?.notification?.body}</p>
                                                        <p className={styles.date}>
                                                            {
                                                                capitalize(
                                                                    moment(item?.created_at)
                                                                    .fromNow()
                                                                    .replace('hace ', '')
                                                                    .replace('horas', 'hr')
                                                                    .replace('minutos', 'min')
                                                                    .replace('segundos', 'seg')
                                                                )
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                {
                                    limit !== 20 &&
                                    <div className={styles.showMore}>
                                        <p onClick={onShowMore}>Ver más</p>
                                    </div>
                                }
                            </>
                        :
                            <div className={styles.emptyNotifications}>
                                <img src={images.noNotifications} alt="" />
                                <p>
                                    <span className={styles.title}>No tienes notificacion aún</span>
                                    <span className={styles.description}>Podrás ver en esta sección las futuras notificaciones que recibas</span>
                                </p>
                            </div>
                }
            </div>
        </>
    )
}
export default Notifications;