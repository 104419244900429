import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { DeleteContainerProps } from "../../../interfaces/containers";
import Delete from "../../../pages/Profile/MyPublications/Delete";
import { getMyPublicationsRequest } from "../../../actions/profile";
import { cleanDeletePublication, deletePublication } from "../../../actions/publication";

function mapStateToProps(state: DeleteContainerProps) {
    return {
        deleteFetching: state.publication.deleteFetching
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        deletePublication,
        cleanDeletePublication,
        getMyPublicationsRequest
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Delete);