import { RouteObject } from "../../interfaces/navigation";
import React from "react";
const EntryPoint = React.lazy(() => import("../../containers/Register/RegisterContainer"));

export const registerRoutes: RouteObject[] = [
    {
        title: "Register",
        path: "/register",
        component: <EntryPoint/>
    }
];