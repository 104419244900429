import { RouteObject } from "../../interfaces/navigation";
import React from "react";
const EntryPoint = React.lazy(() => import("../../containers/Login/LoginContainer"));

export const loginRoutes: RouteObject[] = [
    {
        title: "Login",
        path: "/login",
        component: <EntryPoint/>
    }
];