import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { NotificationsContainerProps } from "../../interfaces/containers";
import { getNotifications, putReadNotification } from "../../actions/notifications";
import Notifications from "../../components/Notifications";
import { getInspectionSchedule, clearInspectionSchedule } from "../../actions/scheduling";
import { getDiscount, cleanGetDiscount } from "../../actions/discount";
import { cleanGetInspectionApproval, getInspectionApproval } from "../../actions/inspection";
import { setOfferSelected } from "../../actions/profile";

function mapStateToProps(state: NotificationsContainerProps) {
    return{
        notifications: state.notifications.notifications,
        fetchingNotifications: state.notifications.fetching,
        fetchingRead: state.notifications.fetchingRead,
        inspection: state.scheduling.inspection,
        discount: state.discount,
        inspectionApproval: state.inspection.approval,
        fetchingRefreshToken: state.auth.fetchingRefreshToken
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        getDiscount,
        cleanGetDiscount,
        getNotifications,
        putReadNotification,
        getInspectionSchedule,
        clearInspectionSchedule,
        getInspectionApproval,
        cleanGetInspectionApproval,
        setOfferSelected
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);