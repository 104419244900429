import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { MyBuysManagementContainerProps } from "../../../../interfaces/containers";
import Management from "../../../../pages/Profile/MyBuys/Management";
import { 
    getPublication, 
    cleanPublication,
    getInspections, 
    cleanInspection,
    getInspectionsFiles,
    cleanInspectionFiles,
    getOffersBuy,
    cleanOffersBuy,
    getHistoryPublication, 
    cleanHistory,
} from "../../../../actions/publication";
import { 
    getMyBuy,
    cleanMyBuy,
    setOfferSelected,
    getPayments,
    getCreditData
 } from "../../../../actions/profile";

function mapStateToProps(state: MyBuysManagementContainerProps) {
    return{
        myBuy: state.profile.myBuy,
        publication: state.publication,
        offerSelected: state.profile.offerSelected
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        getMyBuy, 
        cleanMyBuy,
        getPublication,
        cleanPublication,
        getInspections,
        cleanInspection,
        getInspectionsFiles,
        cleanInspectionFiles,
        getOffersBuy,
        cleanOffersBuy,
        setOfferSelected,
        getHistoryPublication,
        cleanHistory,
        getPayments,
        getCreditData
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Management);