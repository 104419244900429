import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { InspectionApprovalContainerProps } from "../../interfaces/containers";
import InspectionApproval from "../../components/InspectionApproval";
import { getInspectionApproval, cleanGetInspectionApproval, putInspectionApproval, cleanPutInspectionApproval } from "../../actions/inspection";
import { getArticlesFromPublication } from "../../actions/publication";

function mapStateToProps(state: InspectionApprovalContainerProps) {
    return{
        inspectionApproval: state.inspection.approval,
        publication: state.publication.articles
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        getInspectionApproval, 
        cleanGetInspectionApproval, 
        putInspectionApproval, 
        cleanPutInspectionApproval,
        getArticlesFromPublication
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(InspectionApproval);