import Address from "../../../containers/Profile/Documentation/Address/AddressContainer";
import BankData from "../../../containers/Profile/Documentation/BankData/BankDataContainer";
import Identity from "../../../containers/Profile/Documentation/Identity/IdentityContainer";
import Previred from "../../../containers/Profile/Documentation/Previred/PreviredContainer";
import References from "../../../containers/Profile/Documentation/References/ReferencesContainer";
import Salary from "../../../containers/Profile/Documentation/Salary/SalaryContainer";
import Documentation from "../../../containers/Profile/Documentation/EntryPointContainer";
import { RouteObject } from "../../../interfaces/navigation";

export const documentationSubRoutes: RouteObject[] = [
    {
        title: "Dirección",
        path: "address",
        component: <Address/>
    },
    {
        title: "Datos Bancarios",
        path: "bank_data",
        component: <BankData/>
    },
    {
        title: "Identificación",
        path: "identity",
        component: <Identity/>
    },
    {
        title: "Certificado Previred",
        path: "previred",
        component: <Previred/>
    },
    {
        title: "Referencias",
        path: "references",
        component: <References/>
    },
    {
        title: "Salario",
        path: "salary",
        component: <Salary/>
    }
];

export const documentationRoutes: RouteObject[] = [
    {
        title: "Documentos",
        path: "documentation",
        component: <Documentation/>,
        subRoutes: documentationSubRoutes
    }
];