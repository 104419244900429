import { api } from "../config/api";
import { axiosClient } from "../config/axios";
import { CoordsProps } from '../interfaces/index';

export const getPlaces = (text: string = "", coords: CoordsProps) => () =>  _getPlaces(text, coords);
export const _getPlaces = async (text: string, coords: CoordsProps) => {
    try {
        const response = await axiosClient.get(`${api.bikleta}?query=${text}&location=${coords.lat},${coords.long}&radius=10000&language=es-419`);

        return response?.data?.results;
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        return errorResponse?.code;
    }
};