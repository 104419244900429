import { RouteObject } from "../../interfaces/navigation";
import React from "react";
const EntryPoint = React.lazy(() => import("../../containers/DeleteAccount/DeleteAccountContainer"));

export const deleteAccountRoutes: RouteObject[] = [
    {
        title: "deleteAccount",
        path: "/delete_account",
        component: <EntryPoint/>
    }
];