
import styles from './selectDocuments.module.scss';
import { SelectSharedProps } from '../../interfaces/components';
import { FormControl, TextField, FormHelperText, MenuItem } from '@mui/material';

const SelectDocuments = ({
    label,
    name,
    options,
    className,
    formik,
    value,
    error,
    touched,
    onChange = () => { },
    disabled = false,
    keyValue = "name",
    onClickItem
}: SelectSharedProps) => {
    return (
        <FormControl className={`${styles.input} ${className}`} error={touched && Boolean(error)}>
            {
                <TextField
                    select
                    disabled={disabled}
                    fullWidth
                    autoComplete="off"
                    id={name}
                    name={name}
                    label={label}
                    value={value}
                    onChange={onChange ? onChange : formik.handleChange}
                >
                    {
                        options && options.map((option: any, index: number) => {
                            return <MenuItem onClick={() => onClickItem && onClickItem(option)} className={`${option?.flag !== undefined && styles.flag}`} key={index} value={option?.dial_code ?? option?.[keyValue]}>{option?.flag || option?.name}</MenuItem>
                        })
                    }
                </TextField>
            }
            <FormHelperText className={styles.helperText}>{touched && error}</FormHelperText>
        </FormControl>
    );
};

export default SelectDocuments;