import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { MyBuysContainerProps } from "../../../interfaces/containers";
import EntryPoint from "../../../pages/Profile/MyBuys/EntryPoint";
import { getMyBuysRequest, cleanMyBuys } from "../../../actions/profile";

function mapStateToProps(state: MyBuysContainerProps) {
    return{
        fetchingMyBuys: state.profile.fetchingMyBuys,
        myBuys: state.profile.myBuys,
        totalBuys: state.profile.totalBuys,
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        getMyBuysRequest,
        cleanMyBuys
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(EntryPoint);