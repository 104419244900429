import { useEffect } from "react";
import styles from "./interested.module.scss";
import { useToast } from "../../../../../helpers/hooks";
import { images } from "../../../../../constants/images";
import { InterestedManagementProps } from "../../../../../interfaces/components";

const Interested = ({
    id,
    getInterested,
    interested,
    cleanGetInterested,
    className
}: InterestedManagementProps) => {
    const toast = useToast();

    useEffect(() => {
        if(interested?.fetching === "failure"){
            toast("Ha ocurrido un error mostrar la vistas");
            cleanGetInterested();
        }
        if(interested?.fetching === "success"){
            cleanGetInterested();
        }
    }, [interested?.fetching]);

    useEffect(() => {
        if(id){
            getInterested(id);
        }
    }, []);

    return(
        <div className={`${styles.interested} ${className}`}>
            <div className={styles.content}>
                <div className={styles.cardLeft}>
                    <p className={`${styles.text} ${styles.title}`}>Me interesa</p>
                    <p className={`${styles.text} ${styles.value}`}>{interested?.data ?? 0}</p>
                    <p className={`${styles.text} ${styles.period}`}>Últimos 30 días</p>
                </div>
                <img src={images.purpleCursor} className={styles.cardImg} />
            </div>
        </div>
    )
};

export default Interested;