import { memo, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const EntryPoint = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if(location.pathname === "/frequent_questions"){
            navigate("buy");
        }
    },[location]);

    return (
        <Outlet/>
    )
}

export default memo(EntryPoint);