import { useEffect } from "react";
import styles from "../frequentQuestions.module.scss";
import { images } from "../../../constants/images";
import MenuQuestions from "../../../components/MenuQuestions";
import Icon from '@mdi/react';
import { mdiCurrencyUsd, mdiFileDocumentOutline, mdiAccountOutline, mdiFileDocumentEditOutline, mdiCertificateOutline, mdiHomeOutline, mdiBankOutline, mdiAccountGroupOutline, mdiCashMultiple } from '@mdi/js';

const Financing = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={styles.content}>
            <div className={styles.questionsContainer}>
                <MenuQuestions section={3} />
                <div className={styles.sectionContent}>
                    <div className={`${styles.item} ${styles.howSell}`}>
                        <p className={styles.title}>En Linze podras financiar la compra de tu nuevo auto</p>
                        <p className={styles.description}>
                            ¡Simulamos tu crédito al instante! Simplemente cargas la documentación, eliges un vehículo, las características del crédito que prefieras y listo. En menos de 48hs obtendrá una respuesta acerca del estado de su crédito.
                        </p>
                        <div className={styles.info}>
                            <p className={styles.title}>Requisitos</p>
                            <div className={styles.option}>
                                <div className={styles.optionContent}>
                                    <Icon path={mdiCurrencyUsd} className={styles.icon} title="Foot value" />
                                    <p className={styles.data}>Contar con un pie igual o mayor al 30% del valor total del auto.</p>
                                </div>
                            </div>
                            <div className={styles.option}>
                                <div className={styles.optionContent}>
                                    <Icon path={mdiFileDocumentOutline} className={styles.icon} title="Documentation" />
                                    <p className={styles.data}>Cargar la documentación necesaria:</p>
                                </div>
                                <div className={styles.option}>
                                    <div className={styles.optionContent}>
                                        <Icon path={mdiAccountOutline} className={styles.icon} title="Identity" />
                                        <p className={styles.data}>Carnet de identidad (imagen de frente y dorso).</p>
                                    </div>
                                </div>
                                <div className={styles.option}>
                                    <div className={styles.optionContent}>
                                        <Icon path={mdiFileDocumentEditOutline} className={styles.icon} title="Liquidation" />
                                        <p className={styles.data}>Liquidación de sueldo (completar el formulario de solicitud de crédito el cual especifica su renta líquida, antigüedad laboral, tipo de contrato y cómo recibe sus ingresos).</p>
                                    </div>
                                </div>
                                <div className={styles.option}>
                                    <div className={styles.optionContent}>
                                        <Icon path={mdiBankOutline} className={styles.icon} title="Bank" />
                                        <p className={styles.data}>Datos bancarios (deberá ingresar los datos de su cuenta bancaria: nombre del banco, tipo de cuenta y número de cuenta).</p>
                                    </div>
                                </div>
                                <div className={styles.option}>
                                    <div className={styles.optionContent}>
                                        <Icon path={mdiAccountGroupOutline} className={styles.icon} title="References" />
                                        <p className={styles.data}>Referencias (deberá ingresar 2 contactos de referencia indicando sus nombres, parentesco y números telefónicos).</p>
                                    </div>
                                </div>
                            </div>
                            <p className={styles.title}>En Linze contamos con dos modalidades de créditos</p>
                            <div className={styles.option}>
                                <div className={styles.optionContent}>
                                    <Icon path={mdiCashMultiple} className={styles.icon} title="Credit" />
                                    <p className={styles.data}>Crédito convencional</p>
                                </div>
                                <div className={styles.option}>
                                    <div className={styles.optionContent}>
                                        <p className={styles.data}>Cuotas fijas y en pesos.</p>
                                    </div>
                                </div>
                                <div className={styles.option}>
                                    <div className={styles.optionContent}>
                                        <p className={styles.data}>Pie mínimo del 30% del valor total del auto.</p>
                                    </div>
                                </div>
                                <div className={styles.option}>
                                    <div className={styles.optionContent}>
                                        <p className={styles.data}>Plazo de 12, 24, 36 o 48 cuotas.</p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.option}>
                                <div className={styles.optionContent}>
                                    <Icon path={mdiCashMultiple} className={styles.icon} title="Credit" />
                                    <p className={styles.data}>Crédito Inteligente:</p>
                                </div>
                                <div className={styles.option}>
                                    <div className={styles.optionContent}>
                                        <p className={styles.data}>Renueva tu auto cada dos o tres años.</p>
                                    </div>
                                </div>
                                <div className={styles.option}>
                                    <div className={styles.optionContent}>
                                        <p className={styles.data}>Cuotas bajas, fijas y en pesos. Incluye una VFMG* al final del período.</p>
                                    </div>
                                </div>
                                <div className={styles.option}>
                                    <div className={styles.optionContent}>
                                        <p className={styles.data}>Pie mínimo del 30% del valor total del auto.</p>
                                    </div>
                                </div>
                                <div className={styles.option}>
                                    <div className={styles.optionContent}>
                                        <p className={styles.data}>Plazo de 24 o 36 meses.</p>
                                    </div>
                                </div>
                                <div className={styles.option}>
                                    <div className={styles.optionContent}>
                                        <p className={styles.data}>Renueva, refinancia o conserva tu vehículo al final del período.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Financing;