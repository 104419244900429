import styles from './shareButton.module.scss';
import { IconButton, Skeleton } from '@mui/material';
import { useToast } from "../../helpers/hooks";
import { copyToClipboard } from '../../helpers/app';
import { ShareButtonProps } from '../../interfaces/components';
import { images } from '../../constants/images';

const ShareButton = ({
    className,
    loading = false,
    withText = false,
    color = "black",
    size = "normal",
    path = null
}: ShareButtonProps) => {
    const toast = useToast();

    const handlePressShare = () => {
        copyToClipboard(`https://${process.env.REACT_APP_DOMAIN}.cl${path ?? window.location.pathname}`);
        toast("URL copiada al portapapeles");
    }

    return (
        <>
        {
            loading
            ? <Skeleton animation="wave" variant="circular" className={styles.skeleton} />
            : 
            <div className={`${styles.share} ${className} ${color === "black" ? styles.black : styles.blue} ${size === "small" && styles.small}`} onClick={(event: any) => handlePressShare()}>
                <IconButton id="icon">
                    <img src={images.share} alt="share" title="Compartir" className={styles.icon} />
                </IconButton>
                {
                    withText &&
                    <p className={styles.text}>Compartir</p>
                }
            </div>
        }
        </>
    );
};

export default ShareButton;