import { useEffect, useMemo, useState } from 'react';
import styles from './select.module.scss';
import { SelectSharedProps } from '../../interfaces/components';
import { FormControl, Select, MenuItem, ClickAwayListener, IconButton } from '@mui/material';
import ButtonShared from '../Button/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { capitalize } from '../../helpers/app';
import Icon from '@mdi/react';
import { mdiChevronDown, mdiCheck, mdiMagnify } from '@mdi/js';
import TextFieldShared from '../TextField/TextField';

const SelectShared = ({
    label,
    name,
    options,
    className,
    selectClassName,
    optionsClassName,
    value,
    error = "",
    multiple = false,
    open,
    onChange = () => {},
    onOpen = () => {},
    onClose = () => {},
    onPressOption = () => {},
    disabled = false,
    autoFocus,
    loading = false,
    variant = "filled",
    optionsEmptyMessage = "",
    customOptions,
    keyValue = "id",
    fullWidth = false,
    arrow = true,
    input = true,
    loadingMessage
}: SelectSharedProps) => {
    const [search, setSearch] = useState<string>("");
    const [showOptions, setShowOptions] = useState(false);
    const [iconSelected, setIconSelected] = useState<any>({icon: "", value: ""});
    const [openSelectorIcon, setOpenSelectorIcon] = useState(false);
    const [optionSelected, setOptionSelected] = useState<any>({value: "", name: ""});

    const onClickAway = () => {
        if(showOptions){
            handlePressSelect();
        }
    }

    const handlePressSelectorIcon = () => {
        if(!loading) setOpenSelectorIcon(!openSelectorIcon);
    }

    const handleChangeIconValue = (value: any) => {
        if(!loading){
            const option = options.find((option: any) => option.id === value);
            setIconSelected(option ? option?.icon : '');
            if (onChange) onChange(option?.id);
        }
    }

    const handleChangeSelectValue = (option: any) => {
        if(!loading && optionSelected?.name !== option?.name){
            setOptionSelected({value: keyValue ? option[keyValue] : option?.id, name: option?.name});
            if (onChange) onChange(keyValue ? option[keyValue] : option?.id);
            handlePressSelect();
        }
    }

    const handlePressSelect = () => {
        if(!disabled){
            setShowOptions(!showOptions);
        }
    }

    const optionsFiltered: any = useMemo(() => {
        let data = options;
        if(search){
            data = options && options.filter((item: any) => item?.name.toLowerCase().includes(search.toLowerCase()));
        }
        return data;
    }, [options, search]);

    useEffect(() => {
        if(variant === "filled") {
            const option = options.find((option: any) => (keyValue ? option[keyValue] : option?.id) === value);
            setOptionSelected({value: value, name: option?.name});
        }
        if(variant === "icon") {
            const option = options.find((option: any) => option.id === value);
            setIconSelected({icon: option ? option?.icon : '', value: option?.id});
        }
    }, [value, options]);

    return (
        <ClickAwayListener onClickAway={onClickAway} disableReactTree={true}>
            <FormControl 
                variant="filled"
                className={`
                    ${fullWidth && styles.fullWidth}
                    ${styles.selectSharedContainer} 
                    ${className}
                    ${(variant === "border" 
                        ? styles.border
                        : variant === "text"
                            ? styles.text
                            : variant === "icon"
                                ? styles.icon
                                : variant === "filled"
                                    ? styles.filled
                                    : styles.scroll
                    )}`
                }
            >
                {
                    variant === "text" &&
                    <Select
                        labelId={name}
                        id={name}
                        disabled={disabled}
                        label={label}
                        name={name}
                        open={open}
                        multiple={multiple}
                        value={value}
                        onOpen={onOpen}
                        onClose={onClose}
                        onChange={onChange}
                        autoFocus={autoFocus}
                        disableUnderline
                        className={`${styles.text} ${styles.select}`}
                        classes={{
                            select: `${selectClassName} ${styles.selected}`,
                            icon: !loading && styles.arrow
                        }}
                        IconComponent={(props: any) => (
                            arrow 
                            ?   <Icon path={mdiChevronDown} {...props} />
                            :   null
                        )}
                    >
                        {
                            options && options.length > 0
                                ?
                                options.map((option: any, index: number) => (
                                    <MenuItem key={index} value={option?.id}>{option?.name}</MenuItem>
                                ))
                                :
                                !!optionsEmptyMessage
                                    ?
                                        <MenuItem value="">{optionsEmptyMessage}</MenuItem>
                                    :
                                        []
                        }
                    </Select>
                }
                {
                    variant === "icon"
                        ?
                        loading
                            ?   <CircularProgress
                                    size={styles.loader}
                                    classes={{
                                        colorPrimary: styles.loader
                                    }}
                                />
                            :   <>
                                    {
                                        !openSelectorIcon
                                            ?
                                            <div className={`${className} ${styles.selector}`} onClick={handlePressSelectorIcon}>
                                                <div className={styles.iconSelected}>
                                                    <p>
                                                        <span className={styles.icon}>{iconSelected?.icon}</span>
                                                        <span className={styles.value}>({iconSelected?.value})</span>
                                                    </p>
                                                </div>
                                                {
                                                    arrow && <Icon path={mdiChevronDown} className={styles.arrow} title="Arrow"/>
                                                }
                                            </div>
                                            :
                                            <SelectShared
                                                label={label}
                                                name={name}
                                                value={value}
                                                open={openSelectorIcon}
                                                disabled={disabled}
                                                onChange={handleChangeIconValue}
                                                onOpen={handlePressSelectorIcon}
                                                onClose={handlePressSelectorIcon}
                                                className={className}
                                                options={options}
                                            />
                                    }
                                </>
                        :
                        null
                }
                {
                    (variant === "border" || variant === "filled") &&
                    <>
                        <div className={`${styles.selector} ${loading && styles.loading} ${(showOptions) && styles.open} ${optionSelected.name && styles.optionSelected}`} onClick={handlePressSelect}>
                            <div className={styles.data}>
                                {
                                    variant === "filled" && optionSelected.name &&
                                    <p className={styles.label}>{label}</p>
                                }
                                <p className={variant === "filled" && optionSelected.name && styles.selected}>{(variant === "filled" && optionSelected.name) ? optionSelected.name : label}</p>
                            </div>
                            {
                                loading 
                                ?   <CircularProgress
                                        size={styles.loader}
                                        classes={{
                                            colorPrimary: styles.loader
                                        }}
                                    />
                                :
                                arrow 
                                ?   <div className={styles.arrow}>
                                        <Icon path={mdiChevronDown} className={styles.icon} />
                                    </div>
                                :   null
                            }
                        </div>
                        <div className={`${styles.options} ${optionsClassName} ${(showOptions) && styles.open}`}>
                            {
                                customOptions && customOptions.length > 0
                                    ?
                                    customOptions
                                    :
                                    options && options.length > 0
                                        ?
                                        options.map((option: any, index: number) => (
                                            <ButtonShared
                                                key={index}
                                                onPress={() => variant === "filled" ? handleChangeSelectValue(option) : onPressOption(option)}
                                                className={styles.option}
                                                title={
                                                    <div className={styles.content}>
                                                        {!option?.isSelected && option?.isNotSelected && option?.isNotSelected}
                                                        {option?.isSelected && (option?.check ?? <Icon path={mdiCheck} className={styles.check} title="Check"/>)}
                                                        <p>{option?.name}</p>
                                                    </div>
                                                }
                                            />
                                        ))
                                        :
                                            !!optionsEmptyMessage
                                            ?
                                                <MenuItem value="">{optionsEmptyMessage}</MenuItem>
                                            :
                                                []
                            }
                        </div>
                        {
                            (variant === "filled") && error && <p className={styles.error}>{capitalize(error)}</p>
                        }
                    </>
                }
                {
                    variant === "scroll" &&
                    <div className={styles.selectorScroll}>
                        {
                            input && 
                            <TextFieldShared
                                variant="search"
                                label={label}
                                name="search"
                                value={search}
                                onChange={(value: any) => setSearch(value)}
                                className={styles.inputSearch}
                            />
                        }
                        <div className={styles.options}>
                            {
                                loading
                                ?
                                    <div className={styles.loaderOptions}>
                                        <CircularProgress 
                                            size={styles.loader}
                                            classes={{
                                                colorPrimary: styles.loader
                                            }}
                                        />
                                        <p>{loadingMessage ?? "Cargando..."}</p>
                                    </div>
                                :
                                    <div className={styles.optionsContainer}>
                                        {
                                            optionsFiltered && optionsFiltered.map((item: any, index: number) => (
                                                <div key={index} className={styles.option} onClick={() => onPressOption(item)}>
                                                    <p>{item?.name}</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                            }
                        </div>
                    </div>
                }
            </FormControl>
        </ClickAwayListener>
    );
};

export default SelectShared;