import { useMemo } from "react";
import styles from "./publicationCardDetail.module.scss"
import { images } from "../../constants/images";
import { addPointsInText } from "../../helpers/app";
import { PublicationCardDetailProps } from "../../interfaces/components";
import ToolTip from "../../components/ToolTip";
import LoaderCardDetail from "./Loader/LoaderCardDetail";

const PublicationCardDetail = ({
    publication,
    children,
    extraDetail,
    type = "full_screen",
    picture,
    information = "",
    loading = false
}: PublicationCardDetailProps) => {

    const publicationData = useMemo(() => {
        if (publication?.publication) {
            return publication.publication;
        }
        return publication;
    }, [publication]);

    return (
        <div className={styles.cardDetailContainer}>
        {
            loading 
            ?   <LoaderCardDetail type={type} children={children} extraDetail={extraDetail} />
            :   type === "full_screen" 
                ?
                    <>
                        <div className={styles.cardContainer}>
                            <div className={styles.noChildren}>
                                <div className={styles.cardTitleContainer}>
                                    <p className={styles.cardTitle}>{publicationData?.title ? `${publicationData?.primary_description} ${publicationData?.title}` : publication?.data?.title}</p>
                                    {
                                        information &&
                                        <ToolTip
                                            icon={images.showDetail}
                                            className={styles.showDetail}
                                        >
                                            <div>
                                                <p className={styles.text}>{information}</p>
                                            </div>
                                        </ToolTip>
                                    }
                                </div>
                                <p className={styles.priceTitle}>Valor original</p>
                                <p className={styles.price}>$ {addPointsInText(publicationData?.price ?? publication?.articles?.data?.price)}</p>
                                {extraDetail}
                            </div>
                            <div className={styles.separator} />
                            <div className={styles.children}>{children}</div>
                        </div>
                        <img className={styles.carImage} src={picture ?? publicationData?.pictures[0]?.path ?? publication?.data?.pictures[0]?.path} />
                    </>
                :
                type === "right" ?
                    <>
                        <div className={`${styles.children} ${styles.right}`}>{children}</div>
                        <img className={styles.carImage} src={picture ?? publicationData?.pictures[0]?.path ?? publication?.pictures[0]?.path} />
                        <div className={styles.bottomDetail}>
                            <div className={styles.cardTitleContainer}>
                                <p className={styles.cardTitle}>{`${publicationData?.primary_description} ${publicationData?.title}`}</p>
                                <img src={images.showDetail} />
                            </div>
                            <p className={styles.priceTitle}>Valor original</p>
                            <p className={styles.price}>$ {addPointsInText(publicationData?.price)}</p>
                            {extraDetail}
                        </div>
                    </>
                :
                type === "onlyPicture" &&
                    <>
                        <div className={`${styles.onlyPicture} ${styles.children}`}>{children}</div>
                        <div className={styles.rightSide}>
                            <img className={styles.carImage} src={picture ?? publicationData?.pictures[0]?.path} />
                            {extraDetail}
                        </div>
                    </>
        }
        </div>
    )
}

export default PublicationCardDetail;