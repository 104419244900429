export const optionsIngressType = [
    {
        label: "dependiente",
        value: "dependent"
    },
    {
        label: "independente",
        value: "independent"
    },
];

export const optionsContractType = [
    {
        label: "plazo fijo",
        value: "fixed_term"
    },
    {
        label: "indefinido",
        value: "indefinite"
    },
];

export const optionsAntiquity = [
    {
        label: {
            value: "0-6",
            time: "meses"
        },
        value: 0
    },
    {
        label: {
            value: "6-12",
            time: "meses"
        },
        value: 1
    },
    {
        label: {
            value: "1-2",
            time: "años"
        },
        value: 2
    },
    {
        label: {
            value: "+2",
            time: "años"
        },
        value: 3
    },
];