import styles from './detailLoader.module.scss';
import { Skeleton } from "@mui/material";

const DetailLoader = () => {

    return (
        <>
            <li className={styles.item}>
                <Skeleton animation="wave" variant="circular" className={styles.icon} />
                <Skeleton animation="wave" variant="text" className={styles.text} />
            </li>
            <li className={styles.item}>
                <Skeleton animation="wave" variant="circular" className={styles.icon} />
                <Skeleton animation="wave" variant="text" className={styles.text} />
            </li>
            <li className={styles.item}>
                <Skeleton animation="wave" variant="circular" className={styles.icon} />
                <Skeleton animation="wave" variant="text" className={styles.text} />
            </li>
            <li className={styles.item}>
                <Skeleton animation="wave" variant="circular" className={styles.icon} />
                <Skeleton animation="wave" variant="text" className={styles.text} />
            </li>
        </>
    )
}

export default DetailLoader;