import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { DiscountLinzerContainerProps } from "../../interfaces/containers";
import DiscountLinzer from "../../components/DiscountLinzer";
import { putDiscount, cleanPutDiscount } from "../../actions/discount";
import { getArticlesFromPublication } from "../../actions/publication";

function mapStateToProps(state: DiscountLinzerContainerProps) {
    return{
        discount: state.discount,
        publication: state.publication.articles
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        putDiscount, 
        cleanPutDiscount,
        getArticlesFromPublication
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscountLinzer);