import { useEffect } from "react";
import styles from "../frequentQuestions.module.scss";
import MenuQuestions from "../../../components/MenuQuestions";

const Sell = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={styles.content}>
            <div className={styles.questionsContainer}>
                <MenuQuestions section={2} />
                <div className={styles.sectionContent}>
                    <div className={`${styles.item} ${styles.howSell}`}>
                        <p className={styles.title}>Cómo podrás vender tu auto en Linze</p>
                        <div className={styles.info}>
                            <p className={styles.data}>
                                <span className={styles.subTitle}>¿Cómo vender en Linze?</span>
                                <span>Ingresa en simples pasos toda la información de tu auto. Asegúrate de colocar fotos en buena calidad y asignar un precio acorde a la competencia para atraer rápidamente a posibles compradores.</span>
                            </p>
                            <p className={styles.data}>
                                <span className={styles.subTitle}>¿Qué sucede una vez que publiqué mi auto?</span>
                                <span>Ahora podrás recibir ofertas de todas aquellas personas interesadas en adquirir tu auto.</span>
                            </p>
                            <p className={styles.data}>
                                <span className={styles.subTitle}>¿Qué puedo hacer con las ofertas que reciba?</span>
                                <span>Podrán ser aceptadas o rechazadas según tu conveniencia.</span>
                                <span>Si decides aceptar una oferta, el resto se cancelan automáticamente hasta concretar el match de entrega con el futuro comprador. Este último, tendrá 24hs para finalizar la compra con el nuevo precio acordado, si no lo hace, las anteriores ofertas se vuelven a habilitar.</span>
                            </p>
                            <p className={styles.data}>
                                <span className={styles.subTitle}>¿Cómo entrego el auto que vendí?</span>
                                <span>Deberás aguardar a que la persona interesada en tu auto proponga 3 posibles fechas de entrega. De esas 3 deberás elegir 1 para llevar a cabo la entrega.</span>
                            </p>
                            <p className={styles.data}>
                                <span className={styles.subTitle}>¿Ya coordiné fecha de entrega, cómo sigo?</span>
                                <span>El día acordado un Linzer (personal de Linze encargado del proceso de compra/venta) irá a tu domicilio para revisar el auto, llevar a cabo una inspección y hacer una videollamada con el comprador.</span>
                                <span>En este paso el comprador tendrá la posibilidad de renegociar el precio según lo que haya visto en la videollamada.</span>
                            </p>
                            <p className={styles.data}>
                                <span className={styles.subTitle}>¿Qué sucede luego de la inspección?</span>
                                <span>En esta instancia pueden suceder tres cosas:</span>
                                <span>Si no se aprueba la inspección se cancela la transacción y tu publicación se da de baja de nuestra plataforma.</span>
                                <span>Si no se llega a un acuerdo en la negociación del precio, se cancela la transacción y tu publicación sigue visible.</span>
                                <span>Si se aprueba la inspección y, en caso de ser necesario, se llega a un acuerdo de negociación, procedes a firmar toda la documentación necesaria.</span>
                            </p>
                            <p className={styles.data}>
                                <span className={styles.subTitle}>¿Cuándo entrego el auto?</span>
                                <span>Una vez que hayas firmado los papeles, se te transferirá el monto acordado y el Linzer retirará el auto para entregárselo al comprador.</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sell;