import { RouteObject } from "../../../interfaces/navigation";
import EntryPoint from "../../../containers/Profile/MyPublications/MyPublicationsContainer";
import Detail from "../../../containers/Profile/MyPublications/Detail/EntryPointContainer";
import Edit from "../../../containers/Profile/MyPublications/Edit/EntryPointContainer";
import Management from "../../../containers/Profile/MyPublications/Management/ManagementContainer";

export const myPublicationsSubRoutes: RouteObject[] = [
    {
        title: "Detail",
        path: ":id/detail",
        component: <Detail/>
    },
    {
        title: "Edit",
        path: ":id/edit",
        component: <Edit/>
    },
    {
        title: "Gestión",
        path: ":id/management",
        component: <Management/>
    }
];

export const myPublicationsRoutes: RouteObject[] = [
    {
        title: "Mis Publicaciones",
        path: "my_publications",
        component: <EntryPoint/>,
        subRoutes: myPublicationsSubRoutes
    }
];