import { tabProps } from "../interfaces/sidebar";

export const tabs: tabProps[] = [
    {
        id: "my_account",
        label: "Mi cuenta",
        icon: "my_account",
        navigate: "my_account"
    },
    {
        id: "my_publications",
        label: "Mis publicaciones",
        icon: "my_publications",
        navigate: "my_publications"
    },
    {
        id: "my_buys",
        label: "Mis compras",
        icon: "my_buys",
        navigate: "my_buys"
    },
    {
        id: "my_offers",
        label: "Mis ofertas",
        icon: "my_offers",
        navigate: "my_offers"
    },
    {
        id: "favorites",
        label: "Favoritos",
        icon: "favorites",
        navigate: "favorites"
    },
    {
        id: "documentation",
        label: "Documentación",
        icon: "documentation",
        navigate: "documentation"
    },
    {
        id: "settings",
        label: "Ajustes",
        icon: "settings",
        navigate: "settings"
    }
];