import { Skeleton } from "@mui/material";
import styles from "./loaderCreditData.module.scss";

const LoaderCreditData = () => {
    return (
        <div className={styles.skeletonContainer}>
            <div className={styles.skeletonRow}>
                <Skeleton width={120} height={24} animation="wave" variant="text" />
                <Skeleton width={120} height={24} animation="wave" variant="text" />
            </div>
            <div className={styles.skeletonRow}>
                <Skeleton width={120} height={24} animation="wave" variant="text" />
                <Skeleton width={120} height={24} animation="wave" variant="text" />
            </div>
            <div className={styles.skeletonRow}>
                <Skeleton width={120} height={24} animation="wave" variant="text" />
                <Skeleton width={120} height={24} animation="wave" variant="text" />
            </div>
            <div className={styles.skeletonRow}>
                <Skeleton width={120} height={24} animation="wave" variant="text" />
                <Skeleton width={120} height={24} animation="wave" variant="text" />
            </div>
            <div className={styles.skeletonRow}>
                <Skeleton width={120} height={24} animation="wave" variant="text" />
                <Skeleton width={120} height={24} animation="wave" variant="text" />
            </div>
            <div className={styles.skeletonRow}>
                <Skeleton width={120} height={24} animation="wave" variant="text" />
                <Skeleton width={120} height={24} animation="wave" variant="text" />
            </div>
            <div className={styles.skeletonRow}>
                <Skeleton width={120} height={24}animation="wave" variant="text" />
                <Skeleton width={120} height={24} animation="wave" variant="text" />
            </div>
        </div>
    )
};

export default LoaderCreditData;