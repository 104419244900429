import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { FooterContainerProps } from "../../interfaces/containers";
import Footer from "../../components/Footer";
import { setTypeReducer } from "../../actions/publications";

function mapStateToProps(state: FooterContainerProps) {
    return{
        user: state.auth.user,
        searchType: state.publications.typeSelected
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        setTypeReducer
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);