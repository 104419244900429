import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { SearchContainerProps } from "../../interfaces/containers";
import Search from "../../pages/Sell/Search";
import { initialFlow, clearSalePost, getIsPlateRepeated, cleanPlateRepeated, getPlateInfo, setPlate } from "../../actions/salePost";
import { reSendValidation, cleanReSendValidation } from "../../actions/auth";

function mapStateToProps(state: SearchContainerProps) {
    return{
        user: state.auth.user,
        plate: state.salePost.plate,
        fetchingPlateRepeated: state.salePost.fetchingPlateRepeated,
        plateRepeatedStatus: state.salePost.plateRepeatedStatus,
        fetchingPlateInfo: state.salePost.fetchingPlateInfo,
        brand: state.salePost.brands?.selected,
        year: state.salePost.year,
        documents: state.documents,
        reSendValidationFetching: state.auth.reSendValidationFetching,
        isFoundPlate: state.salePost.isFoundPlate
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        initialFlow,
        getPlateInfo,
        getIsPlateRepeated,
        cleanPlateRepeated,
        clearSalePost,
        setPlate,
        reSendValidation,
        cleanReSendValidation
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);