import { useEffect } from "react";
import Button from "../../../../components/Button";
const Previred = () => {
    
    return(
        <>
            
        </>
    )
}
export default Previred;