import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { BuyProps } from "../../../interfaces/containers";
import CreditRequest from "../../../pages/Buy/CreditRequest/CreditRequest";
import { setStep, setCreditRequest, setCleanAll } from "../../../actions/buy";

function mapStateToProps(state: BuyProps) {
    return{
        step: state.buy.step,
        method: state.buy.method,
        publication: state.buy.publication,
        creditRequestReducer: state.buy.creditRequest
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        setStep,
        setCleanAll,
        setCreditRequest
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditRequest);