import { useEffect, useMemo, useState } from 'react';
import styles from './phoneInput.module.scss';
import { PhoneInputProps } from '../../interfaces/components';
import { CircularProgress, ClickAwayListener } from '@mui/material';
import { capitalize, checkerType } from "../../helpers/app";
import countries from '../../constants/countries.json'
import Icon from '@mdi/react';
import { mdiChevronDown } from '@mdi/js';

const PhoneInput = ({
    label = "",
    className,
    numberValue,
    codeValue,
    onChange = () => { },
    error,
    loading = false,
}: PhoneInputProps) => {
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [phoneCode, setPhoneCode] = useState<any>({icon: "", value: ""});
    const [openSelectorIcon, setOpenSelectorIcon] = useState<boolean>(false);
    const [blurInput, setBlurInput] = useState<boolean>(false);

    const onClickAway = () => {
        if(openSelectorIcon){
            handlePressSelectorIcon();
        }
    }

    const handlePressSelectorIcon = () => {
        if(!loading) setOpenSelectorIcon(!openSelectorIcon);
    }

    const handleChangePhoneCode = (option: any) => {
        if(!loading){
            setPhoneCode({...phoneCode, icon: option ? option?.icon : ''});
            if (onChange) onChange(option?.id, "code");
            handlePressSelectorIcon();
        }
    }

    const handleChangePhoneNumber = (value: any) => {
        let validValue: boolean = false;
        if(!loading){
            validValue = checkerType(value, "onlyNumbers");
            if(validValue){
                if (onChange) onChange(value, "number");
            }
        }
    }

    const phoneCodesNormalized: any = useMemo(() => {
        return countries && countries.map((country: any) => (
            {
                id: country?.dial_code,
                name: country?.name,
                icon: country?.flag,
            }
        ))
    }, [countries]);

    useEffect(() => {
        if(numberValue || numberValue === ""){
            setPhoneNumber(numberValue);
        }
        if(codeValue){
            const option = phoneCodesNormalized.find((option: any) => option.id === codeValue);
            setPhoneCode({value: codeValue, icon: option?.icon ?? ""});
        }
    }, [numberValue, codeValue]);

    return (
        <ClickAwayListener onClickAway={onClickAway} disableReactTree={true}>
        <div className={`${styles.phoneInput} ${className} ${loading && styles.loading} ${blurInput && styles.focus}`}>
            <p className={styles.label}>{label}</p>
            {
                loading 
                ?
                    <CircularProgress
                        size={styles.loader}
                        classes={{
                            colorPrimary: styles.loader
                        }}
                    />
                :
                <>
                    <div className={styles.inputs}>
                        {
                            <>
                                <div className={styles.codeIconSelector} onClick={handlePressSelectorIcon}>
                                    <div className={styles.iconSelected}>
                                        <p>
                                            <span className={styles.icon}>{phoneCode?.icon}</span>
                                            <span className={styles.value}>{phoneCode?.value}</span>
                                        </p>
                                    </div>
                                    <Icon path={mdiChevronDown} className={styles.arrow} title="Arrow"/>
                                </div>
                                <div className={`${styles.options} ${openSelectorIcon && styles.open}`}>
                                    {
                                        phoneCodesNormalized && phoneCodesNormalized.map((option: any, index: number) => (
                                            <div
                                                key={index}
                                                onClick={() => handleChangePhoneCode(option)}
                                                className={styles.option}
                                            >
                                                <p className={styles.content}>
                                                    <span>{option?.icon}</span>
                                                    <span>{option?.name}</span>
                                                    <span>({option?.id})</span>
                                                </p>
                                            </div>
                                        ))
                                    }
                                </div>
                            </>
                        }
                        <input
                            type="text"
                            value={phoneNumber}
                            onChange={(e: any) => handleChangePhoneNumber(e.target.value)}
                            placeholder=""
                            className={styles.numberInput}
                            onFocus={() => setBlurInput(!blurInput)}
                            onBlur={() => setBlurInput(!blurInput)}
                        />
                    </div>
                    {
                        error && <p className={styles.error}>{capitalize(error)}</p>
                    }
                </>
            }
        </div>
        </ClickAwayListener>
    );
};

export default PhoneInput;