import Modal from "../Modal/Modal";
import styles from "./modalAcceptRejectOffer.module.scss";
import ButtonShared from "../Button/Button";
import { addPointsInText } from "../../helpers/app";
import { images } from "../../constants/images";
import { MODAL_BUY_OFFERS_TEXTS, MODAL_SELL_OFFERS_TEXTS } from "../../constants/offers";
import { ModalAcceptRejectOfferProps } from "../../interfaces/components";

const ModalAcceptRejectOffer = ({
    open, 
    onClose, 
    price,
    offer = true,
    counteroffer = false,
    type,
    variant = "sell"
}: ModalAcceptRejectOfferProps) => {
    return(
        <Modal open={open} onClose={onClose} >
            <div className={styles.modalContainerOffers}>
                {type === "accepted" && <img alt="success" src={images.successModal} />}
                <p className={styles.title}>
                    {
                        variant === "buy" 
                        ?   MODAL_BUY_OFFERS_TEXTS[type].title
                        :   type === "accepted"
                            ?   MODAL_SELL_OFFERS_TEXTS[type].title    
                            :   offer 
                                ?   MODAL_SELL_OFFERS_TEXTS[type]["offer"].title 
                                :   counteroffer
                                    ?   MODAL_SELL_OFFERS_TEXTS[type]["counteroffer"].title 
                                    :   null
                    }
                </p>
                <p className={styles.subTitle}>
                    {
                        variant === "buy" 
                        ?   MODAL_BUY_OFFERS_TEXTS[type].subTitle
                        :   type === "accepted"
                            ?   MODAL_SELL_OFFERS_TEXTS[type].subTitle    
                            :   offer 
                                ?   MODAL_SELL_OFFERS_TEXTS[type]["offer"].subTitle 
                                :   counteroffer
                                    ?   MODAL_SELL_OFFERS_TEXTS[type]["counteroffer"].subTitle 
                                    :   null
                    }
                </p>
                <p className={styles.value}>{addPointsInText(price)}</p>
                <p className={styles.description}>
                    {
                        variant === "buy" 
                        ?   MODAL_BUY_OFFERS_TEXTS[type].description
                        :   type === "accepted"
                            ?   MODAL_SELL_OFFERS_TEXTS[type].description    
                            :   offer 
                                ?   MODAL_SELL_OFFERS_TEXTS[type]["offer"].description 
                                :   counteroffer
                                    ?   MODAL_SELL_OFFERS_TEXTS[type]["counteroffer"].description 
                                    :   null
                    }
                </p>
                <ButtonShared
                    onPress={() => onClose()}
                    title="Aceptar" 
                    secondary
                    className={styles.button}
                />
            </div>
        </Modal>
    )
};

export default ModalAcceptRejectOffer;