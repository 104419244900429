import {ConfortItem} from "../interfaces/salePost";
import { images } from "./images";

export const confortItems: ConfortItem[] = [
    {
        id: 'audio_hifi',
        label: 'Sistema de audio Hi-Fi',
        icon: images.audio_hifi,
        iconSelected: images.audio_hifi_selected,
        code: 'audioHiFi',
        isSelected: false,
        vectorIcon: null,
    },
    {
        id: 'air_filter',
        label: 'Climatizador automático',
        icon: images.airFilter,
        iconSelected: images.airFilter,
        code: 'airFilter',
        isSelected: false,
        vectorIcon: 'air-filter',
    },
    {
        id: 'leather_seats',
        label: 'Asientos de cuero',
        icon: images.leatherSeats,
        iconSelected: images.leatherSeats,
        code: 'leatherSeats',
        isSelected: false,
        vectorIcon: 'car-seat',
    },
    {
        id: 'on_board_computer',
        label: 'Computadora a bordo',
        icon: images.onBoardComputer,
        iconSelected: images.onBoardComputer,
        code: 'onBoardComputer',
        isSelected: false,
        vectorIcon: 'laptop',
    },
    {
        id: 'cruice_speed',
        label: 'Velocidad crucero',
        icon: images.cruiceSpeed,
        iconSelected: images.cruiceSpeed,
        code: 'cruiceSpeed',
        isSelected: false,
        vectorIcon: 'speedometer',
    },
    {
        id: 'parking_sensor',
        label: 'Sensor de estacionamiento',
        icon: images.parkingSensor,
        iconSelected: images.parkingSensor,
        code: 'parkingSensor',
        isSelected: false,
        vectorIcon: 'access-point',
    },
    {
        id: 'bt_wifi',
        label: 'Conectividad BT / Wifi',
        icon: images.btWiFi,
        iconSelected: images.btWiFi_selected,
        code: 'btWiFi',
        isSelected: false,
        vectorIcon: null
    },
    {
        id: 'electric_crystals',
        label: 'Cristales eléctricos',
        icon: images.cristales_electricos,
        iconSelected: images.cristales_electricos_selected,
        code: 'electricCrystals',
        isSelected: false,
        vectorIcon: null
    }
]