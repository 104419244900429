import { useState } from "react";
import styles from "./dropdown.module.scss"
import { DropdownProps } from "../../interfaces/components";
import { IconButton } from "@mui/material";
import { images } from "../../constants/images";
import { ClickAwayListener } from "@mui/base";

const Dropdown = ({
    onPress = () => {},
    variant = "click",
    type = "icon",
    title = "",
    icon = "",
    children,
    className,
    titleClassName,
    id,
}: DropdownProps) => {
    const [openDropdown, setOpenDropdown] = useState<boolean>(false);

    const onClickAway = () => {
        if(openDropdown){
            setOpenDropdown(false);
            if(onPress) onPress();
        }
    }

    const onHover = () => {
        if(variant === "hover"){
            setOpenDropdown(!openDropdown);
            if(onPress) onPress();
        }
    }
    
    const onClick = () => {
        if(variant === "click"){
            setOpenDropdown(!openDropdown);
            if(onPress) onPress();
        }
    }

    return(
        <ClickAwayListener onClickAway={onClickAway} disableReactTree={true}>
            <div className={styles.dropdown} id={id}>
                {
                    type === "icon" 
                    ?
                        <IconButton className={titleClassName} aria-label='Information' onClick={onClick} onMouseEnter={onHover} onMouseLeave={onHover}>
                            {icon}
                        </IconButton>
                    :
                        <button className={`${styles.title} ${titleClassName}`} onClick={onClick} onMouseEnter={onHover} onMouseLeave={onHover}>
                            <p title={title}>{title}</p>
                        </button>
                }
                <>
                    <div className={`${styles.triangle} ${openDropdown && styles.open}`}></div>
                    <div className={`${styles.dropdownContainer} ${className} ${type === "icon" && styles.iconType} ${openDropdown && styles.open}`}>
                        {children}
                    </div>
                </>
            </div>
        </ClickAwayListener>
    )
}

export default Dropdown;