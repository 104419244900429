import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { ModelContainerProps } from "../../interfaces/containers";
import Model from "../../pages/Sell/Model";
import { getModels, cleanGetModels, setModel } from "../../actions/salePost";

function mapStateToProps(state: ModelContainerProps) {
    return{
        models: state.salePost.models,
        brands: state.salePost.brands,
        plate: state.salePost.plate,
        isFoundPlate: state.salePost.isFoundPlate
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        setModel,
        getModels,
        cleanGetModels
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Model);