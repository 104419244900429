import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { LocationContainerProps } from "../../interfaces/containers";
import Location from "../../pages/Sell/Location";
import { setCountry, setRegion, cleanPlateRepeated } from "../../actions/salePost";

function mapStateToProps(state: LocationContainerProps) {
    return{
        country: state.salePost.country,
        region: state.salePost.region,
        plate: state.salePost.plate,
        isFoundPlate: state.salePost.isFoundPlate
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        setRegion,
        setCountry,
        cleanPlateRepeated
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Location);