import styles from "./inspectionApproval.module.scss";
import { capitalize } from "../../helpers/app";
import { InspectionApprovalProps } from "../../interfaces/components";
import Modal from "../Modal/Modal";
import ButtonShared from "../Button";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { images } from "../../constants/images";
import { useToast } from "../../helpers/hooks";

const InspectionApproval = ({ 
    open,
    setOpen,
    notification,
    inspectionApproval,
    putInspectionApproval, 
    cleanPutInspectionApproval,
    getArticlesFromPublication,
    publication
 }: InspectionApprovalProps) => {
    const toast = useToast();
    const [statusSelected, setStatusSelected] = useState<string>("");
    const [showConfirmationSection, setShowConfirmationSection] = useState<any>({absolute: true, show: false});
    const [showInspectionSection, setShowInspectionSection] = useState<any>({absolute: false, show: true});
    const [loading, setLoading] = useState<boolean>(false);

    const onPress = (status: string) => {
        setStatusSelected(status);
        setShowInspectionSection({...showInspectionSection, show: false});
        setTimeout(() => setShowInspectionSection({show: false, absolute: true}), 500);
        setTimeout(() => setShowConfirmationSection({absolute: false, show: true}), 500);
    }

    const onBack = () => {
        if(inspectionApproval?.fetchingPut === "loading") return;
        setShowConfirmationSection({...showConfirmationSection, show: false});
        setTimeout(() => setShowConfirmationSection({show: false, absolute: true}), 500);
        setTimeout(() => setShowInspectionSection({absolute: false, show: true}), 500);
    }

    const onClose = () => {
        setStatusSelected("");
        setShowConfirmationSection({absolute: true, show: false});
        setShowInspectionSection({absolute: false, show: true});
        setOpen({open: false, notification: notification});
    }

    const onConfirmation = () => {
        putInspectionApproval(notification?.inspection_approval, statusSelected);
    }

    useEffect(() => {
        if(publication?.fetching === "loading"){
            setLoading(true);
        }
        if(publication?.fetching === "failure"){
            toast("Ha ocurrido un error al cargar la inspección");
            setLoading(false);
            setOpen({open: false, notification: notification});
            return;
        }
        if(publication?.fetching === "success"){
            setLoading(false);
        }
    }, [publication?.fetching]);

    useEffect(() => {
        if(open){
            getArticlesFromPublication(notification?.publication);
        }
    }, [open]);

    useEffect(() => {
        if(inspectionApproval?.fetchingPut === "failure"){
            toast(`Ha ocurrido un error al ${statusSelected === "accepted" ? "aceptar" : "rechazar"} la inspección`);
            onClose();
            cleanPutInspectionApproval();
        }
        if(inspectionApproval?.fetchingPut === "success"){
            toast(`Se ha ${statusSelected === "accepted" ? "aceptado" : "rechazado"} la inspección exitosamente`);
            onClose();
            cleanPutInspectionApproval();
        }
    }, [inspectionApproval?.fetchingPut]);

    return (
        <>
            <Modal open={open} className={styles.inspectionApprovalModal} onClose={onClose}>
                <div className={`${styles.confirmation} ${styles.content} ${showConfirmationSection?.show && styles.show} ${showConfirmationSection?.absolute && styles.absolute}`}>
                    <p className={`${styles.text} ${styles.message}`}>
                        {
                            statusSelected === "accepted"
                            ?
                                '¿Esta seguro que desea aceptar esta inspección?'
                            :
                                '¿Está seguro que desea rechazar esta inspección?'
                        }
                    </p>
                    <div className={styles.buttons}>
                        <ButtonShared 
                            onPress={onBack}
                            title="Volver"
                            fourtuary
                            size="small"
                        />
                        <ButtonShared 
                            onPress={onConfirmation}
                            title={statusSelected === "accepted" ? "Aceptar" : "Rechazar"}
                            secondary 
                            size="small" 
                            loading={inspectionApproval?.fetchingPut === "loading"}
                        />
                    </div>
                </div>
                <div className={`${styles.inspection} ${styles.content} ${showInspectionSection?.show && styles.show} ${showInspectionSection?.absolute && styles.absolute}`}>
                    {
                        loading
                        ? 
                            <div className={styles.loaderInspection} id="loaderInspection">
                                <CircularProgress 
                                    size={styles.loader}
                                    classes={{
                                        colorPrimary: styles.loader
                                    }}
                                />
                                <p>Cargando inspección</p>
                            </div>
                        :
                            <>
                                <img src={images.successModal} alt="approval" />
                                <p className={styles.text}>{`La inspección del ${capitalize(publication?.data?.brand)} ${capitalize(publication?.data?.model)} ${publication?.data?.year} ha sido aprobada`}</p>
                                <div className={styles.buttons}>
                                    <ButtonShared 
                                        onPress={() => onPress("rejected")}
                                        title="Rechazar"
                                        fourtuary
                                        size="small"
                                    />
                                    <ButtonShared 
                                        onPress={() => onPress("accepted")}
                                        title="Aceptar" 
                                        secondary 
                                        size="small" 
                                    />
                                </div>
                            </>
                    }
                </div>
            </Modal>
        </>
    )
}
export default InspectionApproval;