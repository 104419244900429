import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { MyAccountContainerProps } from "../../../interfaces/containers";
import MyAccount from "../../../pages/Profile/MyAccount/MyAccount";
import { getRegions, getCities, getCommunes, patchResidence, cleanProfileFetching } from "../../../actions/profile";
import { editUserRequest, postUserImage, cleanPostImage, deleteUser, logoutUser, reSendValidation, cleanReSendValidation, cleanExternalUser } from "../../../actions/auth";
import { getIsValidDocuments, cleanIsValidDocuments } from "../../../actions/documents";

function mapStateToProps(state: MyAccountContainerProps) {
    return {
        user: state.auth.user,
        token: state.auth.token,
        regions: state.profile.regions,
        cities: state.profile.cities,
        communes: state.profile.communes,
        editUserReducer: state.auth.fetchingEdit,
        fetchingPatchResidence: state.auth.fetchingPatchResidence,
        postImage: state.auth.postImage,
        deleteFetching: state.auth.deleteFetching,
        reSendValidationFetching: state.auth.reSendValidationFetching,
        valid: state.documents.valid,
        validation: state.buy.validation
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        getRegions,
        getCities,
        getCommunes,
        patchResidence,
        editUserRequest,
        cleanProfileFetching,
        postUserImage,
        cleanPostImage,
        deleteUser,
        logoutUser,
        reSendValidation,
        cleanReSendValidation,
        getIsValidDocuments,
        cleanIsValidDocuments,
        cleanExternalUser
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);