import { useMemo, useEffect, useState } from "react";
import styles from "./edit.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { MyPublicationEditProps } from '../../../../interfaces/pages';
import { addPointsInText, capitalize, formatInput, removePointsInText } from "../../../../helpers/app";
import { optionsAccidentsReported, optionsPersonalUse, optionsSoleOwner } from "../../../../constants/app";
import { Skeleton } from "@mui/material";
import { COLORS, FUEL_TYPES } from "../../../../constants/Publication";
import ButtonShared from "../../../../components/Button/Button";
import ProfileCard from "../../../../components/ProfileCard";
import Slider from "../../../../components/Slider";
import TextFieldShared from "../../../../components/TextField";
import SelectShared from "../../../../components/Select";
import { useToast } from "../../../../helpers/hooks";
import { images } from "../../../../constants/images";
import { VehiclePost } from '../../../../interfaces/publication';
import { myPubEditBreadCrumb } from "../../../../constants/breadCrumbs";
import Icon from '@mdi/react';
import { mdiClose, mdiAlertCircleOutline, mdiPencil } from '@mdi/js';
import LinzeAiItemLoader from "./LinzeAiItem/LinzeAiItemLoader";
import PicturesModal from "./PicturesModal";

const Edit = ({
    models,
    getModels,
    versions,
    getVersions,
    publication,
    getPublication,
    getArticlesFromPublication,
    setUploadImages,
    updateReducer,
    updatePublication,
    cleanAll,
    cleanUpdate,
    deletePublicationImage,
    postPublicationImages,
    cleanImagesSaved,
    getMyPublicationsRequest
}: MyPublicationEditProps) => {
    const toast = useToast();
    const navigate = useNavigate();
    const params = useParams();
    const [timeOutMessage, setTimeOutMessage] = useState<any>({ open: false, text: "" });
    const [loading, setLoading] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [modelSelected, setModelSelected] = useState<any>({ value: "", error: "" });
    const [versionSelected, setVersionSelected] = useState<any>({ value: "", error: "" });
    const [colorSelected, setColorSelected] = useState<any>({ value: "", error: "" });
    const [fuelSelected, setFuelSelected] = useState<any>({ value: "", error: "" });
    const [price, setPrice] = useState<any>({ value: "", error: "" });
    const [mileage, setMileage] = useState<any>({ value: "", error: "" });
    const [userDescription, setUserDescription] = useState<any>({ value: "", error: "" });
    const [accidentsReported, setAccidentsReported] = useState<any>({ value: "", error: "" });
    const [personalUse, setPersonalUse] = useState<any>({ value: "", error: "" });
    const [soleOwner, setSoleOwner] = useState<any>({ value: "", error: "" });
    const [tagsSelected, setTagsSelected] = useState<any[]>([]);
    const [newImages, setNewImages] = useState<any[]>([]);
    const [deleteImages, setDeleteImages] = useState<any[]>([]);
    const [imagesSelected, setImagesSelected] = useState<any[]>([]);
    const [confortsNormalized, setConfortsNormalized] = useState<any>();

    const handleChangeImage = (event: any) => {
        let images: any = [];
        Array.from(event.target.files).forEach((file: any, index: number) => {
            const image = {
                id: Math.floor((index + Math.random()) * 0x10000000000).toString(16),
                name: `${Math.floor((index + Math.random()) * 0x10000000000).toString(16)}.jpg`,
                path: URL.createObjectURL(file),
                file: file,
            }
            images = images.concat(image);
            setNewImages(newImages.concat(images));
            setImagesSelected(images.concat(imagesSelected));
        });
        setUploadImages(publication.update.newImages.data.concat(images));
    }

    const handleDeleteImage = (value: any) => {
        const newImageFound = newImages.some((item: any) => item?.id === value);
        const filterImages = imagesSelected.filter((item: any) => item?.id !== value);
        const uploadImages = publication.update.newImages.data.filter((item: any) => item?.id !== value);
        setUploadImages(uploadImages);
        if (!newImageFound) {
            const deleteImage = imagesSelected.find((item: any) => item?.id === value);
            setDeleteImages([deleteImage, ...deleteImages]);
        } else {
            const filterNewImages = newImages.filter((item: any) => item?.id !== value);
            setNewImages(filterNewImages);
        }
        setImagesSelected(filterImages);
    };

    const onChangePosition = (pictures: any) => {
        setImagesSelected(pictures);
    };


    const handlePressPrice = (value: any) => {
        const { formattedNumber } = formatInput(value);
        setPrice({ ...price, value: formattedNumber });
    }

    const handlePressMileage = (value: any) => {
        const { formattedNumber } = formatInput(value);
        setMileage({ ...mileage, value: formattedNumber });
    }

    const handleChangeColor = (id: string) => {
        setColorSelected({ ...colorSelected, value: id });
    }

    const handleChangeFuel = (id: string) => {
        setFuelSelected({ ...fuelSelected, value: id });
    }

    const handleChangeModel = (id: string) => {
        const model = modelsNormalized.find((item: any) => item?.id === id);
        setModelSelected({ value: { id: model?.id, name: model?.name }, error: "" });
    }

    const handleChangeVersion = (id: string) => {
        const version = versionsNormalized.find((item: any) => item?.id === id);
        setVersionSelected({ value: { id: version?.id, name: version?.name }, error: "" });
    }

    const onChangeAccidentsReported = (value: any) => {
        setAccidentsReported({ ...accidentsReported, value: value });
    }

    const onChangePersonalUse = (value: any) => {
        setPersonalUse({ ...personalUse, value: value });
    }

    const onChangeSoleOwner = (value: any) => {
        setSoleOwner({ ...soleOwner, value: value });
    }

    const updateDataPublication = () => {
        let pictures = [...publication?.data?.pictures];

        if (updateReducer?.newImages?.data.length !== 0) {
            pictures = updateReducer?.newImages?.data.concat(publication?.data?.pictures);
        }
        if (deleteImages.length !== 0) {
            pictures = pictures.filter((picture: any) => {
                if (deleteImages.find(deleted => deleted.id === picture.id)) {
                    return false
                }
                return true
            });
        }

        const orderedImages = imagesSelected.map((image: any) => {
            const currentImage = { ...image };
            const finded = publication.update.newImages.data.find((pic: any) => pic.name === currentImage.name);
            if (finded) {
                return {
                    ...currentImage,
                    id: finded.id
                }
            }
            return currentImage;
        });

        const vehicle: VehiclePost = {
            brand: publication?.articles?.data?.brand.toString(),
            brand_id: publication?.articles?.data?.brand_id?.toString(),
            model: modelSelected?.value?.name,
            model_id: modelSelected?.value?.id,
            version: versionSelected?.value?.name,
            version_id: versionSelected?.value?.id,
            year: publication?.articles?.data?.year,
            mileage: parseInt(removePointsInText(mileage?.value)),
            color: colorSelected?.value,
            plate: publication?.articles?.data?.plate,
            price: parseInt(removePointsInText(price?.value)),
            pick_up_price: publication?.data?.other_prices?.pick_up_price?.toString() === "0" ? parseInt(removePointsInText(price?.value)) : publication?.data?.other_prices?.pick_up_price,
            sale_price: publication?.data?.other_prices?.sale_price?.toString() === "0" ? parseInt(removePointsInText(price?.value)) : publication?.data?.other_prices?.sale_price,
            confort: confortsNormalized,
            vehicle_paid: publication?.articles?.data?.vehicle_paid,
            fuel: fuelSelected?.value
        }

        const publicationObject = {
            article: vehicle,
            country: publication?.data?.country,
            region: publication?.data?.region,
            pictures: orderedImages,
            tags: tagsSelected,
            user_description: userDescription,
            record: {
                accidents_reported: accidentsReported?.value,
                personal_use: personalUse?.value,
                sole_owner: soleOwner?.value
            },
        }

        updatePublication(publicationObject);
    }

    const handleSave = () => {
        if (!price?.value) return setPrice({ ...price, error: "Debes ingresar un nombre" });
        if (!mileage?.value) return setMileage({ ...mileage, error: "Debes ingresar un nombre" });
        if (!modelSelected?.value) return setModelSelected({ ...modelSelected, error: "Debes seleccionar un modelo" });
        if (!versionSelected?.value) return setVersionSelected({ ...versionSelected, error: "Debes seleccionar una version" });
        if (!colorSelected?.value) return setColorSelected({ ...colorSelected, error: "Debes seleccionar un color" });
        if (!fuelSelected?.value) return setFuelSelected({ ...fuelSelected, error: "Debes seleccionar un combustible" });

        if (publication?.data?.pictures.length + updateReducer?.newImages?.data?.length - deleteImages.length < 3) {
            return toast("Debes subir al menos 3 fotos");
        }

        setLoading(true);
        setTimeOutMessage({ open: true, text: `Guardando` });

        if (newImages.length === 0 && deleteImages.length === 0) {
            updateDataPublication();
        } else {
            if (newImages.length > 0) {
                newImages.forEach(image => {
                    const formData = new FormData();
                    formData.append('file', image.file, image?.name);
                    //ESTO LO HAGO PARA QUE TIRE ERROR
                    postPublicationImages(formData);
                });
            }
            if (deleteImages.length > 0) {
                deleteImages.forEach(image => deletePublicationImage(image?.id))
            }
        }
    }
    const handleOpenEditPictures = () => {
        setOpenModal(true);
    }

    const onClickItem = () => {
        setOpenModal(true);
    }

    const modelsNormalized: any = useMemo(() => {
        return models?.data && models?.data.map((model: any) => (
            {
                id: model?.model_id,
                name: model?.name
            }
        ))
    }, [models?.data]);

    const versionsNormalized: any = useMemo(() => {
        return versions?.data && versions?.data.map((version: any) => (
            {
                id: version?.version_id,
                name: version?.name
            }
        ))
    }, [versions?.data]);

    const colorsNormalized: any = useMemo(() => {
        return COLORS && COLORS.map((color: any) => (
            {
                id: color,
                name: capitalize(color)
            }
        ))
    }, [COLORS]);

    const fuelsNormalized: any = useMemo(() => {
        return FUEL_TYPES && FUEL_TYPES.map((fuel: any) => (
            {
                id: fuel,
                name: capitalize(fuel)
            }
        ))
    }, [FUEL_TYPES]);

    const numberOfInvalidPictures = useMemo(() => {
        const count = {
            invalid: 0,
            total: 0
        };
        if (imagesSelected && imagesSelected.length) {
            imagesSelected.forEach((picture: any) => {
                if (picture.valid === false) count.invalid++;
            })
            count.total = imagesSelected.length;
        }
        return count;
    }, [imagesSelected])

    useEffect(() => {
        if (publication?.articles?.fetching === "success" && colorsNormalized.length > 0) {
            const colorFound = colorsNormalized.find((item: any) => item?.id === publication?.articles?.data?.color.toLowerCase());
            setColorSelected({ value: colorFound?.id, error: "" });
        }
    }, [colorsNormalized, publication?.articles?.fetching]);

    useEffect(() => {
        if (publication && publication?.data && publication?.data?.pictures && ((publication?.data?.pictures.length + updateReducer?.newImages?.data?.length - deleteImages.length) < 3)) {
            setDisabled(true);
        } else {
            setDisabled(false)
        }
    }, [publication?.data?.pictures, newImages, deleteImages]);

    useEffect(() => {
        if (publication?.articles?.fetching === "success") {
            if (publication?.articles?.data?.brand_id) {
                getModels(publication?.articles?.data?.brand_id.toString());
            }
            getVersions({
                model_id: publication?.articles?.data?.model_id,
                year: publication?.articles?.data?.year
            });
            setPrice({ ...price, value: publication?.data && addPointsInText(publication?.data?.price), error: "" });
            setMileage({ value: publication?.data && addPointsInText(parseInt(publication?.data?.secondary_description)), error: "" });
            setModelSelected({ value: { id: publication?.articles?.data?.model_id, name: publication?.articles?.data?.model }, error: "" });
            setVersionSelected({ value: { id: publication?.articles?.data?.version_id, name: publication?.articles?.data?.version }, error: "" });
            setFuelSelected({ value: publication?.articles?.data?.fuel.toLowerCase(), error: "" });
            setConfortsNormalized(publication?.articles?.data?.confort);
        }
    }, [publication?.articles?.fetching]);

    useEffect(() => {
        if (publication?.fetching === "success") {
            setTagsSelected(publication?.data?.tags);
            setImagesSelected(publication?.data?.pictures);
            setUserDescription(publication?.data?.user_description);
            setPrice({ value: addPointsInText(publication?.data?.price), error: "" });
            setMileage({ value: addPointsInText(publication?.data?.secondary_description), error: "" });
            setAccidentsReported({ ...accidentsReported, value: publication?.data?.record_edit?.accidents_reported });
            setPersonalUse({ ...personalUse, value: publication?.data?.record_edit?.personal_use });
            setSoleOwner({ ...soleOwner, value: publication?.data?.record_edit?.sole_owner });
        }
    }, [publication?.fetching]);

    useEffect(() => {
        if (updateReducer?.newImages?.fetching === "failure") {
            if (updateReducer?.newImages.data.length) {
                updateReducer?.newImages.data.forEach((newImage: any) => {
                    if (newImage.error) {
                        setNewImages(prev => {
                            return prev.map((prevImage) => {
                                if (prevImage.name === newImage.name) {
                                    return {
                                        ...prevImage,
                                        error: true
                                    }
                                }
                                return prevImage
                            })
                        });
                        setImagesSelected(prev => {
                            return prev.map((prevImage) => {
                                if (prevImage.name === newImage.name) {
                                    return {
                                        ...prevImage,
                                        error: true
                                    }
                                }
                                return prevImage
                            })
                        });
                    }
                })
            }
            toast("Error al subir nuevas imagenes");
            setLoading(false);
            setTimeOutMessage({ ...timeOutMessage, open: false });
            return;
        }
        if (updateReducer?.newImages?.fetching === "success" && updateReducer?.newImages?.count === updateReducer?.newImages?.data.length && !updateReducer?.newImages?.reloaded && updateReducer?.deleteImages?.data.length === 0) {
            updateDataPublication();
        }
        if (updateReducer?.newImages?.fetching === "success" && updateReducer?.newImages?.reloaded) {
            updateReducer?.newImages.data.find((newImage: any) => {
                if (newImage.name === updateReducer?.newImages?.reloadedName) {
                    setNewImages(prev => {
                        return prev.filter((prevImage) => {
                            if (prevImage.name === newImage.name) return false;
                            return true;
                        })
                    });
                    setImagesSelected(prev => {
                        return prev.map((prevImage) => {
                            if (prevImage.name === newImage.name) {
                                return {
                                    ...prevImage,
                                    error: false
                                }
                            }
                            return prevImage
                        })
                    });
                    return true;
                }
                return false;
            })
        }
    }, [updateReducer?.newImages]);

    useEffect(() => {
        if (updateReducer?.deleteImages?.fetching === "failure") {
            toast("Error al eliminar imagenes");
        }
        if (updateReducer?.deleteImages?.fetching === "success" && deleteImages.length === updateReducer?.deleteImages?.data.length) {
            setDeleteImages([]);
        }
        if (updateReducer?.deleteImages?.fetching === "success" && deleteImages.length === updateReducer?.deleteImages?.data.length && newImages.length === 0 && !updateReducer?.newImages?.reloaded) {
            updateDataPublication();
        }
    }, [updateReducer?.deleteImages]);

    useEffect(() => {
        if (updateReducer?.newImages?.count === updateReducer?.newImages?.data.length && deleteImages.length === 0 && updateReducer?.deleteImages?.fetching === "success" && updateReducer?.newImages?.fetching === "success" && !updateReducer?.newImages?.reloaded) {
            updateDataPublication();
        }
    }, [updateReducer?.newImages, deleteImages]);

    useEffect(() => {
        if (updateReducer?.fetching === "failure") {
            if (updateReducer?.error?.code === "PUBLICATION_IN_TRANSACTION") {
                toast("Error: La publicación está en transacción");
            } else {
                toast("Ha ocurrido un error");
            }
            setLoading(false);
            setTimeOutMessage({ ...timeOutMessage, open: false });
            cleanUpdate();
        }
        if (updateReducer?.fetching === "success") {
            toast("Publicación actualizada correctamente");
            setLoading(false);
            setTimeOutMessage({ ...timeOutMessage, open: false });
            cleanAll();
            getMyPublicationsRequest(1);
            return navigate(`/profile/my_publications/${params?.id}/detail`);
        }
    }, [updateReducer?.fetching]);

    useEffect(() => {
        getPublication(params?.id);
        getArticlesFromPublication(params?.id);
        cleanImagesSaved();
    }, []);

    return (
        <div className={styles.edit}>
            <ProfileCard breadCrumbs={myPubEditBreadCrumb(params?.id, publication?.data?.title)}>
                <div className={styles.reviewContainer}>
                    <div className={`${styles.item} ${styles.photos}`}>
                        {
                            publication?.fetching === "loading"
                                ? <Skeleton animation="wave" variant="rounded" className={`${styles.title} ${styles.skeleton}`} />
                                : <p className={styles.title}>Fotos</p>
                        }
                        {
                            numberOfInvalidPictures.invalid ?
                                <div className={styles.invalidContainer}>
                                    <Icon path={mdiAlertCircleOutline} title="AlertCircle" color={"red"} className={styles.closeIcon} />
                                    <p className={styles.textInvalidPictures}>Necesitas revisar <p className={styles.numberOfInvalid}>{numberOfInvalidPictures.invalid}</p>/{numberOfInvalidPictures.total} fotos.</p>
                                </div>
                                : null
                        }
                        <div className={styles.content}>
                            {
                                publication?.fetching === "loading"
                                    ? <div className={styles.skeletonItems}>
                                        <Skeleton animation="wave" variant="rounded" className={`${styles.oldPrice} ${styles.skeleton}`} />
                                        <Skeleton animation="wave" variant="rounded" className={`${styles.oldPrice} ${styles.skeleton}`} />
                                        <Skeleton animation="wave" variant="rounded" className={`${styles.oldPrice} ${styles.skeleton}`} />
                                    </div>
                                    : <Slider
                                        type="normal"
                                        className={styles.sliderContent}
                                        perView="auto"
                                        sliderClassName={styles.sliderContent}
                                        spaceBetween={40}
                                        options={
                                            imagesSelected && imagesSelected.map((picture: any, index: number) => (
                                                <div onClick={onClickItem} className={`${styles.image}`} key={index}>
                                                    {picture.valid === false ?
                                                        <div className={styles.invalidPicture}>
                                                            <div className={styles.invalidBackground}>
                                                                <Icon path={mdiAlertCircleOutline} title="AlertCircle" color={"red"} className={styles.closeIcon} />
                                                                <p>La foto no cumple con los criterios establecidos.</p>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    {picture.error ?
                                                        <div className={styles.invalidPicture}>
                                                            <div className={styles.invalidBackground}>
                                                                <Icon path={mdiClose} title="AlertCircle" color={"red"} className={styles.closeIcon} />
                                                                <p>Error al subir esta foto.</p>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    <p className={styles.index}>{index + 1}</p>
                                                    <img key={index} className={`${styles.picture} ${picture.valid === false && styles.invalidPic}`} src={picture?.path ?? images.noImagePublication} alt="Car" />
                                                </div>
                                            ))
                                        }
                                    />
                            }
                            <ButtonShared
                                onPress={handleOpenEditPictures}
                                title="Editar"
                                secondary
                                rightIcon={<Icon path={mdiPencil} color={"#000000"} title="Editar" className={styles.editIcon} />}
                                className={styles.editButton}
                            />
                        </div>
                    </div>
                    <div className={`${styles.item} ${styles.information}`}>
                        {
                            publication?.fetching === "loading"
                                ? <Skeleton animation="wave" variant="rounded" className={`${styles.title} ${styles.skeleton}`} />
                                : <p className={styles.title}>Información</p>
                        }
                        <div className={styles.content}>
                            <TextFieldShared
                                label="Precio"
                                name="price"
                                value={price?.value}
                                onChange={handlePressPrice}
                                error={price?.error}
                                loading={(publication?.fetching === "loading")}
                            />
                            <SelectShared
                                label="Modelo"
                                name="model"
                                value={modelSelected?.value && modelSelected?.value?.id}
                                onChange={handleChangeModel}
                                loading={(publication?.fetching === "loading")}
                                options={modelsNormalized}
                                error={modelSelected?.error}
                            />
                            <SelectShared
                                label="Versión"
                                name="version"
                                value={versionSelected?.value && versionSelected?.value?.id}
                                onChange={handleChangeVersion}
                                loading={(publication?.fetching === "loading")}
                                options={versionsNormalized}
                                error={versionSelected?.error}
                            />
                            <TextFieldShared
                                label="Kilometraje"
                                name="mileage"
                                value={mileage?.value}
                                onChange={handlePressMileage}
                                error={mileage?.error}
                                loading={(publication?.fetching === "loading")}
                            />
                        </div>
                    </div>
                    <div className={`${styles.item} ${styles.caracteristics}`}>
                        {
                            publication?.fetching === "loading"
                                ? <Skeleton animation="wave" variant="rounded" className={`${styles.title} ${styles.skeleton}`} />
                                : <p className={styles.title}>Características</p>
                        }
                        <div className={styles.content}>
                            <SelectShared
                                label="Color"
                                name="color"
                                value={colorSelected?.value}
                                onChange={handleChangeColor}
                                loading={(publication?.fetching === "loading")}
                                options={colorsNormalized}
                                error={colorSelected?.error}
                            />
                            <SelectShared
                                label="Combustible"
                                name="fuel"
                                value={fuelSelected?.value}
                                onChange={handleChangeFuel}
                                loading={(publication?.fetching === "loading")}
                                options={fuelsNormalized}
                                error={fuelSelected?.error}
                            />
                        </div>
                    </div>
                    <div className={`${styles.item} ${styles.history}`}>
                        {
                            publication?.fetching === "loading"
                                ? <Skeleton animation="wave" variant="rounded" className={`${styles.title} ${styles.skeleton}`} />
                                : <p className={styles.title}>Historial</p>
                        }
                        <div className={styles.content}>
                            <SelectShared
                                label="Uso del vehículo"
                                name="personalUse"
                                value={personalUse?.value}
                                onChange={onChangePersonalUse}
                                options={optionsPersonalUse}
                                className={styles.input}
                                keyValue="value"
                            />
                            <SelectShared
                                label="Unico dueño"
                                name="soleOwner"
                                value={soleOwner?.value}
                                onChange={onChangeSoleOwner}
                                options={optionsSoleOwner}
                                className={styles.input}
                                keyValue="value"
                            />
                            <SelectShared
                                label="Accidentes reportados"
                                name="accidentsReported"
                                value={accidentsReported?.value}
                                onChange={onChangeAccidentsReported}
                                options={optionsAccidentsReported}
                                className={styles.input}
                                keyValue="value"
                            />
                        </div>
                    </div>
                    <div className={`${styles.description}`}>
                        {
                            publication?.fetching === "loading"
                                ? <Skeleton animation="wave" variant="rounded" className={`${styles.title} ${styles.skeleton}`} />
                                : <p className={styles.title}>Descripción</p>
                        }
                        <div className={styles.content}>
                            <TextFieldShared
                                label="Descripción"
                                name="description"
                                multiline
                                value={userDescription}
                                onChange={setUserDescription}
                                loading={(publication?.fetching === "loading")}
                            />
                        </div>
                    </div>
                    <div className={styles.buttons}>
                        {
                            publication?.fetching === "loading"
                                ? <Skeleton animation="wave" variant="rounded" className={styles.skeleton} />
                                : <ButtonShared
                                    onPress={handleSave}
                                    title="Publicar"
                                    primary
                                    loading={loading}
                                    disabled={publication?.data?.in_transaction || publication?.data?.reserved || disabled}
                                />
                        }
                        {
                            (publication?.data?.in_transaction || publication?.data?.reserved) &&
                            <p className={styles.error}>La publicación esta en transacción</p>
                        }
                        {
                            <div className={`${styles.timeOut} ${timeOutMessage?.open && styles.show}`}>
                                <p className={styles.loader}>
                                    {timeOutMessage?.text}
                                    <span className={styles.dotOne}>.</span>
                                    <span className={styles.dotTwo}>.</span>
                                    <span className={styles.dotThree}>.</span>
                                </p>
                            </div>
                        }
                    </div>
                </div>
            </ProfileCard>
            {
                publication?.fetching === "loading" || publication?.articles?.fetching === "loading"
                    ?
                    <div className={styles.linzeAiContainer}>
                        <div className={`${styles.item} ${styles.linzeAiItem}`}>
                            <LinzeAiItemLoader />
                        </div>
                    </div>
                    :
                    publication?.data?.chatgpt_description_summary &&
                    <div className={styles.linzeAiContainer}>
                        {
                            publication?.data?.chatgpt_description_summary &&
                            <div className={`${styles.item} ${styles.linzeAiItem}`}>
                                <p className={styles.title}>Descripción</p>
                                <div className={styles.content}>
                                    <p>{publication?.data?.chatgpt_description_summary}</p>
                                </div>
                            </div>
                        }
                    </div>
            }
            <PicturesModal
                open={openModal}
                onClose={() => setOpenModal(false)}
                handleDeleteImage={handleDeleteImage}
                handleChangeImage={handleChangeImage}
                pictures={imagesSelected}
                onChangePosition={onChangePosition}
                postPublicationImages={postPublicationImages}
                loadingReload={updateReducer.newImages.fetching}
            />
        </div>
    )
}

export default Edit;