import { createSlice } from "@reduxjs/toolkit";
import { initialStateProps } from '../interfaces/scheduling';

const initialState: initialStateProps = {
    scheduling: {
        fetching: "unknown",
        data: []
    },
    inspection: {
        fetching: "unknown",
        data: []
    },
    publication: {
        fetching: "unknown",
        data: []
    }
}

const schedulingSlice = createSlice({
    name: "scheduling",
    initialState,
    reducers: {
        getSchedulingRequest: (state) => {
            state.scheduling.fetching = "loading";
        },
        getSchedulingSuccess: (state, action) => {
            state.scheduling.fetching = "success";
            state.scheduling.data = action.payload;
        },
        getSchedulingFailure: (state) => {
            state.scheduling.fetching = "loading";
        },
        getInspectionRequest: (state) => {
            state.inspection.fetching = "loading";
        },
        getInspectionSuccess: (state, action) => {
            state.inspection.fetching = "success";
            state.inspection.data = action.payload;
        },
        getInspectionFailure: (state) => {
            state.inspection.fetching = "failure";
        },
        getSchedulingPublicationRequest: (state) => {
            state.publication.fetching = "loading";
        },
        getSchedulingPublicationSuccess: (state, action) => {
            state.publication.fetching = "success";
            state.publication.data = action.payload;
        },
        getSchedulingPublicationFailure: (state) => {
            state.publication.fetching = "failure";
        },
        clearPublication: (state) => {
            state.publication.fetching = initialState.publication.fetching;
        },
        clearInspection: (state) => {
            state.inspection = initialState.inspection;
        }
    },
});

export const {
    getSchedulingRequest,
    getSchedulingSuccess,
    getSchedulingFailure,
    getInspectionRequest,
    getInspectionSuccess,
    getInspectionFailure,
    getSchedulingPublicationRequest,
    getSchedulingPublicationSuccess,
    getSchedulingPublicationFailure,
    clearPublication,
    clearInspection
} =
    schedulingSlice.actions;

export default schedulingSlice.reducer;
