import { images } from "../../constants/images";
import { Helmet } from 'react-helmet-async';

const MetaDecoration = ({
    title = "Vende o compra tu auto en un mismo lugar",
    description = "En Linze puedes comprar cualquier auto que tengamos disponible, con la posibilidad de financiarlo hasta el 100%", 
    image = "https://res.cloudinary.com/gtt-dev/image/upload/v1681738556/metaImage_r5ausy.png"
}: any) => {

    return(
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta property="og:description" content={description} />
            <meta name="twitter:description" content={description} />

            <meta property="og:image" content={image} />
            <meta itemProp="image" content={image} />
        </Helmet>
    )
}

export default MetaDecoration;