import { createSlice } from '@reduxjs/toolkit';
import { PublicationsStateProps } from '../interfaces/publications';

const initialState: PublicationsStateProps = {
    publications: {
        fetching: "unknown",
        data: [],
        total: 0,
        found: false
    },
    searchText: "",
    brands: {
        fetching: "unknown",
        data: []
    },
    models: {
        fetching: "unknown",
        data: []
    },
    versions: {
        fetching: "unknown",
        data: []
    },
    brandSelected: [],
    modelSelected: [],
    yearSelected: [],
    priceSelected: {
        min: "",
        max: ""
    },
    mileageSelected: [],
    colorSelected: [],
    fuelSelected: [],
    typeSelected: [],
}

const publicationsSlice = createSlice({
    name: 'publications',
    initialState,
    reducers: {
        cleanFilters: (state) => {
            state.searchText = initialState.searchText;
            state.brandSelected = initialState.brandSelected;
            state.models.fetching = initialState.models.fetching;
            state.models.data = initialState.models.data;
            state.modelSelected = initialState.modelSelected;
            state.yearSelected = initialState.yearSelected;
            state.priceSelected = initialState.priceSelected;
            state.mileageSelected = initialState.mileageSelected;
            state.colorSelected = initialState.colorSelected;
            state.fuelSelected = initialState.fuelSelected;
            state.typeSelected = initialState.typeSelected;
        },
        getPublicationsRequest: (state) => {
            state.publications.fetching = "loading";
        },
        getPublicationsSuccess: (state, action) => {
            state.publications.fetching = "success";
            state.publications.data = action.payload.publications;
            state.publications.total = action.payload.total ? action.payload.total : initialState.publications.total;
            state.publications.found = action.payload.found;
        },
        getPublicationsFailure: (state) => {
            state.publications.fetching = "failure";
        },
        setSearch: (state, action) => {
            state.searchText = action.payload;
        },
        getBrandsRequest: (state) => {
            state.brands.fetching = "loading";
        },
        getBrandsSuccess: (state, action) => {
            state.brands.fetching = "success";
            state.brands.data = action.payload;
        },
        getBrandsFailure: (state) => {
            state.brands.fetching = "failure";
        },
        getModelsRequest: (state) => {
            state.models.fetching = "loading";
        },
        getModelsSuccess: (state, action) => {
            state.models.fetching = "success";
            state.models.data = state.models.data.concat(action.payload);
        },
        getModelsFailure: (state) => {
            state.models.fetching = "failure";
        },
        updateModels: (state, action) => {
            state.models.data = action.payload;
        },
        getVersionsRequest: (state) => {
            state.versions.fetching = "loading";
        },
        getVersionsSuccess: (state, action) => {
            state.versions.fetching = "success";
            state.versions.data = action.payload;
        },
        getVersionsFailure: (state, action) => {
            state.versions.fetching = "failure";
        },
        setBrand: (state, action) => {
            state.brandSelected = action.payload;
        },
        setModel: (state, action) => {
            state.modelSelected = action.payload;
        },
        setYear: (state, action) => {
            state.yearSelected = action.payload;
        },
        setPrice: (state, action) => {
            state.priceSelected.min = action.payload.min;
            state.priceSelected.max = action.payload.max;
        },
        setMileage: (state, action) => {
            state.mileageSelected = action.payload;
        },
        setColor: (state, action) => {
            state.colorSelected = action.payload;
        },
        setFuel: (state, action) => {
            state.fuelSelected = action.payload;
        },
        setType: (state, action) => {
            state.typeSelected = action.payload;
        }
    }
});

export const { 
    cleanFilters,
    getPublicationsRequest,
    getPublicationsSuccess,
    getPublicationsFailure,
    setSearch,
    getBrandsRequest,
    getBrandsSuccess,
    getBrandsFailure,
    getModelsRequest,
    getModelsSuccess,
    getModelsFailure,
    getVersionsRequest,
    getVersionsSuccess,
    getVersionsFailure,
    updateModels,
    setBrand,
    setModel,
    setYear,
    setPrice,
    setMileage,
    setColor,
    setFuel,
    setType
} = publicationsSlice.actions;

export default publicationsSlice.reducer;