import { useEffect, useMemo } from "react";
import styles from './identity.module.scss';
import { IdentityProps } from "../../../../interfaces/components";
import { useDocumentType, useToast } from "../../../../helpers/hooks";
import Button from "../../../../components/Button";
import FileUploader from "../../../../components/FileUploader";
import { useLocation } from "react-router-dom";

const Identity = ({
    types,
    setDocuments,
    getDocuments,
    fetchingDocuments,
    documents,
    cleanGetDocuments,
    getIsValidDocuments,
    postDocuments,
    fetchingPostDocuments,
    cleanPostDocuments,
    postDocumentImage,
    fetchingPostDocumentImage,
    cleanPostDocumentImage,
    deleteDocumentImage,
    fetchingDeleteDocumentImage,
    cleanDeleteDocumentImage,
    deleteDocument,
    fetchingDeleteDocument,
    cleanDeleteDocument
}: IdentityProps) => {
    const toast = useToast();
    const location = useLocation();
    const documentType = useDocumentType();

    const onChangeFileUploader = (files: any) => {
        setDocuments("identity_card", files);
        files && files.forEach(async(file: any) => {
            if(file.toDelete){
                if(file?._id){
                    deleteDocument(file?._id, "identity_card");
                }else{
                    deleteDocumentImage(file?.id, "identity_card");
                }
                return;
            }
            if(file?.previewURL){
                let formData = new FormData();
                formData.append("file", file?.file, file?.name);
                postDocumentImage(formData, file?.id, "identity_card");
            }
        })
    }

    const onSaveData = () => {
        if(documents.length > 2){
            return toast("Solamente puedes subir 2 archivos");
        }
        postDocuments(documents, "identity_card");
    };

    const disabled: boolean = useMemo(() => {
        return !documents.some((document: any) => document?.id) || documents.some((document: any) => document?.upload === "loading");
    }, [documents])
    
    useEffect(() => {
        if(fetchingDocuments === "failure"){
            cleanGetDocuments();
        }
        if(fetchingPostDocumentImage === "success"){
            cleanGetDocuments();
        }
    }, [fetchingDocuments]);

    useEffect(() => {
        if(fetchingPostDocumentImage === "failure"){
            cleanPostDocumentImage();
        }
        if(fetchingPostDocumentImage === "success"){
            cleanPostDocumentImage();
        }
    }, [fetchingPostDocumentImage]);

    useEffect(() => {
        if(fetchingPostDocuments === "failure"){
            toast("Ha ocurrido un error al guardar los cambios");
            cleanPostDocuments();
        }
        if(fetchingPostDocuments === "success"){
            getIsValidDocuments();
            toast("Se han actualizado los documentos correctamente");
            cleanPostDocuments();
        }
    }, [fetchingPostDocuments]);

    useEffect(() => {
        if(fetchingDeleteDocument === "failure"){
            toast("Error al eliminar el documento");
            cleanDeleteDocument();
        }
        if(fetchingDeleteDocument === "success"){
            cleanDeleteDocument();
        }
    }, [fetchingDeleteDocument]);

    useEffect(() => {
        if(fetchingDeleteDocumentImage === "failure"){
            toast("Error al eliminar el documento");
            cleanDeleteDocumentImage();
        }
        if(fetchingDeleteDocumentImage === "success"){
            cleanDeleteDocumentImage();
        }
    }, [fetchingDeleteDocumentImage]);
    
    useEffect(() => {
        if(types.data.length > 0 || types.fetching === "success"){
            getDocuments(documentType("identity_card"));
        }
    }, [types.fetching, location.pathname]);

    return(
        <>
            <FileUploader 
                values={documents}
                onChange={onChangeFileUploader}
                enumeration={false}
                draggable={false}
                read={fetchingDeleteDocument === "loading" || fetchingDeleteDocumentImage === "loading"}
            />
            <Button 
                onPress={onSaveData} 
                title="Guardar Cambios" 
                primary 
                className={styles.button} 
                loading={fetchingPostDocuments === "loading"} 
                disabled={disabled || documents.length === 0 || types.fetching === "loading" || types.fetching === "failure" || fetchingPostDocuments === "loading" || fetchingPostDocumentImage === "loading" || fetchingDeleteDocument === "loading" || fetchingDeleteDocumentImage === "loading"} 
            />
        </>
    )
}
export default Identity;