import { Dispatch } from 'redux';
import { api } from '../config/api';
import { GetState } from "../config/store";
import {
    getPublicationsRequest,
    getPublicationsSuccess,
    getPublicationsFailure,
    cleanFilters,
    setSearch as setSearchText,
    getBrandsRequest,
    getBrandsSuccess,
    getBrandsFailure,
    setBrand,
    getModelsRequest,
    getModelsSuccess,
    getModelsFailure,
    getVersionsRequest,
    getVersionsSuccess,
    getVersionsFailure,
    updateModels,
    setModel,
    setYear,
    setPrice,
    setMileage,
    setColor,
    setFuel,
    setType
} from '../reducers/publications';
import { versionsObjectProps } from '../interfaces';
import { axiosClient } from '../config/axios';

export const getPublications = (filters: any, page?: number, limit?: number) => (dispatch: Dispatch, getState: GetState) => _getPublications(filters, page, limit, dispatch, getState);
const _getPublications = async (filters: any, page = 1, limit = 9, dispatch: Dispatch, getState: GetState) => {
    try {
        dispatch({ type: getPublicationsRequest });
        const token = getState().auth.token;
        let url = "";
        let brands = "";
        let models = "";
        let years = "";
        let colors = "";
        let fuels = "";
        let types = "";
        if (filters?.brand && filters?.brand.length > 0) {
            filters.brand.forEach((brand: any) => {
                brands = brands.concat(brand.name + ",")
            })
            url = brands ? `article.brand=${brands.slice(0, -1)}` : "";
        }

        if (filters?.model && filters?.model.length > 0) {
            filters.model.forEach((model: any) => {
                models = models.concat(model.value + ",")
            })
            url = url + `${url ? '&' : ''}article.model=${models.slice(0, -1)}`;
        }

        if (filters?.year && filters?.year.length > 0) {
            filters.year.forEach((year: any) => {
                years = years.concat(year + ",")
            })
            url = url + `${url ? '&' : ''}article.year=1,${years.slice(0, -1)}`;
        }

        if (filters?.price?.min && filters?.price?.min.length > 0 && filters?.price?.max && filters?.price?.max.length > 0) { 
            url = url + `${url ? '&' : ''}article.price=${filters.price.min},${filters.price.max}`;
        } else {
            if (filters?.price?.min && filters?.price?.min.length > 0) url = url + `${url ? '&' : ''}article.price=${filters.price.min},*`;
            if (filters?.price?.max && filters?.price?.max.length > 0) url = url + `${url ? '&' : ''}article.price=*,${filters.price.max}`;
        }

        if (filters?.mileage && filters?.mileage.length > 0) {
            url = url + `${url ? '&' : ''}article.mileage=${filters.mileage[0]?.value}`;
        }

        if (filters?.color && filters?.color.length > 0) {
            filters.color.forEach((color: any) => {
                colors = colors.concat(color + ",");
            })
            url = url + `${url ? '&' : ''}article.color=${colors.slice(0, -1)}`;
        }

        if (filters?.fuel && filters?.fuel.length > 0) {
            filters?.fuel.forEach((fuel: any) => {
                fuels = fuels.concat(fuel + ",")
            })
            url = url + `${url ? '&' : ''}article.fuel=${fuels.slice(0, -1)}`;
        }

        if (filters?.type && filters?.type.length > 0) {
            filters?.type.forEach((type: any) => {
                types = types.concat(type + ",")
            })
            url = url + `${url ? '&' : ''}article.type=${types.slice(0, -1)}`;
        }

        if (filters?.text && filters?.text.length > 0) {
            url = url + `${url ? '&' : ''}text=${filters?.text}`;
        }

        if (filters?.order && filters?.order !== "relevancia") {
            url = url + `${url ? '&' : ''}order=${filters?.order}`;
        }

        if (url) {
            url = url + "&";
        }

        const response = await axiosClient.get(`${api.publications}?${url}page=${page}&limit=${limit}`);

        dispatch({
            type: getPublicationsSuccess,
            payload: {
                publications: response?.data.data.publications,
                total: response?.data.data.total,
                found: response?.data.data.found
            }
        });

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getPublicationsFailure });
    }
}

export const cleanFiltersReducer = () => (dispatch: Dispatch) => _cleanFiltersReducer(dispatch);
const _cleanFiltersReducer = (dispatch: Dispatch) => dispatch({ type: cleanFilters });

export const setSearchReducer = (text: string) => (dispatch: Dispatch) => _setSearchReducer(text, dispatch);
const _setSearchReducer = (text: string, dispatch: Dispatch) => dispatch({ type: setSearchText, payload: text });

export const getBrands = () => (dispatch: Dispatch) => _getBrands(dispatch);
const _getBrands = async (dispatch: Dispatch) => {
    try {
        dispatch({ type: getBrandsRequest });

        const response = await axiosClient.get(`${api.autoRed}/brands`);

        dispatch({ type: getBrandsSuccess, payload: response?.data.data.brands });

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getBrandsFailure });
    }
}

export const getModels = (car_id: string) => (dispatch: Dispatch) => _getModels(car_id, dispatch);
const _getModels = async (car_id: string, dispatch: Dispatch) => {
    try {
        dispatch({ type: getModelsRequest });

        const response = await axiosClient.get(`${api.autoRed}/models/${car_id}`);

        dispatch({ type: getModelsSuccess, payload: response?.data });

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getModelsFailure });
    }
}

export const getVersions = (versionsObject: versionsObjectProps) => (dispatch: Dispatch) => _getVersions(versionsObject, dispatch);
const _getVersions = async (versionsObject: versionsObjectProps, dispatch: Dispatch) => {
    try {
        dispatch({type: getVersionsRequest});


        const response = await axiosClient.post(`${api.autoRed}/versions`, versionsObject);

        dispatch({type: getVersionsSuccess, payload: response?.data});
        
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({type: getVersionsFailure});
    }
}

export const updateModelsReducer = (models: any[]) => (dispatch: Dispatch) => _updateModelsReducer(models, dispatch);
const _updateModelsReducer = (models: any[], dispatch: Dispatch) => dispatch({ type: updateModels, payload: models });

export const setBrandReducer = (brand: string) => (dispatch: Dispatch) => _setBrandReducer(brand, dispatch);
const _setBrandReducer = (brand: string, dispatch: Dispatch) => dispatch({ type: setBrand, payload: brand });

export const setModelReducer = (model: string) => (dispatch: Dispatch) => _setModelReducer(model, dispatch);
const _setModelReducer = (model: string, dispatch: Dispatch) => dispatch({ type: setModel, payload: model });

export const setYearReducer = (year: string) => (dispatch: Dispatch) => _setYearReducer(year, dispatch);
const _setYearReducer = (year: string, dispatch: Dispatch) => dispatch({ type: setYear, payload: year });

export const setPriceReducer = (min: string, max: string) => (dispatch: Dispatch) => _setPriceReducer(min, max, dispatch);
const _setPriceReducer = (min: string, max: string, dispatch: Dispatch) => dispatch({ type: setPrice, payload: {min: min, max: max} });

export const setMileageReducer = (mileage: string) => (dispatch: Dispatch) => _setMileageReducer(mileage, dispatch);
const _setMileageReducer = (mileage: string, dispatch: Dispatch) => dispatch({ type: setMileage, payload: mileage });

export const setColorReducer = (color: string) => (dispatch: Dispatch) => _setColorReducer(color, dispatch);
const _setColorReducer = (color: string, dispatch: Dispatch) => dispatch({ type: setColor, payload: color });

export const setFuelReducer = (fuel: string) => (dispatch: Dispatch) => _setFuelReducer(fuel, dispatch);
const _setFuelReducer = (fuel: string, dispatch: Dispatch) => dispatch({ type: setFuel, payload: fuel });

export const setTypeReducer = (type: string) => (dispatch: Dispatch) => _setTypeReducer(type, dispatch);
const _setTypeReducer = (type: string, dispatch: Dispatch) => dispatch({ type: setType, payload: type });