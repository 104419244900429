import { useEffect, useState } from 'react';
import styles from './datePicker.module.scss';
import { DatePickerSharedProps } from '../../interfaces/components';
import { TextField, Skeleton, InputAdornment, CircularProgress } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { capitalize } from '../../helpers/app';

const DatePickerShared = ({
    label,
    className,
    onChange = () => {},
    inputFormat = "DD/MM/YYYY",
    value,
    disabled = false,
    autoFocus,
    fullWidth = true,
    loading = false,
    error = ""
}: DatePickerSharedProps) => {
    const [open, setOpen] = useState(false);
    const [date, setDate] = useState<string>("");

    useEffect(() => {
        setDate(value);
    }, [value]);
    
    return (
        <div className={`${styles.datePicker} ${className}`}>
            <LocalizationProvider fullWidth={fullWidth} dateAdapter={AdapterDayjs}>
                <DatePicker
                    open={open}
                    disabled={disabled}
                    label={label}
                    value={date}
                    onChange={onChange}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    renderInput={
                        (params) => <TextField 
                            {...params} 
                            variant="filled" 
                            fullWidth
                            className={styles.renderInput} 
                            onClick={(e) => setOpen(true)}
                            InputLabelProps={{
                                className: styles.label,
                            }}
                            InputProps={{ 
                                className: styles.input,
                                disableUnderline: true,
                                endAdornment: (
                                    loading &&
                                    <InputAdornment position="end">
                                        <CircularProgress
                                            size={styles.loader}
                                            classes={{
                                                colorPrimary: styles.loader
                                            }}
                                        />
                                    </InputAdornment>
                                )
                            }}
                        />
                        
                    }
                    inputFormat={inputFormat}
                    autoFocus={autoFocus}
                />
            </LocalizationProvider>
            {error && <p className={styles.error}>{capitalize(error)}</p>}
        </div>
    );
};

export default DatePickerShared;