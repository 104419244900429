import { useEffect, useMemo, useState } from "react";
import { _deleteDocument } from "../actions/documents";
import { useSnackbar } from "notistack";
import { profileRole, roleRestrictedRoutes } from "../constants/auth";
import { CoordsProps } from "../interfaces";
import { DocumentsTypes } from "../interfaces/types";
import { _getPlaces } from "../actions/places";
import { store } from "../config/store";

export const useIsMobileDevice = () => {
    const isMobileDevice = (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i));
    return isMobileDevice ? true : false;
}

export const useProfileRole = () => {
    const getProfileRole = (userRole: any) => {
        const role = Object.entries(profileRole).find((entry: any) => {
            const [key, value] = entry;
            const roleFound = value.some((item: any) => item === userRole);
            if(roleFound) return key;
        });
        return role && role[0];
    }
    return getProfileRole;
};

export const useRoutesRole = () => {
    const getRoutesRole = (route: any, userRole: any) => {
        const role = Object.entries(roleRestrictedRoutes).find((entry: any) => {
            const [key, value] = entry;
            if(userRole === key){
                const roleFound = value.some((item: any) => route.match(item));
                if(roleFound) return key;
            }
        });
        if(role){
            return role[0];
        }else{
            return "";
        }
    }
    return getRoutesRole;
};

export const useDebounce = (value: any, delay: number) => {
    const [debounceValue, setDebounceValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebounceValue(value)
        }, delay);
        return () => {
            clearTimeout(handler)
        }

    }, [value, delay]);
    return debounceValue;
}

export const useDocumentType = () => {
    const types = store.getState().documents.types;

    const documentType = (type: DocumentsTypes) => {
        const typeFound = types && types.data.length > 0 ? types.data.find((item: any) => item.code === type) : null
        return typeFound;
    };

    return documentType;
}

export const useOnChangeFiles = (
    token: string | undefined,
    postFilesRequest: Function, 
    deleteFilesRequest: Function, 
    documentType?: string, 
    types?: any
) => {
    const [files, setFiles] = useState<any>([]);

    const identityType: any = useMemo(() => {
        return types && types.length > 0 ? types.find((type: any) => type.code === documentType) : null;
    }, [types]);

    const onSubmitFiles = async (newFiles: any) => {
        const allFiles: any = [];
        const submitFiles: Function = await new Promise((resolve, reject) => {
            /* newFiles && newFiles.forEach((file: any) => {
                const formData = new FormData();
                formData.append("file", file?.file, file?.file?.name);
                _postDocumentsAndImages(formData, token)
                .then((res: any) => {
                    if(identityType){
                        allFiles.push({
                            document_type: identityType?._id,
                            url: res.file.path
                        });
                    }else{
                        allFiles.push({
                            url: res.file.path
                        });
                    }
                    if (allFiles.length === newFiles.length){
                        resolve(allFiles);
                    }
                })
                .catch((reason) => reject(reason));
            }) */
        });

        if (submitFiles && Array.isArray(submitFiles) && submitFiles.length > 0) {
            postFilesRequest(submitFiles);
        }
    }

    const onDeleteFiles = async (deleteFiles: any) => {
        deleteFiles && deleteFiles.forEach((file: any) => {
            deleteFilesRequest(file?._id);
        });
    }

    const onUpdateFiles = (values: any) => {
        setFiles(values);
    }

    const onChangeFiles = async () => {
        const newFiles = files.filter((file: any) => file?.previewURL);
        const deleteFiles = files.filter((file: any) => file?.toDelete);
        if(newFiles && newFiles.length > 0) onSubmitFiles(newFiles);
        if(deleteFiles && deleteFiles.length > 0) onDeleteFiles(deleteFiles);
    }

    return {
        identityType,
        onChangeFiles,
        onUpdateFiles,
        files
    }
};

export const useOnChangeDocument = (token: string | undefined, postDocumentRequest: Function, documentType?: string, types?: any) => {

    const [archives, setArchives] = useState();

    const identityType: any = useMemo(() => types?.length > 0 ? types.find((type: any) => type.code === documentType) : null, [types]);

    const onChangeDocument = async (documents: any) => {

        setArchives(documents);
        const allDocuments: any = [];

        let quantityOfDocumentsForUpload = 0;

        if (!documents) return;

        documents.forEach((doc: any) => {
            if (!doc._id) {
                quantityOfDocumentsForUpload++;
            }
        });

        const uploadDocuments = await new Promise((resolve, reject) => {

            documents.forEach((document: any) => {

                if (quantityOfDocumentsForUpload === 0) resolve("ok");

                const formData = new FormData();
                if(!document?._id){
                    formData.append("file",document.element);

                    /* _postDocumentsAndImages(formData, token).then((res: any) => {
                        if(identityType){
                            allDocuments.push({
                                document_type: identityType?._id,
                                url: res.file.path
                            });
                        }
                        else {
                            allDocuments.push({
                                url: res.file.path
                            });
                        }
                        if (allDocuments.length === quantityOfDocumentsForUpload) {
                            resolve(allDocuments);
                        }
                    }).catch((reason) =>  reject(reason)); */
                }
            })
        });

        if (uploadDocuments && Array.isArray(uploadDocuments) && uploadDocuments.length > 0) {
            postDocumentRequest(uploadDocuments);
        }
    }
    return {
        archives,
        setArchives,
        identityType,
        onChangeDocument
    }
};

export const useOnGetPlaces = () => {
    const [places, setPlaces] = useState<any>({
        fetching: "unknown",
        data: []
    });

    const getPlaces = async (text: string, coords: CoordsProps) => {
        const placesResult = await new Promise((resolve, reject) => {
            setPlaces({...places, fetching: "loading"});
            _getPlaces(text, coords)
            .then((res: any) => resolve(res))
            .catch((reason: any) => reject(reason));
        });
        setPlaces({fetching: "success", data: placesResult ?? []});
    }
    
    return {
        getPlaces,
        places
    }
}

export const useToast = () => {
    const { enqueueSnackbar } = useSnackbar();
    const pushToast = (
        message: string,
        secondParameter?: any
    ) => {
        let content = `message\n${message}`;

        if(secondParameter){
            if(typeof(secondParameter) === "string"){
                content = `title\n${secondParameter}\n${message}`;
            }
        }

        enqueueSnackbar(content, { autoHideDuration: 4000 });
    };

    return pushToast;
};