export const myPubDetailBreadCrumb = (text: any) => {

    return [
        {
            label: "Mis publicaciones",
            to: "/profile/my_publications"
        },
        {
            label: `Detalle - ${text}`,
        }
    ];
}

export const myPubManagementBreadCrumb = (id: any) => {

    return [
        {
            label: "Mis publicaciones",
            to: "/profile/my_publications"
        },
        {
            label: `Gestión`,
            to: `/profile/my_publications/${id}/management`,
            bold: true
        }
    ];
}

export const myPubEditBreadCrumb = (id: any, text: any) => {

    return [
        {
            label: "Mis publicaciones",
            to: "/profile/my_publications"
        },
        {
            label: "Detalle",
            to: "/profile/my_publications/" + id + "/detail"
        },
        {
            label: `Revisar - ${text}`
        }
    ];
}

export const myBuysManagementBreadCrumb = (id: any) => {

    return [
        {
            label: "Mis compras",
            to: "/profile/my_buys"
        },
        {
            label: `Gestión`,
            to: `/profile/my_buys/${id}/management`,
            bold: true
        }
    ];
}

export const myBuysPaymentsBreadCrumb = (text: any) => {

    return [
        {
            label: "Mis compras",
            to: "/profile/my_buys"
        },
        {
            label: `Pagos - ${text}`
        }
    ];
}

export const myBuysPaymentMethodBreadCrumb = (id: any, text: any) => {

    return [
        {
            label: "Mis compras",
            to: "/profile/my_buys"
        },
        {
            label: "Pagos",
            to: "/profile/my_buys/" + id + "/payments"
        },
        {
            label: `Vale vista - ${text}`
        }
    ];
}

export const myBuysQRBreadCrumb = (text: any) => {

    return [
        {
            label: "Mis compras",
            to: "/profile/my_buys"
        },
        {
            label: `Generar QR - ${text}`
        }
    ];
}

export const myBuysInspectionsBreadCrumb = (text: any) => {

    return [
        {
            label: "Mis compras",
            to: "/profile/my_buys"
        },
        {
            label: `Ver inspecciones - ${text}`
        }
    ];
}