import styles from './breadCrumbs.module.scss';
import { BreadCrumbsProps } from '../../interfaces/components';
import { Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';

const BreadCrumbs = ({
    options = []
}: BreadCrumbsProps) => {
    return (
        <Breadcrumbs aria-label="breadcrumb" separator=">" className={styles.breadCrumbs}>
        {
            options && options.map((option: any, index: number) => {
                if(option.to){
                    return (
                        <Link 
                            color="inherit" 
                            key={index}
                            to={option?.to}
                            className={`${styles.option} ${option?.bold && styles.bold}`}
                        >
                            {option?.label}
                        </Link>
                    )
                }else{
                    return <p key={index} className={styles.option}>{option?.label}</p>
                }
            })
        }
        </Breadcrumbs>
    );
};

export default BreadCrumbs;