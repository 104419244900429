import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { CarSearchInformationContainerProps } from "../../interfaces/containers";
import CarSearchInformation from "../../pages/Sell/CarSearchInformation";
import { getModels, cleanGetModels, clearSalePost, setModel } from "../../actions/salePost";

function mapStateToProps(state: CarSearchInformationContainerProps) {
    return{
        plate: state.salePost.plate,
        brands: state.salePost.brands,
        models: state.salePost.models,
        year: state.salePost.year,
        transmission: state.salePost.transmission,
        fuel: state.salePost.fuel,
        model_image: state.salePost.model_image,
        vehicle_type: state.salePost.vehicle_type,
        vin: state.salePost.vin,
        color: state.salePost.color,
        isFoundPlate: state.salePost.isFoundPlate
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        clearSalePost,
        setModel,
        getModels,
        cleanGetModels
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(CarSearchInformation);