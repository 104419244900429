import { Dispatch } from 'redux';
import { api } from "../config/api";
import { GetState } from "../config/store";
import { 
    postTransactionConfirmationRequest,
    postTransactionConfirmationSuccess,
    postTransactionConfirmationFailure,
    cleanTransaction as cleanTransactionReducer
} from '../reducers/transaction';
import { TransactionPropsBuy, TransactionPropsSell } from "../interfaces";
import { axiosClient } from '../config/axios';

export const postTransactionConfirmation = (transaction: TransactionPropsBuy | TransactionPropsSell) => (dispatch: Dispatch) => _postTransactionConfirmation(transaction, dispatch);
const _postTransactionConfirmation = async (transaction: TransactionPropsBuy | TransactionPropsSell, dispatch: Dispatch) => {
    try {
        dispatch({type: postTransactionConfirmationRequest});

        const body = JSON.stringify(transaction);

        await axiosClient.post(`${api.transactions}/inspection-schedules`, body);

        dispatch({type: postTransactionConfirmationSuccess})
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        if(errorResponse?.code) return dispatch({ type: postTransactionConfirmationFailure, payload: errorResponse.code });
        dispatch({type: postTransactionConfirmationFailure, payload: "Ha ocurrido un error"});

    }
}

export const cleanTransaction = () => (dispatch: Dispatch) => _cleanTransaction(dispatch);
const _cleanTransaction = (dispatch: Dispatch) => dispatch({type: cleanTransactionReducer})