import styles from "./rightButtons.module.scss";

const RightButtons = ({ 
    onShare = () => {}, 
    onDelete = () => {}
}: any) => {
    return (
        <div className={styles.rightButtons}>
            <p className={styles.button} onClick={onShare}>Compartir</p>
        </div>
    );
}

export default RightButtons;