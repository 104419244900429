import React, { useEffect, useState } from "react";
import Modal from "../../../../../components/Modal";
import { PicturesModalProps } from "../../../../../interfaces/components";
import FileUploader from "../../../../../components/FileUploader";
import styles from './picturesModal.module.scss';
import Button from "../../../../../components/Button";
import { useNavigate } from "react-router-dom";
import Icon from '@mdi/react';
import { mdiClose, mdiAlertCircleOutline, mdiTrashCanOutline, mdiReload } from '@mdi/js';
import { CircularProgress, IconButton } from "@mui/material";

const PicturesModal = ({open, onClose, pictures, handleDeleteImage, handleChangeImage, onChangePosition, postPublicationImages, reloadedName, loadingReload}: PicturesModalProps) => {
    const [selectedPicture, setSelectedPicture] = useState<any>();
    const [_reloadedName, _setReloadedName] = useState<any>();
    const navigate = useNavigate();

    const onPressReload = (image: any) => {
        const formData = new FormData();
        formData.append('file', image.file, image?.name);
        postPublicationImages(formData,true);
        _setReloadedName(image?.name);
    };


    const onDeleteItem = (picture: any) => {
        handleDeleteImage && handleDeleteImage(picture?.id);
        setSelectedPicture(undefined);
    };
    const _onClose = () => {
        setSelectedPicture(undefined);
        onClose && onClose();
    };
    
    const handleClickPicturesPolicies = () => {
        navigate(`/frequent_questions/pictures`);
    };

    useEffect(() => {
        if(reloadedName && reloadedName === selectedPicture?.name){
            setSelectedPicture((prev: any) => ({...prev, error: false}));
        }
    },[reloadedName])

    useEffect(() => {
        if(loadingReload === "success"){
            _setReloadedName(undefined);
            if(_reloadedName === selectedPicture?.name){
                setSelectedPicture((prev: any) => ({...prev, error: false}));
            }
        }
    },[loadingReload]);

    return(
        <Modal open={open} onClose={_onClose} variant="close" className={`${styles.modalContainer} ${selectedPicture && styles.bigModal}`}>
            <div className={styles.modalChildren}>
                <div className={styles.flexRow}>
                    <div className={styles.flexColumn}>
                        <div className={styles.modalPictureTitle}>
                            <p>Añadir fotos</p>
                            <p>Debes añadir mínimo 3 fotos</p>
                            <p onClick={handleClickPicturesPolicies}>{`Conoce mas sobre como subir fotos\xa0\xa0>`}</p>
                        </div>
                        <div className={`${styles.inputsContainer} ${pictures?.length === 0 && styles.noImages}`}>
                            <FileUploader 
                                values={pictures}
                                showInvalids={true}
                                bigPictures={true}
                                selectedPicture={selectedPicture}
                                onAddItem={handleChangeImage}
                                onDeleteItem={onDeleteItem}
                                onMoveItem={onChangePosition}
                                onPressReload={onPressReload}
                                setSelectedPicture={setSelectedPicture}
                                className={`${styles.fileUploader} ${selectedPicture && styles.fullWidth}`}
                                scroll={pictures ? selectedPicture ? pictures.length > 3 :  pictures.length > 5 : false}
                                reloadedName={_reloadedName}
                            />
                        </div>
                        <div className={styles.buttonContainer}>
                            <Button 
                                onPress={onClose}
                                title="Guardar"
                                primary
                                className={styles.submitButton}
                            />
                        </div>
                    </div>
                    {selectedPicture &&
                        <div className={styles.selectedPicture}>
                            <div className={styles.pictureContainer}>
                                <div className={styles.topContainer}>
                                    <img className={styles.bigImage} src={selectedPicture.uri ?? selectedPicture.url ?? selectedPicture.previewURL ?? selectedPicture.path ?? selectedPicture} alt="File" />
                                    {
                                        selectedPicture && <p className={styles.index}>{selectedPicture.index + 1}</p>
                                    }
                                    {
                                        (selectedPicture && selectedPicture?.upload !== "loading") ?
                                        <IconButton onClick={() => onDeleteItem(selectedPicture)} aria-label='Delete' className={styles.delete}>
                                            <Icon path={mdiTrashCanOutline} title="Eliminar" className={styles.icon} />
                                        </IconButton>
                                        : null
                                    }
                                </div>
                                {(selectedPicture?.valid === false) && 
                                    <div className={styles.invalidPicture}>
                                        <div className={styles.invalidBackground}>
                                            <Icon path={mdiAlertCircleOutline} title="AlertCircle" color={"red"} className={styles.closeIcon} />
                                            <p>La foto no cumple con los criterios establecidos.</p>
                                        </div>
                                    </div>
                                }
                                {(selectedPicture?.error === true) && 
                                    <div className={styles.invalidPicture}>
                                        <div onClick={() => onPressReload(selectedPicture)} className={styles.invalidBackground}>
                                            <Icon path={mdiClose} title="AlertCircle" color={"red"} className={styles.closeIcon} />
                                            <p>Error al subir esta foto.</p>
                                            {loadingReload === "loading" ? <CircularProgress
                                                size={"18px"}
                                                color="primary"
                                                className={styles.circularProgress}
                                                classes={{
                                                    colorPrimary: "black"
                                                }}
                                            /> : 
                                            <Icon path={mdiReload} title="AlertCircle" color={"black"} className={`${styles.closeIcon} ${styles.cursorPointer}`} />}
                                        </div>
                                    </div>
                                }
                                {
                                    (selectedPicture) ?
                                    <IconButton aria-label='cancel' onClick={() => setSelectedPicture(undefined)} className={styles.cancel}>
                                        <Icon path={mdiClose} title="Cancel" className={styles.icon} />
                                    </IconButton>
                                    : null
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        </Modal>
    )
};

export default PicturesModal;