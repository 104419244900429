import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { DetailSellContainerProps } from "../../interfaces/containers";
import Detail from "../../pages/Sell/Detail";
import { clearPostFetching, clearSalePost, postPublication } from "../../actions/salePost";
import { setLoginRedirection } from "../../actions/auth";

function mapStateToProps(state: DetailSellContainerProps) {
    return{
        user: state.auth.user,
        salePost: state.salePost
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        clearPostFetching,
        clearSalePost,
        postPublication,
        setLoginRedirection
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Detail);