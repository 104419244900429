import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import Settings from "../../../pages/Profile/Settings";
import { changePassword, cleanSettings } from "../../../actions/profile";
import { SettingsContainerProps } from "../../../interfaces/containers";


function mapStateToProps(state: SettingsContainerProps) {
    return {
        fetchingChangePassword: state.profile.changePassword.fetching
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        changePassword,
        cleanSettings
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);