import styles from "./qr.module.scss";
import { QRManagementProps } from "../../../../../interfaces/components";
import { QRCodeSVG } from "qrcode.react";
import { addPointsInText } from "../../../../../helpers/app";

const QR = ({
    id,
    publication,
    className
}: QRManagementProps) => {

    return(
        <div className={`${styles.qr} ${className}`}>
            <div className={styles.container}>
                <div className={styles.code}>
                    <QRCodeSVG
                        value={id ?? ""}
                        size={90}
                        bgColor={"#ffffff"}
                        fgColor={"rgba(159, 133, 236, 0.7)"}
                        color="rgba(159, 133, 236, 0.7)"
                        level={"L"}
                        includeMargin={false}
                    />
                </div>
                <div className={styles.content}>
                    <p className={`${styles.text} ${styles.title}`}>{publication?.title}</p>
                    <p className={`${styles.text} ${styles.description}`}>{publication?.primary_description} · {addPointsInText(publication?.secondary_description)} km | {publication?.vehicle_data?.plate}</p>
                    <p className={`${styles.text} ${styles.price}`}>${addPointsInText(publication?.price)}</p>
                </div>
            </div>
        </div>
    )
};

export default QR;