import { createSlice } from '@reduxjs/toolkit';
import { PublicationStateProps } from '../interfaces/publication';

const initialState: PublicationStateProps = {
    fetching: "unknown",
    data: [],
    error: "",
    articles: {
        fetching: "unknown",
        data: [],
        error: ""
    },
    update: {
        deleteImages: {
            fetching: "unknown",
            data: []
        },
        newImages: {
            fetching: "unknown",
            data: [],
            count: 0,
            reloaded: false,
            reloadedName: ""
        },
        fetching: "unknown",
        data: [],
        error: {
            code: "",
            message: ""
        }
    },
    views: {
        fetching: "unknown",
        data: 0,
        error: ""
    },
    interested: {
        fetching: "unknown",
        data: 0,
        error: ""
    },
    offer: {
        post: {
            fetching: "unknown",
            error: {
                code: "",
                message: ""
            },
        },
        put: {
            fetching: "unknown",
            data: [],
            error: {
                code: "",
                message: ""
            },
        },
        fetching: "unknown",
        data: [],
        accepted: {
            fetching: "unknown",
            data: []
        },
        buy: {
            fetching: "unknown",
            data: [],
            error: ""
        }
    },
    inspection: {
        fetching: "unknown",
        data: [],
        files: {
            fetching: "unknown",
            data: []
        },
        linzerAssigned: {
            fetching: "unknown",
            data: []
        }
    },
    history: {
        fetching: "unknown",
        data: []
    },
    deleteFetching: "unknown",
    records: {
        fetching: "unknown",
        address: "",
        date_start: "",
        date_end: "",
        error: ""
    },
    counterOffers: {
        fetching: "unknown",
        data: [],
        error: "",
        post: {
            fetching: "unknown",
            error: "",
        },
        put: {
            fetching: "unknown",
            error: "",
        }
    },
    pause: {
        fetching: "unknown",
        paused: false,
        error: ""
    },
}

const publicationSlice = createSlice({
    name: 'publication',
    initialState,
    reducers: {
        getPublicationRequest: (state) => {
            state.fetching = "loading";
        },
        getPublicationSuccess: (state, action) => {
            state.fetching = "success";
            state.data = action.payload;
        },
        getPublicationFailure: (state, action) => {
            state.fetching = "failure";
            state.error = action.payload;
        },
        cleanPublication: (state) => {
            state.fetching = initialState.fetching;
        },
        getPublicationArticlesRequest: (state) => {
            state.articles.fetching = "loading";
        },
        getPublicationArticlesSuccess: (state, action) => {
            state.articles.fetching = "success";
            state.articles.data = action.payload;
        },
        getPublicationArticlesFailure: (state, action) => {
            state.articles.fetching = "failure";
            state.articles.error = action.payload;
        },
        cleanPublicationArticles: (state) => {
            state.articles.fetching = initialState.articles.fetching;
        },
        updatePublicationRequest: (state) => {
            state.update.fetching = "loading";
        },
        updatePublicationSuccess: (state, action) => {
            state.update.fetching = "success";
            state.update.data = action.payload;
        },
        updatePublicationFailure: (state, action) => {
            state.update.fetching = "failure";
            if(action.payload){
                state.update.error.code = action.payload.code;
                state.update.error.message = action.payload.message;
            }
        },
        postImagesPublicationRequest: (state) => {
            state.update.newImages.fetching = "loading";
        },
        postImagesPublicationSuccess: (state, action) => {
            state.update.newImages.fetching = "success";
            state.update.newImages.data = action.payload.data;
            state.update.newImages.count = state.update.newImages.count + 1;
            state.update.newImages.reloaded = action.payload.reloaded;
            state.update.newImages.reloadedName = action.payload.reloadedName;
        },
        postImagesPublicationFailure: (state, action) => {
            state.update.newImages.fetching = "failure";
            state.update.newImages.data = action.payload.newImages;
        },
        setUploadImagesReducer: (state, action) => {
            state.update.newImages.data = action.payload;
        },
        deleteImagesPublicationRequest: (state) => {
            state.update.deleteImages.fetching = "loading";
        },
        deleteImagesPublicationSuccess: (state, action) => {
            state.update.deleteImages.fetching = "success";
            state.update.deleteImages.data = state.update.deleteImages.data.concat(action.payload);
        },
        deleteImagesPublicationFailure: (state) => {
            state.update.deleteImages.fetching = "failure";
        },
        postOfferRequest: (state) => {
            state.offer.post.fetching = "loading";
        },
        postOfferSuccess: (state) => {
            state.offer.post.fetching = "success";
        },
        postOfferFailure: (state, action) => {
            state.offer.post.fetching = "failure";
            state.offer.post.error.code = action.payload.code;
            state.offer.post.error.message = action.payload.message;
        },
        cleanOfferPost: (state) => {
            state.offer.post = initialState.offer.post
        },
        getOfferAcceptedRequest: (state) => {
            state.offer.accepted.fetching = "loading";
        },
        getOfferAcceptedSuccess: (state, action) => {
            state.offer.accepted.fetching = "success";
            state.offer.accepted.data = action.payload;
        },
        getOfferAcceptedFailure: (state) => {
            state.offer.accepted.fetching = "failure";
        },
        cleanOfferAccepted: (state) => {
            state.offer.accepted.fetching = initialState.offer.accepted.fetching;
        },
        getOfferBuyRequest: (state) => {
            state.offer.buy.fetching = "loading";
        },
        getOfferBuySuccess: (state, action) => {
            state.offer.buy.fetching = "success";
            state.offer.buy.data = action.payload;
        },
        getOfferBuyFailure: (state, action) => {
            state.offer.buy.fetching = "failure";
            state.offer.buy.error = action.payload;
        },
        cleanOffersBuy: (state) => {
            state.offer.buy.fetching = initialState.offer.buy.fetching;
        },
        getOffersPublicationRequest: (state) => {
            state.offer.fetching = "loading";
        },
        getOffersPublicationSuccess: (state, action) => {
            state.offer.fetching = "success";
            state.offer.data = action.payload.reverse();
        },
        getOffersPublicationFailure: (state) => {
            state.offer.fetching = "failure";
        },
        putOffersPublicationRequest: (state) => {
            state.offer.put.fetching = "loading";
        },
        putOffersPublicationSuccess: (state, action) => {
            state.offer.put.fetching = "success";
            state.offer.put.data = action.payload;
        },
        putOffersPublicationFailure: (state, action) => {
            state.offer.put.fetching = "failure";
            state.offer.put.error.code = action.payload.code;
            state.offer.put.error.message = action.payload.message;
        },
        cleanOfferPut: (state) => {
            state.offer.put = initialState.offer.put
        },
        getInspectionRequest: (state) => {
            state.inspection.fetching = "loading"
        },
        getInspectionSuccess: (state, action) => {
            state.inspection.fetching = "success";
            state.inspection.data = action.payload;
        },
        getInspectionFailure: (state) => {
            state.inspection.fetching = "failure";
        },
        getInspectionFilesRequest: (state) => {
            state.inspection.files.fetching = "loading";
        },
        getInspectionFilesSuccess: (state, action) => {
            state.inspection.files.fetching = "success";
            state.inspection.files.data = action.payload;
        },
        getInspectionFilesFailure: (state) => {
            state.inspection.files.fetching = "failure";
        },
        cleanInspectionFiles: (state) => {
            state.inspection.files.fetching = initialState.inspection.files.fetching;
        },
        getLinzerAssignedRequest: (state) => {
            state.inspection.linzerAssigned.fetching = "loading";
        },
        getLinzerAssignedSuccess: (state, action) => {
            state.inspection.linzerAssigned.fetching = "success";
            state.inspection.linzerAssigned.data = action.payload;
        },
        getLinzerAssignedFailure: (state) => {
            state.inspection.linzerAssigned.fetching = "failure";
        },
        getHistoryRequest: (state) => {
            state.history.fetching = "loading";
        },
        getHistorySuccess: (state, action) => {
            state.history.fetching = "success";
            state.history.data = action.payload;
        },
        getHistoryFailure: (state) => {
            state.history.fetching = "failure";
        },
        cleanHistory: (state) => {
            state.history.fetching = initialState.history.fetching;
        },
        deletePublicationRequest: (state) => {
            state.deleteFetching = "loading";
        },
        deletePublicationSuccess: (state) => {
            state.deleteFetching = "success";
        },
        deletePublicationFailure: (state) => {
            state.deleteFetching = "failure";
        },
        cleanDeletePublication: (state) => {
            state.deleteFetching = initialState.deleteFetching;
        },
        cleanUpdate: (state) => {
            state.update.fetching = initialState.update.fetching;
            state.update.data = initialState.update.data;
            state.update.newImages.fetching = initialState.update.newImages.fetching;
            state.update.newImages.data = initialState.update.newImages.data;
            state.update.newImages.count = initialState.update.newImages.count;
            state.update.newImages.reloaded = initialState.update.newImages.reloaded;
            state.update.deleteImages.fetching = initialState.update.deleteImages.fetching;
            state.update.deleteImages.data = initialState.update.deleteImages.data;
        },
        cleanOffer: (state) => {
            state.offer.fetching = initialState.offer.fetching;
        },
        cleanInspection: (state) => {
            state.inspection.fetching = initialState.inspection.fetching;
        },
        getRecordsRequest: (state) => {
            state.records.fetching = "loading";
        },
        getRecordsSuccess: (state, action) => {
            state.records.fetching = "success";
            state.records.address = action.payload.address;
            state.records.date_start = action.payload.date_start;
            state.records.date_end = action.payload.date_end;
        },
        getRecordsFailure: (state, action) => {
            state.records.fetching = "failure";
            state.records.error = action.payload;
        },
        cleanGetRecords: (state) => {
            state.records.fetching = initialState.records.fetching;
        },
        getViewsRequest: (state) => {
            state.views.fetching = "loading";
        },
        getViewsSuccess: (state, action) => {
            state.views.fetching = "success";
            state.views.data = action.payload;
        },
        getViewsFailure: (state, action) => {
            state.views.fetching = "failure";
            state.views.error = action.payload;
        },
        cleanGetViews: (state) => {
            state.views.fetching = initialState.views.fetching;
        },
        getInterestedRequest: (state) => {
            state.interested.fetching = "loading";
        },
        getInterestedSuccess: (state, action) => {
            state.interested.fetching = "success";
            state.interested.data = action.payload;
        },
        getInterestedFailure: (state, action) => {
            state.interested.fetching = "failure";
            state.interested.error = action.payload;
        },
        cleanGetInterested: (state) => {
            state.interested.fetching = initialState.interested.fetching;
        },
        getCounterOffersRequest: (state) => {
            state.counterOffers.fetching = "loading";
        },
        getCounterOffersSuccess: (state, action) => {
            state.counterOffers.fetching = "success";
            state.counterOffers.data = action.payload;
        },
        getCounterOffersFailure: (state, action) => {
            state.counterOffers.fetching = "failure";
            state.counterOffers.error = action.payload;
        },
        cleanGetCounterOffers: (state) => {
            state.counterOffers.fetching = initialState.counterOffers.fetching;
        },
        postCounterOffersRequest: (state) => {
            state.counterOffers.post.fetching = "loading";
        },
        postCounterOffersSuccess: (state) => {
            state.counterOffers.post.fetching = "success";
        },
        postCounterOffersFailure: (state, action) => {
            state.counterOffers.post.fetching = "failure";
            state.counterOffers.post.error = action.payload;
        },
        cleanPostCounterOffers: (state) => {
            state.counterOffers.post.fetching = initialState.counterOffers.post.fetching;
        },
        putCounterOffersRequest: (state) => {
            state.counterOffers.put.fetching = "loading";
        },
        putCounterOffersSuccess: (state) => {
            state.counterOffers.put.fetching = "success";
        },
        putCounterOffersFailure: (state, action) => {
            state.counterOffers.put.fetching = "failure";
            state.counterOffers.put.error = action.payload;
        },
        cleanPutCounterOffers: (state) => {
            state.counterOffers.put.fetching = initialState.counterOffers.put.fetching;
        },
        pausePublicationRequest: (state) => {
            state.pause.fetching = "loading";
        },
        pausePublicationSuccess: (state, action) => {
            state.pause.fetching = "success";
            state.pause.paused = action.payload;
        },
        pausePublicationFailure: (state, action) => {
            state.pause.fetching = "failure";
            state.pause.error = action.payload;
        },
        cleanPausePublication: (state) => {
            state.pause.fetching = initialState.pause.fetching;
        },
        cleanAll: (state) => {
            state.fetching = initialState.fetching;
            state.data = initialState.data;
            state.articles = initialState.articles;
            state.update.fetching = initialState.update.fetching;
            state.update.data = initialState.update.data;
            state.update.newImages = initialState.update.newImages;
            state.update.deleteImages = initialState.update.deleteImages;
            state.offer.post = initialState.offer.post;
            state.offer.put = initialState.offer.put;
            state.inspection = initialState.inspection;
            state.history = initialState.history;
        },
        cleanImagesPublicationSaved: (state) => {
            state.update = {
                deleteImages: {
                    fetching: "unknown",
                    data: []
                },
                newImages: {
                    fetching: "unknown",
                    data: [],
                    count: 0,
                    reloaded: false,
                    reloadedName: ""
                },
                fetching: "unknown",
                data: [],
                error: {
                    code: "",
                    message: ""
                }
            }
        },
        setPublicationReducer: (state, action) => {
            state.data = action.payload;
        },
        cleanArticleAndPublication: (state) => {
            state.articles.data = initialState.articles.data;
            state.data = initialState.data;
        }
    }
});

export const { 
    setUploadImagesReducer,
    getPublicationRequest,
    getPublicationSuccess,
    getPublicationFailure,
    cleanPublication,
    getPublicationArticlesRequest,
    getPublicationArticlesSuccess,
    getPublicationArticlesFailure,
    cleanPublicationArticles,
    updatePublicationRequest,
    updatePublicationSuccess,
    updatePublicationFailure,
    postImagesPublicationRequest,
    postImagesPublicationSuccess,
    postImagesPublicationFailure,
    deleteImagesPublicationRequest,
    deleteImagesPublicationSuccess,
    deleteImagesPublicationFailure,
    cleanAll,
    cleanUpdate,
    postOfferRequest,
    postOfferSuccess,
    postOfferFailure,
    cleanOfferPost,
    getOfferAcceptedRequest,
    getOfferAcceptedSuccess,
    getOfferAcceptedFailure,
    cleanOfferAccepted,
    getOfferBuyRequest,
    getOfferBuySuccess,
    getOfferBuyFailure,
    cleanOffersBuy,
    getOffersPublicationRequest,
    getOffersPublicationSuccess,
    getOffersPublicationFailure,
    putOffersPublicationRequest,
    putOffersPublicationSuccess,
    putOffersPublicationFailure,
    cleanOfferPut,
    getInspectionRequest,
    getInspectionSuccess,
    getInspectionFailure,
    getInspectionFilesRequest,
    getInspectionFilesSuccess,
    getInspectionFilesFailure,
    cleanInspectionFiles,
    getLinzerAssignedRequest,
    getLinzerAssignedSuccess,
    getLinzerAssignedFailure,
    getHistoryRequest,
    getHistorySuccess,
    getHistoryFailure,
    cleanHistory,
    cleanOffer,
    cleanInspection,
    deletePublicationRequest,
    deletePublicationSuccess,
    deletePublicationFailure,
    cleanDeletePublication,
    getRecordsRequest,
    getRecordsSuccess,
    getRecordsFailure,
    cleanGetRecords,
    getViewsRequest,
    getViewsSuccess,
    getViewsFailure,
    cleanGetViews,
    getInterestedRequest,
    getInterestedSuccess,
    getInterestedFailure,
    cleanGetInterested,
    getCounterOffersRequest,
    getCounterOffersSuccess,
    getCounterOffersFailure,
    cleanGetCounterOffers,
    postCounterOffersRequest,
    postCounterOffersSuccess,
    postCounterOffersFailure,
    cleanPostCounterOffers,
    putCounterOffersRequest,
    putCounterOffersSuccess,
    putCounterOffersFailure,
    cleanPutCounterOffers,
    cleanImagesPublicationSaved,
    pausePublicationRequest,
    pausePublicationSuccess,
    pausePublicationFailure,
    cleanPausePublication,
    setPublicationReducer,
    cleanArticleAndPublication
} = publicationSlice.actions;

export default publicationSlice.reducer;