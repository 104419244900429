import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { CardContainerProps } from "../../interfaces/containers";
import Card from "../../components/Card";

function mapStateToProps(state: CardContainerProps) {
    return{
        user: state.auth.user
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Card);