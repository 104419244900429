import React from "react";
import { RouteObject } from "../../interfaces/navigation";

const EntryPoint = React.lazy(() => import("../../containers/LinzeAi/SearchVehicles/EntryPointContainer"));

export const linzeAiRoutes: RouteObject[] = [
    {
        title: "search_vehicles",
        path: "/linze_ai",
        component: <EntryPoint />
    }
];