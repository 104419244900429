import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { BuyProps } from "../../../interfaces/containers";
import Stepper from "../../../components/Stepper/Stepper";

function mapStateToProps(state: BuyProps) {
    return{
        step: state.buy.step,
        method: state.buy.method
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({

    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Stepper);