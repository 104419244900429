import styles from "./stepper.module.scss";
import { StepperProps } from "../../interfaces/components";

const Stepper = ({
    step,
    method
}: StepperProps) => {

    const stepStyle = {
        width: `${100 / (((method === "cash") ? 2 : 5) / step)}%`,
    }

    return(
        <div className={styles.stepperContainer}>
            <div className={styles.step} style={stepStyle}></div>
        </div>
    )
}

export default Stepper;