import { useEffect, useState } from "react";
import styles from "./records.module.scss";
import { useNavigate } from "react-router-dom";
import { optionsAccidentsReported, optionsPersonalUse, optionsSoleOwner } from "../../../constants/app";
import { addPointsInText, formatInput } from "../../../helpers/app";
import { RecordsPageProps } from "../../../interfaces/salePost";
import Button from "../../../components/Button";
import SellModal from "../../../components/SellModal/SellModal";
import SelectShared from "../../../components/Select/Select";
import TextFieldShared from "../../../components/TextField/TextField";

const Records = ({
    kms,
    setKms,
    plate,
    isFoundPlate,
    request_id,
    brands,
    models,
    year,
    version,
    getSuggestedPrice,
    setRecord,
    accidentsReported,
    personalUse,
    soleOwner
}: RecordsPageProps) => {
    const navigate = useNavigate();
    const [mileage, setMileage] = useState<any>({value: "0", error: ""});
    const [disabled, setDisabled] = useState<boolean>(true);
    const [accidentsReportedState, setAccidentsReported] = useState<any>({value: "", error: ""});
    const [personalUseState, setPersonalUse] = useState<any>({value: "", error: ""});
    const [soleOwnerState, setSoleOwner] = useState<any>({value: "", error: ""});

    const onChangeMileage = (value: any) => {
        const { formattedNumber } = formatInput(value);
        setMileage({...mileage, value: formattedNumber});
    }

    const onChangeAccidentsReported = (value: any) => {
        setAccidentsReported({...accidentsReportedState, value: value});
    }

    const onChangePersonalUse = (value: any) => {
        setPersonalUse({...personalUseState, value: value});
    }

    const onChangeSoleOwner = (value: any) => {
        setSoleOwner({...soleOwnerState, value: value});
    }

    const onPressBack = () => {
        navigate("/sell/car_detail");
    }

    const onPressNext = () => {
        setKms(mileage?.value.replaceAll(".", ""));
        setRecord({
            accidentsReported: accidentsReportedState?.value,
            personalUse: personalUseState?.value,
            soleOwner: soleOwnerState?.value
        });
        if(!request_id){
            getSuggestedPrice({
                brand_id: brands.selected?.id,
                model_id: models.selected?.id,
                version_id: version.id,
                km: mileage?.value.replace(/\./g, ''),
                year: year
            });
        }else{
            getSuggestedPrice({
                request_id,
                brand_id: brands.selected?.id,
                model_id: models.selected?.id,
                version_id: version.id,
                km: mileage?.value.replace(/\./g, ''),
                year: year
            });
        }
        navigate("/sell/description");
    };

    useEffect(() => {
        if(accidentsReportedState?.value !== null && personalUseState?.value !== null && soleOwnerState?.value !== null && mileage?.value){
            setDisabled(false);
        }else{
            setDisabled(true);
        }
    },[accidentsReportedState?.value, personalUseState?.value, soleOwnerState?.value, mileage?.value]);

    useEffect(() => {
        if(!plate) return navigate("/sell");
    }, [plate]);

    useEffect(() => {
        setMileage({...mileage, value: addPointsInText(kms)});
        setAccidentsReported({...accidentsReportedState, value: accidentsReported});
        setPersonalUse({...personalUseState, value: personalUse});
        setSoleOwner({...soleOwnerState, value: soleOwner});
    }, []);

    return (
        <SellModal 
            step={isFoundPlate ? 4 : 6} 
            title="Detalles"
            onClickBack={onPressBack}
            isFoundPlate={isFoundPlate}
        >
            <div className={styles.inputsContainer}>
                <TextFieldShared
                    variant="borderless"
                    name="mileage"
                    value={mileage?.value}
                    onChange={onChangeMileage}
                    className={styles.mileage}
                    fullWidth
                    icon="km"
                    direction="rtl"
                    autoFocus={true}
                />
                <SelectShared
                    label="Accidentes reportados"
                    name="accidentsReported"
                    value={accidentsReportedState?.value}
                    onChange={onChangeAccidentsReported}
                    options={optionsAccidentsReported}
                    className={styles.input}
                    keyValue="value"
                />
                <SelectShared
                    label="Uso del vehículo"
                    name="personalUse"
                    value={personalUseState?.value}
                    onChange={onChangePersonalUse}
                    options={optionsPersonalUse}
                    className={styles.input}
                    keyValue="value"
                />
                
                <SelectShared
                    label="Unico dueño"
                    name="soleOwner"
                    value={soleOwnerState?.value}
                    onChange={onChangeSoleOwner}
                    options={optionsSoleOwner}
                    className={styles.input}
                    keyValue="value"
                />
            </div>
            <Button 
                onPress={onPressNext}
                title="Continuar" 
                primary 
                disabled={disabled} 
                className={styles.submitButton}
            />
        </SellModal>
    )
};

export default Records;