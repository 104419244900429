import { useWindowSize } from 'use-hooks';
import styles from './informationLoader.module.scss';
import { Skeleton } from "@mui/material";

const InformationLoader = () => {
    const windowSize = useWindowSize();

    return (
        <>
            <Skeleton animation="wave" variant="text" className={`${styles.item} ${styles.description}`} />
            <Skeleton animation="wave" variant="text" className={`${styles.item} ${styles.title}`} />
            <Skeleton animation="wave" variant="text" className={`${styles.item} ${styles.version}`} />
            <Skeleton animation="wave" variant="text" className={`${styles.item} ${styles.price}`} />
            {
                windowSize.width > 1024 &&
                <>
                    <Skeleton animation="wave" variant="circular" className={`${styles.item} ${styles.share}`} />
                    <Skeleton animation="wave" variant="circular" className={`${styles.item} ${styles.favorite}`} />
                    <Skeleton animation="wave" variant="text" className={styles.button} />
                    <Skeleton animation="wave" variant="text" className={styles.button} />
                </>
            }
        </>
    )
}

export default InformationLoader;