import React from "react";
import { Helmet } from "react-helmet";

const GoogleTag = () => {
  return (
    <Helmet>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-WRPSZ8Y4S5"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-WRPSZ8Y4S5');
          `}
        </script>
    </Helmet>
  );
};

export default GoogleTag;
