import { forwardRef } from 'react';
import styles from "./toast.module.scss";
import { useSnackbar, SnackbarContent } from 'notistack';
import { IconButton } from '@mui/material';
import { ToastProps } from '../../interfaces/components';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';

const Toast = forwardRef<HTMLDivElement, ToastProps>((props, ref) => {
    const {id, content} = props;
    const { closeSnackbar } = useSnackbar();
    const contentSplit = content.split(/\r?\n|\r|\n/g);
    
    return (
        <SnackbarContent ref={ref} className={styles.toast}>
            <>
                <p className={styles.message}>
                    {
                        contentSplit[0] === "title" 
                        ?   <>
                                <span className={styles.title}>{contentSplit[1]}</span>
                                <span className={styles.message}>{contentSplit[2]}</span>
                            </>
                        :
                            <span className={styles.message}>{contentSplit[1]}</span>
                    }
                </p>
                <IconButton
                    size="small"
                    aria-label="close"
                    onClick={() => closeSnackbar(id)}
                >
                    <Icon path={mdiClose} className={styles.close} />
                </IconButton>
            </>
        </SnackbarContent>
    );
});

export default Toast;