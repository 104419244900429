import { images } from "../../constants/images";
import styles from "./menuQuestions.module.scss";
import { useNavigate } from "react-router-dom";
import Icon from '@mdi/react';
import { mdiWallet, mdiTag, mdiTextBoxMultiple } from '@mdi/js';

const MenuQuestions = ({section = 1}: any) => {
    const navigate = useNavigate();

    const changeQuestion = (page: string) => {
        navigate(page);
    }

    return (
        <div className={styles.menuContainer}>
            <div className={`${styles.option} ${section !== 1 && styles.disabled}`} onClick={() => changeQuestion('/frequent_questions/buy')}>
                <Icon path={mdiWallet} className={styles.icon} title="Buy" />
                <p>
                    <span className={styles.title}>¿Cómo comprar?</span>
                    <span className={styles.description}>
                        Te explicamos el paso a paso para realizar una compra exitosa
                    </span>
                </p>
            </div>
            <div className={`${styles.option} ${section !== 2 && styles.disabled}`} onClick={() => changeQuestion('/frequent_questions/sell')}>
                <Icon path={mdiTag} className={styles.icon} title="Sell" />
                <p>
                    <span className={styles.title}>¿Cómo vender?</span>
                    <span className={styles.description}>
                        Aquí encontrarás una guía de ayuda para que puedas vender tu auto en simples pasos
                    </span>
                </p>
            </div>
            <div className={`${styles.option} ${section !== 3 && styles.disabled}`} onClick={() => changeQuestion('/frequent_questions/financing')}>
                <Icon path={mdiTextBoxMultiple} className={styles.icon} title="Financing" />
                <p>
                    <span className={styles.title}>Financiamiento</span>
                    <span className={styles.description}>
                        Conoce nuestro proceso de financiamiento y los beneficios de Linze
                    </span>
                </p>
            </div>
        </div>
    )
}
export default MenuQuestions;