import { createSlice } from "@reduxjs/toolkit";
import { initialStateProps } from '../interfaces/transaction';

const initialState : initialStateProps = {
    transactionConfirmation: {
        fetching: "unknown",
        error: ""
    }
}

const transactionSlice = createSlice({
    name: "transaction",
    initialState,
    reducers: {
        postTransactionConfirmationRequest: (state) => {
            state.transactionConfirmation.fetching = "loading";
        },
        postTransactionConfirmationSuccess: (state) => {
            state.transactionConfirmation.fetching = "success";
        },
        postTransactionConfirmationFailure: (state, action) => {
            state.transactionConfirmation.fetching = "failure";
            state.transactionConfirmation.error = action.payload;
        },
        cleanTransaction: (state) => {
            state.transactionConfirmation = initialState.transactionConfirmation;
        },
    }
})

export const {
    postTransactionConfirmationRequest,
    postTransactionConfirmationSuccess,
    postTransactionConfirmationFailure,
    cleanTransaction
} =
    transactionSlice.actions;

export default transactionSlice.reducer;