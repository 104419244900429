import { useEffect, useState } from "react";
import styles from "./fileUploader.module.scss";
import { CircularProgress, IconButton } from "@mui/material";
import Icon from '@mdi/react';
import { mdiPlus, mdiClose, mdiAlertCircleOutline, mdiTrashCanOutline, mdiReload } from '@mdi/js';
import { FileUploaderProps } from '../../interfaces/components';
import { useToast } from "../../helpers/hooks";

const FileUploader = ({
    values = [],
    onChange = () => { },
    className,
    loadingStates = true,
    enumeration = true,
    draggable = true,
    scroll = null,
    read = false,
    showInvalids = false,
    bigPictures = false,
    selectedPicture,
    setSelectedPicture,
    onDeleteItem,
    onAddItem,
    onMoveItem,
    reloadedName,
    onPressReload
}: FileUploaderProps) => {
    const toast = useToast();
    const [files, setFiles] = useState<any>([]);
    const [oneFileToDelete, setOneFileToDelete] = useState<boolean>(false);
    const [dragFile, setDragFile] = useState<any>(null);

    const _onPressReload = (e: any, item: any) => {
        e.stopPropagation();
        onPressReload && onPressReload(item);
    };

    const handleSelectFile = async (event: any) => {
        let filesSelected: any = [];
        await Array.from(event.target.files).map((file: any, index: number) => {
            if(file?.type !== "image/jpeg" && file?.type !== "image/jpg" && file?.type !== "image/png" && file?.type !== "image/webp"){
                toast("Archivo con formato invalido");
                return;
            }
            if (file?.size === 0){
                toast("Archivo con formato invalido");
                return;
            }
            const fileSelected = {
                file: file,
                id: Math.floor((index + Math.random()) * 0x10000).toString(16),
                previewURL: URL.createObjectURL(file),
                name: `${Math.floor((index + Math.random()) * 0x10000).toString(16)}.jpg`,
                upload: loadingStates ? "loading" : "success"
            }
            filesSelected = filesSelected.concat(fileSelected);
        });
        setFiles(files.concat(filesSelected));

        if (onChange) {
            onChange(files.concat(filesSelected));
        }
        onAddItem && onAddItem(event);
    }

    const handleClickDeleteFile = (e: any, file: any) => {
        e.stopPropagation();
        const filesFiltered = files && files.filter((item: any) => item !== file);
        if (!file?.previewURL) {
            file = { ...file, toDelete: true };
            filesFiltered.push(file);
            setOneFileToDelete(true);
        }
        setFiles(filesFiltered);

        if (onChange) {
            onChange(filesFiltered);
        }
        onDeleteItem && onDeleteItem(file);
    }

    const handleDragOver = (e: any, file: any) => {
        if (!draggable || file?.upload === "loading") return;
        e.preventDefault();
    }

    const handleDragStart = (file: any) => {
        if (!draggable || file?.upload === "loading") return;
        setDragFile(file);
    }

    const handleDrop = (e: any, dropFile: any) => {
        if (!draggable || dropFile?.upload === "loading") return;
        e.preventDefault();
        const fileFrom = files.indexOf(dragFile);
        const fileTo = files.indexOf(dropFile);
        const filesMoved = moveItem(fileFrom, fileTo);
        setFiles(filesMoved);
        if (onChange) {
            onChange(filesMoved);
        }
        onMoveItem && onMoveItem(filesMoved);
    }

    const moveItem = (from: any, to: any) => {
        let filesAux: any = [...files];
        let temp = filesAux[from];
        filesAux[from] = filesAux[to];
        filesAux[to] = temp;
        return filesAux;
    }

    useEffect(() => {
        if (values?.some((file: any) => file?.toDelete)) {
            setOneFileToDelete(true);
        } else {
            setOneFileToDelete(false);
        }
        setFiles(values);
    }, [values]);
    
    return (
        <>
            {
                !oneFileToDelete && files && files.length === 0
                    ?
                    <div className={`${styles.fileUploader} ${styles.empty} ${scroll && styles.scroll} ${className}`}>
                        <div className={styles.selector}>
                            <Icon path={mdiPlus} title="Agregar" className={styles.icon} />
                            <p>Añade o arrastra aquí un archivo</p>
                            <input type="file" className={styles.fileSelector} name="imageProfile" multiple accept="image/*" onChange={handleSelectFile} />
                        </div>
                    </div>
                    :
                    <div className={`${styles.fileUploader} ${scroll && styles.scroll} ${className} ${selectedPicture && styles.fullWidth}`}>
                        <div className={styles.content}>
                            <div className={`${styles.fileContainer} ${bigPictures && styles.bigFileContainer}`}>
                                <div className={`${styles.item} ${bigPictures && styles.bigSelector} ${styles.selector}`}>
                                    <Icon path={mdiPlus} title="Agregar" className={styles.icon} />
                                    <p>Añade o arrastra aquí un archivo</p>
                                    <input type="file" className={styles.fileSelector} name="imageProfile" multiple accept="image/*" onChange={handleSelectFile} />
                                </div>
                                {
                                    files && files.map((file: any, index: number) => (
                                        !file?.toDelete ?
                                            <>
                                                <div
                                                    key={index}
                                                    className={`${styles.item} ${bigPictures && styles.bigPictures} ${file?.upload === "failure" && styles.failure} ${file?.upload === "loading" && styles.isLoading}`}
                                                    id={file?.id}
                                                    onClick={() => file?.upload !== "loading" && setSelectedPicture({ ...file, index })}
                                                    draggable={draggable || file?.upload !== "loading"}
                                                    onDragOver={(e) => file?.upload === "loading" ? {} : handleDragOver(e, file)}
                                                    onDragStart={() => file?.upload === "loading" ? {} : handleDragStart(file)}
                                                    onDrop={(e) => file?.upload === "loading" ? {} : handleDrop(e, file)}
                                                >
                                                    {
                                                        file?.upload === "loading" &&
                                                        <CircularProgress
                                                            size={styles.loader}
                                                            classes={{
                                                                colorPrimary: styles.loader
                                                            }}
                                                        />
                                                    }
                                                    {
                                                        file?.upload === "failure" &&
                                                        <div className={styles.error}>
                                                            <Icon path={mdiAlertCircleOutline} title="Error" className={styles.icon} />
                                                            <p>Error al subir la imagen</p>
                                                        </div>
                                                    }
                                                    {
                                                        enumeration && <p className={styles.index}>{index + 1}</p>
                                                    }
                                                    {
                                                        (file?.upload !== "loading") &&
                                                        <IconButton aria-label='Delete' className={styles.delete} onClick={(e) => handleClickDeleteFile(e, file)}>
                                                            <Icon path={mdiTrashCanOutline} title="Eliminar" className={styles.icon} />
                                                        </IconButton>
                                                    }
                                                    {(showInvalids && file?.valid === false) &&
                                                        <div className={styles.invalidPicture}>
                                                            <div className={styles.invalidBackground}>
                                                                <Icon path={mdiAlertCircleOutline} title="AlertCircle" color={"red"} className={styles.closeIcon} />
                                                                <p>La foto no cumple con los criterios establecidos.</p>
                                                            </div>
                                                        </div>
                                                    }
                                                    {(showInvalids && file?.error === true) &&
                                                        <div className={styles.invalidPicture}>
                                                            <div className={styles.invalidBackground}>
                                                                <Icon path={mdiClose} title="AlertCircle" color={"red"} className={styles.closeIcon} />
                                                                <p>Error al subir esta foto.</p>
                                                                {reloadedName === file.name ?
                                                                    <CircularProgress
                                                                        size={"18px"}
                                                                        color="primary"
                                                                        style={{ color: "black", marginRight: "3px" }}
                                                                        classes={{
                                                                            colorPrimary: "black"
                                                                        }}
                                                                    />
                                                                    :
                                                                    <IconButton aria-label='Reload' style={{margin: 0, padding: 0}} onClick={(e) => _onPressReload(e, file)}>
                                                                        <Icon path={mdiReload} title="Reload" color={"black"} className={styles.closeIcon} />
                                                                    </IconButton>
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                    <img src={file.uri ?? file.url ?? file.previewURL ?? file.path ?? file} className={`${styles.image} ${(showInvalids && file?.valid === false) && styles.blur}`} alt="File" />
                                                </div>
                                            </>
                                            :
                                            null
                                    ))
                                }
                            </div>
                        </div>
                    </div>
            }
        </>
    )
};

export default FileUploader;