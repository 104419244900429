import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import Pause from "../../../../../pages/Profile/MyPublications/Management/Pause";
import { MyPublicationManagementContainerProps } from "../../../../../interfaces/containers";
import { getPublication, pausePublication, cleanPausePublication } from "../../../../../actions/publication";

function mapStateToProps(state: MyPublicationManagementContainerProps) {
    return{
        pause: state.publication.pause
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        getPublication,
        pausePublication,
        cleanPausePublication
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Pause);