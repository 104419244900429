import { Skeleton } from '@mui/material';
import styles from "./buttonsLoader.module.scss";
import { ButtonsLoaderProps } from '../../../../../../interfaces/components';

const ButtonsLoader = ({
    lastSection
}: ButtonsLoaderProps) => {
    return (
        <>
            {
                lastSection !== "final-counteroffer" &&
                <Skeleton animation="wave" variant="text" className={`${styles.button} ${styles.large} ${styles.text}`} />
            }
            <Skeleton animation="wave" variant="rounded" className={styles.button} />
            <Skeleton animation="wave" variant="rounded" className={styles.button} />
        </>
    );
}

export default ButtonsLoader;