import { useEffect} from "react";
import styles from "./delete.module.scss";
import Modal from "../../../../components/Modal/Modal";
import ButtonShared from "../../../../components/Button/Button";
import { useToast } from "../../../../helpers/hooks";
import { MyPublicationDeleteProps } from "../../../../interfaces/pages";
import { useNavigate } from "react-router-dom";

const Delete = ({
    deleteFetching,
    deletePublication,
    cleanDeletePublication,
    getMyPublicationsRequest,
    open,
    setOpen,
    item
}: MyPublicationDeleteProps) => {
    const toast = useToast();
    const navigate = useNavigate();
    const handlePressDelete = () => {
        if(item?.in_transaction || item?.reserved){
            return toast("No puedes eliminar una publicación en transacciôn");
        }
        deletePublication(item?._id);
    }

    useEffect(() => {
        if(deleteFetching === "failure"){
            cleanDeletePublication();
            toast("La publicación no se puede eliminar ya que se encuentra en una transacción");
        }
        if(deleteFetching === "success"){
            cleanDeletePublication();
            setOpen(false);
            toast("La publicación ha sido eliminada exitosamente");
            getMyPublicationsRequest();
            navigate("/profile/my_publications");
        }
    }, [deleteFetching]);

    return (
        <Modal open={open} variant="white" className={styles.modalContainer}>
            <p>
                <span className={styles.message}>Estás a punto de ELIMINAR la publicación del vehículo</span>
                <span className={styles.name}>{item?.title}</span>
            </p>
            <div className={styles.buttons}>
                <ButtonShared 
                    onPress={() => setOpen(false)}
                    title="Regresar" 
                    tertiary
                    size="small"
                />
                <ButtonShared 
                    onPress={() => handlePressDelete()}
                    title="Eliminar" 
                    primary
                    size="small"
                    loading={deleteFetching === "loading"}
                />
            </div>
        </Modal>
    )
}

export default Delete;