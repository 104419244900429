import { RouteObject } from "../../interfaces/navigation";
import React from "react";
import MyAccount from "../../containers/Profile/MyAccount/EntryPointContainer";
import Favorites from "../../containers/Profile/Favorites/EntryPointContainer";
import { myBuysRoutes } from "./MyBuys/routes";
import { myPublicationsRoutes } from "./MyPublications/routes";
import { documentationRoutes } from "./Documentation/routes";
import { myOffersRoutes } from "./MyOffers/routes";
import Settings from "../../containers/Profile/Settings/EntryPointContainer";

const ProfileContainer = React.lazy(() => import("../../containers/Profile/ProfileContainer"));

export const profileSubRoutes: RouteObject[] = [
    {
        title: "Mi Cuenta",
        path: "my_account",
        component: <MyAccount/>,
        protected: true
    },
    ...myPublicationsRoutes,
    ...myBuysRoutes,
    {
        title: "Favoritos",
        path: "favorites",
        component: <Favorites/>,
        protected: true
    },
    ...myOffersRoutes,
    ...documentationRoutes,
    {
        title: "Ajustes",
        path: "settings",
        component: <Settings/>,
        protected: true
    },
];

export const profileRoutes: RouteObject[] = [
    {
        title: "Profile",
        path: "/profile",
        component: <ProfileContainer/>,
        protected: true,
        subRoutes: profileSubRoutes
    }
];