import { useEffect, useState } from 'react';
import styles from "./confort.module.scss";
import { Button, Skeleton } from '@mui/material';
import { ConfortProps } from "../../interfaces/components";
import { CONFORT_TYPES } from "../../constants/Publication";

const Confort = ({
    onPress = () => {}, 
    item,
    editable = false,
    selected = false,
    pressable = false,
    loading = false
}: ConfortProps) => {
    const [confortItem, setConfortItem] = useState<any>();
    
    const handleChangeSelected = () => {
        setConfortItem({...confortItem, selected: !selected})
        if (onPress) {
            onPress({
                codeName: confortItem.codeName,
                selected: !selected
            });
        }
    }

    useEffect(() => {
        if(item){
            setConfortItem(CONFORT_TYPES.find((conf: any) => conf.codeName === item[0]));
        }
    }, [item]);

    return (
        <>
        {
            pressable ?
                <div className={styles.confortContainer} onClick={() => onPress(confortItem?.codeName)}>
                    <Button variant="contained" className={`${styles.icon} ${(selected) && styles.selected}`} disableElevation>
                        <img src={confortItem?.image} alt={confortItem?.name} />
                    </Button>
                    <p className={styles.text}>{confortItem?.name}</p>
                </div>
            :
            loading
            ? <div className={styles.skeletonContent}>
                <Skeleton animation="wave" variant="rounded" className={`${styles.image} ${styles.skeleton}`} />
                <Skeleton animation="wave" variant="text" className={`${styles.text} ${styles.skeleton}`} />
            </div>
            : editable
                ?
                    <div className={`${styles.confortContainer} ${styles.hover}`} onClick={handleChangeSelected}>
                        <Button variant="contained" className={`${styles.icon} ${(confortItem?.selected || selected) && styles.selected}`} disableElevation>
                            <img src={confortItem?.image} alt={confortItem?.name} />
                        </Button>
                        <p className={styles.text}>{confortItem?.name}</p>
                    </div>
                :
                    (item[1]) &&
                    <div className={styles.confortContainer}>
                        <div className={`${styles.icon} ${item[1] && styles.selected}`}>
                            <img src={confortItem?.image ?? confortItem?.icon} alt={confortItem?.name ?? confortItem?.label} />
                        </div>
                        <p className={styles.text}>{confortItem?.name ?? confortItem?.label}</p>
                    </div>
        }
        </>
    )
}
export default Confort;