import React from "react";
import { RouteObject } from "../../interfaces/navigation";

const EntryPoint = React.lazy(() => import("../../containers/NotificationsPage/NotificationsContainer"));

export const notificationsRoutes: RouteObject[] = [
    {
        title: "notifications",
        path: "/notifications",
        component: <EntryPoint />,
        onlyMobile: true
    }
];