import { useEffect, useState } from "react";
import styles from "./payments.module.scss";
/* import { PaymentsMyBuysManagementProps } from "../../../../../interfaces/components"; */
import moment from "moment";
import { addPointsInText, capitalize } from "../../../../../helpers/app";
import { translations } from "../../../../../constants/app";
import TextFieldShared from "../../../../../components/TextField/TextField";
import { images } from "../../../../../constants/images";
import ButtonShared from "../../../../../components/Button/Button";
import { Divider, IconButton } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useToast } from "../../../../../helpers/hooks";
import { useLocation } from "react-router-dom";
import LoaderCreditData from "./LoaderCreditData";
import LoaderPaymentsPie from "./LoaderPaymentsPie";
import Icon from '@mdi/react';
import { mdiTrashCanOutline } from '@mdi/js';

const Payments = ({
    myBuy,
    publication,
    payments,
    cleanGetCreditData,
    cleanGetPayments,
    postPaymentDocument,
    cleanPostPaymentDocument,
    postPayment,
    cleanPostPayment
}: any) => {
    const toast = useToast();
    const location = useLocation();
    const [paymentsSection, setPaymentsSection] = useState<string>("detail");
    const [valeVistaCode, setValeVistaCode] = useState<any>({ value: "", error: "" });
    const [fileSelected, setFileSelected] = useState<any>(null);

    const onSelectFile = (event: any) => {
        const file = event.target.files[0];
        const newFile = {
            id: Math.floor(Math.random() * 0x10000000000).toString(16),
            name: `${Math.floor(Math.random() * 0x10000000000).toString(16)}.jpg`,
            path: URL.createObjectURL(file),
            file: file,
        }
        setFileSelected(newFile);
    }

    const onSavePayments = () => {
        const formData = new FormData();
        formData.append("file", fileSelected?.file);
        postPaymentDocument(formData);
    }

    useEffect(() => {
        if (payments?.payment?.fetching === "failure") {
            toast("Ha ocurrido un error al actualizar el pago");
            cleanPostPayment();
        }
        if (payments?.payment?.fetching === "success") {
            toast("Pago actualizado correctamente");
            setPaymentsSection("detail");
            cleanPostPayment();
        }
    }, [payments?.payment?.fetching]);

    useEffect(() => {
        if (payments?.document?.fetching === "failure") {
            toast("Ha ocurrido un error al guardar el Vale vista");
            cleanPostPaymentDocument();
        }
        if (payments?.document?.fetching === "success") {
            toast("Vale vista actualizado correctamente");
            const paymentFound = payments?.data && payments?.data.find((item: any) => item.reason === 'payment');
            postPayment(payments?.document?.data, paymentFound?._id);
            cleanPostPaymentDocument();
        }
    }, [payments?.document?.fetching]);

    useEffect(() => {
        if (payments?.creditData?.fetching === "failure") {
            toast("Ha ocurrido un error al mostrar los datos del credito");
            cleanGetCreditData();
        }
        if (payments?.creditData?.fetching === "success") {
            cleanGetCreditData();
        }
    }, [payments?.creditData?.fetching]);

    useEffect(() => {
        if (payments?.fetching === "failure") {
            toast("Ha ocurrido un error al mostrar los pagos");
            cleanGetPayments();
        }
        if (payments?.fetching === "success") {
            cleanGetPayments();
        }
    }, [payments.fetching]);
    return <>
        {
            (payments?.fetching === "loading" || payments?.creditData?.fetching === "loading")
                ?
                <div className={styles.payments}>
                    <LoaderPaymentsPie />
                    <LoaderCreditData />
                </div>

                :
                ((payments?.data && payments?.data.length > 0) || (payments?.creditData?.data && payments?.creditData?.data?.length > 0))
                    ?
                    <div className={`${styles.payments} ${paymentsSection === "valeVista" && styles.valeVista}`}>
                        {

                            paymentsSection === "detail"
                                ?
                                <>
                                    <p className={styles.subTitle}>Pie</p>
                                    {
                                        payments?.data && payments?.data.map((payment: any) => (
                                            <>
                                                <div className={styles.pie}>
                                                    <p>
                                                        <span className={styles.value}>${addPointsInText(payment?.total_amount)}</span>
                                                        <span className={styles.date}>{moment(payment?.created_at).format("DD/MM/YYYY")}</span>
                                                    </p>
                                                    <p
                                                        className={`${styles.state} ${payment?.status === "pending" ? styles.pending : styles.chip} ${payment?.status === "pending" ? styles.blue : styles.gray}`}
                                                        onClick={() => payment?.status === "pending" ? setPaymentsSection("valeVista") : {}}
                                                    >
                                                        {translations(payment?.status)}
                                                    </p>
                                                </div>
                                                <Divider />
                                            </>
                                        ))
                                    }
                                    <>
                                        <p className={styles.subTitle}>Resumen de Crédito</p>
                                        <p className={styles.cardRow}>
                                            <span className={styles.title}>Estado</span>
                                            <span className={`${styles.value} ${styles.blue}`}>{translations(payments?.creditData?.data[0]?.status ?? (myBuy?.data && myBuy?.data?.financing?.status_credit) ?? "")}</span>
                                        </p>
                                        <Divider />
                                        <p className={styles.cardRow}>
                                            <span className={styles.title}>Monto</span>
                                            <span className={styles.value}>${addPointsInText(payments?.creditData?.data[0]?.credit_request_response?.financing?.total_finance)}</span>
                                        </p>
                                        <Divider />
                                        <p className={styles.cardRow}>
                                            <span className={styles.title}>Plazo</span>
                                            <span className={styles.value}>{(payments?.creditData?.data[0]?.credit_request_response && payments?.creditData?.data[0]?.credit_request_response?.financing?.installment_value) ?? "0"} meses</span>
                                        </p>
                                        <Divider />
                                        <p className={styles.cardRow}>
                                            <span className={styles.title}>Valor cuota</span>
                                            <span className={styles.value}>${addPointsInText(payments?.creditData?.data[0]?.credit_request_response && payments?.creditData?.data[0]?.credit_request_response?.financing?.quota)}</span>
                                        </p>
                                        <Divider />
                                        <p className={styles.cardRow}>
                                            <span className={styles.title}>Tipo de crédito</span>
                                            <span className={`${styles.value}`}>{translations((payments?.creditData?.data[0] && payments?.creditData?.data[0]?.credit?.credit_type === "I" ? "intelligent" : "conventional") ?? "")}</span>
                                        </p>
                                        <Divider />
                                        <p className={styles.cardRow}>
                                            <span className={styles.title}>CAE</span>
                                            <span className={styles.value}>{(payments?.creditData?.data[0]?.credit_request_response && payments?.creditData?.data[0]?.credit_request_response?.financing?.cae) ?? "0"}%</span>
                                        </p>
                                    </>
                                </>
                                :
                                <>
                                    <p className={styles.subTitle}>
                                        <span>Vale Vista</span>
                                        <IconButton id="arrowLeft" className={styles.arrow} onClick={() => setPaymentsSection("detail")}>
                                            <ArrowBackIosNewIcon className={styles.icon} />
                                        </IconButton>
                                    </p>
                                    <p className={styles.description}>Necesitamos que a continuación ingreses el código de Vale Vista y adjuntes una foto del mismo.</p>
                                    <TextFieldShared
                                        label="Código de Vale Vista"
                                        name="valeVistaCode"
                                        value={valeVistaCode?.value}
                                        className={styles.input}
                                        onChange={(value: any) => setValeVistaCode({ ...valeVistaCode, value: value })}
                                        error={valeVistaCode?.error}
                                    />
                                    {
                                        !fileSelected
                                            ?
                                            <div className={styles.noFileSelected}>
                                                <input type="file" className={styles.fileSelector} name="fileSelector" accept="image/*" onChange={onSelectFile} />
                                                <img src={images.noFiles} alt="No hay archivo" className={styles.noFileImg} />
                                                <p>Adjunta tu vale vista</p>
                                            </div>
                                            :
                                            <div className={styles.fileSelected}>
                                                <IconButton aria-label='Delete' className={styles.delete} onClick={() => setFileSelected(null)}>
                                                    <Icon path={mdiTrashCanOutline} title="Eliminar" className={styles.icon} />
                                                </IconButton>
                                                <img src={fileSelected?.path} alt="Imagen Vale Vista" className={styles.fileImg} />
                                            </div>
                                    }
                                    <ButtonShared
                                        onPress={onSavePayments}
                                        title="Guardar"
                                        primary
                                        size="small"
                                        className={styles.savePayments}
                                        loading={payments?.document?.fetching === "loading"}
                                        disabled={valeVistaCode?.value === "" || !fileSelected}
                                    />
                                </>
                        }
                    </div>
                    :
                    <div className={styles.textNoData}>Pagos no inicializados</div>
        }
    </>
};

export default Payments;