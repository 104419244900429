import { CircularProgress, TextField } from "@mui/material";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import ButtonShared from "../../../components/Button/Button";
import Modal from "../../../components/Modal";
import SellModal from "../../../components/SellModal/SellModal";
import { images } from "../../../constants/images";
import { useToast } from "../../../helpers/hooks";
import { SellSearchProps } from "../../../interfaces/salePost";
import styles from "./search.module.scss";
import { capitalize } from "../../../helpers/app";

const Search = ({
    user,
    initialFlow,
    getPlateInfo,
    plate,
    fetchingPlateRepeated,
    plateRepeatedStatus,
    cleanPlateRepeated,
    getIsPlateRepeated,
    fetchingPlateInfo,
    brand, 
    year,
    clearSalePost,
    setPlate,
    documents,
    reSendValidation,
    reSendValidationFetching,
    cleanReSendValidation,
    isFoundPlate
}: SellSearchProps) => {
    const toast = useToast();
    const navigate = useNavigate();
    const text1Ref = useRef(null);
    const text2Ref = useRef(null);
    const text3Ref = useRef(null);
    const EIGHT_YEARS_AGO = moment().subtract(8, "years").year();
    const [platePart1, setPlatePart1] = useState("");
    const [platePart2, setPlatePart2] = useState("");
    const [platePart3, setPlatePart3] = useState("");
    const [status, setStatus] = useState<any>("");
    const [openModalValidationMail, setOpenModalValidationMail] = useState<boolean>(false);
    const [openModalPlateSearch, setOpenModalPlateSearch] = useState<boolean>(false);
    const [timeOutMessage, setTimeOutMessage] = useState<any>({open: false, text: ""});

    const onPressModalPlate = () => {
        cleanPlateRepeated();
        if(status === "success"){
            navigate(`/sell/car_search_information`);
        }else{
            navigate(`/sell/location`);
        }
    }

    const onkeydown = (key: string, ref: any) => {
        if(ref?.current?.value.length === 0){
            if(ref?.current?.name === "platePart1" && key.toLowerCase() === "arrowright"){
               nextRefFocus(0);
            } 
            if(ref?.current?.name === "platePart2"){
                if(key.toLowerCase() === "arrowright"){
                    nextRefFocus(1);
                }
                if(key.toLowerCase() === "backspace" || key.toLowerCase() === "arrowleft"){
                    backRefFocus(0);
                }
            }
            if(ref?.current?.name === "platePart3" && (key.toLowerCase() === "backspace" || key.toLowerCase() === "arrowleft")){
                backRefFocus(2);
            }
        }else{
            if(ref?.current?.name === "platePart3" && key.toLowerCase() === "enter") onPressNext();
        }
    }

    const changeRef = (text: string, type?: undefined | number) => {
        if (text.length === 2 && typeof type !== 'undefined') {
            nextRefFocus(type);
        }
        if (text.length === 0 && type) {
            backRefFocus(type);
        }
    }

    const handlePressValidationMail = () => {
        reSendValidation();
    }

    const nextRefFocus = (key: number) => {
        const refs: any = [
            text2Ref,
            text3Ref
        ]
        if (key === 2) {
            return;
        }
        refs[key].current.focus();
    };

    const backRefFocus = (key: number) => {
        const refs: any = [
            text1Ref,
            text1Ref,
            text2Ref,
        ]
        refs[key].current.focus();
    };

    const onPressNext = () => {
        initialFlow();
        /* if(!documentationApproved){
            return toast("Debes completar tu documentación para publicar un vehículo");
        } */
        /* if(user && !user?.email_verification?.verified){
            return setOpenModalValidationMail(true);
        } */
        if(!isPlateComplete){
            toast("ingresa una patente valida");
            return;
        }
        const currentPlate = `${platePart1}${platePart2}${platePart3}`;
        getIsPlateRepeated(currentPlate.replaceAll(".",""));
        setStatus("loading");
        setOpenModalPlateSearch(true);
        setTimeout(() => setTimeOutMessage({open: true, text: "Espera un momento"}), 5000);
        setTimeout(() => setTimeOutMessage({open: true, text: "Espera un momento..."}), 15000);
    };

    const isPlateComplete = useMemo(() => {
        if(!platePart1 || !platePart2 || !platePart3 || platePart3.length < 2){
            return false;
        }
        return true;
    },[platePart1, platePart2, platePart3]);

    useEffect(() => {
        if(reSendValidationFetching === "failure"){
            toast("Ha ocurrido un error al enviar el email de verificación");
            setOpenModalValidationMail(false);
            cleanReSendValidation();
        }
        if(reSendValidationFetching === "success"){
            toast("Se te ha enviado un email de verificación");
            setOpenModalValidationMail(false);
            cleanReSendValidation();
        }
    }, [reSendValidationFetching]);

    useEffect(() => {
        if(plateRepeatedStatus === "isNotRepeated" && fetchingPlateRepeated === "success"){
            const currentPlate = `${platePart1}${platePart2}${platePart3}`;
            if(currentPlate.replaceAll(".","") === ""){
                return toast("Debes ingresar una patente");
            }
            getPlateInfo(currentPlate.replaceAll(".",""));
        }
        if(plateRepeatedStatus === "isRepeated" && fetchingPlateRepeated === "failure"){
            toast("Esta patente ya se encuentra publicada, por favor intente con otra");
            setOpenModalPlateSearch(false);
            setStatus("");
            clearSalePost();
        }
    },[plateRepeatedStatus, fetchingPlateRepeated]);

    useEffect(() => {
        if(fetchingPlateInfo === "failure"){
            setStatus("failure");
            const currentPlate = `${platePart1}${platePart2}${platePart3}`;
            setPlate(currentPlate.replaceAll(".",""));
        }
        if(fetchingPlateInfo === "success"){
            if(year?.label < EIGHT_YEARS_AGO){
                setOpenModalPlateSearch(false);
                setStatus("");
                toast(`Ups! No se puede publicar un auto con antigüedad mayor a ${EIGHT_YEARS_AGO}`);
                clearSalePost();
                return;
            }
            setStatus("success");
        }
    },[fetchingPlateInfo]);

    useEffect(() => {
        if(plate && plate.length > 0){
            setPlatePart1("");
            setPlatePart2("");
            setPlatePart3("");
        }
    }, []);

    return (
        <SellModal 
            step={0}
            title="Ingresa patente"
            subTitle="Al ingresar manualmente tu patente podemos autocompletar la información de tu vehículo y así agilizar el proceso de venta"
            isFoundPlate={isFoundPlate}
        >
            <Modal open={openModalPlateSearch} variant='white'>
                <div className={styles.modalPlateSearch}>
                    {
                        status === "loading" &&
                        <div className={styles.loaderPlateSearch}>
                            <CircularProgress 
                                size={styles.loader}
                                classes={{
                                    colorPrimary: styles.loader
                                }}
                            />
                            <p>
                                <span>Buscando información de tu vehiculo</span>
                                <span className={`${styles.timeOut} ${timeOutMessage?.open && styles.show}`}>{timeOutMessage?.text}</span>
                            </p>
                        </div>
                    }
                    {
                        status === "failure" &&
                        <div className={styles.plateFailure}>
                            <img src={images.isoLogoTipo} alt="linze" className={styles.image} />
                            <p className={styles.title}>No encontramos información de tu vehiculo</p>
                            <p className={styles.subTitle}>A continuación podrás ingresarla manualmente</p>
                            <Button 
                                onPress={onPressModalPlate}
                                title="Continuar" 
                                primary
                                className={`${styles.button}`}
                            />
                        </div>
                    }
                    {
                        status === "success" &&
                        <div className={styles.plateSuccess}>
                            <img src={images.linzeRoundIsoLogo} alt="linze" className={styles.image} />
                            <p className={styles.subTitle}>Encontramos información de tu</p>
                            <p className={styles.carName}>{capitalize(brand?.label)}</p>
                            <Button 
                                onPress={onPressModalPlate}
                                title="Continuar" 
                                primary
                                className={styles.button}
                            />
                        </div>
                    }
                </div>
            </Modal>
            <Modal open={openModalValidationMail}>
                <div className={styles.modalContainerValidationMail}>
                    <p className={styles.message}>
                        <span className={styles.title}>¡Debes validar tu email!</span>
                        <span className={styles.description}>Para realizar esta acción es necesario que tu cuenta esté validada</span>
                    </p>
                    <ButtonShared 
                        onPress={handlePressValidationMail}
                        title="Enviar email" 
                        secondary
                        loading={reSendValidationFetching === "loading"}
                    />
                    <ButtonShared 
                        onPress={() => setOpenModalValidationMail(false)}
                        title="Volver" 
                        className={styles.buttonBack}
                    />
                </div>
            </Modal>
            <div className={styles.inputsContainer}>
                <TextField
                    name='platePart1'
                    id="platePart1"
                    value={platePart1}
                    inputRef={text1Ref}
                    disabled={false}
                    variant="standard"
                    autoComplete="off"
                    placeholder="GK"
                    className={styles.textFieldInput}
                    onChange={(e) => [setPlatePart1(e.target.value.toUpperCase()), changeRef(e.target.value, 0)]}
                    InputProps={{ disableUnderline: true }}
                    inputProps={{ maxLength: 2 }}
                    onKeyDown={(ev: any) => onkeydown(ev.key, text1Ref)}
                />
                <TextField
                    name='platePart2'
                    id="platePart2"
                    value={platePart2}
                    inputRef={text2Ref}
                    disabled={false}
                    variant="standard"
                    placeholder="SB"
                    autoComplete="off"
                    className={styles.textFieldInput}
                    onChange={(e) => [setPlatePart2(e.target.value.toUpperCase()), changeRef(e.target.value, 1)]}
                    InputProps={{ disableUnderline: true }}
                    inputProps={{ maxLength: 2 }}
                    onKeyDown={(ev: any) => onkeydown(ev.key, text2Ref)}
                />
                <TextField
                    name='platePart3'
                    id="platePart3"
                    value={platePart3}
                    inputRef={text3Ref}
                    disabled={false}
                    variant="standard"
                    placeholder="78"
                    autoComplete="off"
                    className={styles.textFieldInput}
                    onChange={(e) => [setPlatePart3(e.target.value.toUpperCase()), changeRef(e.target.value, 2)]}
                    InputProps={{ disableUnderline: true }}
                    inputProps={{ maxLength: 2 }}
                    onKeyDown={(ev: any) => onkeydown(ev.key, text3Ref)}
                />
                <img src={images.chilePatente} className={styles.chilePatenteBackground} alt="" />
            </div>
            <Button 
                onPress={onPressNext} 
                title="Continuar" 
                primary 
                disabled={!isPlateComplete} 
                className={styles.submitButton}
            />
        </SellModal>
    )
};

export default Search;