import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { AuthContainerProps } from "../../interfaces/containers";
import NavBar from "../../components/NavBar/NavBar";
import { setCleanAll as cleanAllBuy } from "../../actions/buy";
import { cleanExternalUser, logoutUser } from "../../actions/auth";

function mapStateToProps(state: AuthContainerProps) {
    return{
        user: state.auth.user,
        notifications: state.notifications.notifications
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        logoutUser,
        cleanExternalUser, 
        cleanAllBuy
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);