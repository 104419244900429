import { Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import ProfileCard from "../../../components/ProfileCard";
import styles from "./documentation.module.scss";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useProfileRole, useToast } from "../../../helpers/hooks";
import { DocumentationProps } from "../../../interfaces/profile";
import CheckIcon from '@mui/icons-material/Check';
import ModalValidationDocuments from "../../../components/ModalValidationDocuments/ModalValidationDocuments";

let DOCUMENTATION_TABS = [
    {
        label: "Carnet de identidad",
        id: "identity_card",
        navigate: "identity",
        title: "Ingresa fotos del frente y dorso de tu carnet de identidad",
        completed: false
    },
    {
        label: "Liquidaciones de sueldo",
        id: "labor_data",
        navigate: "salary",
        title: "Necesitamos que completes los siguientes datos y adjuntes fotos de las últimas 3 liquidaciones de sueldo",
        completed: false
    },
    /* {
        label: "Certificado previred",
        id: "previred",
        navigate: "previred",
        title: "Necesitamos que adjuntes el certificado de cotizaciones pagadas",
        completed: false
    },
    {
        label: "Certificado de domicilio",
        id: "residence",
        navigate: "address",
        title: "Añade fotos de documentación que certifique tu domicilio como la factura de algún servicio a tu nombre",
        completed: false
    }, */
    {
        label: "Datos bancarios",
        id: "bank_data",
        navigate: "bank_data",
        title: "Añade tus datos bancarios para poder realizar transacciones en la app",
        completed: false
    },
    {
        label: "Referencias",
        id: "references",
        navigate: "references",
        title: "Añade dos personas de confianza que serán tus referencias",
        completed: false
    },
];

const Documentation = ({
    user,
    getIsValidDocuments,
    valid,
    cleanIsValidDocuments,
    validation,
    getDocumentsTypes,
    types,
    cleanGetDocumentsTypes
}: DocumentationProps) => {
    const toast = useToast();
    const location = useLocation();
    const navigate = useNavigate();
    const profileRole = useProfileRole();
    const [value, setValue] = useState<any>("identity_card");
    const [tabSelected, setTabSelected] = useState<any>();
    const [modalValidation, setModalValidation] = useState<any>({open: false, type: ""});
    
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        if(valid?.fetching === "failure"){
            toast("Ha ocurrido un error al calcular la validación de su documentación");
            cleanIsValidDocuments();
        }
        if(valid?.fetching === "success"){
            Object.entries(valid?.data?.completed).map(([key, value]: any) => (
                DOCUMENTATION_TABS = DOCUMENTATION_TABS.map((tab: any) => {
                    if(tab?.id === key){
                        return {
                            ...tab,
                            completed: value
                        }
                    }
                    return tab;
                })
            ));
            
            const someNotCompleted = valid?.data && Object.values(valid?.data?.completed).some((item: any) => !item);
            if(validation?.waiting && someNotCompleted && valid?.data?.completed?.bank_data && valid?.data?.completed?.identity_card && valid?.data?.completed?.labor_data && valid?.data?.completed?.references){
                setModalValidation({open: true, type: "locationFault"});
                return;
            }
            if(validation?.waiting && !someNotCompleted){
                setModalValidation({open: true, type: ""});
            }
            cleanIsValidDocuments();
        }
    }, [valid?.fetching]);

    useEffect(() => {
        if(types.fetching === "failure"){
            cleanGetDocumentsTypes();
        }
        if(types.fetching === "success"){
            cleanGetDocumentsTypes();
        }
    }, [types.fetching]);
    
    useEffect(() => {
        window.scrollTo(0, 0);
        const tabFounded = DOCUMENTATION_TABS && DOCUMENTATION_TABS.find((tab: any) => location.pathname.match(`/profile/documentation/${tab?.navigate}`));
        getDocumentsTypes();
        setTabSelected(tabFounded)
        setValue(tabFounded?.id);
        if(location.pathname === "/profile/documentation"){
            navigate("identity");
        }
        getIsValidDocuments();
    }, [location]);

    return (
        <ProfileCard>
            <ModalValidationDocuments open={modalValidation?.open} setOpen={setModalValidation} type={modalValidation?.type} />
            <div className={styles.documentationContainer}>
                {
                    (profileRole(user?.profile?.code) === "user")
                    ?
                        <>
                            <p className={styles.documentationDescription}>
                                Adjunta la siguiente documentación para financiar la compra del vehículo
                            </p>
                            <Tabs TabScrollButtonProps={{style:{backgroundColor: "orange"},children: <div style={{backgroundColor: "orange"}}/>}} className={styles.documentationTabs} value={value} onChange={handleChange} aria-label="basic tabs example">
                                {
                                    DOCUMENTATION_TABS && DOCUMENTATION_TABS.map((tab: any, index: number) => (
                                        <Tab 
                                            key={index} 
                                            onClick={() => navigate(tab.navigate)} 
                                            value={tab?.id} 
                                            label={tab.label} 
                                            className={styles.tab}
                                            icon={tab?.completed ? <CheckIcon className={styles.icon} /> : ""}
                                            iconPosition="end"
                                            classes={{
                                                selected: styles.selected
                                            }}
                                        />
                                    ))
                                }
                            </Tabs>
                            <div className={styles.documentsContainer}>
                                <p className={styles.documentsSubTitle}>
                                    {tabSelected?.title}
                                </p>
                                <Outlet/>
                            </div>
                        </>
                    :
                        <>
                            <Outlet/>
                        </>
                }
            </div>
        </ProfileCard>
    )
};
export default Documentation;