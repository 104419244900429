import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { MyPublicationManagementContainerProps } from "../../../../../interfaces/containers";
import Views from "../../../../../pages/Profile/MyPublications/Management/Views";
import {
    getViews,
    cleanGetViews
} from "../../../../../actions/publication";

function mapStateToProps(state: MyPublicationManagementContainerProps) {
    return{
        views: state.publication.views
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        getViews,
        cleanGetViews
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Views);