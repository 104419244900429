import styles from './linzeAiItemLoader.module.scss';
import { Skeleton } from "@mui/material";
import Caracteristic from '../../../../../components/Caracteristic';

const LinzeAiItemLoader = () => {

    return (
        <div className={styles.skeletonContainer}>
            <Skeleton animation="wave" variant="text" className={styles.title} />
            <div className={styles.content}>
                <Skeleton animation="wave" variant="text" className={styles.item} />
                <Skeleton animation="wave" variant="text" className={styles.item} />
                <Skeleton animation="wave" variant="text" className={styles.item} />
            </div>
        </div>
    )
}

export default LinzeAiItemLoader;