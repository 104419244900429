import { useEffect, useMemo, } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import SellModal from "../../../components/SellModal/SellModal";
import styles from "./confort.module.scss";
import ConfortItem from "../../../components/Confort";
import { ConfortProps } from "../../../interfaces/salePost";

const Confort = ({ 
    setConfort, 
    confort, 
    plate,
    isFoundPlate
 }: ConfortProps) => {
    const navigate = useNavigate();

    const handleSelectConfortItem = (id: any) => {
        const auxConfortItemsSelected = confort.map(conf => {
            if (id === conf.id) {
                return {...conf, isSelected: !conf.isSelected}
            }
            return {...conf}
        });
        setConfort(auxConfortItemsSelected);
    }

    const onPressBack = () => {
        navigate("/sell/records");
    }

    const onPressContinue = () => {
        navigate("/sell/tags");
    }

    const confortNormalized: any = useMemo(() => confort && confort.map((item: any) => [item.id, item.isSelected]), [confort]);

    useEffect(() => {
        if(!plate) return navigate("/sell");
    }, [plate]);

    return (
        <SellModal
            step={isFoundPlate ? 5 : 7}
            title="Confort"
            subTitle="Selecciona las especificaciones que posee tu auto"
            onClickBack={onPressBack}
            isFoundPlate={isFoundPlate}
        >
            <div className={styles.inputsContainer}>
                {
                    confortNormalized.map((item: any, index: number) => (
                        <ConfortItem key={index} item={item} onPress={(id: any) => handleSelectConfortItem(id)} selected={item[1]} editable pressable />
                    ))
                }
            </div>
            <Button title="Continuar" primary onPress={onPressContinue} className={styles.submitButton} />
        </SellModal>
    )
};

export default Confort;