import { useEffect, useState, useMemo } from 'react';
import styles from './settings.module.scss';
import ProfileCard from "../../../components/ProfileCard";
import TextFieldShared from "../../../components/TextField/TextField";
import ButtonShared from "../../../components/Button/Button";
import { SettingsPageProps } from '../../../interfaces/pages';
import { useToast } from '../../../helpers/hooks';


const MyAccount = ({
    changePassword,
    fetchingChangePassword,
    cleanSettings
}: SettingsPageProps) => {
    const toast = useToast();
    const [, setLoading] = useState<boolean>(false);
    const [password, setPassword] = useState<any>({
        value: "",
        error: "",
        validation: {
            specialCaracter: false,
            mayus: false,
            correctLength: false,
            number: false
        }
    });
    const [confirmPassword, setConfirmPassword] = useState<any>({
        value: "",
        error: "",
        validation: {
            specialCaracter: false,
            mayus: false,
            correctLength: false,
            number: false
        }
    });

    const handleChangePassword = () => {
        changePassword(confirmPassword.value)
    }
    useEffect(() => {
        switch (fetchingChangePassword) {
            case 'loading':
                setLoading(true);
                break;
            case 'failure':
                toast('Error al cambiar contraseña');
                setLoading(false);
                break;
            case 'success':
                toast('Cambiado correctamente');
                setConfirmPassword({
                    value: "",
                    error: "",
                    validation: {
                        specialCaracter: false,
                        mayus: false,
                        correctLength: false,
                        number: false
                    }
                });
                setPassword({
                    value: "",
                    error: "",
                    validation: {
                        specialCaracter: false,
                        mayus: false,
                        correctLength: false,
                        number: false
                    }
                });
                setLoading(false);
                break;
            default:
                break;
        }

    }, [fetchingChangePassword]);

    useEffect(() => {
      return () => {
        cleanSettings()
      }
    }, [])
    

    return (
        <ProfileCard>
            <div className={styles.myAccount}>
                <div className={styles.myAccountContent}>
                    <div className={styles.line}></div>
                    <div className={styles.infoSegment}>
                        <div className={styles.title}>
                            <p>Cambiar contraseña</p>
                        </div>
                        <div className={styles.grid}>
                            <TextFieldShared
                                type="password"
                                label="Nueva contraseña"
                                name="password"
                                value={password?.value}
                                error={password?.error}
                                onChange={(value: any) => setPassword({ ...password, value: value?.value, validation: value?.passValidate })}
                                className={styles.half}
                            />
                            <TextFieldShared
                                type="password"
                                label="Confirmar contraseña"
                                name="password"
                                value={confirmPassword?.value}
                                error={confirmPassword?.error}
                                onChange={(value: any) => setConfirmPassword({ ...confirmPassword, value: value?.value, validation: value?.passValidate })}
                                className={styles.half}
                            />
                        </div>
                    </div>

                    <div className={styles.line}></div>

                    <div className={styles.buttons}>
                        <ButtonShared
                            onPress={handleChangePassword}
                            title="Guardar cambios"
                            primary
                            loading={fetchingChangePassword === 'pending'}
                            disabled={(password?.value?.length > 0 && confirmPassword?.value?.length > 0) ? password.value === confirmPassword.value ? false : true : true}
                        />
                    </div>
                </div>
            </div>
        </ProfileCard>
    )
};
export default MyAccount;