import { useMemo, memo, useEffect, useState } from 'react';
import styles from './scheduleInspection.module.scss';
import { useNavigate, useParams } from "react-router-dom";
import { TransactionPropsSell } from "../../../interfaces";
import { SellScheduleInspectionProps } from '../../../interfaces/pages';
import { capitalize } from '../../../helpers/app';
import { useToast } from '../../../helpers/hooks';
import { images } from '../../../constants/images';
import ButtonShared from '../../../components/Button';
import TextFieldShared from '../../../components/TextField';
import ToggleButtonShared from '../../../components/ToggleButton/ToggleButton';
import Modal from '../../../components/Modal';
import moment from 'moment';
import 'moment/locale/es';

const ScheduleInspection = ({
    user,
    transactionConfirmation,
    scheduling,
    getScheduling,
    postTransactionConfirmation,
    cleanTransaction,
    getIsValidDocuments, 
    isValidDocuments,
    cleanIsValidDocuments
}: SellScheduleInspectionProps) => {
    const params = useParams();
    const toast = useToast();
    const navigate = useNavigate();
    const [selectedDay, setSelectedDay] = useState<any>([]);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [openModalSuccess, setOpenModalSuccess] = useState<boolean>(false);
    const [openNoValidDocuments, setOpenNoValidDocuments] = useState<boolean>(false);
    const [direction, setDirection] = useState<any>({value: "", coords: {lat: "", long: ""}, error: ""});

    const handlePressDate = (date: any) => {
        setSelectedDay(date);
    }

    const handlePressModal = () => {
        navigate('/');
    }

    const handleChangeDirection = ({value, coords}: any) => {
        setDirection({
            ...direction,
            value: value,
            coords: {
                lat: coords?.lat,
                long: coords?.long
            }
        });
    }

    const handleSend = () => {
        if(!isValidDocuments?.data){
            //Si los documentos no estan validados
            setOpenNoValidDocuments(true);
            return;
        }
        const data: TransactionPropsSell = {
            transaction: scheduling?.data?.transaction,
            date_start: new Date(selectedDay?.value).toISOString(),
            date_end: new Date(selectedDay?.value).toISOString(),
            address: direction.value,
            coordinates:{
                lat: direction.coords.lat,
                long: direction.coords.long
            }
        }
        postTransactionConfirmation(data);
    }

    const datesOptions: any = useMemo(() => {
        const dates: any = [];
        scheduling?.data?.dates && scheduling?.data?.dates.map((date: any) => {
            dates.push({
                number: moment(date?.date_start).format('DD'),
                day: moment(date?.date_start).format('dddd'),
                month: moment(date?.date_start).format('MMM'),
                value: date?.date_start
            });
        })
        return dates;
    }, [scheduling?.data?.dates]);

    useEffect(() => {
        if(transactionConfirmation?.fetching === "failure"){
            if(transactionConfirmation?.error === "SELLER_ACCEPTED_TRANSACTION"){
                toast("El vendedor ya ha aceptado la transacción");
            }
            cleanTransaction();
        }
        if(transactionConfirmation?.fetching === "success"){
            setOpenModalSuccess(true);
            cleanTransaction();
        }
    }, [transactionConfirmation?.fetching]);

    useEffect(() => {
        if(direction?.value && direction?.value.length > 0 && selectedDay?.value){
            setDisabled(false);
        }else{
            setDisabled(true);
        }
    }, [direction?.value, selectedDay]);

    useEffect(() => {
        if(isValidDocuments?.fetching === "failure"){
            toast("Ha ocurrido un error al validar sus documentos");
            cleanIsValidDocuments();
        }
        if(isValidDocuments?.fetching === "success"){
            cleanIsValidDocuments();
        }
    }, [isValidDocuments?.fetching]);

    useEffect(() => {
        if(scheduling?.fetching === "failure"){
            toast("Ha ocurrido un error");
        }
    }, [scheduling?.fetching]);
    
    useEffect(() => {
        getScheduling(params?.id);
        getIsValidDocuments();
    }, [params]);

    return (
        <div className={styles.scheduleInspectionContainer}>
            <Modal open={openModalSuccess}>
                <div className={styles.modalContainerBuy}>
                    <p className={styles.message}>
                        <span className={styles.title}>¡Bien hecho!</span>
                        <span className={styles.subtitle}>Agendaste con éxito la fecha para la inspección de tu auto</span>
                        <span className={styles.description}>El día {selectedDay?.number} de {capitalize(moment(selectedDay?.value).format('MMMM'))} un agente Linzer te visitará en tu domicilio. Te avisaremos cuando esté en camino</span>
                    </p>
                    <ButtonShared 
                        onPress={handlePressModal}
                        title="Volver al inicio" 
                        secondary
                    />
                </div>
            </Modal>
            <Modal open={openNoValidDocuments}>
                <div className={styles.modalValidDocuments}>
                    <p className={styles.message}>
                        <span className={styles.title}>Documentación incompleta</span>
                        <span className={styles.subtitle}>Para aceptar una fecha de inspección debes tener toda tu documentación adjunta</span>
                    </p>
                    <ButtonShared 
                        onPress={() => navigate("/profile/documentation")}
                        title="Completar documentación" 
                        secondary
                    />
                </div>
            </Modal>
            <div className={styles.cardContainer}>
                <p className={styles.title}>Agendar inspección</p>
                <div className={styles.body}>
                    <p className={styles.subtitle}>
                        Espera la llamada y visita de nuestro agente Linzer certificado para realizar la inspección técnica. Escoge 1 posible fecha:
                    </p>
                    <div className={`${styles.item} ${styles.dates}`}>
                        <p className={styles.name}>Fechas</p>
                        <div className={styles.content}>
                            {
                                scheduling?.fetching === "loading"
                                ?
                                    <>
                                        <ToggleButtonShared loading />
                                        <ToggleButtonShared loading />
                                        <ToggleButtonShared loading />
                                    </>
                                :
                                    datesOptions && datesOptions.map((date: any, index: number) => (
                                        <ToggleButtonShared 
                                            key={index} 
                                            type="single"
                                            disabled={(selectedDay?.value && (!selectedDay === date?.value)) ? true : false}
                                            selected={(selectedDay?.value === date?.value) ? true : false}
                                            onPress={() => handlePressDate(date)}
                                        >
                                            <p>
                                                <span>{capitalize(date?.day)}</span>
                                                <span>{`${date?.number} ${date?.month}`}</span>
                                            </p>
                                        </ToggleButtonShared>
                                    ))
                            }
                        </div>
                        <p className={styles.description}>
                            {
                                selectedDay.length > 0
                                ?   
                                    <>
                                        Asignaste el dia
                                        <span> {selectedDay?.number} de {selectedDay.month} </span>
                                        de 9 a 14hs
                                    </>
                                :
                                    <>
                                        Asigna 1 dia
                                    </>
                            }
                        </p>
                    </div>
                    <div className={`${styles.item} ${styles.direction}`}>
                        <p className={styles.name}>Dirección</p>
                        <div className={styles.content}>
                            <TextFieldShared
                                variant="location"
                                label="Confirma tu dirección"
                                name="direction"
                                value={direction?.value}
                                onChange={handleChangeDirection}
                                error={direction?.error}
                                className={styles.full}
                            />
                        </div>
                    </div>
                    <div className={`${styles.item} ${styles.participants}`}>
                        <p className={styles.name}>Participantes</p>
                        <div className={styles.content}>
                            <div className={`${styles.people} ${styles.you}`}>
                                <img src={user?.picture ? user?.picture : images.profile} alt="" />
                                <p>Tu</p>
                            </div>
                            <div className={`${styles.people} ${styles.linzer}`}>
                                <div className={styles.image}>
                                    <img src={images.isotipo} alt="" />
                                </div>
                                <p>Linzer</p>
                            </div>
                        </div>
                        <p className={styles.description}>
                            Te informaremos cuando el agente Linze esté en camino. Si aceptas la entrega, gestionaremos tu pago, firma por la compra y listo, el auto será tuyo
                        </p>
                    </div>
                    <ButtonShared
                        onPress={handleSend}
                        title="Agendar inspección"
                        primary
                        disabled={disabled}
                        loading={transactionConfirmation?.fetching === "loading" || scheduling?.fetching === "loading"}
                    />
                </div>
            </div>
        </div>
    )
}

export default memo(ScheduleInspection);