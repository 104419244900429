import { useEffect } from "react";
import styles from "./pictures.module.scss";
import { images } from "../../../constants/images";
import { mdiArrowLeft } from '@mdi/js';
import { IconButton } from "@mui/material";
import Icon from "@mdi/react";
import { useNavigate } from "react-router-dom";

const Pictures = () => {
    const navigate = useNavigate()
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={styles.content}>
            <div className={styles.questionsContainer}>
                <div className={styles.sectionContent}>
                    <IconButton onClick={() => navigate(-1)} aria-label='Delete' className={styles.goBack}>
                        <Icon size={1} color={"black"} path={mdiArrowLeft} title="Back" className={styles.icon} />
                    </IconButton>
                    <div className={styles.item}>
                        <p className={styles.title}>Consejos para subir fotos en tu publicación</p>
                        <p>A continuación, te ofrecemos algunas pautas para optimizar tus fotos:</p>
                    </div>
                    <p>1. La foto principal debe mostrar el vehículo completo.</p>
                    <ul className={styles.numberItem}>
                        <li className={styles.bullet}>Para asegurarte de que la foto sea válida, asegúrate de que la imagen se centre en tu vehículo. Recuerda que no debe haber elementos no relacionados, como personas, animales en la imagen.</li>
                        <div className={styles.picturesExample}>
                            <img src={images.firstPictureUpload} />
                            <img src={images.successPictureUpload} />
                        </div>
                    </ul>
                    <p>2. Aprovecha al máximo el espacio de la foto.</p>
                    <ul className={styles.numberItem}>
                        <li className={styles.bullet}>El vehículo debe ocupar todo el encuadre de la foto, sin recortes ni tamaños pequeños.</li>
                        <div className={styles.picturesExample}>
                            <img src={images.secondPictureUpload} />
                            <img src={images.successPictureUpload} />
                        </div>
                    </ul>
                    <p>3. Verifica la nitidez y la iluminación.</p>
                    <ul className={styles.numberItem}>
                        <li className={styles.bullet}>Siempre toma fotos con la luz del sol detrás de ti para una mejor visibilidad. Evita la iluminación excesivamente fuerte o débil.</li>
                        <div className={styles.picturesExample}>
                            <img src={images.thirdPictureUpload} />
                            <img src={images.successPictureUpload} />
                        </div>
                    </ul>
                    <p>4. Utiliza imágenes de gran tamaño.</p>
                    <ul className={styles.numberItem}>
                        <li className={styles.bullet}>Asegúrate de que tus fotos tengan un tamaño mínimo de 800x600 píxeles para que los compradores puedan hacer zoom y apreciar los detalles.</li>
                        <div className={styles.picturesExample}>
                            <img src={images.fourPictureUpload} />
                            <img src={images.successPictureUpload} />
                        </div>
                    </ul>
                    <p>Recuerda seguir estas recomendaciones al crear tu publicación para evitar infringir los derechos de propiedad intelectual:</p>
                    <ul className={styles.section}>
                        <li className={styles.bullet}>Crea tu propio contenido para aumentar tus ventas y evitar problemas legales.</li>
                        <li className={styles.bullet}>Si utilizas imágenes de internet, asegúrate de tener la autorización expresa del titular de los derechos.</li>
                        <li className={styles.bullet}>Si obtienes imágenes de bancos de imágenes, verifica que sus términos y condiciones te permitan usar las fotos según tus necesidades.</li>
                        <li className={styles.bullet}>No alteres las imágenes para ocultar marcas de productos.</li>
                        <li className={styles.bullet}>Evita utilizar imágenes que contengan logos o marcas de terceros sin una autorización explícita.</li>
                        <li className={styles.bullet}>Ten en cuenta que el incumplimiento de las políticas de propiedad intelectual puede resultar en denuncias por parte de los titulares de los derechos o en la suspensión de tu publicación.</li>
                    </ul>
                    <p>Aprende cómo publicar de manera legal y evita problemas relacionados con la propiedad intelectual.</p>
                </div>
            </div>
        </div>
    )
}

export default Pictures;