import { Dispatch } from "redux";
import { GetState } from "../config/store";
import { api } from "../config/api";
import {
    getDiscountRequest,
    getDiscountSuccess,
    getDiscountFailure,
    cleanGetDiscount as cleanGetDiscountReducer,
    putDiscountRequest,
    putDiscountSuccess,
    putDiscountFailure,
    cleanPutDiscount as cleanPutDiscountReducer
} from "../reducers/discount";
import { axiosClient } from "../config/axios";

export const getDiscount = (id: string) => (dispatch: Dispatch) => _getDiscount(id, dispatch);
const _getDiscount = async (id: string, dispatch: Dispatch) => {
    try {
        dispatch({ type: getDiscountRequest });

        const response = await axiosClient.get(`${api.transactions}/discounts/${id}`);

        dispatch({type: getDiscountSuccess, payload: response?.data?.data.discount});
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        if (errorResponse?.errors) return dispatch({ type: getDiscountFailure, payload: errorResponse?.code });
    }
};

export const cleanGetDiscount = () => (dispatch: Dispatch) => _cleanGetDiscount(dispatch);
const _cleanGetDiscount = (dispatch: Dispatch) => dispatch({type: cleanGetDiscountReducer});

export const putDiscount = (id: string, status: string) => (dispatch: Dispatch) => _putDiscount(id, status, dispatch);
const _putDiscount = async (id: string, status: string, dispatch: Dispatch) => {
    try {
        dispatch({type: putDiscountRequest});
        const body = JSON.stringify({status: status});

        const response = await axiosClient.put(`${api.transactions}/discounts/${id}/status`, body);

        dispatch({type: putDiscountSuccess, payload: response?.data?.data});
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({type: putDiscountFailure, payload: errorResponse?.code});
    }
}

export const cleanPutDiscount = () => (dispatch: Dispatch) => _cleanPutDiscount(dispatch);
const _cleanPutDiscount = (dispatch: Dispatch) => dispatch({type: cleanPutDiscountReducer});