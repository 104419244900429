import { useEffect, useState } from "react";
import styles from "./tags.module.scss";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import SellModal from "../../../components/SellModal/SellModal";
import Tag from "../../../components/Tag";
import TextField from "../../../components/TextField";
import { capitalize } from "../../../helpers/app";
import { TagsProps } from "../../../interfaces/salePost";
import { BsPlus } from 'react-icons/bs';
import { useToast } from "../../../helpers/hooks";

const Tags = ({ 
    tags, 
    brands, 
    models, 
    color, 
    setTags,
    plate,
    isFoundPlate
}: TagsProps) => {
    const toast = useToast();
    const navigate = useNavigate();
    const [inputTags, setInputTags] = useState<any>({value: "", error: "", disabledAdd: false});
    
    const handlePressEnter = (event: any) => {
        if(!inputTags?.disabledAdd){
            const tagFound = tags.some((item: any) => item === event.target.value.replace(/[^\w]/gi, ''));
            if (tagFound) {
                return toast("Ya has colocado ese tag");
            }
            setTags([...tags, event.target.value.replace(/[^\w]/gi, '')]);
            setInputTags("");
        }
    }

    const onAddTag = (value: any) => {
        if(value.length >= 25){
            return setInputTags({...inputTags, error: "Máximo 25 caracteres", disabledAdd: true});
        }
        if(/\s+/.test(value)){
            return setInputTags({...inputTags, error: "No puedes agregar espacios", disabledAdd: true});
        }
        setInputTags({...inputTags, value: value, error: "", disabledAdd: false});
    }

    const onRemoveTag = (tag: string) => {
        const filteredTags = tags.filter((value) => value !== tag);
        setTags(filteredTags);
    }

    useEffect(() => {
        if (!tags.length) {
            let reducerTags = [];
            if (color) {
                reducerTags.push(capitalize(color.replace(' ', '')))
            }
            if (models.selected && models.selected?.id) {
                reducerTags.push(capitalize(models.selected?.label.replace(' ', '') || models.selected?.name.replace(' ', '')))
            }
            if (brands.selected && brands.selected?.id) {
                reducerTags.push(capitalize(brands.selected?.label.replace(' ', '') || brands.selected?.name.replace(' ', '')))
            }
            setTags(reducerTags)
        }
    }, [color, models.selected, brands.selected, tags]);

    useEffect(() => {
        if(!plate) return navigate("/sell");
    }, [plate]);
    
    return (
        <SellModal
            step={isFoundPlate ? 6 : 8}
            title="Descripción"
            subTitle="Ingresa otros detalles que describan tu vehículo para que los futuros compradores lo conozcan mejor"
            onClickBack={() => navigate("/sell/confort")}
            isFoundPlate={isFoundPlate}
        >
            <div className={styles.inputsContainer}>
                <div className={styles.inputContent}>
                    <TextField 
                        onEnter={handlePressEnter} 
                        label="Describe el vehículo"
                        name="Describe el vehículo"
                        value={inputTags?.value}
                        onChange={onAddTag} 
                        className={styles.input}
                        error={inputTags?.error}
                    />
                    <button className={`${styles.button} ${inputTags?.value === "" && styles.disabled}`} onClick={handlePressEnter} disabled={inputTags?.value === ""}>
                        <BsPlus title="Añadir" className={styles.icon} />
                    </button>
                </div>
                <div className={styles.tagsContainer}>
                    {
                        tags.map((tag: any, index: number) => (
                            <Tag key={index} onPress={() => onRemoveTag(tag)} editable text={tag} />
                        ))
                    }
                </div>
            </div>
            <Button 
                onPress={() => navigate("/sell/pictures")} 
                title="Continuar" 
                primary 
                disabled={tags && tags.length < 3}
                className={styles.submitButton}
            />
        </SellModal>
    )
};

export default Tags;