import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { MyOffersContainerProps } from "../../../interfaces/containers";
import EntryPoint from "../../../pages/Profile/MyOffers/EntryPoint";
import { getMyOffers, cleanGetMyOffers } from "../../../actions/profile";

function mapStateToProps(state: MyOffersContainerProps) {
    return{
        myOffers: state.profile.myOffers,
        offerReducer: state.publication.offer,
        counterOffers: state.publication.counterOffers
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        getMyOffers,
        cleanGetMyOffers
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(EntryPoint);