
import { useState } from 'react';
import { ToolTipProps } from "../../interfaces/components";
import { ClickAwayListener } from "@mui/base";
import styles from "./toolTip.module.scss";
import { IconButton } from '@mui/material';
import { images } from '../../constants/images';

const ToolTip = ({
    onPress = () => {},
    variant = "hover",
    children,
    className,
    icon,
}: ToolTipProps) => {
    const [openToolTip, setOpenToolTip] = useState<boolean>(false);

    const onClickAway = () => {
        if(openToolTip){
            setOpenToolTip(false);
            if(onPress) onPress();
        }
    }

    const onHover = () => {
        if(variant === "hover"){
            setOpenToolTip(!openToolTip);
            if(onPress) onPress();
        }
    }

    const onClick = () => {
        if(variant === "click"){
            setOpenToolTip(!openToolTip);
            if(onPress) onPress();
        }
    }

    return(
        <ClickAwayListener onClickAway={onClickAway} disableReactTree={true}>
            <div className={`${styles.toolTip} ${className}`}>
                <IconButton aria-label='Information' onClick={onClick} onMouseEnter={onHover} onMouseLeave={onHover}>
                    <img src={icon ? icon : images.lightShowDetail} className={styles.icon} />
                </IconButton>
                <div className={`${styles.toolTipContent} ${openToolTip && styles.open}`}>
                    {children}
                </div>
            </div>
        </ClickAwayListener>
    )
};

export default ToolTip;