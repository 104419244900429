import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import BankData from "../../../../pages/Profile/Documentation/BankData";
import { DocumentsPagesContainerProps } from "../../../../interfaces/containers";
import { editUserRequest } from "../../../../actions/auth";
import { getBanks } from "../../../../actions/banks";
import { getIsValidDocuments } from "../../../../actions/documents";

function mapStateToProps(state: DocumentsPagesContainerProps) {
    return{
        user: state.auth.user,
        fetchingEdit: state.auth.fetchingEdit,
        errorEdit: state.auth.errorEdit,
        banks: state.banks.banks,
        fetchingBanks: state.banks.fetching,
        fetchingTypes: state.documents.types.fetching
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        editUserRequest,
        getBanks,
        getIsValidDocuments
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(BankData);