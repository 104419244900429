import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { QrGeneratorContainerProps } from "../../../interfaces/containers";
import EntryPoint from "../../../components/QRGenerator";
import { getMyBuy, cleanMyBuy, setProfilePublication } from "../../../actions/profile";
import { getPublication, cleanPublication } from "../../../actions/publication";

function mapStateToProps(state: QrGeneratorContainerProps) {
    return{
        myBuy: state.profile.myBuy,
        publication: state.publication,
        publicationProfile: state.profile.publication
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        getMyBuy,
        cleanMyBuy,
        getPublication,
        cleanPublication,
        setProfilePublication,
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(EntryPoint);