import { useEffect } from 'react';
import { RefreshTokenProps } from '../../interfaces/components';
import { dev } from '../../config/api';
// Este componente se va a encargar de hacer la request del refresh del token
// Esto puede ser cuando el token esta vencido o 5 minutos antes de vencerse

const RefreshToken = ({ expirationTime, refreshTokenRequest, refreshType }: RefreshTokenProps) => {
    useEffect(() => {
        let timer: any = null;
        if (expirationTime) {
            timer && clearTimeout(timer)
            // Cada vez que se abre la web se calcula la fecha fiveMinutesBeforeExp en base al expiration time del token
            const fiveMinutesBeforeExp = new Date(1000 * (expirationTime - 60 * 5));
            const waitingTime = fiveMinutesBeforeExp.getTime() - new Date().getTime();
            
            if(refreshType !== "first_initialization"){
                // Se actualiza el token 5 minutos antes que expire.
                timer = setTimeout(() => { 
                    if(dev) console.log("refresh token timeout")
                    refreshTokenRequest();
                }, waitingTime);
            }
        }
        return () => {
            timer && clearTimeout(timer);
        }
    }, [expirationTime]);
    return null;
}

export default RefreshToken;