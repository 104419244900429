import { Dispatch } from 'redux';
import { api } from "../config/api";
import { GetState } from "../config/store";
import { 
    getInspectionApprovalRequest,
    getInspectionApprovalSuccess,
    getInspectionApprovalFailure,
    cleanGetInspectionApproval as cleanGetInspectionApprovalReducer,
    putInspectionApprovalRequest,
    putInspectionApprovalSuccess,
    putInspectionApprovalFailure,
    cleanPutInspectionApproval as cleanPutInspectionApprovalReducer
} from '../reducers/inspection';
import { axiosClient } from '../config/axios';

export const getInspectionApproval = (id: string, typeUser: string) => (dispatch: Dispatch) => _getInspectionApproval(id, typeUser, dispatch);
const _getInspectionApproval = async (id: string, typeUser: string, dispatch: Dispatch) => {
    try {
        dispatch({type: getInspectionApprovalRequest });

        const response = await axiosClient.get(`${api.transactions}/inspection-approvals/${id}`);

        if(response?.data?.data.inspection_approval){
            const { status_seller, status_buyer } = response?.data?.data.inspection_approval;
            if((typeUser === "seller" && status_seller === "pending") || (typeUser === "buyer" && status_buyer === "pending")){
                dispatch({type: getInspectionApprovalSuccess, payload: true});
                return;
            }
        };

        dispatch({type: getInspectionApprovalSuccess, payload: false});
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({type: getInspectionApprovalFailure });
    }
};

export const cleanGetInspectionApproval = () => (dispatch: Dispatch) => _cleanGetInspectionApproval(dispatch);
const _cleanGetInspectionApproval = (dispatch: Dispatch) => dispatch({type: cleanGetInspectionApprovalReducer})

export const putInspectionApproval = (id: string, status: string) => (dispatch: Dispatch) => _putInspectionApproval(id, status, dispatch);
const _putInspectionApproval = async (id: string, status: string, dispatch: Dispatch ) => {
    try {
        dispatch({type: putInspectionApprovalRequest});
        const body = JSON.stringify({status: status});

        await axiosClient.put(`${api.transactions}/inspection-approvals/${id}/status`, body);

        dispatch({type: putInspectionApprovalSuccess})

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({type: putInspectionApprovalFailure});
    }
}

export const cleanPutInspectionApproval = () => (dispatch: Dispatch) => _cleanPutInspectionApproval(dispatch);
const _cleanPutInspectionApproval = (dispatch: Dispatch) => dispatch({type: cleanPutInspectionApprovalReducer})