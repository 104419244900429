import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { BrandContainerProps } from "../../interfaces/containers";
import Brand from "../../pages/Sell/Brand";
import {  } from "../../actions/salePost";
import { getBrands, cleanGetBrands, setBrand } from "../../actions/salePost";

function mapStateToProps(state: BrandContainerProps) {
    return{
        brands: state.salePost.brands,
        plate: state.salePost.plate,
        isFoundPlate: state.salePost.isFoundPlate
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        setBrand,
        getBrands,
        cleanGetBrands
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Brand);