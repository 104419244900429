import { useState } from "react";
import styles from "./inspection.module.scss";
import { InspectionMyBuysManagementProps } from "../../../../../interfaces/components";
import moment from "moment";
import { capitalize } from "../../../../../helpers/app";
import { translations } from "../../../../../constants/app";
import InspectionSkeleton from "./InspectionSkeleton";

const Inspection = ({
    loading,
    data
}: InspectionMyBuysManagementProps) => {
    const [openInform, setOpenInform] = useState<boolean>(false);

    return(
        loading
        ?   <InspectionSkeleton />
        :   (data && data.length > 0)
            ?
                <>
                    <p className={styles.cardRow}>
                        <span className={styles.title}>Estado</span>
                        <span 
                            className={`
                                ${styles.chip}
                                ${data[0]?.status === "in_progress" ? styles.blue :  
                                data[0]?.status === "accepted" ? styles.green :
                                data[0]?.status === "rejected" ? styles.red :
                                data[0]?.status === "cancelled" ? styles.gray : 
                                data[0]?.status === "pending" ? styles.blue : ""}`
                            }
                        >
                            {translations(data[0]?.status)}
                        </span>
                    </p>
                    <p className={styles.openInform} onClick={() => setOpenInform(!openInform)}>{openInform ? "Cerrar informe" : "Ver informe completo"}</p>
                    {
                        openInform &&
                        <div className={styles.inform}>
                            <p className={styles.cardRow}>
                                <span className={styles.title}>Nº de inspección</span>
                                <span>#{data[0]?._id.slice(0,10)}</span>
                            </p>
                            <p className={styles.cardRow}>
                                <span className={styles.title}>Fecha</span>
                                <span>{moment(data[0]?.started_at).format("DD")} de {moment(data[0]?.started_at).format("MMM YYYY")}</span>
                            </p>
                            <p className={styles.cardRow}>
                                <span className={styles.title}>Linzer asignado</span>
                                <span>
                                    {
                                        data[0]?.assigned_brief
                                        ?
                                            `${capitalize(data[0]?.assigned_brief?.name)} ${capitalize(data[0]?.assigned_brief?.last_name)}`
                                        :
                                            "-"
                                    }
                                </span>
                            </p>
                        </div>
                    }
                </>
            :
                <div className={styles.textNoData}>Inspección no inicializada</div>
    )
};

export default Inspection;