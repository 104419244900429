import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { FavoritesContainerProps } from "../../interfaces/containers";
import Favorite from "../../components/Favorite/Favorite";
import { likePublication, dislikePublication, cleanLikedPublication } from "../../actions/profile";
import { setLoginRedirection } from "../../actions/auth";

function mapStateToProps(state: FavoritesContainerProps) {
    return{
        user: state.auth.user,
        likedFetching: state.profile.favorites.likedFetching
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        likePublication,
        dislikePublication,
        cleanLikedPublication,
        setLoginRedirection
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Favorite);