import { AccountTypeProps } from "../interfaces/profile";

export const civilOptions = [
    {
        id: 0,
        name: 'Soltero'
    },
    {
        id: 1,
        name: 'Casado'
    },
    {
        id: 2,
        name: 'Divorciado'
    },
    {
        id: 3,
        name: 'Viudo'
    },
    {
        id: 4,
        name: 'Otro'
    }
];

export const genderOptions = [
    {
        id: 0,
        name: 'Masculino'
    },
    {
        id: 1,
        name: 'Femenino'
    },
    {
        id: 2,
        name: 'Otro'
    }
];

export const countriesOptions = [
    {
        id: 0,
        name: 'chile'
    }
];

export const residence = {
    region_id: 0,
    region_name: '',
    city_id: 0,
    city_name: '',
    commune_id: 0,
    commune_name: ''
}

export const ACCOUNT_TYPES: AccountTypeProps[] = [
    {
        value: "vista",
        name: "Vista",
        id: "vista"
    },
    {
        value: "rut",
        name: "Rut",
        id: "rut"
    },
    {
        value: "savings_account",
        name: "Cuenta de Ahorro",
        id: "savings_account"
    },
    {
        value: "checking_account",
        name: "Cuenta corriente",
        id: "checking_account"
    }
];

export const BANKS = [
    {
        id: "da",
        name: "Banco Galicia"
    },
    {
        id: "dd",
        name: "Banco Chile"
    }
];

export const RELATIVES = [
    {
        id: 1,
        name: 'Cónyuge',
    },
    {
        id: 2,
        name: 'Hija/o',
    },
    {
        id: 3,
        name: 'Padre',
    },
    {
        id: 4,
        name: 'Madre',
    },
    {
        id: 5,
        name: 'Tía/o',
    },
    {
        id: 6,
        name: 'Sobrina/o',
    },
    {
        id: 7,
        name: 'Prima/o',
    },
    {
        id: 8,
        name: 'Familiar',
    },
    {
        id: 9,
        name: 'Amiga/o',
    },
    {
        id: 10,
        name: 'Otros',
    },
];

export const ROLES = [
    {
        "code": "seller",
        "value": "seller",
        "name": "vendedor"
    },
    {
        "code": "admin-branch",
        "value": "admin branch",
        "name": "administrador de sucursal"
    }
]

export const STATUS = [
    {
        "code": "active",
        "name": "activo"
    },
    {
        "code": "inactive",
        "name": "inactivo"
    }
]