import React from "react";
import { RouteObject } from "../../interfaces/navigation";

const EntryPoint = React.lazy(() => import("../../containers/Terms/EntryPointContainer"));

export const termsRoutes: RouteObject[] = [
    {
        title: "Terms",
        path: "/terms",
        component: <EntryPoint />
    }
];