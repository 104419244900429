import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { BuyProps } from "../../../interfaces/containers";
import Summary from "../../../pages/Buy/Summary/Summary";
import { setStep } from "../../../actions/buy";

function mapStateToProps(state: BuyProps) {
    return{
        step: state.buy.step,
        method: state.buy.method,
        financing: state.buy.financing,
        creditSimulation: state.buy.creditSimulation,
        additionalCost: state.buy.additionalCost
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        setStep
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Summary);