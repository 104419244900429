import * as React from 'react';
import styles from "./inspectionSkeleton.module.scss";
import { Skeleton } from '@mui/material';

const InspectionSkeleton = () => {
    return (
        <div className={styles.informeContainer}>
            <div className={styles.cardRow}>
                <p>Nº de inspección</p>
                <Skeleton animation="wave" width={100} variant="text"/>
            </div>
            <div className={styles.cardRow}>
                <p>Fecha</p>
                <Skeleton animation="wave" width={100} variant="text"/>
            </div>
            <div className={styles.cardRow}>
                <p>Linzer asignado</p>
                <Skeleton animation="wave" width={100} variant="text"/>
            </div>
            <div className={styles.cardRow}>
                <p>Estado</p>
                <Skeleton animation="wave" width={100} variant="text"/>
            </div>
        </div>
    );
}

export default InspectionSkeleton;