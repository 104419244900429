import { useEffect, useState } from "react";
import styles from "./inspection.module.scss";
import { images } from "../../constants/images";
import { IconButton, Skeleton } from '@mui/material';
import { useLocation, useParams } from "react-router-dom";
import PublicationCardDetail from "../../containers/PublicationCardDetail/PublicationCardDetailContainer";
import moment from "moment";
import 'moment/locale/es';
import { useToast } from "../../helpers/hooks";
import ProfileCard from "../../components/ProfileCard";
import { myBuysInspectionsBreadCrumb } from "../../constants/breadCrumbs";
import { translations } from "../../constants/app";
import Slider from "../Slider/Slider";
import { MyPublicationInspectionsProps } from "../../interfaces/pages";
import { capitalize } from "../../helpers/app";

const Inspection = ({
    inspection,
    cleanInspection,
    getInspectionsFiles,
    cleanInspectionFiles,
    getLinzerAssigned
}: MyPublicationInspectionsProps) => {
    const toast = useToast();
    const params = useParams();
    const location = useLocation();
    const [openInspectionFiles, setOpenInspectionFiles] = useState(false);

    const handlePressEye = () => {
        if(inspection?.files?.data.length === 0){
            return toast("Esta inspección no contiene imagenes");
        }
        setOpenInspectionFiles(true);
    }

    useEffect(() => {
        if(inspection?.files?.fetching === "failure"){
            toast("Ha ocurrido un error al mostrar las fotos de la inspección");
            cleanInspectionFiles();
        }
        if(inspection?.files?.fetching === "success"){
            cleanInspectionFiles();
        }
    }, [inspection?.files?.fetching]);

    useEffect(() => {
        if(inspection?.fetching === "failure"){
            toast("Ha ocurrido un error al mostrar la inspección");
            cleanInspection();
        }
        if(inspection?.fetching === "success"){
            if(inspection?.data && inspection?.data.length > 0){
                getInspectionsFiles(inspection?.data[0]?._id);
            }
            cleanInspection();
        }
    }, [inspection?.fetching]);

    useEffect(() => {
        getLinzerAssigned(inspection?.data[0]?.assigned);
    }, [params]);

    return (
        <ProfileCard breadCrumbs={myBuysInspectionsBreadCrumb(params?.id)}>
            <PublicationCardDetail information="En esta pantalla puedes ver el resultado de la inspección del auto que has comprado o vendido, junto con su estado actual. Conoce en detalle la condición del auto y valida su estado actual del mismo. ¡Asegúrate de tener un auto en excelentes condiciones y conduce con tranquilidad! Explora la inspección y descubre todo lo que necesitas saber sobre el auto.">
                <div className={styles.inspectionContainer}>
                    {
                        inspection?.fetching === "loading" && inspection?.files?.fetching === "loading" && inspection?.linzerAssigned?.fetching === "loading"
                        ? <Skeleton animation="wave" variant="rounded" className={`${styles.title} ${styles.skeleton}`} />
                        : <p className={styles.title}>Informe de inspección</p>
                    }
                    <p className={`${styles.item} ${styles.number}`}>
                        {
                            inspection?.fetching === "loading" && inspection?.files?.fetching === "loading" && inspection?.linzerAssigned?.fetching === "loading"
                            ? <Skeleton animation="wave" variant="rounded" className={`${styles.label} ${styles.skeleton}`} />
                            : <span className={styles.label}>Nº de inspección</span>
                        }
                        {
                            inspection?.fetching === "loading" && inspection?.files?.fetching === "loading" && inspection?.linzerAssigned?.fetching === "loading"
                            ? <Skeleton animation="wave" variant="rounded" className={`${styles.value} ${styles.skeleton}`} />
                            : <span className={styles.value}>#{inspection?.data[0]?._id.slice(0,10)}</span>
                        }
                    </p>
                    <p className={`${styles.item} ${styles.date}`}>
                        {
                            inspection?.fetching === "loading" && inspection?.files?.fetching === "loading" && inspection?.linzerAssigned?.fetching === "loading"
                            ? <Skeleton animation="wave" variant="rounded" className={`${styles.label} ${styles.skeleton}`} />
                            : <span className={styles.label}>Fecha</span>
                        }
                        {
                            inspection?.fetching === "loading" && inspection?.files?.fetching === "loading"
                            ? <Skeleton animation="wave" variant="rounded" className={`${styles.value} ${styles.skeleton}`} />
                            : <span className={styles.value}>{moment(inspection?.data[0]?.started_at).format("DD")} de {moment(inspection?.data[0]?.started_at).format("MMM YYYY")}</span>
                        }
                    </p>
                    <p className={`${styles.item} ${styles.linzer}`}>
                        {
                            inspection?.fetching === "loading" && inspection?.files?.fetching === "loading" && inspection?.linzerAssigned?.fetching === "loading"
                            ? <Skeleton animation="wave" variant="rounded" className={`${styles.label} ${styles.skeleton}`} />
                            : <span className={styles.label}>Linzer asignado</span>
                        }
                        {
                            inspection?.fetching === "loading" && inspection?.files?.fetching === "loading" && inspection?.linzerAssigned?.fetching === "loading"
                            ? <Skeleton animation="wave" variant="rounded" className={`${styles.value} ${styles.skeleton}`} />
                            : 
                                <span className={styles.value}>
                                    {
                                        inspection?.data[0]?.assigned_brief
                                        ?   `${capitalize(inspection?.data[0]?.assigned_brief?.name)} ${capitalize(inspection?.data[0]?.assigned_brief?.last_name)}`
                                        :
                                            "-"
                                    }
                                </span>
                        }
                    </p>
                    <div className={`${styles.status} ${styles[inspection?.data[0]?.status]}`}>
                        {
                            inspection?.fetching === "loading" && inspection?.files?.fetching === "loading" && inspection?.linzerAssigned?.fetching === "loading"
                            ? <Skeleton animation="wave" variant="rounded" className={`${styles.value} ${styles.skeleton}`} />
                            : inspection?.data[0]?.status.length > 0
                                ?   <p 
                                        className={styles.value}
                                        style={{color: 
                                            inspection?.data[0]?.status === "approved" ? "#4caf50" :
                                            inspection?.data[0]?.status === "rejected" ? "#DF4956" : "#4874D0",
                                            cursor: inspection?.data[0]?.status === "in_progress" ? "pointer" : "default",
                                            textDecoration: inspection?.data[0]?.status === "in_progress" ? "underline" : "none",
                                        }}
                                    >
                                        {translations(inspection?.data[0]?.status)}
                                    </p>
                                :   <p className={styles.value}>rechazado</p>
                        }
                        {
                            inspection?.fetching === "loading" && inspection?.files?.fetching === "loading" && inspection?.linzerAssigned?.fetching === "loading"
                            ? <Skeleton animation="wave" variant="circular" className={`${styles.eye} ${styles.skeleton}`} />
                            : <IconButton className={styles.eye} onClick={handlePressEye}>
                                <img src={images.eye} />
                            </IconButton>
                        }
                    </div>
                </div>
            </PublicationCardDetail>
            <Slider
                type="preview"
                options={
                    inspection?.files?.data &&
                    inspection?.files?.data.map((item: any, index: number) => (
                        <img key={index} src={item?.url} alt="Image" />
                    ))
                }
                pagination
                open={openInspectionFiles}
                onClose={() => setOpenInspectionFiles(false)}
            />
        </ProfileCard>
    )
}

export default Inspection;