import { Navigate, useLocation } from "react-router-dom";
import { useToast } from "../helpers/hooks";
import { UserProps } from "../interfaces";
export const ProtectedRoute = ({ user, children }: { user: UserProps | null, children: JSX.Element }) => {
	const toast = useToast();
	let location = useLocation();

	if (!user) {
		toast("Debes iniciar sesión");
		return <Navigate to="/login" state={{ from: location }} replace />;
	}else{
		<Navigate to="/profile/my_account" replace />;
	}
	return children;
};