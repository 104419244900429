import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { MyPublicationsContainerProps } from "../../../interfaces/containers";
import EntryPoint from "../../../pages/Profile/MyPublications/EntryPoint";
import { getMyPublicationsRequest } from "../../../actions/profile";

function mapStateToProps(state: MyPublicationsContainerProps) {
    return{
        user: state.auth.user,
        myPublications: state.profile.myPublications,
        pause: state.publication.pause
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        getMyPublicationsRequest,
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(EntryPoint);