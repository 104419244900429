import React from "react";
import { RouteObject } from "../../interfaces/navigation";

const EntryPoint = React.lazy(() => import("../../containers/Search/SearchContainer"));

export const searchRoutes: RouteObject[] = [
    {
        title: "Search",
        path: "/search",
        component: <EntryPoint/>
    }
];