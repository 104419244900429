import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import {  YearContainerProps } from "../../interfaces/containers";
import Year from "../../pages/Sell/Year";
import { getYears, cleanGetYears, setYear } from "../../actions/salePost";

function mapStateToProps(state: YearContainerProps) {
    return{
        plate: state.salePost.plate,
        isFoundPlate: state.salePost.isFoundPlate,
        years: state.salePost.years
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        setYear,
        getYears,
        cleanGetYears
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Year);