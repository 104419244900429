import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { FavoritesContainerProps } from "../../../interfaces/containers";
import Favorites from "../../../pages/Profile/Favorites";
import { getFavorites, cleanFavorites, setFavorites, likePublication, dislikePublication, cleanLikedPublication } from "../../../actions/profile";

function mapStateToProps(state: FavoritesContainerProps) {
    return {
        user: state.auth.user,
        favoritesReducer: state.profile.favorites
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        getFavorites,
        cleanFavorites,
        setFavorites,
        likePublication,
        dislikePublication,
        cleanLikedPublication
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Favorites);