import styles from './descriptionLoader.module.scss';
import { Skeleton } from "@mui/material";

const DescriptionLoader = () => {

    return (
        <>
            <Skeleton animation="wave" variant="text" className={`${styles.item} ${styles.title}`} />
            <Skeleton animation="wave" variant="text" className={`${styles.item} ${styles.content}`} />
            <Skeleton animation="wave" variant="text" className={`${styles.item} ${styles.content}`} />
            <Skeleton animation="wave" variant="text" className={`${styles.item} ${styles.content}`} />
        </>
    )
}

export default DescriptionLoader;