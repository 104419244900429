import { createSlice } from "@reduxjs/toolkit";
import { FetchingTypes } from "../interfaces/types";

export interface initialStateBankProps {
    banks: BankProps[],
    fetching: FetchingTypes
}

const initialState: initialStateBankProps = {
    banks: [],
    fetching: "unknown"
}


const banksSlice = createSlice({
    name: 'banks',
    initialState,
    reducers: {
        getBanksRequest: (state) => {
            state.fetching = "loading";
        },
        getBanksSuccess: (state, action) => {
            state.fetching = "success";
            state.banks = action.payload.map((bank: any) => (
                {
                    value: bank.name,
                    name: bank.name,
                    id: bank._id
                }
            ));
        },
        getBanksFailure: (state) => {
            state.fetching = "failure";
        },
        cleanBanks: (state) => {
            state.fetching = initialState.fetching;
            state.banks = initialState.banks;
        }
    }
});

export const {
    getBanksRequest,
    getBanksSuccess,
    getBanksFailure,
    cleanBanks
} = banksSlice.actions;

export default banksSlice.reducer;