import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import Documentation from "../../../pages/Profile/Documentation/Documentation";
import { DocumentsPagesContainerProps } from "../../../interfaces/containers";
import { getIsValidDocuments, cleanIsValidDocuments, getDocumentsTypes, cleanGetDocumentsTypes } from "../../../actions/documents";

function mapStateToProps(state: DocumentsPagesContainerProps) {
    return{
        user: state.auth.user,
        valid: state.documents.valid,
        validation: state.buy.validation,
        types: state.documents.types
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        getDocumentsTypes, 
        cleanGetDocumentsTypes,
        getIsValidDocuments,
        cleanIsValidDocuments
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Documentation);