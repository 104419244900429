import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import styles from "./timeLine.module.scss";
import { TimeLineProps } from '../../../../../interfaces/components';
import TimeLineSkeleton from '../TimeLineSkeleton/TimeLineSkeleton';
import { images } from '../../../../../constants/images';

const TimeLine = React.forwardRef(({ data, loading, image = true, className }: TimeLineProps, ref: React.Ref<any>) => {

    return (
        <Timeline className={`${styles.timeLine} ${className}`}>
            {
                loading
                    ?
                    <TimeLineSkeleton />
                    :
                    <div className={styles.content}>
                        {
                            data && data.map((time: any, index: number) => {
                                const lastItem: boolean = index === (data.length - 1);
                                return <TimelineItem>
                                    <TimelineOppositeContent className={styles.opposite} />
                                    <TimelineSeparator>
                                        <TimelineDot
                                            variant={lastItem ? "outlined" : "filled"}
                                            className={lastItem ? styles.lastDot : styles.dot}
                                            color={lastItem ? "primary" : "inherit"}
                                            classes={{
                                                outlinedPrimary: styles.outlinedPrimary
                                            }}
                                        />
                                        {
                                            !lastItem &&
                                            <TimelineConnector className={styles.connector} />
                                        }
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <p className={`${styles.text} ${lastItem && styles.lastItem}`}>
                                            <span className={styles.title}>{time.title}</span>
                                            <span className={styles.price}>{time.price}</span>
                                            <span className={styles.description}>{time.description}</span>
                                        </p>
                                    </TimelineContent>
                                </TimelineItem>
                            })
                        }
                        {
                            image && data.length < 3 &&
                            <img src={images.backgroundHistory} className={styles.image} />
                        }
                    </div>
            }
        </Timeline>
    );
})

export default TimeLine;