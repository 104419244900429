import { useMemo } from "react";
import styles from "./sellModal.module.scss";
import { IconButton } from "@mui/material";
import { SellModalProps } from "../../interfaces/components";
import Icon from '@mdi/react';
import { mdiChevronLeft } from '@mdi/js';

const SellModal = ({children, step = 1, title = "", subTitle, className, onClickBack, disableBackButton, isFoundPlate}: SellModalProps) => {

    const currentLineWidth = useMemo(() => {
        return `${step * 100 / (isFoundPlate ? 7 : 9)}%`;
    },[step])

    return(
        <>
            <div className={styles.searchContainer}>
                <div className={`${styles.sellModalContainer} ${className}`}>
                    {
                        step !== 0 &&
                        <div className={styles.headerModal}>
                            <IconButton aria-label='Atras' onClick={onClickBack} className={`${styles.backButton} ${disableBackButton && styles.disabled}`} >
                                <Icon path={mdiChevronLeft} className={styles.icon} title="Atras" />
                            </IconButton>
                            <p className={styles.title}>{title}</p>
                            <p className={styles.stepperNumber}>{step}/{isFoundPlate ? "7" : "9"}</p>
                        </div>
                    }
                    <div className={styles.searchBody}>
                        <div className={styles.searchModalContainer}>
                            {step === 0 && <p className={styles.title}>{title}</p>}
                            {subTitle && <p className={styles.subTitleBody}>{subTitle}</p>}
                            {children}
                        </div>
                    </div>
                </div>
            </div>
            {
                step !== 0 &&
                <div className={styles.topLine}>
                    <div style={{width: currentLineWidth}} className={styles.currentStep}/>
                </div>
            }
        </>
    )
}

export default SellModal;