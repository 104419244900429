import { createSlice } from "@reduxjs/toolkit";
import { filterDocumentObject, generateNewDocumentObject, restoreDocumentObject, updateDocumentObject } from '../helpers/app';

const initialState: initialStateDocumentsProps = {
    types: {
        fetching: "unknown",
        data: []
    },
    fetchingDocuments: "unknown",
    documents: {
        identity_card: [],
        salary_settlements: [],
        previred_certificate: [],
        domicile_certificate: [],
        bank_data: [],
        references: []
    },
    valid: {
        fetching: "unknown",
        data: [],
        error: ""
    },
    fetchingPostDocuments: "unknown",
    fetchingPostDocumentImage: "unknown",
    fetchingDeleteDocument: "unknown",
    fetchingDeleteDocumentImage: "unknown",
}

const documentsSlice = createSlice({
    name: "documents",
    initialState,
    reducers: {
        setDocuments: (state, action) => {
            state.documents = generateNewDocumentObject(action.payload.type, state.documents, action.payload.data);
        },
        getDocumentsTypesRequest: (state) => {
            state.types.fetching = "loading";
        },
        getDocumentsTypesSuccess: (state, action) => {
            state.types.fetching = "success";
            state.types.data = action.payload.documents;
        },
        getDocumentsTypesFailure: (state) => {
            state.types.fetching = "failure";
        },
        cleanGetDocumentsTypes: (state) => {
            state.types.fetching = initialState.types.fetching;
        },
        getDocumentsRequest: (state) => {
            state.fetchingDocuments = "loading";
        },
        getDocumentsSuccess: (state, action) => {
            state.fetchingDocuments = "success";
            state.documents = generateNewDocumentObject(action.payload.type, state.documents, action.payload.data);
        },
        getDocumentsFailure: (state) => {
            state.fetchingDocuments = "failure";
        },
        cleanGetDocuments: (state) => {
            state.fetchingDocuments = initialState.fetchingDocuments;
        },
        getIsValidDocumentsRequest: (state) => {
            state.valid.fetching = "loading";
        },
        getIsValidDocumentsSuccess: (state, action) => {
            state.valid.fetching = "success";
            state.valid.data = action.payload;
        },
        getIsValidDocumentsFailure: (state, action) => {
            state.valid.fetching = "failure";
            state.valid.error = action.payload;
        },
        cleanIsValidDocuments: (state) => {
            state.valid.fetching = initialState.valid.fetching;
        },
        postDocumentImageRequest: (state) => {
            state.fetchingPostDocumentImage = "loading";
        },
        postDocumentImageSuccess: (state, action) => {
            state.fetchingPostDocumentImage = "success";
            state.documents = generateNewDocumentObject(action.payload.type, state.documents, action.payload.data);
        },
        postDocumentImageFailure: (state, action) => {
            state.fetchingPostDocumentImage = "failure";
            state.documents = generateNewDocumentObject(action.payload.type, state.documents, action.payload.data);
        },
        cleanPostDocumentImage: (state) => {
            state.fetchingPostDocumentImage = initialState.fetchingPostDocumentImage;
        },
        postDocumentsRequest: (state) => {
            state.fetchingPostDocuments = "loading";
        },
        postDocumentsSuccess: (state, action) => {
            state.fetchingPostDocuments = "success";
            state.documents = generateNewDocumentObject(action.payload.type, state.documents, action.payload.data)
        },
        postDocumentsFailure: (state) => {
            state.fetchingPostDocuments = "failure";
        },
        cleanPostDocuments: (state) => {
            state.fetchingPostDocuments = initialState.fetchingPostDocuments;
        },
        deleteDocumentImageRequest: (state) => {
            state.fetchingDeleteDocumentImage = "loading";
        },
        deleteDocumentImageSuccess: (state, action) => {
            state.fetchingDeleteDocumentImage = "success";
            state.documents = filterDocumentObject(action.payload.type, state.documents, action.payload.data);
        },
        deleteDocumentImageFailure: (state, action) => {
            state.fetchingDeleteDocumentImage = "failure";
            state.documents = restoreDocumentObject(action.payload.type, state.documents, action.payload.data);
        },
        cleanDeleteDocumentImage: (state) => {
            state.fetchingDeleteDocumentImage = initialState.fetchingDeleteDocumentImage;
        },
        deleteDocumentRequest: (state) => {
            state.fetchingDeleteDocument = "loading";
        },
        deleteDocumentSuccess: (state, action) => {
            state.fetchingDeleteDocument = "success";
            state.documents = filterDocumentObject(action.payload.type, state.documents, action.payload.data);
        },
        deleteDocumentFailure: (state, action) => {
            state.fetchingDeleteDocument = "failure";
            state.documents = restoreDocumentObject(action.payload.type, state.documents, action.payload.data);
        },
        cleanDeleteDocument: (state) => {
            state.fetchingDeleteDocument = initialState.fetchingDeleteDocument;
        }
    },
});

export const {
    setDocuments,
    getDocumentsTypesRequest,
    getDocumentsTypesSuccess,
    getDocumentsTypesFailure,
    cleanGetDocumentsTypes,
    getDocumentsRequest,
    getDocumentsSuccess,
    getDocumentsFailure,
    cleanGetDocuments,
    getIsValidDocumentsRequest,
    getIsValidDocumentsSuccess,
    getIsValidDocumentsFailure,
    cleanIsValidDocuments,
    postDocumentImageRequest,
    postDocumentImageSuccess,
    postDocumentImageFailure,
    cleanPostDocumentImage,
    postDocumentsRequest,
    postDocumentsSuccess,
    postDocumentsFailure,
    cleanPostDocuments,
    deleteDocumentImageRequest,
    deleteDocumentImageSuccess,
    deleteDocumentImageFailure,
    cleanDeleteDocumentImage,
    deleteDocumentRequest,
    deleteDocumentSuccess,
    deleteDocumentFailure,
    cleanDeleteDocument
} =
    documentsSlice.actions;

export default documentsSlice.reducer;
