import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import { ProfileCardProps } from "../../interfaces/components";
import BreadCrumbs from "../BreadCrumbs";
import styles from "./profileCard.module.scss";

const ProfileCard = ({
    children, 
    className, 
    breadCrumbs,
    rightOptions
}: ProfileCardProps) => {
    return(
        <div className={styles.profileCardContainer}>
            <div className={styles.breadCumbsContainer}>
                {
                    breadCrumbs && <BreadCrumbs options={breadCrumbs} />
                }
                {
                    rightOptions && rightOptions
                }
            </div>
            <div className={`${styles.children} ${className}`}>
                {children}
            </div>
        </div>
    )   
};

export default ProfileCard;