export const profileRole = {
    "admin": [
        "admin", 
        "admin-dealership",
        "admin-branch"
    ],
    "seller": [
        "seller"
    ],
    "agent": [
        "agent"
    ],
    "user": [
        "final-user"
    ]
};

export const roleRestrictedRoutes = {
    "admin": [
        "my_account",
        "my_buys"
    ],
    "seller": [
        "sellers",
        "my_buys",
        "documentation"
    ],
    "agent": [
        "sellers",
        "my_schedules"
    ],
    "user": [
        "sellers",
        "my_schedules",
        "signers",
        "reports"
    ]
};