import { addPointsInText } from "../../helpers/app";
import { SuggestedPriceProps } from "../../interfaces/components";
import styles from "./suggestedPrices.module.scss";

const SuggestedPrices = ({lowPrice, mediumPrice, highPrice}: SuggestedPriceProps) => {
    return(
        <div className={styles.suggestedPricesContainer}>
            <div className={styles.box}>
                <p>Min.</p>
                <p>${addPointsInText(lowPrice) ?? ""}</p>
            </div>
            <div className={`${styles.box} ${styles.bigBox}`}>
                <p>Valor sugerido</p>
                <p>${addPointsInText(mediumPrice) ?? ""}</p>
            </div>
            <div className={styles.box}>
                <p>Max.</p>
                <p>${addPointsInText(highPrice) ?? ""}</p>
            </div>
        </div>
    )
};

export default SuggestedPrices;