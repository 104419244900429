import { createSlice } from "@reduxjs/toolkit";
import { ProfileStateProps } from "../interfaces/profile";

const initialState: ProfileStateProps = {
    myPublications: {
        fetching: "unknown",
        data: [],
        total: 0,
        filters: {
            fetching: "unknown",
            data: [],
            text: "",
            states: [],
            branches: [],
            years: [],
            sellers: []
        }
    },
    fetchingMyBuys: "unknown",
    myBuys: null,
    totalBuys: 0,
    myBuy: {
        fetching: "unknown",
        data: [],
        error: ""
    },
    favorites: {
        fetching: "unknown",
        data: [],
        total: 0,
        likedFetching: "unknown"
    },
    regions: {
        fetching: "unknown",
        data: [],
    },
    cities: {
        fetching: "unknown",
        data: [],
    },
    communes: {
        fetching: "unknown",
        data: [],
    },
    concessionary: {
        fetching: "unknown",
        data: [],
    },
    publication: null,
    fetchingPostDocument: "unknown",
    payments: {
        fetching: "unknown",
        data: [],
        error: "",
        payment: {
            fetching: "unknown",
            data: [],
            error: "",
        },
        document: {
            fetching: "unknown",
            data: [],
            error: "",
        },
        creditData: {
            fetching: "unknown",
            data: [],
            error: "",
        }
    },
    status: {
        fetching: "unknown",
        data: []
    },
    offerSelected: null,
    myOffers: {
        fetching: "unknown",
        data: [],
        error: ""
    },
    changePassword: {
        fetching: "unknown",
        error: false
    },
};

const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        getMyPublicationsRequest: (state) => {
            state.myPublications.fetching = "loading";
        },
        getMyPublicationsSuccess: (state, action) => {
            state.myPublications.fetching = "success";
            state.myPublications.data = action.payload.publications;
            state.myPublications.total = action.payload.total ? action.payload.total : state.myPublications.total;
        },
        getMyPublicationsFailure: (state) => {
            state.myPublications.fetching = "failure";
        },
        cleanMyPublications: (state) => {
            state.myPublications.fetching = initialState.myPublications.fetching;
            state.myPublications.data = initialState.myPublications.data;
        },
        getFilterMyPublicationsRequest: (state) => {
            state.myPublications.filters.fetching = "loading";
        },
        getFilterMyPublicationsSuccess: (state, action) => {
            state.myPublications.filters.fetching = "success";
            state.myPublications.filters.data = action.payload.publications;
        },
        getFilterMyPublicationsFailure: (state) => {
            state.myPublications.filters.fetching = "failure";
        },
        cleanFilterMyPublications: (state) => {
            state.myPublications.filters.fetching = initialState.myPublications.filters.fetching;
        },
        setSearchMyPublications: (state, action) => {
            state.myPublications.filters.text = action.payload;
        },
        setStatesMyPublications: (state, action) => {
            state.myPublications.filters.states = action.payload;
        },
        setBranchesMyPublications: (state, action) => {
            state.myPublications.filters.branches = action.payload;
        },
        setYearsMyPublications: (state, action) => {
            state.myPublications.filters.years = action.payload;
        },
        setSellersMyPublications: (state, action) => {
            state.myPublications.filters.sellers = action.payload;
        },
        cleanDataFiltersMyPublications: (state) => {
            state.myPublications.filters = initialState.myPublications.filters;
        },
        getMyBuysRequest: (state) => {
            state.fetchingMyBuys = "loading";
        },
        getMyBuysSuccess: (state, action) => {
            state.fetchingMyBuys = "success";
            state.myBuys = action.payload.buys;
            state.totalBuys = action.payload.total ? action.payload.total : state.totalBuys;
        },
        getMyBuysFailure: (state) => {
            state.fetchingMyBuys = "failure";
        },
        cleanMyBuys: (state) => {
            state.fetchingMyBuys = initialState.fetchingMyBuys;
        },
        getMyBuyRequest: (state) => {
            state.myBuy.fetching = "loading";
        },
        getMyBuySuccess: (state, action) => {
            state.myBuy.fetching = "success";
            state.myBuy.data = action.payload;
        },
        getMyBuyFailure: (state, action) => {
            state.myBuy.fetching = "failure";
            state.myBuy.error = action.payload;
        },
        cleanMyBuy: (state) => {
            state.myBuy.fetching = initialState.myBuy.fetching;
        },
        getRegionsRequest: (state) => {
            state.regions.fetching = "loading";
        },
        getRegionsSuccess: (state, action) => {
            state.regions.fetching = "success";
            state.regions.data = action.payload;
        },
        getRegionsFailure: (state) => {
            state.regions.fetching = "failure";
        },
        getCitiesRequest: (state) => {
            state.cities.fetching = "loading";
        },
        getCitiesSuccess: (state, action) => {
            state.cities.fetching = "success";
            state.cities.data = action.payload;
        },
        getCitiesFailure: (state) => {
            state.cities.fetching = "failure";
        },
        getCommunesRequest: (state) => {
            state.communes.fetching = "loading";
        },
        getCommunesSuccess: (state, action) => {
            state.communes.fetching = "success";
            state.communes.data = action.payload;
        },
        getCommunesFailure: (state) => {
            state.communes.fetching = "failure";
        },
        getFavoritesRequest: (state) => {
            state.favorites.fetching = "loading";
        },
        getFavoritesSuccess: (state, action) => {
            state.favorites.fetching = "success";
            state.favorites.data = action.payload?.favorites;
            state.favorites.total = action.payload?.total;
        },
        setFavorites: (state, action) => {
            state.favorites.data = action.payload;
        },
        getFavoritesFailure: (state) => {
            state.favorites.fetching = "failure";
        },
        cleanFavorites: (state) => {
            state.favorites.fetching = initialState.favorites.fetching;
        },
        likePublicationRequest: (state) => {
            state.favorites.likedFetching = "loading";
        },
        likePublicationSuccess: (state) => {
            state.favorites.likedFetching = "success";
        },
        likePublicationFailure: (state) => {
            state.favorites.likedFetching = "failure";
        },
        dislikePublicationRequest: (state) => {
            state.favorites.likedFetching = "loading";
        },
        dislikePublicationSuccess: (state) => {
            state.favorites.likedFetching = "success";
        },
        dislikePublicationFailure: (state) => {
            state.favorites.likedFetching = "failure";
        },
        cleanLikePublication: (state) => {
            state.favorites.likedFetching = initialState.favorites.likedFetching;
        },
        setPublication: (state, action) => {
            state.publication = action.payload;
        },
        getCreditDataRequest: (state) => {
            state.payments.creditData.fetching = "loading";
        },
        getCreditDataSuccess: (state, action) => {
            state.payments.creditData.fetching = "success";
            state.payments.creditData.data = action.payload;
        },
        getCreditDataFailure: (state, action) => {
            state.payments.creditData.fetching = "failure";
            state.payments.creditData.error = action.payload;
        },
        cleanGetCreditData: (state) => {
            state.payments.creditData.fetching = initialState.payments.creditData.fetching;
        },
        getPaymentsRequest: (state) => {
            state.payments.fetching = "loading";
        },
        getPaymentsSuccess: (state, action) => {
            state.payments.fetching = "success";
            state.payments.data = action.payload;
        },
        getPaymentsFailure: (state, action) => {
            state.payments.fetching = "failure";
            state.payments.fetching.error = action.payload;
        },
        cleanGetPayments: (state) => {
            state.payments.fetching = initialState.payments.fetching;
        },
        postPaymentDocumentRequest: (state) => {
            state.payments.document.fetching = "loading";
        },
        postPaymentDocumentSuccess: (state, action) => {
            state.payments.document.fetching = "success";
            state.payments.document.data = action.payload;
        },
        postPaymentDocumentFailure: (state, action) => {
            state.payments.document.fetching = "failure";
            state.payments.document.error = action.payload;
        },
        cleanPostPaymentDocument: (state) => {
            state.payments.document.fetching = initialState.payments.document.fetching;
        },
        postPaymentRequest: (state) => {
            state.payments.payment.fetching = "loading";
        },
        postPaymentSuccess: (state, action) => {
            state.payments.payment.fetching = "success";
            state.payments.payment.data = action.payload;
        },
        postPaymentFailure: (state, action) => {
            state.payments.payment.fetching = "failure";
            state.payments.payment.error = action.payload;
        },
        cleanPostPayment: (state) => {
            state.payments.payment.fetching = initialState.payments.payment.fetching;
        },
        getStatusPublicationRequest: (state) => {
            state.status.fetching = "loading";
        },
        getStatusPublicationSuccess: (state, action) => {
            state.status.fetching = "success";
            state.status.data = action.payload;
        },
        getStatusPublicationFailure: (state) => {
            state.status.fetching = "failure";
        },
        cleanStatusPublication: (state) => {
            state.status.fetching = initialState.status.fetching;
        },
        setOfferSelected: (state, action) => {
            state.offerSelected = action.payload;
        },
        getMyOffersRequest: (state) => {
            state.myOffers.fetching = "loading";
        },
        getMyOffersSuccess: (state, action) => {
            state.myOffers.fetching = "success";
            state.myOffers.data = action.payload;
        },
        getMyOffersFailure: (state, action) => {
            state.myOffers.fetching = "failure";
            state.myOffers.error = action.payload;
        },
        cleanGetMyOffers: (state, action) => {
            state.myOffers.fetching = initialState.myOffers.fetching;
        },
        cleanSettingsReducer: (state, action) => {
            state.changePassword.fetching = initialState.changePassword.fetching;
        },
        changePasswordRequest: (state) => {
            state.changePassword.fetching = "loading";
            state.changePassword.error = initialState.changePassword.error;
        },
        changePasswordSuccess: (state) => {
            state.changePassword.fetching = "success";
            state.changePassword.error = initialState.changePassword.error;
        },
        changePasswordFailure: (state, action) => {
            state.changePassword.fetching = "failure";
            state.changePassword.error = action.payload;
        },
    },
});

export const {
    getMyBuysRequest,
    getMyBuysSuccess,
    getMyBuysFailure,
    getMyPublicationsRequest,
    getMyPublicationsSuccess,
    getMyPublicationsFailure,
    cleanMyBuys,
    getMyBuyRequest,
    getMyBuySuccess,
    getMyBuyFailure,
    cleanMyBuy,
    cleanMyPublications,
    getRegionsRequest,
    getRegionsSuccess,
    getRegionsFailure,
    getCitiesRequest,
    getCitiesSuccess,
    getCitiesFailure,
    getCommunesRequest,
    getCommunesSuccess,
    getCommunesFailure,
    getFavoritesRequest,
    getFavoritesSuccess,
    getFavoritesFailure,
    cleanFavorites,
    setFavorites,
    likePublicationRequest,
    likePublicationSuccess,
    likePublicationFailure,
    dislikePublicationRequest,
    dislikePublicationSuccess,
    dislikePublicationFailure,
    cleanLikePublication,
    setPublication,
    getCreditDataRequest,
    getCreditDataSuccess,
    getCreditDataFailure,
    cleanGetCreditData,
    getPaymentsRequest,
    getPaymentsSuccess,
    getPaymentsFailure,
    cleanGetPayments,
    postPaymentDocumentRequest,
    postPaymentDocumentSuccess,
    postPaymentDocumentFailure,
    cleanPostPaymentDocument,
    postPaymentRequest,
    postPaymentSuccess,
    postPaymentFailure,
    cleanPostPayment,
    getFilterMyPublicationsRequest,
    getFilterMyPublicationsSuccess,
    getFilterMyPublicationsFailure,
    cleanFilterMyPublications,
    cleanDataFiltersMyPublications,
    getStatusPublicationRequest,
    getStatusPublicationSuccess,
    getStatusPublicationFailure,
    cleanStatusPublication,
    setSearchMyPublications,
    setStatesMyPublications,
    setBranchesMyPublications,
    setYearsMyPublications,
    setSellersMyPublications,
    setOfferSelected,
    getMyOffersRequest,
    getMyOffersSuccess,
    getMyOffersFailure,
    cleanGetMyOffers,
    changePasswordRequest,
    changePasswordSuccess,
    changePasswordFailure,
    cleanSettingsReducer
} =
    profileSlice.actions;

export default profileSlice.reducer;
