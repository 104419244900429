import { RouteObject } from "../../interfaces/navigation";
import React from "react";

const EntryPoint = React.lazy(() => import("../../containers/Home/HomeContainer"));

export const homeRoutes: RouteObject[] = [
    {
        title: "Home",
        path: "/",
        component: <EntryPoint/>
    }
];