import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { CarDetailContainerProps } from "../../interfaces/containers";
import CarDetail from "../../pages/Sell/CarDetail";
import { getVersions, setColor, setFuel, setVersion, setType, setTransmission, setDoors } from "../../actions/salePost";

function mapStateToProps(state: CarDetailContainerProps) {
    return{
        color: state.salePost.color,
        fuel: state.salePost.fuel,
        version: state.salePost.version,
        versions: state.salePost.versions,
        fetchingVersions: state.salePost.fetchingVersions,
        models: state.salePost.models,
        year: state.salePost.years.selected,
        plate: state.salePost.plate,
        isFoundPlate: state.salePost.isFoundPlate,
        vehicleType: state.salePost.vehicle_type,
        transmission: state.salePost.transmission,
        doors: state.salePost.doors
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        setType,
        setTransmission,
        setVersion,
        setFuel,
        setColor,
        getVersions,
        setDoors
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(CarDetail);