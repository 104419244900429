import { connect } from 'react-redux';
import { Dispatch, AnyAction, bindActionCreators } from 'redux';
import { AuthContainerProps } from '../../interfaces/containers';
import RefreshToken from '../../components/RefreshToken'
import { refreshTokenRequest } from '../../actions/auth';

function mapStateToProps(state: AuthContainerProps) {
    return {
        expirationTime: state.auth.expirationTime,
        refreshType: state.auth.refreshType
    };
};
const mapDispatchToProps = (dispatch: Dispatch<AnyAction> ) => {
    return bindActionCreators({
        refreshTokenRequest
    }, dispatch);
};
export default connect( mapStateToProps, mapDispatchToProps )(RefreshToken);