import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { CounterOfferSidebarContainerProps } from "../../interfaces/containers";
import EntryPoint from "../../components/CounterOfferSidebar";
import { 
    getPublication,
    cleanPublication,
    getOffersPublication,
    getCounterOffers, 
    cleanGetCounterOffers, 
    postCounterOffers, 
    cleanPostCounterOffers, 
    putCounterOffers, 
    cleanPutCounterOffers,
    putOffersPublication,
    cleanOfferPut
} from "../../actions/publication";
function mapStateToProps(state: CounterOfferSidebarContainerProps) {
    return{
        publication: state.publication,
        counterOffers: state.publication.counterOffers
    }
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        getPublication,
        cleanPublication,
        getOffersPublication,
        getCounterOffers, 
        cleanGetCounterOffers, 
        postCounterOffers, 
        cleanPostCounterOffers, 
        putCounterOffers, 
        cleanPutCounterOffers,
        putOffersPublication,
        cleanOfferPut
    }, dispatch)
};
export default connect(mapStateToProps, mapDispatchToProps)(EntryPoint);