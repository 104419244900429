import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { ProtectedRoute } from "../routes/ProtectedRoute";
import { AuthContainerProps } from "../interfaces/containers";

function mapStateToProps(state: AuthContainerProps) {
    return{
        user: state.auth.user
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);