import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { ConfortContainerProps } from "../../interfaces/containers";
import Confort from "../../pages/Sell/Confort";
import { setConfort } from "../../actions/salePost";

function mapStateToProps(state: ConfortContainerProps) {
    return{
        confort: state.salePost.confort,
        plate: state.salePost.plate,
        isFoundPlate: state.salePost.isFoundPlate
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        setConfort
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Confort);