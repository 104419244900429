import styles from "./discountLinzer.module.scss";
import { addPointsInText, capitalize } from "../../helpers/app";
import { DiscountLinzerProps } from "../../interfaces/components";
import Modal from "../Modal/Modal";
import ButtonShared from "../Button";
import { useEffect, useState } from "react";
import { useToast } from "../../helpers/hooks";
import { CircularProgress } from "@mui/material";

const DiscountLinzer = ({ 
    open,
    setOpen,
    type,
    discount,
    putDiscount, 
    cleanPutDiscount,
    getArticlesFromPublication,
    publication
 }: DiscountLinzerProps) => {
    const toast = useToast();
    const [loading, setLoading] = useState<boolean>(true);
    const [statusSelected, setStatusSelected] = useState<string>("");
    const [showConfirmationSection, setShowConfirmationSection] = useState<any>({absolute: true, show: false});
    const [showDiscountSection, setShowDiscountSection] = useState<any>({absolute: false, show: true});

    const onPress = (status: string) => {
        setStatusSelected(status);
        setShowDiscountSection({...showDiscountSection, show: false});
        setTimeout(() => setShowDiscountSection({show: false, absolute: true}), 500);
        setTimeout(() => setShowConfirmationSection({absolute: false, show: true}), 500);
    }

    const onBack = () => {
        if(discount?.put?.fetching === "loading") return;
        setShowConfirmationSection({...showConfirmationSection, show: false});
        setTimeout(() => setShowConfirmationSection({show: false, absolute: true}), 500);
        setTimeout(() => setShowDiscountSection({absolute: false, show: true}), 500);
    }

    const onClose = () => {
        setStatusSelected("");
        setShowConfirmationSection({absolute: true, show: false});
        setShowDiscountSection({absolute: false, show: true});
        setOpen({open: false, type: type});
    }

    const onConfirmation = () => {
        putDiscount(discount?.get?.data?._id, statusSelected);
    }

    useEffect(() => {
        if(publication?.fetching === "success"){
            setLoading(false);
        }
    }, [publication?.fetching]);

    useEffect(() => {
        if(discount?.get?.fetching === "loading"){
            setLoading(true);
        }
        if(open && discount?.get?.fetching === "success"){
            getArticlesFromPublication(discount?.get?.data?.publication);
        }
    }, [discount?.get?.fetching]);

    useEffect(() => {
        if(discount?.put?.fetching === "failure"){
            if(discount?.put?.error === "DISCOUNT_ALREADY_ANSWERED"){
                toast("El descuento ya fue aceptado");
            }else{
                toast("Ha ocurrido un error al actualizar el descuento");
            }
            cleanPutDiscount();
        }
        if(discount?.put?.fetching === "success"){
            onClose();
            cleanPutDiscount();
        }
    }, [discount?.put?.fetching]);

    return (
        <>
            <Modal open={open} className={styles.discountLinzerModal} onClose={onClose}>
                <div className={`${styles.confirmation} ${styles.content} ${showConfirmationSection?.show && styles.show} ${showConfirmationSection?.absolute && styles.absolute}`}>
                    <p className={`${styles.text} ${styles.message}`}>
                        {
                            statusSelected === "confirmationAccepted"
                            ?
                                '¿Está seguro que quiere aceptar este descuento?'
                            :
                                '¿Está seguro que quiere rechazar este descuento?'
                        }
                    </p>
                    <div className={styles.buttons}>
                        <ButtonShared 
                            onPress={onBack}
                            title="Volver"
                            fourtuary
                            size="small"
                        />
                        <ButtonShared 
                            onPress={onConfirmation}
                            title="Aceptar" 
                            secondary 
                            size="small" 
                            loading={discount?.put?.fetching === "loading"}
                        />
                    </div>
                </div>
                <div className={`${styles.discount} ${styles.content} ${showDiscountSection?.show && styles.show} ${showDiscountSection?.absolute && styles.absolute}`}>
                    {
                         loading
                         ? 
                             <div className={styles.loaderDiscount} id="loaderDiscount">
                                 <CircularProgress 
                                     size={styles.loader}
                                     classes={{
                                         colorPrimary: styles.loader
                                     }}
                                 />
                                 <p>Cargando descuento</p>
                             </div>
                         :
                            <>
                                <p className={`${styles.text} ${styles.message}`}>
                                    {
                                        type === "seller"
                                        ?
                                            `El comprador de tu ${capitalize(publication?.data?.brand)} ${capitalize(publication?.data?.model)} ${publication?.data?.year} realizó la siguiente oferta`
                                        :
                                            `Acabas de realizar la siguiente oferta para comprar ${capitalize(publication?.data?.brand)} ${capitalize(publication?.data?.model)} ${publication?.data?.year}`
                                    }
                                </p>
                                <p className={`${styles.text} ${styles.title}`}>Valor ofertado</p>
                                <p className={`${styles.text} ${styles.value}`}>${addPointsInText("19000000")}</p>
                                <p className={`${styles.text} ${styles.subTitle}`}>
                                    <span>${addPointsInText(discount?.get?.data?.total_amount - discount?.get?.data?.discount)} menos que el</span>
                                    <span>valor original</span>
                                </p>
                                <div className={styles.buttons}>
                                    <ButtonShared 
                                        onPress={() => onPress("rejected")}
                                        title="Rechazar"
                                        fourtuary
                                        size="small"
                                    />
                                    <ButtonShared 
                                        onPress={() => onPress("accepted")}
                                        title="Aceptar" 
                                        secondary 
                                        size="small" 
                                    />
                                </div>
                            </>
                    }
                </div>
            </Modal>
        </>
    )
}
export default DiscountLinzer;