import { Year } from "../interfaces/salePost";

const currentYear = new Date().getFullYear();

const yearOptions: string[] = [];
for (let index = currentYear; index > 1985; index--) {
    yearOptions.push(index.toString())
}

export const formatYears: Year[] = yearOptions.map((opt, index) => (
    { id: index.toString(), label: opt}
))

export const optionsPayd = [
    {
        label: "SI",
        value: "SI"
    },
    {
        label: "NO",
        value: "NO"
    }
];

export const optionsNegotiate = [
    {
        label: "SI",
        value: "SI"
    },
    {
        label: "NO",
        value: "NO"
    }
];

export const TRANSMISSION_TYPES = ["Mecánica", "Automático"];

export const TYPE_TYPES = [
    {
        id: 0,
        value: "Sedan"
    },
    {
        id: 1,
        value: "Hatchback"
    },
    {
        id: 2,
        value: "SUV"
    },
    {
        id: 3,
        value: "Coupé"
    },
    {
        id: 5,
        value: "Cabina Simple"
    },
    {
        id: 6,
        value: "Station Wagon"
    },
    {
        id: 7,
        value: "Otro"
    }
];

export const DOORS_TYPES = [
    {
        label: '2',
        id: '2'
    },
    {
        label: '3',
        id: '3'
    },
    {
        label: '4',
        id: '4'
    },
    {
        label: '5',
        id: '5'
    },
];