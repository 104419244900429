import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { MyPublicationDetailContainerProps } from "../../../../interfaces/containers";
import EntryPoint from "../../../../pages/Profile/MyPublications/Detail";
import { getArticlesFromPublication, getPublication, cleanAll as cleanAllPublication } from "../../../../actions/publication";

function mapStateToProps(state: MyPublicationDetailContainerProps) {
    return{
        user:state.auth.user,
        publication: state.publication
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        getPublication,
        getArticlesFromPublication,
        cleanAllPublication
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(EntryPoint);