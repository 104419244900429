import ModalMaterial from '@mui/material/Modal';
import { ModalProps } from '../../interfaces/components';
import styles from "./modal.module.scss";
import { mdiClose } from '@mdi/js';
import { IconButton } from '@mui/material';
import Icon from '@mdi/react';

const Modal = ({ variant = "black", open, onClose = () => {}, children, className }: ModalProps) => {
    return(
        <ModalMaterial open={open} onClose={() => onClose()}>
            {
                variant === "close"
                ?   
                    <div className={`${styles.modalContainer} ${styles.variantClose} ${styles.white} ${className}`}>
                        <div className={styles.header}>
                            <IconButton aria-label="Eliminar" className={styles.close} onClick={() => onClose()}>
                                <Icon path={mdiClose} title="Eliminar" className={styles.icon} />
                            </IconButton>
                        </div>
                        {children}
                    </div>
                :
                    <div className={`${styles.modalContainer} ${className} ${(variant === "black" ? styles.black : styles.white)}`}>
                        {children}
                    </div>
            }
        </ModalMaterial>
    )
}

export default Modal;