import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { MyPublicationInspectionContainerProps } from "../../../interfaces/containers";
import EntryPoint from "../../../components/Inspection";
import { getInspectionsFiles, cleanInspectionFiles, getLinzerAssigned, cleanInspection } from "../../../actions/publication";

function mapStateToProps(state: MyPublicationInspectionContainerProps) {
    return{
        inspection: state.publication.inspection,
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        getInspectionsFiles,
        cleanInspectionFiles,
        cleanInspection,
        getLinzerAssigned
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(EntryPoint);