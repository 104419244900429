import { getYears } from "../helpers/app";
import { images } from "./images";

export const YEARS = getYears();

export const CONFORT_TYPES = [
    {
        name: "Climatizador automático",
        codeName: "air_filter",
        image: images.airFilter,
        selected: false
    },
    {
        name: "Velocidad crucero",
        codeName: "cruice_speed",
        image: images.cruiceSpeed,
        selected: false
    },
    {
        name: "Cristales eléctricos",
        codeName: "electric_crystals",
        image: images.electricCrystals,
        selected: false
    },
    {
        name: "Sensor de estacionamiento",
        codeName: "parking_sensor",
        image: images.parkingSensor,
        selected: false
    },
    {
        name: "Computadora a bordo",
        codeName: "on_board_computer",
        image: images.onBoardComputer,
        selected: false
    },
    {
        name: "Sistema de audio HI-Fi",
        codeName: "audio_hifi",
        image: images.audioHifi,
        selected: false
    },
    {
        name: "Conectividad BT/Wifi",
        codeName: "bt_wifi",
        image: images.bluetoothWifi,
        selected: false
    },
    {
        name: "Asientos de cuero",
        codeName: "leather_seats",
        image: images.leatherSeats,
        selected: false
    }
];

export const MILEAGE_TYPES = [
    {
        name: "0 a 45.000 km",
        value: "*,45000"
    },
    {
        name: "45.000 km a 75.000 km",
        value: "45000,75000"
    },
    {
        name: "75.000 km a 100.000 km",
        value: "75000,100000"
    },
    {
        name: "más de 100.000 km",
        value: "100000,*"
    },
];

export const CARACTERISTICS = [
    {
        image: images.carType,
        title: "tipo",
        type: "type"
    },
    {
        image: images.color,
        title: "color",
        type: "color"
    },
    {
        image: images.transmission,
        title: "transmisión",
        type: "transmission"
    },
    {
        image: images.fuel,
        title: "combustible",
        type: "fuel"
    }
]

export const COLORS = [
    "blanco",
    "negro",
    "gris",
    "plata",
    "beige",
    "dorado",
    "verde",
    "azul",
    "morado",
    "rojo",
    "otro"
];

export const FUEL_TYPES = [
    "bencina",
    "diesel",
    "hibrido",
    "electrico"
];

export const TYPE_TYPES = [
    "suv",
    "deportivo",
    "hatchback",
    "sedan",
    "pickup",
    "van"
];

export const VEHICLE_TYPES = [
    "sedan",
    "hatchback",
    "suv",
    "coupé",
    "cabina simple",
    "doblecabina",
    "station wagon",
    "otro"
];

export const STATES = [
    "Publicado",
    "Visita de Linze",
    "Pago",
    "Inspección",
    "Entregado",
    "Math",
    "Vendido"
]

export const DOORS = [2, 3, 4, 5];