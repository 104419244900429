import styles from "./loaderCardDetail.module.scss"
import { LoaderCardDetailProps } from "../../../interfaces/components";
import { Skeleton } from '@mui/material';

const LoaderCardDetail = ({
    type = "full_screen",
    children,
    extraDetail,
}: LoaderCardDetailProps) => {

    return (
        <>
            {type === "full_screen" &&
                <>
                    <div className={styles.cardContainer}>
                        <div className={styles.noChildren}>
                            <div className={styles.cardTitleContainer}>
                                <Skeleton animation="wave" variant="text" className={styles.cardTitle} />
                                <Skeleton animation="wave" variant="circular" className={styles.showDetail} />
                            </div>
                            <Skeleton animation="wave" variant="text" className={styles.priceTitle} />
                            <Skeleton animation="wave" variant="text" className={styles.price} />
                            {extraDetail}
                        </div>
                        <div className={styles.separator} />
                        <div className={styles.children}>{children}</div>
                    </div>
                    <Skeleton animation="wave" variant="rounded" className={styles.carImage} />
                </>
            }
            {type === "right" &&
                <>
                    <div className={`${styles.children} ${styles.right}`}>{children}</div>
                    <Skeleton animation="wave" variant="rounded" className={styles.carImage} />
                    <div className={styles.bottomDetail}>
                        <div className={styles.cardTitleContainer}>
                            <Skeleton animation="wave" variant="text" className={styles.cardTitle} />
                            <Skeleton animation="wave" variant="circular" className={styles.showDetail} />
                        </div>
                        <Skeleton animation="wave" variant="text" className={styles.priceTitle} />
                        <Skeleton animation="wave" variant="text" className={styles.price} />
                        {extraDetail}
                    </div>
                </>
            }
            {type === "onlyPicture" &&
                <>
                    <div className={`${styles.onlyPicture} ${styles.children}`}>{children}</div>
                    <div className={styles.rightSide}>
                        <Skeleton animation="wave" variant="rounded" className={styles.carImage} />
                        {extraDetail}
                    </div>
                </>
            }
        </>
    )
}

export default LoaderCardDetail;