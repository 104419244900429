import { useEffect, useState } from "react";
import Button from "../../../../components/Button";
import SelectShared from "../../../../components/Select";
import TextFieldShared from "../../../../components/TextField";
import { ACCOUNT_TYPES } from "../../../../constants/profile";
import { formatRut } from "../../../../helpers/app";
import { useToast } from "../../../../helpers/hooks";
import { BankDataProps } from "../../../../interfaces/components";
import styles from "./bankData.module.scss";

const BankData = ({
    editUserRequest,
    user,
    fetchingEdit,
    errorEdit,
    getBanks,
    banks,
    fetchingBanks,
    getIsValidDocuments,
    fetchingTypes
}: BankDataProps) => {
    const toast = useToast();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [identifier, setIdentifier] = useState("");
    const [bankName, setBankName] = useState<any>({value: "", error: ""});
    const [accountType, setAccountType] = useState<any>({value: "", error: ""});
    const [accountNumber, setAccountNumber] = useState<any>({value: 0, error: ""});

    const handleChangeBankName = (name: string) => {
        setBankName({...bankName, value: name});
    }

    const handleChangeAccountType = (id: string) => {
        setAccountType({...accountType, value: id});
    }

    const validateBankData = () => {
        const isValidName = banks.find((bank) => bank.name === bankName?.value);
        if(!isValidName){
            setBankName({...bankName, error: "Nombre del banco incorrecto"});
            return false;
        }
        const isValidType = ACCOUNT_TYPES.find((account) => account.value === accountType?.value);
        if(!isValidType){
            setAccountType({...accountType, error: "Tipo de cuenta incorrecto"});
            return false;
        }
        if(accountNumber?.value === 0 || !accountNumber?.value){
            setAccountNumber({...accountNumber, error: "Debe ingresar un Número de cuenta."});
            return false;
        }
        setBankName({...bankName, error: ""});
        setAccountType({...accountType, error: ""});
        return true;
    };

    const onConfirmBankData = () => {
        const isValid = validateBankData();
        if(isValid){
            editUserRequest({
                ...user,
                bank_data: {
                    bank: bankName?.value,
                    account_number: accountNumber?.value,
                    account_type: accountType?.value
                }
            })
        }
    };

    useEffect(() => {
        if (user?.bank_data) {
            setName(`${user.name} ${user.last_name}`);
            setEmail(user.email);
            setIdentifier(formatRut(user.legal_id));
            const accountType = ACCOUNT_TYPES.find((type) => type.id === user.bank_data?.account_type);
            if (accountType) {
                setAccountType({...accountType, value: accountType.value});
            }
            setBankName({...bankName, value: user.bank_data.bank});
            setAccountNumber({...accountNumber, value: user.bank_data.account_number});
        }
    }, [user]);

    useEffect(() => {
        if(fetchingBanks === "success"){
            setBankName({value: user?.bank_data.bank ?? ""});
        }
    },[fetchingBanks]);

    useEffect(() => {
        if(fetchingEdit === "failure"){ 
            if(errorEdit === "BANK_DATA.BANK_INVALID_VALUE"){
                toast("Ha ocurrido un error con el nombre del banco");
            }
        }
        if(fetchingEdit === "success"){ 
            toast("Felicitaciones! Los cambios han sido guardados.");
            getIsValidDocuments();
        }
    },[fetchingEdit]);

    useEffect(() => {
        getBanks();
    }, []);

    return (
        <div className={styles.bankDataContainer}>
            <div className={styles.grid}>
                <TextFieldShared
                    label="Nombre y apellido"
                    name="name"
                    value={name}
                    disabled
                    className={styles.half}
                />
                <TextFieldShared
                    label="RUT"
                    name="rut"
                    value={identifier}
                    className={styles.half}
                    disabled
                />
                <TextFieldShared
                    label="E-mail"
                    name="email"
                    value={email}
                    className={styles.half}
                    disabled
                />
                <SelectShared
                    label="Nombre del Banco"
                    name="bankName"
                    value={bankName?.value}
                    onChange={handleChangeBankName}
                    className={styles.half}
                    options={banks}
                    keyValue="name"
                    loading={fetchingBanks === "loading"}
                    error={bankName?.error}
                />
                <SelectShared
                    label="Tipo de cuenta"
                    name="accountType"
                    value={accountType?.value}
                    onChange={handleChangeAccountType}
                    className={styles.half}
                    options={ACCOUNT_TYPES}
                    loading={fetchingBanks === "loading"}
                    error={accountType?.error}
                />
                <TextFieldShared
                    label="Número de cuenta"
                    name="countNumber"
                    className={styles.half}
                    value={accountNumber?.value}
                    onChange={(value: any) => setAccountNumber({...accountNumber, value: value})}
                    error={accountNumber?.error}
                />
            </div>
            <Button
                title="Guardar Cambios"
                primary
                onPress={onConfirmBankData}
                className={styles.button}
                disabled={fetchingTypes === "loading" || fetchingTypes === "failure"}
                loading={fetchingBanks === "loading" || fetchingEdit === "loading"}
            />
        </div>
    )
}
export default BankData;