import { createSlice } from '@reduxjs/toolkit';
import { NotificationsStateProps } from '../interfaces/notifications';

const initialState: NotificationsStateProps = {
    notifications: [],
    fetching: "unknown",
    fetchingRead: "unknown"
}

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        getNotificationsRequest: (state) => {
            state.fetching = "loading";
        },
        getNotificationsSuccess: (state, action) => {
            state.fetching = "success";
            state.notifications = action.payload;
        },
        getNotificationsFailure: (state) => {
            state.fetching = "failure";
        },
        putReadNotificationRequest: (state) => {
            state.fetchingRead = "loading";
        },
        putReadNotificationSuccess: (state, action) => {
            state.fetchingRead = "success";
            state.notifications = state.notifications.map(notification => {
                if (notification._id === action.payload._id) notification = action.payload;
                return notification;
            });
        },
        putReadNotificationFailure: (state) => {
            state.fetchingRead = "failure";
        },
    }
});

export const { 
    getNotificationsRequest, 
    getNotificationsSuccess, 
    getNotificationsFailure,
    putReadNotificationRequest, 
    putReadNotificationSuccess, 
    putReadNotificationFailure
} = notificationsSlice.actions;

export default notificationsSlice.reducer;