import { useEffect, useState } from "react";
import styles from './checkbox.module.scss';
import { FormControlLabel, Checkbox } from '@mui/material';
import { CheckboxSharedProps } from '../../interfaces/components';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const CheckboxShared = ({
    onPress = () => {},
    text = null,
    className,
    checked = false,
    disabled = false,
    circular = false,
    size = "normal",
    id
}: CheckboxSharedProps) => {
    const [check, setCheck] = useState<boolean>(checked);

    const handleChange = () => {
        setCheck(!check);
        if(onPress) onPress();
    }

    useEffect(() => {
        setCheck(checked);
    }, [checked]);

    return (
        <FormControlLabel 
            className={`${className} ${styles.checkbox}`}
            control={
                <Checkbox 
                    id={id}
                    checked={check}
                    defaultChecked 
                    disabled={disabled}
                    className={styles.checker}
                    onChange={handleChange}
                    icon={circular ? <RadioButtonUncheckedIcon /> : <CheckBoxOutlineBlankIcon />}
                    checkedIcon={circular ? <CheckCircleIcon /> : <CheckBoxIcon />}
                    size={size === "normal" ? "medium" : "small"}
                />
            }
            label={text}
        />
    );
};

export default CheckboxShared;