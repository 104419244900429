import styles from "./entryPoint.module.scss";

const EntryPoint = () => {
	return (
		<div className={styles.container}>
			<h1>
				<b>POLÍTICA DE PRIVACIDAD LINZECAR</b>
			</h1>
			<h2>I. GENERAL. </h2>
			<p>
				La presente Política de Privacidad tiene como propósito
				establecer e informar los términos en que se usa y como se
				protege la información personal que es proporcionada por todos
				quienes utilicen la Plataforma de LINZECAR y, viene en informar
				cómo administramos los datos personales, el derecho a la
				privacidad y cómo la ley les otorga protección a los datos
				personales.
				<br />
				<br />
				Por el solo hecho de descargar la aplicación móvil, ingresar y/o
				registrarse en la Aplicación LINZECAR, se entiende que los
				Usuarios conocen y aceptan, sin limitación alguna, los términos
				y condiciones aquí reguladas, así como también la Política de
				privacidad, las normas legales y reglamentarias de Chile que le
				son aplicables. Asimismo, se invita a hacer uso y navegar en la
				plataforma y Aplicación LINZECAR de manera responsable e
				informada.
				<br />
				<br />
				LINZECAR, comprometido con la seguridad y privacidad de los
				datos de sus Usuarios, solicita leer detenidamente esta Política
				antes de usar los servicios de LINZECAR, la cual será aplicable
				desde el momento en que el Usuario descarga la Aplicación de
				LINZECAR, crea cuenta de usuario, utiliza los servicios de
				intermediación y/o comparte con LINZECAR sus datos a través de
				la Plataforma.
				<br />
				<br />
				El Usuario debe leer, entender y aceptar todas las condiciones
				establecidas en los Términos y Condiciones y en el Aviso de
				Privacidad así como en los demás documentos incorporados a los
				mismos por referencia. La utilización del Sitio y/o los
				Servicios por parte del Usuario, implica su consentimiento
				expreso, libre, informado, específico e inequívoco a los Avisos
				de Privacidad de LINZECAR.
			</p>
			<h2>II. SERVICIOS.</h2>
			<p>
				Se entenderá por Plataforma los sitios y canales web,
				aplicaciones móviles, sitios móviles u otras propiedades en
				línea mediante las que LINZECAR ofrece sus servicios de
				intermediación.
				<br />
				<br />
				El Usuario acepta que LINZECAR no presta asesoría en materia
				legal, tributario, contable, ni en general, señala acciones a
				seguir, de tal manera que el Usuario es libre de actuar y lo
				hace bajo su propia responsabilidad. El Usuario entiende y
				acepta que alguno o algunos de los Servicios presentados en o a
				través del Portal y Aplicaciones son desarrollados y/o
				entregados por terceros.
				<br />
				<br />
				A la mayoría de los Servicios que son otorgados por o a través
				del Portal y Aplicaciones se accede a través de una clave
				secreta que ha sido proporcionada al Usuario con anterioridad.
				Es de responsabilidad exclusiva del Usuario el ingreso y el uso
				y manejo de la clave secreta, debiendo manejarla con la debida
				confidencialidad; dicha clave será considerada como firma
				electrónica para los efectos de realizar las operaciones en el
				Portal. El Usuario deberá notificar a LINZECAR cuando crea que
				su identificación, firma electrónica, cuenta y/o clave secreta
				ha sido utilizada por un tercero, y LINZECAR frente a esta
				notificación entregará al Usuario un número o código de
				recepción del aviso y la fecha y hora de su recepción, además de
				bloquear inmediatamente las mismas.
				<br />
				<br />
				<b>Enlaces a Terceros (Sitios Ligados).&nbsp;</b>
				Existe la posibilidad de que la Plataforma pudiera contener
				enlaces a otros sitios que pudieran ser de su interés. Estos
				links se incluyen con el único objeto de dar facilidad al
				Usuario en el acceso a la información disponible a través de
				internet, sin embargo, una vez que usted haga clic en estos
				enlaces y abandone nuestra página, ya no tenemos control sobre
				al sitio al que es redirigido y por lo tanto no somos
				responsables de los términos, condiciones o privacidad, ni de la
				protección de sus datos en esos otros sitios terceros. Dichos
				sitios están sujetos a sus propias políticas de privacidad por
				lo cual es recomendable que las revise para tomar conocimiento y
				confirmar que usted está de acuerdo con éstas.
			</p>
			<h2>III. CONDICIONES DE USO, ACCESO Y CONFIDENCIALIDAD.</h2>
			<p>
				Los Términos y Condiciones de LINZECAR están regulados en el
				contrato correspondiente debidamente publicado en conjunto a
				esta Política.
				<br />
				<br />
				<b>1. Condiciones de Uso:</b>&nbsp; El uso del Portal está
				condicionado a que el Usuario lo utilice de acuerdo a la
				legislación vigente, Términos y Condiciones de uso y a esta
				Políticas de Privacidad; y para acceder a ellos deberá emplear
				los procedimientos establecidos o, en su defecto, los que
				generalmente se emplean en Internet, pero, en caso alguno, podrá
				poner en riesgo el Portal, los Servicios y/o contenido del
				mismo.
				<br />
				<br />
				LINZECAR podrá, por problemas técnicos, tales como corte
				operacional, corte de energía eléctrica y/o caso fortuito o
				fuerza mayor, suspender temporalmente el suministro de estos
				servicios. Asimismo, LINZECAR podrá efectuar mantenciones a los
				sistemas que importen una suspensión temporal de la Plataforma,
				lo que será informado al Usuario por los medios que
				habitualmente emplea para estos efectos, tales como notificación
				por correo electrónico, notificaciones electrónicas u otros
				autorizados.
				<br />
				<br />
				El Usuario deberá utilizar los procedimientos y/o medios de
				seguridad, identificación e integridad que LINZECAR ha
				implementado o ponga en uso en el futuro, y que pudieren estar
				asociados a los elementos requeridos para su utilización. El
				Usuario entiende y acepta que deberá tener la diligencia y
				cuidado que las personas emplean ordinariamente en sus negocios
				propios para evitar virus, infecciones, caballos de Troya entre
				otras; para tales efectos, deberá utilizar las medidas de
				seguridad necesarias.
				<br />
				<br />
				<b>2. Condiciones de acceso:</b>
				&nbsp; Es de cargo del Usuario obtener el acceso a Internet y/o
				World Wide Web así como obtener e implementar los equipos y/o
				instrumentos que le permitan el acceso a esta red; de tal manera
				que el Usuario debe proveerse de todo lo necesario para el
				adecuado uso de la Plataforma.
				<br />
				<br />
				<b>3. Confidencialidad:</b>
				&nbsp; LINZECAR tratará los datos de carácter personal, que sean
				ingresados por el Usuario o aquellos que obran en poder de
				LINZECAR por cualquier causa, con la debida confidencialidad y
				de acuerdo a las normas vigentes. LINZECAR declara que ha tomado
				las medidas necesarias en sus instalaciones y sistemas que
				garantizan la confidencialidad de los datos ingresados por el
				Usuario al Portal.
				<br />
				<br />
				El Usuario se obliga a proporcionar información auténtica,
				cierta, verdadera, fidedigna, completa y actualizada cuando así
				sea requerido en o a través de la Plataforma.
				<br />
				<br />
				La información obtenida por o a través de la Plataforma y/o
				cualesquiera de los Servicios que por este medio se ofrece u
				otorga es para el uso personal del Usuario, obligándose éste a
				mantener la debida confidencialidad; le queda prohibida al
				Usuario la cesión, reproducción, recirculación, retransmisión,
				comercialización y/o distribución, en todo o parte, y cualquier
				forma de los contenidos del Portal.
				<br />
				<br />
				Para los efectos de la ley 19.628 Sobre Protección de Datos de
				Carácter personal el Usuario autoriza a LINZECAR a divulgar a
				las autoridades competentes o compartir exclusivamente con
				terceros con quienes suscriba alianzas o acuerdos comerciales,
				ciertos datos personales como el número de Rut, domicilio,
				actividad, correo electrónico, actividad o información
				relacionada a productos contratados con LINZECAR que no se
				encuentren sujeto a secreto bancario, cuando ello se realice con
				el propósito de facilitar el desarrollo, materialización y/o
				implementación de acciones comerciales, sean de carácter general
				o personal, cuando ello fuere necesario o conveniente a fin de
				agregar nuevos atributos o beneficios a sus usuarios o mejorar
				la calidad de prestación de servicios y/o productos de LINZECAR.
			</p>
			<h2>IV. TRATAMIENDO DE DATOS.</h2>
			<p>
				<b>1. RESPONSABLE DEL TRATAMIENTO.</b>
				<br />
				Informamos que el área encargada de recibir y tramitar todas las
				solicitudes relativas a los Datos Personales y de fomentar la
				protección de Datos Personales, es el área de Servicio de
				Atención del Usuario, a quien puede ubicar y contactar
				directamente al siguiente correo electrónico:
				contacto@linzecar.cl.
				<br />
				<br />
				<b>2. DATOS PERSONALES SUJETOS A TRATAMIENTO.</b>
				<br />
				Para llevar a cabo las finalidades descritas anteriormente,
				recabaremos para su tratamiento los siguientes datos personales,
				de los cuales usted es titular: (i) nombre completo; (ii) correo
				electrónico; (iii) número de teléfono/móvil; (iv) domicilio; (v)
				fecha y lugar de nacimiento; (vi) nacionalidad; (vii) estado
				civil; (viii) datos de identificación, contacto y laborales;
				(ix) datos patrimoniales y/o financieros; (x) datos de deuda
				vigente; y los siguientes datos de la empresa, a la cual usted
				representa: (i) razón social; (ii) domicilio; (iii) datos de
				identificación y contacto; (iv) datos patrimoniales y/o
				financieros; (v) datos de deuda vigente; (vi) datos sobre
				ingresos y egresos; y (vii) datos de los libros de compra y
				venta (los “Datos Personales”).
				<br />
				<br />
				LINZECAR NO recaba: (i) datos personales sensibles; ni (ii)
				datos de menores de edad, los servicios de LINZECAR no están
				diseñados para el uso de menores de 18 años y no recopilamos
				datos de ninguna persona menor de edad. Si tenemos conocimiento
				de que una persona es menor de 18 años y nos brindó sus datos
				personales, los borraremos inmediatamente.
				<br />
				<br />
				Los Datos Personales podrán ser recabados personalmente o a
				través de nuestra Plataforma, sitio web o APP o por cualquier
				otro medio electrónico, telefónico, video, u otra tecnología y,
				en su caso, a través de agentes o ejecutivos. Lo anterior para
				que sea posible dar seguimiento a una solicitud de creación de
				un usuario realizada por usted, brindarle nuestros servicios
				consistentes en la gestión de intermediación de compraventa de
				vehículos, su transferencia y trámites en relación, el
				otorgamiento de créditos u otros que se encuentren promocionados
				en el Sitio, de conformidad con los Términos y Condiciones del
				Sitio.
				<br />
				<br />
				LINZECAR podrá realizar los análisis, reportes, videollamadas
				y/o fotografías, realizar entrevistas presenciales o por medios
				remotos, (medios electrónicos o cualquier otra tecnología), así
				como las acciones necesarias para comprobar por sí o a través de
				terceros contratados particularmente para ello, la veracidad de
				los datos que fueron proporcionados por usted.
				<br />
				<br />
				<b>3. FINALIDADES NECESARIAS O PRIMARIAS.</b>
				<br />
				Los Datos Personales que recabamos serán utilizados para el
				cumplimiento de la relación contractual derivado de la solicitud
				y/o contratación de nuestros Servicios. Verificar, confirmar y
				validar su identidad y de la empresa a la que representa, así́
				como la veracidad de la información que proporciona como propia,
				referencias, obligados solidarios, avales, según resulte
				aplicable de acuerdo con los Servicios contratados con LINZECAR,
				asimismo, el origen y propiedad del vehículo, su estado técnico
				y legal.
				<br />
				<br />
				Entre las finalidades primarias se contempla: (i) Administrar,
				operar y dar seguimiento a los Servicios y productos que
				solicita o contrata con LINZECAR; (ii) Formalizar la relación
				jurídica entre LINZECAR y el titular de los Datos Personales;
				(iii) Incluirlo en nuestra base de datos de manera directa o a
				través de un tercero; (iv) Programar la cita presencial para la
				evaluación del vehículo; (v) Autenticar el origen legítimo y la
				propiedad del vehículo; (vi) Proporcionar al cliente / usuario
				una cotización preliminar respecto del vehículo y/o del
				financiamiento del vehículo; (vii) Gestionar la documentación
				contractual necesaria relacionada con la compraventa y/o
				financiamiento del vehículo; (viii) Elaboración y formalización
				del contrato de compraventa y/o de financiamiento; (ix) Integrar
				el expediente de compraventa del vehículo automotor y/o el
				expediente de financiamiento del vehículo; (x) Realizar el pago
				por concepto de compraventa del vehículo automotor cuando sea el
				caso; (xi) Realizar, de manera directa o encargando a
				proveedores externos el análisis de riesgo y evaluación
				crediticia en caso de que usted solicite financiamiento; (xii)
				Elaboración de perfiles de clientes, usuarios o prospectos;
				(xiii) Cumplir con las actividades complementarias de post venta
				relacionadas con la compraventa del vehículo y/o financiamiento
				del vehículo; (xiv) Procesar las solicitudes para llevar a cabo
				la facturación; (xv) Procesar las solicitudes que realice por
				medio de nuestros Medios Electrónicos; (xvi) Crear su perfil
				como usuario en la Plataforma; (xvii) Cumplir con las leyes,
				reglamentos y demás disposiciones legales aplicables; (xviii)
				Cumplir con las obligaciones derivadas de la relación jurídica;
				(xix) Atención a requerimientos legales de autoridades
				competentes; (xx) Realizar notificaciones relacionadas con la
				relación jurídica; (xxi) Difundir su participación en eventos y
				reportajes a través de medios de comunicación interna y en su
				caso, en las distintas redes sociales en las cuales nuestras
				controladoras, empresas relacionadas, filiales y/o subsidiarios
				tengan presencia; (xxii) Procesos de cobranza que pudieran
				derivar de dicha relación jurídica; (xxiii) Para llevar a cabo
				análisis de riesgo y viabilidad los productos y servicios
				ofrecidos por LINZECAR; y, (xxiv) Cumplir con regulaciones,
				políticas, medidas de seguridad y prevención de delito.
				<br />
				<br />
				<b>4. FINALIDADES NO NECESARIAS O SECUNDARIAS.</b>
				<br />
				Envío de ofertas, promociones, mercadotecnia o eventos
				especiales que puedan ser de interés del usuario. Ofrecer una
				ampliación o mejora de los servicios financieros contratados.
				Informar sobre actualizaciones de Servicios. Llevar a cabo
				inteligencia comercial, realizar investigaciones de mercado,
				darle visibilidad a su actividad en nuestros Medios
				Electrónicos, seguimiento de calidad de nuestros Medios
				Electrónicos, evaluación y mejora de nuestros Medios
				Electrónicos y enviar nuestro boletín de noticias y ofertas.
				Enviar y recibir comunicaciones a través de nuestros Medios
				Electrónicos relacionado con nuestros Servicios. El titular de
				los Datos Personales podrá negarse al tratamiento de sus datos
				para las finalidades no necesarias, enviando un correo
				electrónico a la dirección contacto@linzecar.cl en el entendido
				de que, en caso de no recibir la solicitud correspondiente, se
				entenderá por consentido el uso de su información para todas las
				finalidades antes mencionadas.
				<br />
				<br />
				<b>5. TRANSFERENCIA DE DATOS PERSONALES.</b>
				<br />
				LINZECAR podrá compartir sus Datos Personales, comerciales,
				económicos, financieros, contables y legales con: (i) sus
				sociedades controladoras, subsidiarias o afiliadas bajo el
				control común de LINZECAR, así como a su sociedad matriz o a
				cualquier sociedad de su grupo corporativo que opere bajo los
				mismos procesos y políticas internas, con la finalidad que
				dichos relacionados puedan ofrecer sus productos y servicios;
				(ii) con autoridades competentes, en caso de ser solicitados, en
				términos de la legislación aplicable; (iii) a sociedades o
				compañías encargadas de investigaciones de fraude y recolección
				de pagos; (iv) con otras empresas en caso de ser adquiridos,
				fusionados o cualquier otra operación similar por esa compañía;
				(v) terceros, incluidos entidades financieras, para cesión o
				descuento de cartera de crédito, endoso o cualquier otra forma
				de negociación de la cartera crediticia; (vi) socios comerciales
				con quienes se tengan celebrados contratos para la
				comercialización de productos y/o servicios en beneficio de los
				clientes / usuarios; (vii) terceros proveedores o prestadores de
				servicios con los que LINZECAR tenga obligaciones legales
				adquiridas; (viii) autoridades financieras y sociedades de
				información crediticia con la finalidad de dar cumplimiento a
				las disposiciones legales; y (ix) programadores para el
				mantenimiento y gestión de los medios electrónicos.
				<br />
				<br />
				Los terceros y las entidades receptores de datos personales
				asumen las mismas obligaciones y/o responsabilidades de
				LINZECAR, de conformidad con lo descrito en el presente Aviso de
				Privacidad.
				<br />
				<br />
				<b>6. DERECHOS DEL TITULAR DE DATOS (DERECHOS ARCO).</b>
				<br />
				El titular de los Datos Personales o su representante legal
				tiene derecho a (i) acceder a sus datos personales en posesión
				de LINZECAR y conocer los detalles del tratamiento de los
				mismos; (ii) rectificarlos en caso de estar desactualizados, ser
				inexactos o estar incompletos; (iii) cancelarlos cuando
				considere que no están siendo utilizados conforme a los
				principios, deberes y obligaciones aplicables; y (iv) oponerse
				al tratamiento de los mismos para fines específicos. El
				ejercicio de los Derechos ARCO debe hacerse por escrito, la
				solicitud deberá ser presentada en nuestro domicilio dirigido al
				encargado del área de Servicio de Atención del Usuario (subárea
				de protección de datos personales) identificado en este Aviso de
				Privacidad, acompañada de la siguiente información y
				documentación: (i) datos de identificación y copia de la
				identificación del titular de los datos personales y en su caso,
				de su representante legal; (ii) la descripción de manera clara y
				precisa de los datos personales respecto de los cuales busca
				ejercer los Derechos ARCO, así como el derecho o derechos que
				desea ejercer; y (iii) domicilio para oír y recibir la
				contestación de LINZECAR, y, en su caso, futuras comunicaciones
				y/o notificaciones, o bien su deseo de que nuestra contestación
				y/o futuras notificaciones o contestaciones sean enviadas a
				través de correo electrónico, indicando la respectiva cuenta. La
				respuesta a las solicitudes que cumplan con los requisitos antes
				mencionados podrá ser notificada al titular o su representante
				legal vía correo electrónico en un plazo no mayor a 20 (veinte)
				días hábiles contados a partir de su recepción.
				<br />
				<br />
				<b>7. INFORMACIÓN REGISTRADA.</b>
				<br />
				Excluyendo la dirección IP, nuestro sitio no almacena datos
				personales mediante las cookies, exceptuando se genere un
				registro de usuarios en el sitio web o Plataforma, para acceder
				a nuevo contenido, notificaciones e información sobre nuevos
				productos y servicios. La dirección IP sólo está disponible para
				las plataformas de analítica y medios, cuya información no está
				disponible para las personas que manejan los datos y no puede
				ser descargada.
			</p>
			<h2>V. COOKIES.</h2>
			<p>
				En la plataforma LINZECAR, sea linzecar.cl y la Aplicación móvil
				LINZECAR utilizamos cookies propias y de terceros con el
				objetivo mejorar nuestro servicio y proporcionarte una mejor
				experiencia en tu navegación. En este documento te contaremos de
				forma clara y precisa qué son las cookies y cuál es su función.
				Siempre puedes deshabilitar su funcionamiento o cambiar su
				configuración. Las cookies son un archivo que contiene pequeñas
				cantidades de datos sobre la conducta de navegación que se
				envían desde un sitio web que se visita al dispositivo que se
				está usando para navegar, ya sea un computador, un smartphone o
				una tablet. Las cookies se utilizan para entregar experiencias y
				contenidos personalizados y de mejor calidad, según tus
				intereses dentro y fuera de nuestro sitio.
				<br />
				<br />
				<b>a. Cookies propias:</b>
				&nbsp; Son aquellas que se envían a tu equipo desde nuestros
				propios servidores, equipos o dominios, desde donde prestamos el
				servicio o disponemos la información que nos solicitas.
				<br />
				<br />
				<b>b. Cookies de terceros:</b>
				&nbsp; Son aquellas que se envían a tu equipo desde un equipo o
				dominio que no es gestionado por nosotros, sino por otra entidad
				colaboradora. Como, por ejemplo, las usadas por redes sociales,
				o por contenido externo como Google Maps, Google Analytics.
				<br />
				<br />
				<b>c. Cookies de sesión:</b>
				&nbsp; Son cookies temporales que permanecen en el equipo del
				usuario hasta que abandona la página o el sitio web, por lo que
				no queda registrada en el disco duro del ordenador
				permanentemente. La información obtenida por medio de estas
				cookies, sirven para analizar pautas de tráfico en la web, lo
				que permite proporcionar una mejor experiencia para mejorar el
				contenido y facilitar su uso.
				<br />
				<br />
				<b>d. Cookies persistentes:</b>
				&nbsp; Son almacenadas en el disco duro y nuestra web las lee
				cada vez que realizas una nueva visita. Una cookie permanente
				posee una fecha de expiración determinada, la cookie dejará de
				funcionar después de esa fecha. o al momento de borrar cookies
				del navegador. Estas cookies las utilizamos, generalmente, para
				facilitar los servicios de compra y registro.
				<br />
				<br />
				<b>e. Cookies técnicas:</b>
				&nbsp; Son aquellas necesarias para la navegación y el buen
				funcionamiento de nuestro sitio web. Permiten, por ejemplo,
				controlar el tráfico y la comunicación de datos, acceder a
				partes de acceso restringido, realizar el proceso de compra de
				un pedido, utilizar elementos de seguridad, almacenar contenidos
				para poder difundir vídeos o compartir contenidos a través de
				redes sociales.
				<br />
				<br />
				<b>f. Cookies de personalización:</b>
				&nbsp; Son aquéllas que permiten acceder al servicio con
				características predefinidas, en función de una serie de
				criterios, como por ejemplo el idioma, el tipo de navegador a
				través del cual se accede al servicio, configuración regional
				desde donde se accede al servicio, etc.
				<br />
				<br />
				<b>g. Cookies de análisis:</b>
				&nbsp; Son aquéllas que permiten cuantificar el número de
				usuarios y así realizar la medición y análisis estadístico de la
				utilización que hacen los usuarios de los servicios prestados.
				Para ello se analiza su navegación en nuestra página web con el
				fin de mejorar la oferta de productos y servicios que se
				ofrecen.
				<br />
				<br />
				<b>h. Cookies publicitarias:</b>
				&nbsp; Son aquéllas que permiten la gestión, de la forma más
				eficaz posible, de los espacios publicitarios que se pudieran
				incluir en nuestra página o sitio web. Cookies de publicidad
				comportamental: Estas cookies almacenan información del
				comportamiento de los usuarios obtenida a través de la
				observación continuada. Gracias a ellas, se puede conocer los
				hábitos de navegación que tienes en internet y mostrar
				publicidad relacionada con tu perfil de navegación.
			</p>
			<h2>VI. DECLARACIÓN.</h2>
			<p>
				En este acto y por el presente, el Usuario reconoce haber leído
				por vía electrónica, y comprendido el contenido íntegro del
				presente Aviso de Privacidad, y que al presionar el botón de
				“Registrarme” en el Sitio, acepta expresa, inequívoca e
				irrevocablemente las estipulaciones del mismo. El documento
				electrónico de Aviso de Privacidad será archivado en la base de
				datos de LINZECAR y será accesible al Usuario en su Perfil de
				Usuario.
			</p>
			<h2>VII. CONSENTIMIENTO.</h2>
			<p>
				Se entenderá que al utilizar nuestros Medios Electrónicos y/o
				contratar los Servicios que ofrecemos para las finalidades aquí
				previstas, expresamente reconoce y acepta el presente Aviso de
				Privacidad, por lo que dicho consentimiento nos otorga la
				facultad para proceder al tratamiento de los Datos Personales en
				la forma en que se señala en el presente Aviso de Privacidad.
			</p>
			<h2>VIII. MODIFICACIONES. </h2>
			<p>
				El presente aviso de privacidad puede sufrir modificaciones,
				cambios o actualizaciones derivadas de nuevos requerimientos
				legales; de necesidades propias de LINZECAR, por los productos o
				servicios que ofrece; de nuestras prácticas de privacidad; de
				cambios en nuestro modelo de negocio, o por otras causas.
				Publicaremos los cambios en la Plataforma y le avisaremos por
				correo electrónico o Sitio Web. En caso que se apliquen
				modificaciones que alteren la información que se recopila, el
				tratamiento que se le dará o el propósito de su tratamiento,
				Usted deberá aceptar las modificaciones realizadas, para poder
				continuar utilizando la Plataforma. En caso de que los nuevos
				Términos y Condiciones no sean aceptados dentro de un período
				razonable LINZECAR procederá a eliminar su cuenta y la totalidad
				de los datos recopilados sobre su persona, conforme a esta
				Política.
			</p>
			<h2>IX. COMPETENCIA. </h2>
			<p>
				La legislación aplicable será la de la República de Chile.
				<p>
					La ilegalidad, invalidación y/o nulidad de alguna o algunas
					de las cláusulas contenidas en estas Políticas Generales no
					invalidará o anulará las demás.
				</p>
				Santiago, 01 noviembre 2022.
			</p>
		</div>
	);
};

export default EntryPoint;