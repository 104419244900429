import * as React from 'react';
import { Skeleton } from '@mui/material';
import styles from "./offerSkeleton.module.scss";

const OfferSkeleton = () => {
    return (
        <>
            {[1,2].map((_: any) => (
                <div className={styles.offerContainer}>
                    <div className={styles.offerRow}>
                        <Skeleton animation="wave" height={24} width={100} variant="text"/>
                        <Skeleton animation="wave" height={24} width={100} variant="text"/>
                    </div>
                    <Skeleton animation="wave" width={100} height={28} variant="text"/>
                </div>
            ))}
        </>
    );
}

export default OfferSkeleton;