export const offersStatus = [
    {
        status: "pending",
        translate: "Pendiente"
    },
    {
        status: "accepted",
        translate: "Aceptada"
    },
    {
        status: "cancelled",
        translate: "Cancelada"
    },
    {
        status: "rejected",
        translate: "Rechazada"
    }
];

export const MODAL_SELL_OFFERS_TEXTS: any = {
    rejected: {
        offer: {
            title: "¡Ups!",
            subTitle: "Acabas de rechazar una oferta de compra por el monto de",
            description: "No te preocupes, si el comprador aún está interesado por tu vehículo realizará una mejor oferta"
        },
        counteroffer: {
            title: "¡Ups!",
            subTitle: "Acabas de rechazar una contraoferta de compra por el monto de",
            description: "No te preocupes, si el comprador aún está interesado por tu vehículo realizará una mejor oferta"
        }
    },
    accepted: {
        title: "¡Felicitaciones!",
        subTitle: "Aceptaste vender tu vehículo por el monto de",
        description: "El comprador pronto asignará las fechas para que puedas finalizar la entrega"
    }
}

export const MODAL_BUY_OFFERS_TEXTS: any = {
    rejected: {
        title: "¡Ups!",
        subTitle: "Acabas de rechazar una oferta de compra por el monto de",
        description: ""
    },
    accepted: {
        title: "¡Felicitaciones!",
        subTitle: "Aceptaste comprar el vehículo por el monto de",
        description: ""    
    }
}

export const MODAL_SELL_TEXTS: any = {
    rejected: {
        title: "¡Ups!",
        description: "Ha ocurrido un error al publicar por favor reintente nuevamente",
        button: "Aceptar"
    },
    accepted: {
        title: "¡Felicitaciones!",
        description: "Tu auto ya se encuentra publicado. Podrás editar los datos de tu publicación desde notificaciones",
        button: "Finalizar" 
    },
    pending: {
        title: "¡Felicitaciones!",
        description: "Tu auto se encuentra en proceso de revisión. Te avisaremos cuando esté publicado.",
        button: "Finalizar" 
    }
}
