import { createSlice } from '@reduxjs/toolkit';
import { confortItems } from '../constants/confort';
import { initialStateProps } from '../interfaces/salePost';

const initialState: initialStateProps = {
    imagesVideos: [],
    countPostImagesVideos: 0,
    brands: {
        fetching: "unknown",
        data: [],
        selected: null
    },
    years: {
        fetching: "unknown",
        data: [],
        selected: null
    },
    models: {
        fetching: "unknown",
        data: [],
        selected: null
    },
    version: { 
        id: null, 
        name: null 
    },
    confort: confortItems,
    color: 'blanco',
    kms: '0',
    plate: '',
    price: '0',
    record: {
        accidentsReported: null,
        goodMaintenance: null,
        personalUse: null,
        soleOwner: null
    },
    doors: 5,
    financingBonus: false,
    negotiatePrice: true,
    vehiclePaid: true,
    imagesSent: [],
    fetchingUpload: false,
    errorUpload: false,
    country: null,
    region: null,
    fetchingPlateInfo: 'unknown',
    isFoundPlate: false,
    fuel: 'bencina',
    fetchingVersions: "unknown",
    versions: [],
    request_id: null,
    suggestedPrices: {
        retake: "0",
        publication: "0",
        sale: "0"
    },
    fetchingSuggedtedPrices: "unknown",
    aditionalFuel: '',
    cylinder_capacity: '',
    model_image: '',
    traction: 0,
    transmission: 'Mecánica',
    vehicle_type: 'Sedan',
    vin: '',
    tags: [],
    fetchingPlateRepeated: 'unknown',
    plateRepeatedStatus: 'unknown',
    additionalCost: null,
    fetchingAdditionalCost: "unknown",
    fetchingDeleteImage: "unknown",
    fetchingPostImages: "unknown",
    fetchingPostPublication: "unknown",
    descriptionGPT: {
        fetching: "unknown",
        data: [],
        error: ""
    },
    publicationPublishedStatus: "",
    userDescription: ''
};

const salePostSlice = createSlice({
    name: 'salePost',
    initialState,
    reducers: {
        setPublicationImagesReducer: (state, action) => {
            state.imagesVideos = action.payload;
        },
        setPublicationBrandReducer: (state, action) => {
            state.brands.selected = action.payload;
        },
        setPublicationYearReducer: (state, action) => {
            state.years.selected = action.payload;
        },
        setPublicationModelReducer: (state, action) => {
            state.models.selected = action.payload;
        },
        setPublicationVersionReducer: (state, action) => {
            state.version = {
                id: action.payload.id,
                name: action.payload.label
            }
        },
        setPublicationTagsReeducer: (state, action) => {
            state.tags = action.payload;
        },
        setPublicationConfortReducer: (state, action) => {
            state.confort = action.payload;
        },
        setPublicationColorReducer: (state, action) => {
            state.color = action.payload;
        },
        setPublicationKmsReducer: (state, action) => {
            state.kms = action.payload;
        },
        setPublicationPlateReducer: (state, action) => {
            state.plate = action.payload;
        },
        setPublicationPriceReducer: (state, action) => {
            state.price = action.payload;
        },
        setPublicationRecordReducer: (state, action) => {
            state.record.accidentsReported = action.payload.accidentsReported;
            state.record.personalUse = action.payload.personalUse;
            state.record.soleOwner = action.payload.soleOwner;
        },
        setPublicationDoorsReducer: (state, action) => {
            state.doors = action.payload;
        },
        setPublicationIsFinancingBonusReducer: (state, action) => {
            state.financingBonus = action.payload;
        },
        setPublicationIsNegotiatePriceReducer: (state, action) => {
            state.negotiatePrice = action.payload;
        },
        setPublicationIsPaidReducer: (state, action) => {
            state.vehiclePaid = action.payload;
        },
        setPublicationCountryReducer: (state, action) => {
            state.country = action.payload;
        },
        setPublicationRegionReducer: (state, action) => {
            state.region = action.payload;
        },
        setPublicationTypeReducer: (state, action) => {
            state.vehicle_type = action.payload;
        },
        setPublicationTransmissionReducer: (state, action) => {
            state.transmission = action.payload;
        },
        getPlateInfoRequest: (state) => {
            state.fetchingPlateInfo = "loading";
            state.isFoundPlate = false;
        },
        getPlateInfoSuccess: (state, action) => {
            state.fetchingPlateInfo = "success";
            state.isFoundPlate = true;
            state.brands.selected = {
                id: action.payload.brand_id,
                label: action.payload.brand_name
            };
            state.years.selected = action.payload.year;
            state.models.selected = {
                id: action.payload.model_id,
                label: action.payload.model_name,
            };
            state.version = {
                id: action.payload.version_id,
                name: action.payload.version_name
            };
            state.color = action.payload.color || state.color;
            state.request_id = action.payload.request_id;
            state.cylinder_capacity = action.payload.cylinder_capacity;
            state.model_image = action.payload.model_url;
            state.traction = typeof action.payload.traction === 'number' ? action.payload.traction : state.traction;
            state.fuel = (action.payload.fuelTypeName ?? state.fuel).toLowerCase();
            state.transmission = action.payload.transmissionName ?? state.transmission;
            state.vehicle_type = action.payload.vehicle_type ?? state.vehicle_type;
            state.vin = action.payload.vin;
        },
        getPlateInfoFailure: (state) => {
            state.fetchingPlateInfo = "failure";
            state.isFoundPlate = false;
            state.price = initialState.price;
        },
        getBrandsRequest: (state) => {
            state.brands.fetching = "loading";
        },
        getBrandsSuccess: (state, action) => {
            state.brands.fetching = "success";
            state.brands.data = action.payload;
        },
        getBrandsFailure: (state) => {
            state.brands.fetching = "failure";
        },
        cleanGetBrands: (state) => {
            state.brands.fetching = initialState.brands.fetching;
        },
        getYearsRequest: (state) => {
            state.years.fetching = "loading";
        },
        getYearsSuccess: (state, action) => {
            state.years.fetching = "success";
            state.years.data = action.payload;
        },
        getYearsFailure: (state) => {
            state.years.fetching = "failure";
        },
        cleanGetYears: (state) => {
            state.years.fetching = initialState.years.fetching;
        },
        getModelsRequest: (state) => {
            state.models.fetching = "loading";
        },
        getModelsSuccess: (state, action) => {
            state.models.fetching = "success";
            state.models.data = action.payload;
        },
        getModelsFailure: (state) => {
            state.models.fetching = "failure";
        },
        cleanGetModels: (state) => {
            state.models.fetching = initialState.models.fetching;
        },
        getVersionRequest: (state) => {
            state.fetchingVersions = "loading";
        },
        getVersionSuccess: (state, action) => {
            state.fetchingVersions = "success";
            state.versions = action.payload;
        },
        getVersionFailure: (state) => {
            state.fetchingVersions = "failure";
        },
        getSuggestedPriceRequest: (state) => {
            state.fetchingSuggedtedPrices = "loading";
        },
        getSuggestedPriceSuccess: (state, action) => {
            state.fetchingSuggedtedPrices = "success";
            state.suggestedPrices.retake = action.payload.retake;
            state.suggestedPrices.publication = action.payload.publication;
            state.suggestedPrices.sale = action.payload.sale === "0" ? state.price : action.payload.sale;
        },
        getSuggestedPriceFailure: (state) => {
            state.fetchingSuggedtedPrices = "failure";
        },
        setSuggestedPrices: (state, action) => {
            state.suggestedPrices.retake = action.payload.retake;
            state.suggestedPrices.publication = action.payload.publication;
            state.suggestedPrices.sale = action.payload.sale === "0" ? state.price : action.payload.sale;
        },
        postPublicationsImagesRequest: (state) => {
            state.fetchingPostImages = "loading";
        },
        postPublicationsImagesSuccess: (state, action) => {
            state.fetchingPostImages = "success";
            state.imagesVideos = action.payload;
            state.countPostImagesVideos = state.countPostImagesVideos + 1
        },
        postPublicationsImagesFailure: (state, action) => {
            state.fetchingPostImages = "failure";
            state.imagesVideos = action.payload;
        },
        deletePublicationsImageRequest: (state) => {
            state.fetchingDeleteImage = "loading";
        },
        deletePublicationsImageSuccess: (state, action) => {
            state.fetchingDeleteImage = "success";
            state.imagesVideos = state.imagesVideos.filter((image: any) => image.id !== action.payload);
        },
        deletePublicationsImageFailure: (state) => {
            state.fetchingDeleteImage = "failure";
        },
        cleanDeleteImage: (state) => {
            state.fetchingDeleteImage = initialState.fetchingDeleteImage;
        },
        setFuelReducer: (state, action) => {
            state.fuel = action.payload;
        },
        setAditionalFuelReducer: (state, action) => {
            state.aditionalFuel = action.payload;
        },
        getIsPlateRepeatedRequest: (state, action) => {
            state.fetchingPlateRepeated = "loading";
            state.plate = action.payload;
        },
        getIsPlateRepeatedSuccess: (state) => {
            state.fetchingPlateRepeated = "success";
            state.plateRepeatedStatus = "isNotRepeated";
        },
        getIsPlateRepeatedFailure: (state) => {
            state.fetchingPlateRepeated = "failure";
            state.plateRepeatedStatus = "isRepeated";
        },
        cleanPlateRepeated: (state) => {
            state.fetchingPlateRepeated = initialState.fetchingPlateRepeated;
        },
        getAdditionalExpensesSalePostRequest: (state) => {
            state.fetchingAdditionalCost = "loading";
        },
        getAdditionalExpensesSalePostSuccess: (state, action) => {
            state.fetchingAdditionalCost = "success";
            state.additionalCost = action.payload;
        },
        getAdditionalExpensesSalePostFailure: (state) => {
            state.fetchingAdditionalCost = "failure";
        },
        getVersionsRequest: (state) => {
            state.fetchingVersions = "loading";
        },
        getVersionsSuccess: (state, action) => {
            state.fetchingVersions = "success";
            state.versions = action.payload;
        },
        getVersionsFailure: (state) => {
            state.fetchingVersions = "loading";
        },
        postPublicationRequest: (state) => {
            state.fetchingPostPublication = "loading";
        },
        postPublicationSuccess: (state, action) => {
            state.fetchingPostPublication = "success";
            state.publicationPublishedStatus = action.payload.status;
        },
        postPublicationFailure: (state) => {
            state.fetchingPostPublication = "failure";
        },
        clearPublicationPostFetching: (state) => {
            state.fetchingPostPublication = initialState.fetchingPostPublication;
        },
        getDescriptionGPTRequest: (state) => {
            state.descriptionGPT.fetching = "loading";
        },
        getDescriptionGPTSuccess: (state, action) => {
            state.descriptionGPT.fetching = "success";
            state.descriptionGPT.data = action.payload;
        },
        getDescriptionGPTFailure: (state, action) => {
            state.descriptionGPT.fetching = "failure";
            state.descriptionGPT.error = action.payload;
        },
        cleanDescriptionGPT: (state) => {
            state.descriptionGPT = initialState.descriptionGPT;
        },
        clearSalePostReducer: () => initialState,
        initialFlow: (state) => {
            state.brands = initialState.brands;
            state.years = initialState.years;
            state.models = initialState.models;
            state.tags = initialState.tags;
            state.color = initialState.color;
            state.version = initialState.version;
            state.transmission = initialState.transmission;
            state.vehicle_type = initialState.vehicle_type;
            state.imagesVideos = initialState.imagesVideos;
            state.suggestedPrices = initialState.suggestedPrices;
            state.record = initialState.record;
            state.doors = initialState.doors;
            state.financingBonus = initialState.financingBonus;
            state.negotiatePrice = initialState.negotiatePrice;
            state.vehiclePaid = initialState.vehiclePaid;
            state.price = initialState.price;
            state.kms = initialState.kms;
            state.vin = initialState.vin;
            state.traction = initialState.traction;
            state.region = initialState.region;
            state.country = initialState.country;
            state.additionalCost = initialState.additionalCost;
            state.aditionalFuel = initialState.aditionalFuel;
            state.fuel = initialState.fuel;
            state.descriptionGPT = initialState.descriptionGPT;
            state.cylinder_capacity = initialState.cylinder_capacity;
            state.fetchingPostPublication = initialState.fetchingPostPublication;
            state.userDescription = initialState.userDescription;
        },
        setDescriptionReducer: (state, action) => {
            state.userDescription = action.payload;
        }
    }
});

export const {
    initialFlow,
    setPublicationImagesReducer,
    setPublicationBrandReducer,
    setPublicationYearReducer,
    setPublicationModelReducer,
    setPublicationVersionReducer,
    setPublicationTagsReeducer,
    setPublicationConfortReducer,
    setPublicationColorReducer,
    setPublicationKmsReducer,
    setPublicationPlateReducer,
    setPublicationPriceReducer,
    setPublicationRecordReducer,
    setPublicationDoorsReducer,
    setPublicationIsFinancingBonusReducer,
    setPublicationIsNegotiatePriceReducer,
    setPublicationIsPaidReducer,
    setPublicationCountryReducer,
    setPublicationRegionReducer,
    setFuelReducer,
    setAditionalFuelReducer,
    getPlateInfoRequest,
    getPlateInfoSuccess,
    getPlateInfoFailure,
    getBrandsRequest,
    getBrandsSuccess,
    getBrandsFailure,
    cleanGetBrands,
    getYearsRequest,
    getYearsSuccess,
    getYearsFailure,
    cleanGetYears,
    getModelsRequest,
    getModelsSuccess,
    getModelsFailure,
    cleanGetModels,
    getVersionRequest,
    getVersionSuccess,
    getVersionFailure,
    getSuggestedPriceRequest,
    getSuggestedPriceSuccess,
    getSuggestedPriceFailure,
    getIsPlateRepeatedRequest,
    getIsPlateRepeatedSuccess,
    getIsPlateRepeatedFailure,
    getAdditionalExpensesSalePostRequest,
    getAdditionalExpensesSalePostSuccess,
    getAdditionalExpensesSalePostFailure,
    clearSalePostReducer,
    postPublicationsImagesRequest,
    postPublicationsImagesSuccess,
    postPublicationsImagesFailure,
    deletePublicationsImageRequest,
    deletePublicationsImageSuccess,
    deletePublicationsImageFailure,
    cleanDeleteImage,
    getVersionsRequest,
    getVersionsSuccess,
    getVersionsFailure,
    postPublicationRequest,
    postPublicationSuccess,
    postPublicationFailure,
    clearPublicationPostFetching,
    cleanPlateRepeated,
    getDescriptionGPTRequest,
    getDescriptionGPTSuccess,
    getDescriptionGPTFailure,
    cleanDescriptionGPT,
    setSuggestedPrices,
    setPublicationTypeReducer,
    setPublicationTransmissionReducer,
    setDescriptionReducer
} = salePostSlice.actions;

export default salePostSlice.reducer;