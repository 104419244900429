import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { RecordsContainerProps } from "../../interfaces/containers";
import Records from "../../pages/Sell/Records";
import { getSuggestedPrice, setKms, setRecord } from "../../actions/salePost";

function mapStateToProps(state: RecordsContainerProps) {
    return{
        kms: state.salePost.kms,
        request_id: state.salePost.request_id,
        brands: state.salePost.brands,
        models: state.salePost.models,
        year: state.salePost.years.selected,
        version: state.salePost.version,
        plate: state.salePost.plate,
        isFoundPlate: state.salePost.isFoundPlate,
        accidentsReported: state.salePost.record.accidentsReported,
        personalUse: state.salePost.record.personalUse,
        soleOwner: state.salePost.record.soleOwner
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        setRecord,
        setKms,
        getSuggestedPrice
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Records);