import styles from "./timeLineSkeleton.module.scss";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { Skeleton } from '@mui/material';

const TimeLineSkeleton = () => {
    return (
        <div className={styles.timeLineSkeleton}>
            {
                [1, 2, 3].map((_: any, index: number) => {
                    const lastItem: boolean = index === 2;
                    return <TimelineItem>
                        <TimelineOppositeContent className={styles.opposite} />
                        <TimelineSeparator>
                            <TimelineDot className={styles.dot} />
                            {
                                !lastItem &&
                                <TimelineConnector className={styles.connector} />
                            }
                        </TimelineSeparator>
                        <TimelineContent>
                            <Skeleton animation="wave" width={80} variant="text"/>
                            <Skeleton animation="wave" width={80} variant="text"/>
                        </TimelineContent>
                    </TimelineItem>
                })
            }
        </div>
    );
}

export default TimeLineSkeleton;