import React from "react";
import { RouteObject } from "../../interfaces/navigation";

const EntryPoint = React.lazy(() => import("./EntryPoint"));

export const downloadRoutes: RouteObject[] = [
    {
        title: "Download",
        path: "/download",
        component: <EntryPoint />
    }
];