import { Dispatch } from 'redux';
import { api } from "../config/api";
import { 
    getBanksRequest, 
    getBanksFailure, 
    getBanksSuccess, 
    cleanBanks as cleanBanksReducer
} from '../reducers/banks';
import { axiosClient } from '../config/axios';

export const getBanks = () => (dispatch: Dispatch) => _getBanks(dispatch);
const _getBanks = async ( dispatch: Dispatch ) => {
    try {
        dispatch({type: getBanksRequest});

        const response = await axiosClient.get(`${api.transactions}/banks`);

        dispatch({type: getBanksSuccess, payload: response?.data.data.banks});
        
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse?.code);
        dispatch({type: getBanksFailure });
    }
};

export const cleanBanks = () => (dispatch: Dispatch) => _cleanBanks(dispatch);
const _cleanBanks = async ( dispatch: Dispatch ) => dispatch({ type: cleanBanksReducer });