import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { BuyProps } from "../../../interfaces/containers";
import Financing from "../../../pages/Buy/Financing/Financing";
import { setStep, setFinancingRequest, getCreditSimulation, cleanCreditSimulation } from "../../../actions/buy";

function mapStateToProps(state: BuyProps) {
    return{
        user: state.auth.user,
        step: state.buy.step,
        method: state.buy.method,
        publication: state.buy.publication,
        creditSimulation: state.buy.creditSimulation,
        financingReducer: state.buy.financing
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        setStep,
        setFinancingRequest,
        getCreditSimulation,
        cleanCreditSimulation
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Financing);