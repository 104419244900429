import React from "react";
import { RouteObject } from "../../interfaces/navigation";
import Buy from "./Buy";
import Sell from "./Sell";
import Financing from "./Financing";
import FrequentQuestions from "./FrequentQuestions";
import Pictures from "./Pictures";

export const frequentQuestionSubRoutes: RouteObject[] = [
    {
        title: "Comprar",
        path: "buy",
        component: <Buy />
    },
    {
        title: "Vender",
        path: "sell",
        component: <Sell />
    },
    {
        title: "Financiamiento",
        path: "financing",
        component: <Financing />
    },
    {
        title: "Imágenes",
        path: "pictures",
        component: <Pictures />
    },
];

export const frequentQuestionRoutes: RouteObject[] = [
    {
        title: "Frequent Questions",
        path: "/frequent_questions",
        component: <FrequentQuestions/>,
        subRoutes: frequentQuestionSubRoutes
    }
];