import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { MyBuysManagementContainerProps } from "../../../../../interfaces/containers";
import OffersModal from "../../../../../pages/Profile/MyBuys/Management/OffersModal";
import { 
    getCounterOffers, 
    cleanGetCounterOffers, 
    postCounterOffers, 
    cleanPostCounterOffers, 
    putCounterOffers, 
    cleanPutCounterOffers,
    putOffersPublication,
    cleanOfferPut,
    getOffersPublication,
    getPublication,
    cleanPublication
} from "../../../../../actions/publication";

function mapStateToProps(state: MyBuysManagementContainerProps) {
    return{
        publicationReducer: state.publication,
        offerReducer: state.publication.offer,
        counterOffers: state.publication.counterOffers
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        getPublication,
        cleanPublication,
        getCounterOffers,
        cleanGetCounterOffers,
        postCounterOffers, 
        cleanPostCounterOffers, 
        putCounterOffers, 
        cleanPutCounterOffers,
        putOffersPublication,
        cleanOfferPut,
        getOffersPublication
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(OffersModal);