import { useEffect } from "react";
import styles from "./views.module.scss";
import { useToast } from "../../../../../helpers/hooks";
import { images } from "../../../../../constants/images";
import { ViewsManagementProps } from "../../../../../interfaces/components";

const Views = ({
    id,
    getViews,
    views,
    cleanGetViews,
    className
}: ViewsManagementProps) => {
    const toast = useToast();

    useEffect(() => {
        if(views?.fetching === "failure"){
            toast("Ha ocurrido un error mostrar la vistas");
            cleanGetViews();
        }
        if(views?.fetching === "success"){
            cleanGetViews();
        }
    }, [views?.fetching]);

    useEffect(() => {
        if(id){
            /* getViews(id); */
        }
    }, []);

    return(
        <div className={`${styles.views} ${className}`}>
            <div className={styles.content}>
                <div className={styles.cardLeft}>
                    <p className={`${styles.text} ${styles.title}`}>Vistas</p>
                    <p className={`${styles.text} ${styles.value}`}>{views.data ?? 0}</p>
                    <p className={`${styles.text} ${styles.period}`}>Últimos 30 días</p>
                </div>
                <img src={images.purpleVistas} className={styles.cardImg} />
            </div>
        </div>
    )
};

export default Views;