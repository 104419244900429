import React from "react";
import { RouteObject } from "../../interfaces/navigation";
import Pictures from "../../containers/Sell/PicturesContainer";
import SearchContainer from "../../containers/Sell/SearchContainer";
import CarSearchInformation from "../../containers/Sell/CarSearchInformationContainer";
import Records from "../../containers/Sell/RecordsContainer";
import CarDetail from "../../containers/Sell/CarDetailContainer";
import Confort from "../../containers/Sell/ConfortContainer";
import Tags from "../../containers/Sell/TagsContainer";
import ValueAndPayment from "../../containers/Sell/ValueAndPaymentContainer";
import Location from "../../containers/Sell/LocationContainer";
import Brand from "../../containers/Sell/BrandContainer";
import Year from "../../containers/Sell/YearContainer";
import Model from "../../containers/Sell/ModelContainer";
import ScheduleInspection from "../../containers/Sell/ScheduleInspectionContainer";
import Detail from "../../containers/Sell/DetailContainer";
import Description from "../../containers/Sell/DescriptionContainer";

const EntryPoint = React.lazy(() => import("./EntryPoint"));

const sellSubRoutes: RouteObject[] = [
    {
        title: "Sell",
        path: "search",
        component: <SearchContainer/>
    },
    {
        title: "Location",
        path: "location",
        component: <Location/>
    },
    {
        title: "Brand",
        path: "brand",
        component: <Brand/>
    },
    {
        title: "Year",
        path: "year",
        component: <Year/>
    },
    {
        title: "Model",
        path: "model",
        component: <Model/>
    },
    {
        title: "car Detail",
        path: "car_detail",
        component: <CarDetail/>
    },
    {
        title: "records",
        path: "records",
        component: <Records/>
    },
    {
        title: "Confort",
        path: "confort",
        component: <Confort/>
    },
    {
        title: "Tags",
        path: "tags",
        component: <Tags/>
    },
    {
        title: "Pictures",
        path: "pictures",
        component: <Pictures/>
    },
    {
        title: "ValueAndPayment",
        path: "value_and_payment",
        component: <ValueAndPayment/>
    },
    {
        title: "CarSearchInformation",
        path: "car_search_information",
        component: <CarSearchInformation/>
    },
    {
        title: "Schedule inspection",
        path: ":id/schedule_inspection",
        component: <ScheduleInspection/>
    },
    {
        title: "Detail",
        path: "detail",
        component: <Detail/>
    },
    {
        title: 'Description',
        path: 'description',
        component: <Description/>
    }
]

export const sellRoutes: RouteObject[] = [
    {
        title: "Sell",
        path: "/sell",
        component: <EntryPoint/>,
        subRoutes: sellSubRoutes
    }
];