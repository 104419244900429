import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { MyPublicationManagementContainerProps } from "../../../../interfaces/containers";
import Management from "../../../../pages/Profile/MyPublications/Management";
import { 
    getPublication, 
    getArticlesFromPublication, 
    getOffersPublication, 
    getInspections, 
    getInspectionsFiles,
    cleanInspection,
    cleanInspectionFiles,
    getHistoryPublication, 
    cleanAll, 
    cleanOfferPut,
    getRecords,
    cleanGetRecords
} from "../../../../actions/publication";
import { getSchedulingPublication, clearPublicationSchedule } from "../../../../actions/scheduling";

function mapStateToProps(state: MyPublicationManagementContainerProps) {
    return{
        offer: state.publication.offer,
        publication: state.publication.data,
        fetchingPublication: state.publication.fetching,
        inspection: state.publication.inspection,
        history: state.publication.history.data,
        fetchingHistory: state.publication.history.fetching,
        article: state.publication.articles.data,
        fetchingArticle: state.publication.articles.fetching,
        user: state.auth.user,
        schedules: state.scheduling.publication,
        records: state.publication.records
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        getPublication,
        getArticlesFromPublication,
        getOffersPublication,
        getInspections,
        getInspectionsFiles,
        cleanInspection,
        cleanInspectionFiles,
        getHistoryPublication,
        cleanAll,
        cleanOfferPut,
        getSchedulingPublication,
        clearPublicationSchedule,
        getRecords,
        cleanGetRecords
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Management);