import styles from "./tag.module.scss";
import { IconButton, Skeleton } from '@mui/material';
import { TagProps } from "../../interfaces/components";
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';

const Tag = ({
    onPress = () => {}, 
    text,
    editable = false,
    loading = false,
}: TagProps) => {

    return (
        <>
        {
            loading
            ? <Skeleton animation="wave" variant="text" className={styles.skeleton} />
            : <div className={`${styles.tagContainer} ${editable && styles.editable}`}>
                <p className={styles.text}>#{text}</p>
                {
                    editable && 
                    <IconButton aria-label="Eliminar" className={styles.close} onClick={() => onPress()}>
                        <Icon path={mdiClose} title="Eliminar" className={styles.icon} />
                    </IconButton>
                }
            </div>
        }
        </>
    )
}
export default Tag;