import { createSlice } from "@reduxjs/toolkit";
import { discountStateProps } from "../interfaces/discount";

const initialState: discountStateProps = {
    get: {
        fetching: "unknown",
        data: [],
        error: ""
    },
    put: {
        fetching: "unknown",
        data: [],
        error: ""
    }
}


const banksSlice = createSlice({
    name: 'discount',
    initialState,
    reducers: {
        getDiscountRequest: (state) => {
            state.get.fetching = "loading";
        },
        getDiscountSuccess: (state, action) => {
            state.get.fetching = "success";
            state.get.data = action.payload;
        },
        getDiscountFailure: (state) => {
            state.get.fetching = "failure";
        },
        cleanGetDiscount: (state) => {
            state.get.fetching = initialState.get.fetching;
        },
        putDiscountRequest: (state) => {
            state.put.fetching = "loading";
        },
        putDiscountSuccess: (state, action) => {
            state.put.fetching = "success";
            state.put.data = action.payload;
        },
        putDiscountFailure: (state, action) => {
            state.put.fetching = "failure";
            state.put.error = action.payload;
        },
        cleanPutDiscount: (state) => {
            state.put.fetching = initialState.put.fetching;
        }
    }
});

export const {
    getDiscountRequest,
    getDiscountSuccess,
    getDiscountFailure,
    cleanGetDiscount,
    putDiscountRequest,
    putDiscountSuccess,
    putDiscountFailure,
    cleanPutDiscount
} = banksSlice.actions;

export default banksSlice.reducer;