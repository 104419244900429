import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "./management.module.scss";
import { ManagementMyBuysProps as ManagementProps } from '../../../../interfaces/pages';
import ProfileCard from "../../../../components/ProfileCard";
import { myBuysManagementBreadCrumb } from "../../../../constants/breadCrumbs";
import moment from "moment";
import { translations } from "../../../../constants/app";
import { addPointsInText, copyToClipboard } from "../../../../helpers/app";
import OfferSkeleton from "./OfferSkeleton/OfferSkeleton";
import RightButtons from "./RightButtons/RightButtons";
import { useToast } from "../../../../helpers/hooks";
import { Divider, IconButton } from "@mui/material";
import QR from "./QR";
import Inspection from "./Inspection";
import Payments from "../../../../containers/Profile/MyBuys/Management/Payments/PaymentsContainer";
import OffersModal from "../../../../containers/Profile/MyBuys/Management/OffersModal/OffersModalContainer";
import { images } from "../../../../constants/images";
import Slider from "../../../../components/Slider";
import TimeLine from "./TimeLine";

const Management = ({
    getMyBuy,
    myBuy,
    cleanMyBuy,
    getPublication,
    publication,
    cleanPublication,
    getInspections,
    cleanInspection,
    getInspectionsFiles,
    cleanInspectionFiles,
    getOffersBuy,
    cleanOffersBuy,
    offerSelected,
    setOfferSelected,
    getHistoryPublication,
    cleanHistory,
    getPayments,
    getCreditData
}: any/* ManagementProps */) => {
    const toast = useToast();
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const timeLineRef = useRef<any>(null);
    const [openInspectionFiles, setOpenInspectionFiles] = useState<boolean>(false);
    const [offersModal, setOffersModal] = useState<any>({open: false, offer: {}});

    const historyNormalized = useMemo(() => {
        return publication?.history?.data && publication?.history?.data.map((item: any) => (
            {
                title: item.title,
                price: item.description,
                description: moment(item.created_at).format("DD MMM HH:mm")
            }
        ))
    }, [publication?.history?.data]);

    const offerItem = (item: any) => {
        let stateCode = "";
        switch(item?.status){
            case "accepted":
                stateCode = styles.green
                break;
            case "rejected":
                stateCode = styles.red
                break;
            case "cancelled":
            case "pause":
                stateCode = styles.gray
                break;
            default:
                stateCode = styles.blue
                break;
        }
        return (
            <>
                <div className={`${styles.item} ${styles.hover}`} onClick={() => setOffersModal({open: true, offer: item})}>
                    <p className={styles.info}>
                        <span className={styles.price}><strong>$ {addPointsInText(item.offer)}</strong></span>
                        <span className={styles.date}>{moment(item.created_at).format("DD MMM HH:mm")}</span>
                    </p>
                    <p className={`${styles.chip} ${stateCode}`}>
                        { translations(item.status) }
                    </p>
                </div>
                <Divider />
            </>
        );
    }

    const handleClickEye = () => {
        if(publication?.inspection?.files?.data.length === 0){
            return toast("La inspección no contiene imagenes");
        }
        setOpenInspectionFiles(true);
    }
    
    const onShare = () => {
        copyToClipboard(`https://${process.env.REACT_APP_DOMAIN}.cl/publication/${publication?.data?._id}`);
        toast("Publicación copiada al portapapeles");
    };

    useEffect(() => {
        if(publication?.history?.fetching === "failure"){
            toast("Ha ocurrido un error al mostrar el historial");
            cleanHistory();
        }
        if(publication?.history?.fetching === "success"){
            cleanHistory();
        }
    }, [publication?.history?.fetching]);

    useEffect(() => {
        if(publication?.inspection?.files?.fetching === "failure"){
            toast("Ha ocurrido un error al mostrar las fotos de la inspección");
            cleanInspectionFiles();
        }
        if(publication?.inspection?.files?.fetching === "success"){
            cleanInspectionFiles();
        }
    }, [publication?.inspection?.files?.fetching]);

    useEffect(() => {
        if(publication?.inspection?.fetching === "failure"){
            toast("Ha ocurrido un error al buscar las inspecciones");
            cleanInspection();
        }
        if(publication?.inspection?.fetching === "success"){
            if(publication?.inspection?.data && publication?.inspection?.data.length > 0){
                getInspectionsFiles(publication?.inspection?.data[0]?._id);
            }
            cleanInspection();
        }
    }, [publication?.inspection?.fetching]);

    useEffect(() => {
        if(publication?.offer?.buy?.fetching === "failure"){
            toast("Ha ocurrido un error al mostrar las ofertas");
            cleanOffersBuy();
        }
        if(publication?.offer?.buy?.fetching === "success"){
            if(offerSelected){
                const offerFound = publication?.offer?.buy?.data && publication?.offer?.buy?.data.find((item: any) => item?._id === offerSelected);
                setOffersModal({open: true, offer: offerFound});
                setOfferSelected();
            }
            cleanOffersBuy();
        }
    }, [publication?.offer?.buy?.fetching]);

    useEffect(() => {
        if(publication?.fetching === "failure"){
            toast("Ha ocurrido un error al buscar la publicación");
            cleanPublication();
            navigate("/profile/my_buys");
        }
        if(publication?.fetching === "success"){
            cleanPublication();
        }
    }, [publication?.fetching]);

    useEffect(() => {
        if(myBuy?.fetching === "failure"){
            toast("Ha ocurrido un error al mostrar la compra");
            cleanMyBuy();
        }
        if(myBuy?.fetching === "success"){
            if(myBuy?.data){
                const idPublication = myBuy?.data?.publication;
                getOffersBuy(idPublication);
                getPublication(idPublication);
                getHistoryPublication(idPublication);
            }
            cleanMyBuy();
        }
    }, [myBuy?.fetching]);

    useEffect(() => {
        window.scrollTo(0, 0);
        getMyBuy(params?.id);
        getInspections(params?.id, "buy");
        getPayments(params?.id);
        getCreditData(params?.id);
    }, [location]);

    return (
        <ProfileCard 
            className={styles.profileCardContainer} 
            breadCrumbs={myBuysManagementBreadCrumb(params?.id)}
            rightOptions={
                <RightButtons 
                    onShare={onShare} 
                />
            }
        >
            <div className={styles.managementContainer}>
                <QR id={publication?.data?._id} publication={publication?.data} className={`${styles.card} ${styles.qr}`}/>
                <div className={`${styles.card} ${styles.inspection}`}>
                    <div className={styles.header}>
                        <p className={styles.title}>Informe de inspección</p>
                        <IconButton aria-label='Ver fotos' onClick={handleClickEye}>
                            <img src={images.purpleEye} width={20}/>
                        </IconButton>
                    </div>
                    <div className={styles.content}>
                        <Inspection 
                            loading={publication?.inspection?.fetching === "loading"}
                            data={publication?.inspection?.data} 
                        />
                    </div>
                </div>
                <div className={`${styles.card} ${styles.history}`}>
                    <div className={styles.header}>
                        <p className={styles.title}>Historial</p>
                    </div>
                    <div className={styles.content}>
                        <TimeLine ref={timeLineRef} data={historyNormalized} loading={publication?.history?.fetching === "loading"}/>
                    </div>
                </div>
                <div className={`${styles.card} ${styles.offers}`}>
                    <div className={styles.header}>
                        <p className={styles.title}>Ofertas</p>
                    </div>
                    <div className={styles.content}>
                        {
                            publication?.offer?.buy?.fetching === "loading"
                            ?   <OfferSkeleton />
                            :   
                                !publication?.offer?.buy?.data.length
                                ?
                                    <div className={styles.textNoData}>No hay ofertas</div>
                                :
                                    publication?.offer?.buy?.data && publication?.offer?.buy?.data.map((offer: any) => (
                                        offerItem(offer)
                                    ))
                        }
                        {
                            publication?.offer?.buy?.fetching === "loading"
                            ?   <OfferSkeleton />
                            :   
                                !publication?.offer?.buy?.data.length
                                ?
                                    <div className={styles.textNoData}>No hay ofertas</div>
                                :
                                    publication?.offer?.buy?.data && publication?.offer?.buy?.data.map((offer: any) => (
                                        offerItem(offer)
                                    ))
                        }
                    </div>
                </div>
                <div className={`${styles.card} ${styles.payments}`}>
                    <div className={styles.header}>
                        <p className={styles.title}>Pagos</p>
                    </div>
                    <div className={styles.content}>
                        <Payments />
                    </div>
                </div>
            </div>
            <Slider
                type="preview"
                options={
                    publication?.inspection?.files?.data &&
                    publication?.inspection?.files?.data.map((item: any, index: number) => (
                        <img key={index} src={item?.url} alt="Image" />
                    ))
                }
                pagination
                open={openInspectionFiles}
                onClose={() => setOpenInspectionFiles(false)}
            />
            <OffersModal
                open={offersModal?.open}
                offer={offersModal?.offer}
                publication={publication?.data}
                onClose={() => setOffersModal({...offersModal, open: false})}
            />
        </ProfileCard>
    )
}

export default Management;