import { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./slider.module.scss";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/pagination";
import { FreeMode, Navigation, Pagination, Thumbs } from "swiper";
import { SliderProps } from "../../interfaces/components";
import { IconButton, Skeleton } from '@mui/material';
import Icon from '@mdi/react';
import { mdiChevronLeft, mdiChevronRight, mdiClose } from '@mdi/js';
import { useWindowSize } from "use-hooks";

const Slider = ({
    type = 'normal',
    options,
    className,
    sliderClassName,
    thumbsClassName,
    itemsClassName,
    addOption = "",
    spaceBetween = 10,
    perView = 5,
    perGroup = 1,
    limit = 0,
    loading = false,
    touchMove = false,
    arrows = true,
    pagination = false,
    paginationClassName,
    activeBulletClassName,
    bulletClassName,
    center = false,
    open = false,
    onClose = () => {}
}: SliderProps) => {
    const windowSize = useWindowSize();
    const [thumbsSwiper, setThumbsSwiper] = useState<any>();
    const [openPreview, setOpenPreview] = useState<boolean>(false);
    const viewerSliderRef: any = useRef(null);
    const previewSliderRef: any = useRef(null);

    const onCloseBkgr = (e: any) => {
        e.stopPropagation();
        if(e.target.id === "slider"){
            onClosePreview();
        }
    }

    const openPreviewByRef = (index: any) => {
        viewerSliderRef?.current?.swiper.slideToLoop(index);
        onOpenPreview();
    }

    const onOpenPreview = () => {
        if(windowSize.width >= 1024){
            setOpenPreview(true);
            document.body.style.overflowY = 'hidden';
        }
    }
    
    const onClosePreview = () => {
        setOpenPreview(false);
        document.body.style.overflowY = 'auto';
        if(onClose) onClose();
    }

    useEffect(() => {
        if(open){
            onOpenPreview();
        }else{
            onClosePreview()
        }
    }, [open]);

    return (
        <>
            {
                type === "thumbs"
                ?
                <>
                    <div className={`${styles.viewer} ${openPreview && styles.open}`} id="viewerContentSlider">
                        <IconButton className={styles.close} aria-label='Cerrar' onClick={onClosePreview}>
                            <Icon path={mdiClose} className={styles.icon} title="Cerrar" />
                        </IconButton>
                        <IconButton className={`${styles.arrow} ${styles.prev}`} id="swiper-button-prev" aria-label='Anterior pagina'>
                            <Icon path={mdiChevronLeft} className={styles.icon} title="Anterior" />
                        </IconButton>
                        <Swiper
                            ref={viewerSliderRef}
                            spaceBetween={10}
                            slidesPerView={1}
                            slidesPerGroup={1}
                            loop={true}
                            loopFillGroupWithBlank={true}
                            allowTouchMove={false}
                            modules={[Navigation, Pagination]}
                            className={sliderClassName}
                            navigation = {{
                                nextEl: "#swiper-button-next",
                                prevEl: "#swiper-button-prev",
                            }}
                        >
                            {
                                options && options.map((item: any, index: number) => (
                                    <SwiperSlide key={index} id="slider" className={`${styles.item}`} onClick={(e: any) => onCloseBkgr(e)}>
                                        <label>
                                            <input type="checkbox" className={styles.checkbox} id="zoomCheck" />
                                            {item}
                                        </label>
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                        <IconButton className={`${styles.arrow} ${styles.next}`} id="swiper-button-next" aria-label='Siguiente pagina'>
                            <Icon path={mdiChevronRight} className={styles.icon} title="Siguiente" />
                        </IconButton>
                    </div>
                    <div className={styles.thumbsSlider}>
                        <Swiper
                            ref={previewSliderRef}
                            thumbs={{ swiper: windowSize.width <= 1024 ? null : thumbsSwiper }}
                            modules={[FreeMode, Navigation, Thumbs, Pagination]}
                            className={`${styles.preview} ${sliderClassName}`}
                            loop={false}
                            allowTouchMove={windowSize.width < 1024 ? true : false}
                            pagination={
                                pagination 
                                ? 
                                    {
                                        type: 'bullets',
                                        clickable: true,
                                        bulletClass: styles.page,
                                        bulletActiveClass: styles.pageActive,
                                        clickableClass: `${styles.pages} ${paginationClassName}`,
                                        renderBullet: function (index, className) {
                                            return '<div class="' + className + '"></div>';
                                        },
                                    }
                                :
                                false
                            }
                        >
                            {
                                loading
                                ?   <SwiperSlide className={styles.item}>
                                        <Skeleton animation="wave" variant="rounded" className={styles.skeleton}/>
                                    </SwiperSlide>
                                :
                                    options && options.map((item: any, index: number) => (
                                        <SwiperSlide key={index} className={styles.item} onClick={() => openPreviewByRef(previewSliderRef?.current?.swiper?.activeIndex)}>{item}</SwiperSlide>
                                    ))
                            }
                        </Swiper>
                        <Swiper
                            onSwiper={setThumbsSwiper}
                            modules={[FreeMode, Navigation, Thumbs]}
                            className={`${styles.thumbs} ${thumbsClassName}`}
                            loop={false}
                            spaceBetween={32}
                            navigation={false}
                            slidesPerView={3}
                            direction="vertical"
                            watchSlidesProgress={true}
                            allowTouchMove={false}
                        >
                            {
                                loading 
                                ?   <>
                                        <SwiperSlide className={styles.item}>
                                            <Skeleton animation="wave" variant="rounded" className={styles.skeleton}/>
                                        </SwiperSlide>
                                        <SwiperSlide className={styles.item}>
                                            <Skeleton animation="wave" variant="rounded" className={styles.skeleton}/>
                                        </SwiperSlide>
                                        <SwiperSlide className={styles.item}>
                                            <Skeleton animation="wave" variant="rounded" className={styles.skeleton}/>
                                        </SwiperSlide>
                                        <SwiperSlide className={styles.item}>
                                            <Skeleton animation="wave" variant="rounded" className={styles.skeleton}/>
                                        </SwiperSlide>
                                    </>
                                :
                                    options && options.length > 4
                                    ?
                                        <>
                                            {
                                                options && options.map((item: any, index: number) => (
                                                    (index < 3) && <SwiperSlide key={index} className={styles.item}>{item}</SwiperSlide>
                                                ))
                                            }
                                            <SwiperSlide className={`${styles.item} ${styles.moreImages}`} onClick={onOpenPreview}>
                                                <div className={styles.quantity}>
                                                    <p>+ {options.length - 3}</p>
                                                    <img src={options[3]?.props?.src} alt="Image" />
                                                </div>
                                            </SwiperSlide>
                                        </>
                                    :
                                        options && options.map((item: any, index: number) => (
                                            <SwiperSlide key={index} className={styles.item}>{item}</SwiperSlide>
                                        ))
                            }
                        </Swiper>
                    </div>
                </>
                :
                type === "normal"
                ?
                <div className={`${styles.normalSlider} ${className}`}>
                    {
                        (options && options.length < limit)
                        ?   <div className={styles.noSlide}>
                                {options && options.map((item: any) => item)}
                            </div>
                        :
                        <>
                            {
                                arrows &&    
                                <IconButton className={`${styles.arrow} ${styles.prev} image-swiper-button-prev`} aria-label='Anterior pagina'>
                                    <Icon path={mdiChevronLeft} className={styles.icon} title="Anterior" />
                                </IconButton>
                            }
                            <Swiper
                                spaceBetween={spaceBetween}
                                slidesPerView={perView}
                                slidesPerGroup={perGroup}
                                loop={false}
                                loopFillGroupWithBlank={true}
                                allowTouchMove={touchMove}
                                modules={[Navigation, Pagination]}
                                className={sliderClassName}
                                centeredSlides={center}
                                navigation={{
                                    nextEl: ".image-swiper-button-next",
                                    prevEl: ".image-swiper-button-prev"
                                }}
                                pagination={pagination 
                                    ? 
                                        {
                                            type: 'bullets',
                                            clickable: true,
                                            bulletClass: `${styles.page} ${bulletClassName}`,
                                            bulletActiveClass: `${styles.pageActive} ${activeBulletClassName}`,
                                            clickableClass: `${styles.pages} ${paginationClassName}`,
                                            renderBullet: function (index, className) {
                                                return '<div class="' + className + '"></div>';
                                            },
                                        }
                                    :
                                    false
                                }
                            >
                                {
                                    addOption && 
                                    <SwiperSlide className={styles.item}>
                                        {addOption}
                                    </SwiperSlide>
                                }
                                {
                                    options && options.map((item: any, index: number) => (
                                        <SwiperSlide key={index} className={`${styles.item} ${itemsClassName}`}>{item}</SwiperSlide>
                                    ))
                                }
                            </Swiper>
                            {
                                arrows &&
                                <IconButton className={`${styles.arrow} ${styles.next} image-swiper-button-next`} aria-label='Siguiente pagina'>
                                    <Icon path={mdiChevronRight} className={styles.icon} title="Siguiente" />
                                </IconButton>
                            }
                        </>
                    }
                </div>
                :
                <div className={`${styles.viewer} ${open && styles.open}`}>
                    <IconButton className={styles.close} aria-label='Cerrar' onClick={onClosePreview}>
                        <Icon path={mdiClose} className={styles.icon} title="Cerrar" />
                    </IconButton>
                    <IconButton className={`${styles.arrow} ${styles.prev}`} id="swiper-button-prev" aria-label='Anterior pagina'>
                        <Icon path={mdiChevronLeft} className={styles.icon} title="Anterior" />
                    </IconButton>
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={1}
                        slidesPerGroup={1}
                        loop={true}
                        loopFillGroupWithBlank={true}
                        allowTouchMove={false}
                        modules={[Navigation, Pagination]}
                        className={sliderClassName}
                        navigation = {{
                            nextEl: "#swiper-button-next",
                            prevEl: "#swiper-button-prev",
                        }}
                    >
                        {
                            options && options.map((item: any, index: number) => (
                                <SwiperSlide key={index} className={styles.item}>{item}</SwiperSlide>
                            ))
                        }
                    </Swiper>
                    <IconButton className={`${styles.arrow} ${styles.next}`} id="swiper-button-next" aria-label='Siguiente pagina'>
                        <Icon path={mdiChevronRight} className={styles.icon} title="Siguiente" />
                    </IconButton>
                </div>
            }
        </>
    )
}
export default Slider;